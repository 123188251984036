import styled, { css } from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import { BodyStyle, Caption, CaptionStyle } from 'components/styles/body';
import { FlexBetween, FlexCenter, FlexStart } from 'components/styles/flex';
import {
  H0Style,
  H5Style,
  H7,
  H7aStyle,
  H8aStyle,
} from 'components/styles/title';
import { Container as Con } from 'components/styles/layout';
import Button from 'components/Button';
import { RateBox } from 'pages/Shop/style';
import { infiniteTranslateX } from 'components/styles/animate';
import Link from 'components/Link';
import { motion } from 'framer-motion';
import Globe2 from '../../../components/icons/Globe2';

export const BgBox = styled.div`
  @media ${DEVICE_QUERY_MOBILE} {
    height: auto;
    max-height: none;
  }
  position: relative;
  min-height: 750px;
  height: 1056px;
  max-height: calc(1056px + var(--height-header));
`;

export const BackImg = styled(FlexCenter)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  img {
    position: absolute;
    top: 0px;
    opacity: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    height: 536px !important;
    top: 190px;
    overflow: hidden;
    img {
      width: 100% !important;
      height: 100% !important;
      top: -28px;
      left: 0;
      object-fit: contain;
    }
  }
`;

/**
 * BackImgAdaptive is a temporary component created to handle bad cases
 * where historical images have incorrect dimensions. This component is
 * intended for compatibility purposes only and should not be used for
 * new implementations. Future updates will remove the need for this component.
 */
export const BackImgAdaptive = styled(BackImg)`
  @media ${DEVICE_QUERY_MOBILE} {
    img {
      width: 1274px !important;
      height: 510px !important;
      top: -30px;
      right: -130px;
    }
  }
`;

export const BannerBgImage = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  ${(props) =>
    props.isUseNewBannerImage
      ? css`
          background-image: url(${require('./images/banner-n.jpg')});
        `
      : css`
          background-image: url(${require('./images/banner.jpg')});
        `}
  background-size: cover;
  background-position: center;
  @media ${DEVICE_QUERY_MOBILE} {
    background-size: contain;
    ${(props) =>
    props.isUseNewBannerImage
      ? css`
            background-image: url(${require('./images/banner-m-n.jpg')});
          `
      : css`
            background-image: url(${require('./images/banner-m.jpg')});
          `}
  }
`;

export const ImgMaskTop = styled.div`
  @media ${DEVICE_QUERY_MOBILE} {
    position: absolute;
    top: 216px;
    width: 100%;
    height: 99px;
    background: linear-gradient(180deg, rgba(17, 18, 20, 0) 0%, #111214 88.02%);
    transform: rotate(180deg);
  }
`;

export const ImgMaskBottom = styled.div`
  @media ${DEVICE_QUERY_MOBILE} {
    position: absolute;
    bottom: 420px;
    width: 100%;
    height: 99px;
    background: linear-gradient(180deg, rgba(17, 18, 20, 0) 0%, #111214 88.02%);
  }
`;

export const Container = styled(Con)`
  padding-bottom: 160px;
  height: 100%;
  @media ${DEVICE_QUERY_MOBILE} {
    padding-bottom: 50px;
  }
`;

export const Main = styled.div`
  position: absolute;
  top: 255px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 20;
  @media ${DEVICE_QUERY_MOBILE} {
    position: relative;
    padding: 0 24px;
    top: 80px;
    align-items: center;
  }
`;

export const NewsTip = styled(Link)`
  display: block;
  margin-bottom: 27px;
  padding: 4px;
  border: 1px solid var(--color-border-slight);
  border-radius: 32px;
  background: var(--color-bg-muted);
  color: var(--color-fg-muted);
  .link-text {
    display: flex;
    align-items: center;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    height: 32px;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }
`;

export const NewsTipPrefix = styled(FlexCenter)`
  padding: 7px 8px;
  background: var(--color-bd-primary);
  border-radius: 100px;
  color: var(--color-fg-emphasis);
  font-size: 12px;
  line-height: 16px;
  @media ${DEVICE_QUERY_MOBILE} {
    padding: 2px 6px;
    ${H8aStyle}
  }
`;
export const NewsTipContent = styled(Caption)`
  display: flex;
  margin: 0 8px 0 12px;
  .link-arrow {
    line-height: 20px;
    color: var(--color-bd-pink);
  }
  @media ${DEVICE_QUERY_MOBILE} {
    margin: 0 18px 0 8px;
    overflow: hidden;
    span {
      animation: ${infiniteTranslateX} 10s linear infinite;
    }
  }
`;
export const NewsTipAffix = styled(FlexCenter).attrs({
  className: 'link-arrow',
})`
  right: 3px;
  transition: var(--transition);
  svg {
    color: var(--color-bd-pink);
  }
  @media ${DEVICE_QUERY_MOBILE} {
    position: absolute;
    right: 5px;
  }
`;
export const SubTitle = styled(motion.h3)`
  ${H5Style};
  background: var(--color-gd-variant);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  text-transform: uppercase;
  @media ${DEVICE_QUERY_MOBILE} {
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }
`;

export const Title = styled(motion.h1)`
  ${H0Style};
  width: 640px;
  margin: 16px 0 32px;
  color: var(--color-fg-default);
  @media ${DEVICE_QUERY_MOBILE} {
    width: 100%;
    min-height: 140px;
    margin: 20px 0 ${(props) => (props.isZh ? '400px' : '420px')} 0;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
  }
`;

export const Desc = styled(motion.h4)`
  ${BodyStyle}
  font-weight: normal;
  @media ${DEVICE_QUERY_MOBILE} {
    width: 100%;
    min-height: 120px;
    margin-bottom: 32px;
    font-size: 14px;
    text-align: center;
  }
  width: 680px;
  color: var(--color-fg-subtle);
  margin-bottom: 56px;
  em {
    color: var(--color-fg-default);
  }
`;

export const CallToActionContainer = styled.div`
  display: flex;
  gap: 56px;
  @media ${DEVICE_QUERY_MOBILE} {
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
    a {
      display: inline-block;
    }
  }
`;

export const StyledGlobe2 = styled(Globe2)`
  color: var(--color-bd-cyan);
`;

export const Features = styled(FlexBetween).attrs({ as: 'ul' })`
  width: 100%;
  margin-top: 160px;
  .feature {
    height: 56px;
    &_title {
      ${H5Style}
      text-transform: capitalize;
    }
    &_desc {
      ${BodyStyle}
    }
  }
  @media ${DEVICE_QUERY_MOBILE} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin: 65px 0 120px;
    .feature {
      img {
        margin-right: 16px;
      }
      &_title {
        ${H7aStyle}
      }
      &_desc {
        ${CaptionStyle}
      }
    }
  }
`;

export const Feature = styled(FlexStart).attrs({
  className: 'feature',
  as: 'li',
})`
  height: 48px;
  margin-right: 50px;
  @media ${DEVICE_QUERY_MOBILE} {
    width: 100%;
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const FeatureIcon = styled.img`
  @media ${DEVICE_QUERY_MOBILE} {
    margin-right: 8px;
  }
  width: 48px;
  height: 48px;
  margin-right: 16px;
`;

export const FeatureTitle = styled(H7).attrs({ className: 'feature_title' })`
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 12px;
  }
  color: var(--color-fg-muted);
  margin-bottom: 4px;
  text-transform: uppercase;
`;

export const FeatureDesc = styled(Caption).attrs({ className: 'feature_desc' })`
  font-weight: 100;
  color: rgb(255 255 255 / 50%);
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 12px;
  }
`;

export const RainbowButton = styled(Button)`
  --border-bg: linear-gradient(
    90deg,
    #ffc93d 25%,
    #11a0de 50%,
    #b439d2 75%,
    #6b38fb 100%
  ) !important;
  height: 64px;

  ::after {
    background: var(--color-bg-emphasis) !important;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    height: 48px;
  }
`;

export const AnimatedButton = styled(motion(Button))`
  height: 64px;
`;

export const AnimatedRateBox = styled(motion(RateBox))`
  margin-top: 32px;
`;

export const BannerClickBox = styled(Link)`
  position: absolute;
  right: 0;
  display: inline-block;
  width: 45%;
  height: 650px;

  @media ${DEVICE_QUERY_MOBILE} {
    top: 18%;
    left: 10%;
    width: 80%;
    height: 30%;
  }
`;

export const ButtonBox = styled(motion.div)`
  display: flex;
  gap: 0 40px;
`;
