import { find, flatten, some, toPairs } from 'lodash';
import { isDev } from 'config';

export const ProductsConfigProduct = {
  k3Pro: {
    path: '/shop/products/keystone-3-pro',
    id: 'gid://shopify/Product/7698055823513',
    variantId: 'gid://shopify/ProductVariant/44416761102489',
    variants: [
      {
        name: 'metamask',
        id: 'gid://shopify/Product/7702045327513',
      },
      {
        sku: 'KV032',
        id: 'gid://shopify/ProductVariant/44416761102489',
      },
      {
        sku: 'KV032-SD',
        id: 'gid://shopify/ProductVariant/44416761397401',
      },
      {
        sku: 'KV032-P02',
        id: 'gid://shopify/ProductVariant/44440627511449',
      },
    ],
  },
  k3ProLimitedEdition: {
    path: '/shop/products/keystone-3-pro-limited',
    id: 'gid://shopify/Product/8083259424921',
    variantId: 'gid://shopify/ProductVariant/44448147767449',
    variants: [
      {
        sku: 'KV032-OKX',
        id: 'gid://shopify/ProductVariant/44448147767449',
      },
      {
        sku: 'KV032-Solflare',
        id: 'gid://shopify/ProductVariant/44448147800217',
      },
      {
        sku: 'KV032-Safe',
        id: 'gid://shopify/ProductVariant/44448147832985',
      },
      {
        sku: 'KV032-VESPR',
        id: 'gid://shopify/ProductVariant/44811135778969',
      },
    ],
  },
  k3ProNFTCustomization: {
    path: '/shop/products/keystone-3-pro-nft-customization',
    id: 'gid://shopify/Product/8117449851033',
    variantId: 'gid://shopify/ProductVariant/44596350550169',
    variants: [
      {
        sku: 'KV032-NFT-Custom',
      },
      {
        sku: 'KV032-NFT-MAD',
      },
      {
        sku: 'KV032-NFT',
        id: 'gid://shopify/ProductVariant/44596350550169',
      },
    ],
  },
  k3ProBundlePack: {
    path: '/shop/products/keystone-bundle-pack',
    id: 'gid://shopify/Product/7960271716505',
    variantId: 'gid://shopify/ProductVariant/44452204544153',
  },
  k3ProPresale: {
    id: 'gid://shopify/Product/7806870618265',
    variantId: 'gid://shopify/ProductVariant/43680916635801',
  },
  k3ProBundle2PackPresale: {
    id: 'gid://shopify/Product/7807055954073',
    variantId: 'gid://shopify/ProductVariant/43681331544217',
  },
  k3ProBundle3PackPresale: {
    id: 'gid://shopify/Product/7807056052377',
    variantId: 'gid://shopify/ProductVariant/43681332723865',
    variants: [
      {
        sku: 'KV032-BP',
        id: 'gid://shopify/ProductVariant/44452204544153',
      },
      {
        sku: 'KV032-BP2',
        id: 'gid://shopify/ProductVariant/44452204576921',
      },
    ],
  },
  pouch: {
    path: '/shop/products/keystone-wallet-protective-leather-pouch',
    id: 'gid://shopify/Product/7470545797273',
    variantId: 'gid://shopify/ProductVariant/42384502784153',
    variants: [
      {
        sku: 'KVP001',
        id: 'gid://shopify/ProductVariant/42384502784153',
      },
    ],
  },
  pouch2: {
    path: '/shop/products/keystone-wallet-pouch-2',
    id: 'gid://shopify/Product/8024604180633',
    variantId: 'gid://shopify/ProductVariant/44301633683609',
    variants: [
      {
        sku: 'KVP002',
        id: 'gid://shopify/ProductVariant/44301633683609',
      },
    ],
  },
  tablet: {
    path: '/shop/products/keystone-tablet',
    id: 'gid://shopify/Product/3459924918372',
    variantId: 'gid://shopify/ProductVariant/44413756276889',
    variants: [
      {
        sku: 'KT002',
        id: 'gid://shopify/ProductVariant/44413756276889',
      },
      {
        sku: 'KT002-P01',
        id: 'gid://shopify/ProductVariant/44413756309657',
      },
    ],
  },
  tabletPlus: {
    path: '/shop/products/keystone-tablet-plus',
    id: 'gid://shopify/Product/5444389503129',
    variantId: 'gid://shopify/ProductVariant/44415710920857',
    variants: [
      {
        sku: 'KT003',
        id: 'gid://shopify/ProductVariant/44415710920857',
      },
      {
        sku: 'KT003-P01',
        id: 'gid://shopify/ProductVariant/44415710953625',
      },
      {
        sku: 'KT003-P02',
        id: 'gid://shopify/ProductVariant/44415710986393',
      },
    ],
  },
  tabletPunch: {
    path: '/shop/products/keystone-tablet-punch',
    id: 'gid://shopify/Product/5430293495961',
    variantId: 'gid://shopify/ProductVariant/40902524076185',
    variants: [
      {
        sku: 'KT004',
        id: 'gid://shopify/ProductVariant/40902524076185',
      },
    ],
  },
};

// Test Products ID
export const ProductsConfigStaging = {
  k3Pro: {
    path: '/shop/products/keystone-3-pro',
    id: 'gid://shopify/Product/8653285196064',
    variantId: 'gid://shopify/ProductVariant/46655441240352',
    variants: [
      {
        name: 'metamask',
        id: 'gid://shopify/Product/8652086214944',
      },
    ],
  },
  k3ProLimitedEdition: {
    path: '/shop/products/keystone-3-pro-limited',
    id: 'gid://shopify/Product/8083259424921',
    variantId: 'gid://shopify/ProductVariant/44448147767449',
    variants: [
      {
        sku: 'KV032-OKX',
      },
      {
        sku: 'KV032-Solflare',
      },
      {
        sku: 'KV032-Safe',
      },
      {
        sku: 'KV032-VESPR',
      },
    ],
  },
  k3ProNFTCustomization: {
    path: '/shop/products/keystone-3-pro-nft-customization',
    id: 'gid://shopify/Product/8117449851033',
    variantId: 'gid://shopify/ProductVariant/44596350550169',
    variants: [
      {
        sku: 'KV032-NFT-Custom',
      },
      {
        sku: 'KV032-NFT-MAD',
      },
    ],
  },
  pouch2: {
    path: '/shop/products/keystone-wallet-pouch-2',
    id: 'gid://shopify/Product/8024604180633',
    variantId: 'gid://shopify/ProductVariant/44301633683609',
  },
  k3ProBundlePack: {
    path: '/shop/products/keystone-bundle-pack',
    id: 'gid://shopify/Product/7960271716505',
    variantId: 'gid://shopify/ProductVariant/44149746172057',
  },
  pouch: {
    path: '/shop/products/keystone-wallet-protective-leather-pouch',
    id: 'gid://shopify/Product/8652091851040',
    variantId: 'gid://shopify/ProductVariant/46650299056416',
  },
  tablet: {
    path: '/shop/products/keystone-tablet',
    id: 'gid://shopify/Product/8652094570784',
    variantId: 'gid://shopify/ProductVariant/46650310557984',
  },
  tabletPlus: {
    path: '/shop/products/keystone-tablet-plus',
    id: 'gid://shopify/Product/8652092473632',
    variantId: 'gid://shopify/ProductVariant/46650301940000',
  },
  tabletPunch: {
    path: '/shop/products/keystone-tablet-punch',
    id: 'gid://shopify/Product/8652092997920',
    variantId: 'gid://shopify/ProductVariant/46650304102688',
  },
};

export const ProductsConfig = isDev
  ? ProductsConfigStaging
  : ProductsConfigProduct;

export const ProductsID = {
  k3Pro: ProductsConfig.k3Pro.id,
  k3ProLimitedEdition: ProductsConfig.k3ProLimitedEdition.id,
  k3ProBundlePack: ProductsConfig.k3ProBundlePack.id,
  k3ProNFTCustomization: ProductsConfig.k3ProNFTCustomization.id,
  pouch2: ProductsConfig.pouch2.id,
  walletPouch: ProductsConfig.pouch.id,
  tablet: ProductsConfig.tablet.id,
  tabletPlus: ProductsConfig.tabletPlus.id,
  tabletPunch: ProductsConfig.tabletPunch.id,
};

export function isK3ProProduct(productId) {
  return [ProductsConfig.k3Pro.id].some((id) =>
    isProductVariantByProductId(productId, id),
  );
}
export function isProductVariantByProductId(productId, mainId) {
  if (productId === mainId)
    return true;
  const product = find(ProductsConfig, (it) => it.id === mainId);
  const variant
    = (product.variants && some(product.variants, (it) => it.id === productId))
    || (product.specialEditions
      && some(product.specialEditions, (it) => it.id === productId));
  return Boolean(variant);
}

export function getProductVariantIdByVariantName(productId, variantName) {
  const product = find(ProductsConfig, (it) => it.id === productId);
  const variant
    = (product.variants
      && find(product.variants, (it) => it.name === variantName))
    || (product.specialEditions
      && find(product.specialEditions, (it) => it.name === variantName));
  return variant?.id;
}

const productRedirects = [
  {
    path: '/shop/products/keystone-3pro',
    redirect: '/shop/products/keystone-3-pro',
    variants: [
      {
        name: 'woofi2',
        redirect: 'woofi-dark',
      },
      {
        name: 'woofi-dark',
        redirect: 'limited',
      },
      {
        name: 'haqq',
        redirect: 'limited',
      },
      {
        name: 'ghostlab',
        redirect: 'limited',
      },
      {
        name: 'special-edition-for-ghost-lab',
        redirect: 'limited',
      },
      {
        name: 'custom',
        redirect: 'custom-version',
      },
      {
        name: 'btc-halving',
        redirect: '',
      },
      {
        name: '6th-anniversary',
        redirect: '',
      },
    ],
  },
  {
    path: '/shop/products/keystone-3-pro',
    redirect: '/shop/products/keystone-3-pro',
    variants: [
      {
        name: 'pontem',
        redirect: 'limited',
      },
      {
        name: 'dodo',
        redirect: 'limited',
      },
      {
        name: 'alienswap',
        redirect: 'limited',
      },
      {
        name: 'solflare',
        redirect: 'limited',
      },
      {
        name: 'woofi-dark',
        redirect: 'limited',
      },
      {
        name: 'woofi',
        redirect: 'limited',
      },
      {
        name: 'shardeum',
        redirect: 'limited',
      },
      {
        name: 'blockwallet',
        redirect: 'limited',
      },
      {
        name: 'haqq-network',
        redirect: 'limited',
      },
      {
        name: 'cowswap',
        redirect: 'limited',
      },
      {
        name: 'slowmist',
        redirect: 'limited',
      },
      {
        name: 'okx',
        redirect: 'limited',
      },
      {
        name: 'safe',
        redirect: 'limited',
      },
      {
        name: 'orderly-network',
        redirect: 'limited',
      },
      {
        name: 'platon',
        redirect: 'limited',
      },
      {
        name: 'ghostlab',
        redirect: 'limited',
      },
      {
        name: 'special-edition-for-ghost-lab',
        redirect: 'limited',
      },
      {
        name: 'btc-halving',
        redirect: '',
      },
      {
        name: '6th-anniversary',
        redirect: '',
      },
    ],
  },
  {
    path: '/shop/products/keystone-3pro-bundle-pack',
    redirect: '/shop/products/keystone-bundle-pack',
  },
  {
    path: '/shop/products/keystone-leather-pouch',
    redirect: '/shop/products/keystone-wallet-protective-leather-pouch',
  },
];

export function transferedVariantsProducts() {
  const list = flatten(
    toPairs(ProductsConfig).map(([key, config]) => {
      const redirects = productRedirects.filter(
        (it) => it.redirect === config.path,
      );
      const productRoutes = [
        {
          id: config.id,
          path: config.path,
          name: key,
        },
        ...redirects.map((redirect) => {
          return [
            {
              path: redirect.path,
              redirect: redirect.redirect,
            },
            ...(config.variants
              ? config.variants?.map((it) => ({
                path: `${redirect.path}${it.name ? `-${it.name}` : ''}`,
                redirect: `${redirect.redirect}${it.name ? `-${it.name}` : ''
                }`,
              }))
              : []),
            ...(redirect?.variants
              ? redirect?.variants.map((it) => ({
                path: `${redirect.path}${it.name ? `-${it.name}` : ''}`,
                redirect: `${config.path}${it.redirect ? `-${it.redirect}` : ''
                }`,
              }))
              : []),
          ];
        }),
        ...(config.variants
          ? config.variants?.map((it) => {
            return {
              id: it.id,
              path: `${config.path}${it.name ? `-${it.name}` : ''}`,
              name: it.name,
              parentName: key,
            };
          })
          : []),
        ...(config.specialEditions
          ? config.specialEditions?.map((it) => ({
            id: it.id,
            path: `${config.path}${it.name ? `-${it.name}` : ''}`,
            name: it.name,
            redirect: it.redirect,
            parentName: key,
          }))
          : []),
      ];
      return productRoutes;
    }),
  )
    .flat()
    .filter((product) => product.redirect !== product.path)
    .filter((product) => product.path);
  return list;
}

export function getProductIdById(id) {
  return find(ProductsConfig, (item) => {
    return (
      item.id === id
      || item.variantId === id
      || item.variants?.some((it) => it.id === id)
    );
  })?.id;
}

export function getProductNameById(id) {
  const list = transferedVariantsProducts();
  const finded = list.find((it) => it.id === id);
  return finded?.parentName || finded?.name;
}

export function getProductByPath(path) {
  const pathTrimEndSlash = path.replace(/\/$/, '');
  return find(ProductsConfig, (item) =>
    [
      item.path,
      ...(item?.variants || []).map(
        (it) => `${item.path}${it.name ? `-${it.name}` : ''}`,
      ),
      ...(item?.specialEditions || []).map(
        (it) => `${item.path}${it.name ? `-${it.name}` : ''}`,
      ),
    ].includes(pathTrimEndSlash));
}

export const PRICE_IN_CURRENCY = {
  Keystone3Pro: {
    USD: 149,
    EUR: 149,
    GBP: 118,
  },
  LedgerStax: {
    USD: 399,
    EUR: 399,
    GBP: 310.33,
  },
  LedgerFlex: {
    USD: 249,
    EUR: 249,
    GBP: 193.66,
  },
  TrezorSafe5: {
    USD: 169,
    EUR: 169,
    GBP: 131.44,
  },
};
