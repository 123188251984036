import styled, { css } from 'styled-components';
import CloseIcon from 'components/icons/Close';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import { Body, CaptionStyle, OpenSansFontStyle } from 'components/styles/body';
import Link from 'components/Link';
import { infiniteTranslateX } from 'components/styles/animate';

export const BannerBox = styled.div.attrs({ className: 'theme-dark' })`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  z-index: 1200;
  background: var(--color-bd-primary);
  > a {
    gap: 0 20px;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    height: 40px;
    > a {
      width: 100%;
      justify-content: flex-start;
      margin-left: 2vw;
    }
  }
`;

export const BannerTitle = styled(Body)`
  color: var(--color-fg-emphasis);
  font-weight: 600;
  span > em {
    color: var(--color-bd-pink);
  }
  @media ${DEVICE_QUERY_MOBILE} {
    ${CaptionStyle}
    overflow: hidden;
    display: flex;
    ${(props) =>
    !props.animateEnabled
      ? css`
            justify-content: center;
          `
      : ''}
    width: 60vw;
    > span {
      white-space: nowrap;
      ${(props) =>
    props.animateEnabled
      ? css`
              animation: ${infiniteTranslateX} 10s linear infinite;
            `
      : ''}
    }
  }
`;

export const BannerButton = styled(Link)`
  ${OpenSansFontStyle}
  font-weight: 600;
  letter-spacing: 0.04em;
  color: var(--color-bd-pink);
`;

export const BannerBgClose = styled(CloseIcon)`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--color-fg-subtle, #83868f);
  @media ${DEVICE_QUERY_MOBILE} {
    right: 10px;
  }
`;
