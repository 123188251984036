import React from 'react';
import Format from './Format';
import { IconTypes } from './types';

export default function HeaderBannerBuy({ color = '#000', ...args }) {
  return (
    <Format {...args}>
      <svg
        width="262"
        height="64"
        viewBox="0 0 262 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M262 0H0V64H262V0ZM39.2044 48.4827L39.6251 46.648V20.792L39.2044 19H47.8867L49.5314 20.8347V31.8853L47.8485 33.72L49.5314 35.5547V46.648L47.8867 48.4827H39.2044ZM45.8596 44.7707L46.242 44.344V35.9813L45.8596 35.5547H43.3352L42.9527 35.9813V44.344L43.3352 44.7707H45.8596ZM45.8596 22.6693H43.3352L42.9527 23.096V31.4587L43.3352 31.8853H45.8596L46.242 31.4587V23.096L45.8596 22.6693ZM59.5198 20.8347L59.0991 19H63.2299L62.8092 20.8347V46.648L61.1645 48.4827H54.5476L52.9029 46.648V20.8347L52.4822 19H56.613L56.1922 20.8347V44.3867L56.5747 44.8133H59.1374L59.5198 44.3867V20.8347ZM77.7191 20.6213L78.5606 19H73.703L74.1238 20.28L72.0966 28.9413L71.9436 30.264L71.7906 28.9413L69.7635 20.28L70.146 19H65.3267L66.1681 20.4507L70.299 35.7253V46.648L69.8782 48.4827H74.009L73.5883 46.648V35.896L77.7191 20.6213ZM92.6604 30.3493L92.8134 32.952V46.6907L93.2341 48.4827H89.1033L89.524 46.6907V20.8347L89.1033 19H92.8134L96.4852 34.5307L97.0589 37.1333L96.9059 34.5307V20.8347L96.4852 19H100.616L100.195 20.8347V46.6907L100.616 48.4827H96.9059L93.2341 32.952L92.6604 30.3493ZM105.333 19L103.688 20.8347V46.648L105.333 48.4827H111.95L113.595 46.648V20.8347L111.95 19H105.333ZM110.267 44.3867L109.885 44.8133H107.36L106.978 44.3867V23.1387L107.36 22.712H109.885L110.267 23.1387V44.3867ZM132.081 19H136.403L135.715 21.304L132.081 47.0747L132.464 48.4827H127.951L128.333 47.0747L126.153 31.9707L126.076 30.4773L126 31.9707L123.896 47.0747L124.279 48.4827H119.766L120.148 47.0747L116.438 21.3893L115.711 19H120.033L119.613 20.536L121.946 37.688L122.022 39.1813L122.099 37.688L124.279 20.792L123.935 19H128.218L127.874 20.792L130.131 37.688L130.207 39.1813L130.284 37.688L132.502 20.536L132.081 19ZM194.475 33.0675L181.484 18.5755L184.189 15.5585L201.318 34.667L184.189 53.7755L181.484 50.7585L193.518 37.3341L160.642 37.3341V33.0675L194.475 33.0675Z"
          fill={color}
        />
      </svg>
    </Format>
  );
}

HeaderBannerBuy.propTypes = IconTypes;
