import produce from 'immer';
import {
  CLEAR_VERIFY_STATUS,
  GET_QRCODE,
  GET_QRCODE_ERROR,
  GET_QRCODE_SUCCESS,
  VERIFY_QRCODE,
  VERIFY_QRCODE_ERROR,
  VERIFY_QRCODE_SUCCESS,
} from './constants';

// The initial state of the App
export const initialState = {
  qrcode: '',
  actionId: '',
  matched: true,
  verifyQrcodeLoading: false,
  verifyQrcodeSuccess: false,
  error: false,
  validated: false,
};

function webAuthReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_QRCODE:
        draft.error = false;
        draft.qrcode = '';
        draft.actionId = '';
        break;

      case GET_QRCODE_SUCCESS:
        draft.qrcode = action.payload.qrcode;
        draft.actionId = action.payload.actionId;
        break;

      case GET_QRCODE_ERROR:
        draft.error = action.error;
        break;

      case VERIFY_QRCODE:
        draft.verifyQrcodeLoading = true;
        draft.verifyQrcodeSuccess = false;
        draft.error = false;
        draft.validated = false;
        break;

      case VERIFY_QRCODE_SUCCESS:
        draft.matched = true;
        draft.qrcode = '';
        draft.verifyQrcodeLoading = false;
        draft.verifyQrcodeSuccess = true;
        draft.validated = true;
        break;

      case VERIFY_QRCODE_ERROR:
        draft.matched = false;
        draft.qrcode = '';
        draft.verifyQrcodeSuccess = true;
        draft.verifyQrcodeLoading = false;
        draft.validated = true;
        break;

      case CLEAR_VERIFY_STATUS:
        draft.matched = false;
        draft.qrcode = '';
        draft.verifyQrcodeSuccess = true;
        draft.verifyQrcodeLoading = false;
        draft.validated = false;
        break;
    }
  });
}

export default webAuthReducer;
