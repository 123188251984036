import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { TrackEvent } from './events';
import { track as baseTrack, trackLinks as baseTrackLinks } from './lib';

const mixpanelKey = 'mixpanel';
const dataMixpanel = `data-${mixpanelKey}`;
const listenKey = `${dataMixpanel}-listen`;
const IgnoreDatasetKeys = ['Event', 'Listen'];
const ignoreMapKeys = IgnoreDatasetKeys.map((k) => mixpanelKey + k);

function getAttrElements(doc = document) {
  return doc.querySelectorAll(`[${dataMixpanel}-id]:not([${listenKey}])`);
}

export function useTrack() {
  const intl = useIntl();
  const { pathname } = useLocation();
  const [isEnableAttributeMode, setIsEnableAttributeMode] = useState(false);
  const [attrElements, setAttrElements] = useState([]);

  /**
   * track a event
   * @param {keyof TrackEvent} event track event
   * @param {object} data external data
   * @param {{
   *   includeExpand: boolean
   *   intl: React Intl
   * }} config track config
   */
  const track = useCallback(
    (event, data, config = {}) =>
      baseTrack(event, data, {
        ...config,
        intl,
      }),
    [intl],
  );

  const trackLinks = useCallback((selector, event, setData, config = {}) =>
    baseTrackLinks(selector, event, setData, {
      ...config,
      intl,
    }), [intl]);

  const mixpanelElementOnClick = useCallback(
    (el) => {
      return () => {
        const elMixpanelDataset
          = el.dataset
          && Object.fromEntries(
            Object.entries(el.dataset)
              .filter(
                ([k]) => k.startsWith('mixpanel') && !ignoreMapKeys.includes(k),
              )
              .map(([key, value]) => {
                const joinKey
                  = key[mixpanelKey.length].toLowerCase()
                  + key.slice(mixpanelKey.length + 1, key.length);
                const k = { id: 'dom_id' }[joinKey] || joinKey;
                return [k, value];
              }),
          );

        const event
          = el.getAttribute(`${dataMixpanel}-event`) || TrackEvent.Navigation;

        track(
          event,
          {
            ...elMixpanelDataset,
          },
          {
            includeExpand: true,
          },
        );
      };
    },
    [track],
  );

  const reSetAttrElements = useCallback((elements) => {
    if (!isEnableAttributeMode)
      return;
    setAttrElements(
      elements
      || document.querySelectorAll(`[${dataMixpanel}-id]:not([${listenKey}])`),
    );
  }, [isEnableAttributeMode]);

  useEffect(() => {
    if (!isEnableAttributeMode)
      return;
    track(TrackEvent.PV, null, {
      includeExpanded: true,
      intl,
    });

    if (!attrElements)
      return;

    for (let i = 0; i < attrElements.length; i++) {
      if (attrElements[i] && !attrElements[i].getAttribute(listenKey)) {
        attrElements[i].setAttribute(listenKey, 'true');
        attrElements[i].addEventListener(
          'click',
          mixpanelElementOnClick(attrElements[i]),
        );
      }
    }
  }, [isEnableAttributeMode, attrElements, track, intl, mixpanelElementOnClick]);

  useEffect(() => {
    reSetAttrElements();
    return () => {
      for (let i = 0; i < attrElements.length; i++) {
        attrElements[i]
          && attrElements[i].removeEventListener(
            'click',
            mixpanelElementOnClick(attrElements[i]),
          );
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnableAttributeMode, pathname]);

  const setupAttrubiteMode = useCallback(() => {
    setIsEnableAttributeMode(true);
  }, []);

  return {
    track,
    trackLinks,
    setupAttrubiteMode,
    getAttrElements,
    reSetAttrElements,
  };
}
