import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import Modal from 'components/Modal';
import { H6 } from 'components/styles/titleNew';
import Button from 'components/Button';
import mainBg from './images/main.png';
import rotateBg from './images/bg.png';
import starBg from './images/star.png';

export const ModalContainer = styled(Modal)`
  z-index: 1100;
  .modal-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;

    .close-icon {
      top: 50%;
      transform: translateY(-50%);
    }

    @media ${DEVICE_QUERY_MOBILE} {
      width: 312px;
      min-width: 312px;
      height: 400px;

      .close-icon {
        top: 110%;
      }
    }
  }
`;

export const ModalContentWrapper = styled.div`
  padding: 164px 20px 20px;
  width: 100%;
  height: 560px;
  border: 2px solid #3d71ff;
  background: #000;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    top: 20px;
    left: 20px;
    width: 920px;
    height: 520px;
    border: 2px solid #e1526e;
    z-index: 1;
    pointer-events: none;
  }

  @media ${DEVICE_QUERY_MOBILE} {
    padding: 80px 10px 10px;
    width: 312px;
    height: 400px;
    &:after {
      top: 10px;
      left: 10px;
      width: 288px;
      height: 378px;
    }
  }
`;

export const MainImageContentWrapper = styled(ModalContentWrapper)`
  position: absolute;
  overflow: visible;
  z-index: 2;
  background: transparent;
  pointer-events: none;
  &:before {
    conent: none;
    display: none;
  }
  &:after {
    conent: none;
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -20px auto 0;
  position: relative;
  border-radius: 32px;
  width: 650px;
  height: 266px;
  overflow: hidden;

  @media ${DEVICE_QUERY_MOBILE} {
    flex-direction: column;
    margin-top: 0;
    text-align: center;
    width: 100%;
    background: transparent;
  }
`;

export const BgRotate = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: 809px;
  height: 809px;
  background: url(${rotateBg}) center / 150%;
  animation: rotate 15s linear infinite;
  z-index: 0;

  @media ${DEVICE_QUERY_MOBILE} {
  }
`;

export const MainImage = styled.div`
  position: absolute;
  right: 50%;
  top: -99px;
  transform: translateX(50%);
  width: 100%;
  height: 263px;
  background: url(${mainBg}) no-repeat top center / contain;

  @media ${DEVICE_QUERY_MOBILE} {
    position: absolute;
    top: -60px;
    left: 50%;
    height: 132px;
    transform: translateX(-50%);
  }
`;

export const CountDownContainer = styled.div`
  width: 100%;
  text-align: center;
  z-index: 1;

  @media ${DEVICE_QUERY_MOBILE} {
    margin-top: 1px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    height: 90%;
  }
`;

export const PromotionTitle = styled.p`
  text-align: center;
  font-family: Mont;
  font-size: 24px;
  font-style: normal;
  line-height: 36px; /* 150% */
  letter-spacing: 3.12px;

  background: linear-gradient(90deg, #c5ffea 0%, #fff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.32px;
  }
`;

export const StagePromotionTitle = styled(PromotionTitle)`
  margin-top: 15px;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  background: linear-gradient(90deg, #ffada3 0%, #fff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${DEVICE_QUERY_MOBILE} {
    padding: 0 16px;
    margin-top: 0;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.28px;
  }
`;

export const StageTitle = styled.div`
  margin-top: 15px;
  font-size: 48px;
  font-style: italic;
  font-weight: 800;
  line-height: 64px;
  letter-spacing: 1.92px;
  background: linear-gradient(90deg, #ffada3 0%, #fff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  > em {
    background: #e0516e;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media ${DEVICE_QUERY_MOBILE} {
    padding: 0 16px;
    margin-top: 10px;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.96px;
  }
`;

export const Desc = styled(H6)`
  margin-top: 24px;
  color: #f5f8ff;
  text-align: center;

  @media ${DEVICE_QUERY_MOBILE} {
    padding: 0 14px;
  }
`;

export const StageDesc = styled(Desc)`
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
  color: var(--color-fg-muted);

  @media ${DEVICE_QUERY_MOBILE} {
    margin-top: 10px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.24px;
  }
`;

export const RainbowButton = styled(Button)`
  margin-top: 26px;
  --border-bg: linear-gradient(
    90deg,
    #ffc93d 25%,
    #11a0de 50%,
    #b439d2 75%,
    #6b38fb 100%
  ) !important;
  height: 56px;

  ::after {
    background: var(--color-bg-emphasis) !important;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    margin-top: 22px;
    height: 40px;
  }
`;

export const Star = styled.div`
  position: absolute;
  top: 48px;
  left: 54px;
  width: 56px;
  height: 56px;
  background: url(${starBg}) center / cover;
`;
