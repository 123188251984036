import mixpanel from 'mixpanel-browser';
import { CONTEXT } from './context';

const PROJECT_TOKEN = process.env.MIXPANEL_PROJECT_TOKEN;
export function trackInit() {
  const trackToken = PROJECT_TOKEN;
  const isNonProd = CONTEXT.env !== 'production';

  mixpanel.init(trackToken, {
    debug: isNonProd,
  });
}

export function trackEvent(event) {
  return (data = {}) => {
    mixpanel.track(event, {
      ...data,
      ...CONTEXT,
    });
  };
}
