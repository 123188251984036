import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useIsMobile from 'hooks/useIsMobile';
import { FlexCenter } from 'components/styles/flex';
import { FormattedMessage } from 'react-intl';
import { request } from 'api/request';
import { logger } from 'logger';
import { LINKS } from 'config/links';
import { Link, useLocation } from 'react-router-dom';
import { trackHeaderTopBannerClick } from 'tracking/click/header';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback } from 'components/ErrorBoundary';
import { bannerRfsnMsgs } from '../messages';
import {
  BannerBgClose,
  BannerBox,
  BannerButton,
  BannerTitle,
} from './index.style';

const customTitles = {
  6479870: {
    title:
      'Bienvenidos seguidores de btcenespanol! Les agradecemos que nos visiten en la tienda de Keystone!',
    mobileTitle: 'Comunidad de btcenespanol, ¡bienvenidos a Keystone!',
  },
};

export default function RfsnBanner({ rfsnId: refId, onClose: close }) {
  const isMobile = useIsMobile();
  const [affiliteName, setAffiliteName] = useState('');
  const closeButtonSize = useMemo(() => (isMobile ? '20' : '30'), [isMobile]);
  const location = useLocation();
  const [initPath] = useState(location.pathname);

  const trackClick = useCallback(() => {
    if (!refId)
      return;
    if (location.pathname === LINKS.shop) {
      return;
    }
    trackHeaderTopBannerClick('rfsn', refId);
  }, [refId, location.pathname]);

  useEffect(() => {
    if (initPath !== location.pathname) {
      close();
    }
  }, [close, initPath, location.pathname]);

  useEffect(() => {
    if (!refId)
      return;
    async function load() {
      const version = 'v1';
      const url = '/affilite_user/';
      try {
        const affiliteUser = await request(url, { ref_id: refId }, { version });
        if (affiliteUser && affiliteUser.name) {
          setAffiliteName(affiliteUser.name);
        } else {
          close();
        }
      } catch (e) {
        logger.error(e);
        close();
      }
    }
    load();
  }, [close, refId]);

  const shouldUseCustomTitle = useMemo(() => {
    return refId in customTitles;
  }, [refId]);

  const message = useMemo(() => {
    const titleName = isMobile ? 'mobileTitle' : 'title';
    if (shouldUseCustomTitle) {
      return customTitles?.[refId]?.[titleName];
    }
    return bannerRfsnMsgs[titleName];
  }, [shouldUseCustomTitle, refId, isMobile]);

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <BannerBox>
        <FlexCenter as={Link} to={LINKS.shop} onClick={trackClick}>
          {affiliteName && (
            <>
              <BannerTitle animateEnabled={shouldUseCustomTitle}>
                <span>
                  {shouldUseCustomTitle ? (
                    message
                  ) : (
                    <FormattedMessage
                      {...message}
                      values={{
                        value: affiliteName,
                      }}
                    />
                  )}
                </span>
              </BannerTitle>
              {!isMobile && location.pathname !== LINKS.shop && (
                <BannerButton>
                  <FormattedMessage {...bannerRfsnMsgs.button} />
                </BannerButton>
              )}
            </>
          )}
        </FlexCenter>
        <BannerBgClose
          width={closeButtonSize}
          height={closeButtonSize}
          onClick={close}
        />
      </BannerBox>
    </ErrorBoundary>
  );
}
