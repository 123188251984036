import { CollapseHeader } from 'components/Collapse/style';
import Link from 'components/Link';
import { BodyPlus } from 'components/styles/bodyNew';
import { H6 } from 'components/styles/titleNew';
import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 120px;
  padding: 40px 0;
  @media ${DEVICE_QUERY_MOBILE} {
    gap: 30px 0;
    padding: 12px 24px;
    flex-direction: column;

    ${CollapseHeader} {
      color: var(--color-fg-muted);
    }
  }
`;

export const WalletsAndCoins = styled.div``;

export const CardLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  .link-wrapper {
    color: var(--color-fg-muted);
    margin-top: 24px;
    display: flex;
  }
  :hover {
    .link-wrapper {
      color: var(--color-fg-default);
    }
    .link-arrow {
      color: var(--color-bd-primary);
      transform: translateX(6px);
    }
  }
`;

export const CardLinkImage = styled.img`
  width: 380px;
  height: 208px;
  background: #000;
  border-radius: 16px;
  @media ${DEVICE_QUERY_MOBILE} {
    width: 100%;
    height: auto;
  }
`;

export const CardLinkTitle = styled(H6)``;

export const Features = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px 56px;
  width: 620px;
  @media ${DEVICE_QUERY_MOBILE} {
    width: 100%;
  }
`;

export const FeatureItemContainer = styled.div`
  width: calc(50% - 32px);
  @media ${DEVICE_QUERY_MOBILE} {
    width: 100%;
  }
`;

export const FeatureTitle = styled(BodyPlus)``;

export const Feature = styled(Link)`
  display: flex;
  padding: 16px 12px;
  color: var(--color-fg-muted);
  :hover {
    color: var(--color-fg-emphasis);
    background-color: var(--color-bg-subtle);
    .link-arrow {
      opacity: 1;
      color: var(--color-bd-primary);
      transform: translateX(6px);
    }
  }
  .link-text {
    display: flex;
    align-items: center;
    height: 48px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .link-arrow {
    opacity: 0;
    color: var(--color-fg-muted);
  }
  @media ${DEVICE_QUERY_MOBILE} {
  }
`;

export const ItemIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-right: 12px;
  @media ${DEVICE_QUERY_MOBILE} {
    margin-right: 16px;
  }
`;
