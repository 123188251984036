import { trackEvent } from './base';
import { EVENT_CATEGORY, EVENT_TYPE } from './type';

export function trackHomeBannerNewsTip(value) {
  trackEvent(EVENT_TYPE.BUTTON_CLICK, {
    event_category: EVENT_CATEGORY.Notification,
    event_label: 'banner-news-tip',
    event_value: value,
  });
}

export function trackHomeLinkClick(name, value) {
  trackEvent(EVENT_TYPE.BUTTON_CLICK, {
    event_category: EVENT_CATEGORY.HomeLink,
    event_label: name,
    event_value: value,
  });
}
