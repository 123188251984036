import React from 'react';
import { useI18nDescriptor, useMsg } from 'hooks/useMessages';
import { useIntl } from 'react-intl';

const key = 'keystone.campaign.ledgerConnectKit.';

export default function useMessages() {
  const intl = useIntl();
  const messagesDiscriptorGetter = useI18nDescriptor(key);
  const messagesGetter = useMsg(key);

  return {
    title: messagesGetter('title'),
    desc2: messagesGetter('desc2'),
    desc3: messagesGetter('desc3'),
    desc1: intl.formatMessage(messagesDiscriptorGetter('desc1'), {
      em: (chunk) => (
        <a
          href="https://twitter.com/Ledger/status/1735291427100455293"
          target="_blank"
        >
          <span style={{ color: 'var(--color-bd-pink)' }}>{chunk}</span>
        </a>
      ),
    }),
    buyNow: messagesGetter('buyNow'),
  };
}
