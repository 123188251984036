import { find } from 'lodash';
import { ProductsConfig, ProductsID } from 'config/products';
import { filterPreOrderList } from 'pages/Cart/utils';
import { transformInfo } from '../shopRequest/productsUtils';

const UltimateID = 'gid://shopify/Product/1531337736292';
export const ProductsOrder = [
  ProductsConfig.k3ProBundlePack.id, // 3Pro Bundle Pack
  ProductsConfig.k3Pro.id, // 3Pro
  ProductsConfig.tabletPlus.id, // Tablet Plus
  ProductsConfig.pouch2.id, // Pouch 2
  ProductsConfig.tablet.id, // Tablet
  ProductsConfig.pouch.id, // Wallet Pouch
  ProductsConfig.tabletPunch.id, // Tablet Punch
];

const HIDDEN_LIST = [UltimateID];

export function getRecommendList(allProducts, inputProducts, type) {
  const productsList = allProducts
    .filter((item) => item.availableForSale)
    .filter((item) => !HIDDEN_LIST.includes(item.id))
    .filter((item) => ProductsOrder.includes(item.id))
    .sort((a, b) => ProductsOrder.indexOf(a.id) - ProductsOrder.indexOf(b.id));

  const inputList = [];
  if (!inputProducts)
    return [];
  if (type) {
    inputProducts.forEach((item) => {
      inputList.push(item.merchandise.product);
    });
  } else {
    inputList.push(inputProducts);
  }
  const inputListFilter = inputList.map((item) => item.id);
  const recommendList = productsList
    .filter((item) => !inputListFilter.includes(item.id))
    .map((item) => {
      const product = find(ProductsConfig, (it) => it.id === item.id);
      return {
        ...transformInfo(item),
        path: product && product.path,
        featuredImage: item.featuredImage,
      };
    });
  return filterPreOrderList(recommendList).slice(0, 8);
}

export function getProductIds(config) {
  const variantsIds = config.variants?.map((it) => it.id) || [];
  const specialEditionIds = config.specialEditions?.map((it) => it.id) || [];
  return [config.id, ...variantsIds, ...specialEditionIds];
}

export function screenProduct(id) {
  if (
    [
      ...getProductIds(ProductsConfig.k3Pro),
      ProductsConfig.k3ProLimitedEdition.id,
      ProductsConfig.k3ProNFTCustomization.id,
      ProductsConfig.k3ProBundlePack.id,
    ].includes(id)
  ) {
    return 'KV';
  }
  if (
    [ProductsID.tablet, ProductsID.tabletPlus, ProductsID.tabletPunch].includes(
      id,
    )
  ) {
    return 'KT';
  }
  if ([ProductsID.walletPouch, ProductsID.pouch2].includes(id))
    return 'KVP';
  return 'Other';
}
