import React from 'react';
import Format from './Format';
import { IconTypes } from './types';

export default function Globe({
  width = '18',
  height = '18',
  color = 'currentColor',
  ...args
}) {
  return (
    <Format {...args}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="8.25" stroke={color} strokeWidth="1.5" />
        <path
          d="M6.90909 9.51451C7.15844 8.37039 6.39216 8.00867 6 7.28522C7.88235 3.37861 13.7273 3.66392 16 4.57229V5.33549C14.2353 5.33549 17.1765 8.91298 13.0588 8.37039C8.94118 7.82781 9.95722 10.101 10.5455 11.1861C11.1337 12.2713 9.31551 12.2713 8.72727 11.1861C8.13904 10.101 6.6772 10.5785 6.90909 9.51451Z"
          fill={color}
        />
        <path
          d="M17.075 13.3921C16.0583 13.3921 15.5606 12.3587 14.787 12.0726C14.2992 11.8923 13.88 12.3609 13.7149 12.854C13.598 13.2028 13.3682 13.5026 13.0617 13.706L12.5039 14.0762C12.3064 14.2072 12.0781 14.3412 12.0149 14.5695C11.8206 15.2708 13.587 15.7511 13.9772 16.9163C14.3312 17.9736 13.9772 18.6784 13.9772 20L17.5175 17.7974C18.4026 14.7137 17.9601 13.3921 17.075 13.3921Z"
          fill={color}
        />
      </svg>
    </Format>
  );
}

Globe.propTypes = IconTypes;
