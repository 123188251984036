import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${(props) => (props.large ? '146px' : '114px')};
  border: 1px solid var(--color-border-subtle);
  & > div:first-child {
    z-index: 1;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    width: 110px;
  }
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.large ? '40px' : '32px')};
  height: ${(props) => (props.large ? '40px' : '32px')};
  cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};
  transition: var(--transition);
  border: 1px solid transparent;
  & > span > svg {
    fill: ${(props) => props.disabled && 'var(--color-fg-slight)'};
  }
  :hover {
    & > span > svg {
      fill: ${(props) => !props.disabled && 'var(--color-fg-emphasis)'};
    }
    border-color: ${(props) =>
    props.disabled
      ? 'transparent'
      : 'var(--color-border-default) !important'};
  }
`;

export const QuantityContent = styled.p`
  width: ${(props) => (props.large ? '64px' : '48px')};
  padding: ${(props) => (props.large ? '9px 0' : '5px 0')};
  color: var(--color-fg-emphasis);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.04em;
  border-left: 1px solid var(--color-border-subtle);
  border-right: 1px solid var(--color-border-subtle);
`;
