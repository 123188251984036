import { defineMessages } from 'react-intl';

export const scope = 'keystone.banner.modal';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: '',
  },
  desc: {
    id: `${scope}.desc`,
    defaultMessage: '',
  },
  t1: {
    id: `${scope}.t1`,
    defaultMessage: '',
  },
  t2: {
    id: `${scope}.t2`,
    defaultMessage: '',
  },
  button: {
    id: `${scope}.button`,
    defaultMessage: '',
  },
  buy: {
    id: `${scope}.buy`,
    defaultMessage: '',
  },
  footer: {
    id: `${scope}.footer`,
    defaultMessage: '',
  },
  codeTitle1: {
    id: `${scope}.code.title.t1`,
    defaultMessage: '',
  },
  codeTitle2: {
    id: `${scope}.code.title.t2`,
    defaultMessage: '',
  },
  codeTitle3: {
    id: `${scope}.code.title.t3`,
    defaultMessage: '',
  },
  codeDesc1: {
    id: `${scope}.code.desc.t1`,
    defaultMessage: '',
  },
  codeDesc2: {
    id: `${scope}.code.desc.t2`,
    defaultMessage: '',
  },
  codeDesc3: {
    id: `${scope}.code.desc.t3`,
    defaultMessage: '',
  },
  codeButton: {
    id: `${scope}.code.button`,
    defaultMessage: '',
  },
});
