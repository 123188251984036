import React, { useMemo } from 'react';
import { LINKS } from 'config/links';
import Link from 'components/Link';
import { FormattedMessage } from 'react-intl';
import useIsMobile from 'hooks/useIsMobile';
import { mainMsgs as messages } from '../messages';
import { Submenu } from '../style';
import {
  Affiliate,
  CardBox,
  CardBoxTitle,
  CardLinkContainer,
  CardLinkDesc,
  CardLinkImage,
  CardLinkTitle,
  CardList,
  Container,
  Feature,
  FeatureDesc,
  FeatureInfo,
  FeatureItemContainer,
  FeatureTitle,
  ItemIcon,
  NamedBox,
  NamedItemLink,
  NamedItemTitle,
  NamedList,
} from './index.style';
import { useAffiliateMsgs, useStrategicPartnerMsgs } from './messages';

function CardLink({ imageUrl, link, title, desc }) {
  const isMobile = useIsMobile();
  return (
    <Link href={link} arrow={false}>
      <CardLinkContainer>
        {!isMobile && <CardLinkImage src={imageUrl}></CardLinkImage>}
        <Link>
          <CardLinkTitle>{title}</CardLinkTitle>
        </Link>
        <CardLinkDesc>{desc}</CardLinkDesc>
      </CardLinkContainer>
    </Link>
  );
}

function CardItem({ href, title, desc, icon }) {
  return (
    <FeatureItemContainer>
      <Feature href={href} blank={!href.startsWith('/')}>
        <ItemIcon src={icon} />
        <FeatureInfo>
          <FeatureTitle>{title}</FeatureTitle>
          <FeatureDesc>{desc}</FeatureDesc>
        </FeatureInfo>
      </Feature>
    </FeatureItemContainer>
  );
}

function NamedItem({ href, title }) {
  return (
    <NamedItemLink href={href} blank={!href.startsWith('/')}>
      <NamedItemTitle>{title}</NamedItemTitle>
    </NamedItemLink>
  );
}

function Partnerships({ open, ...args }) {
  const isMobile = useIsMobile();
  const strategicPartnerMsgs = useStrategicPartnerMsgs();
  const affiliateMsgs = useAffiliateMsgs();
  const walletList = [
    {
      id: 'metamask',
      href: LINKS.metamask,
      icon: require('./images/metamask.svg'),
      ...strategicPartnerMsgs.at(0),
    },
    {
      id: 'okx',
      href: LINKS.okx,
      icon: require('./images/okx.svg'),
      ...strategicPartnerMsgs.at(1),
    },
  ];

  const coBrandedList = [
    {
      title: 'Keystone 3 Pro <> Solflare',
      href: `${LINKS.shopProducts.k3ProLimitedEdition}?variant=KV032-Solflare`,
    },
    {
      title: 'Keystone 3 Pro <> OKX',
      href: `${LINKS.shopProducts.k3ProLimitedEdition}?variant=KV032-OKX`,
    },
    {
      title: 'Keystone 3 Pro <> Safe',
      href: `${LINKS.shopProducts.k3ProLimitedEdition}?variant=KV032-Safe`,
    },
  ];

  const affiliteComponent = useMemo(
    () => (
      <Affiliate>
        <CardLink
          imageUrl={require('./images/affiliate.jpg')}
          title={affiliateMsgs.title}
          desc={affiliateMsgs.desc}
          link={LINKS.affiliates}
        />
      </Affiliate>
    ),
    [affiliateMsgs],
  );

  return (
    <Submenu height={360} className={open ? 'active' : ''} {...args}>
      <Container>
        {!isMobile && affiliteComponent}
        <CardBox>
          <CardBoxTitle>
            <FormattedMessage {...messages.partnershipsStrategicPartner} />
          </CardBoxTitle>
          <CardList>
            {walletList.map((it) => (
              <CardItem {...it} key={it.id}></CardItem>
            ))}
          </CardList>
        </CardBox>
        <NamedBox>
          <CardBoxTitle>
            <FormattedMessage {...messages.partnershipsLimitedEditions} />
          </CardBoxTitle>
          <NamedList>
            {coBrandedList.map((it) => (
              <NamedItem {...it} key={it.title}></NamedItem>
            ))}
          </NamedList>
        </NamedBox>
        {isMobile && affiliteComponent}
      </Container>
    </Submenu>
  );
}

export default Partnerships;
