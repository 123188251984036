/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
// Import all the third party stuff
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import 'sanitize.css/sanitize.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './assets/style.scss';

import { initSentry } from 'plugins/sentry';
import { initCrisp } from 'plugins/crisp';

// Import Language Provider
import Sitemap from 'containers/Sitemap';
import { renderToString } from 'react-dom/server';
import router from 'router';
import LanguageProvider from './components/LanguageProvider';

import { store } from './configureStore';

// Import i18n messages
import { trackInit } from './tracking';
import { isNonProd } from './config';
import Routers from './router';

import 'plugins/firebase';

// Init tracking
trackInit();

initCrisp();

initSentry();

const gtagConfig = isNonProd ? { debug_mode: true } : {};
window.gtag('config', 'G-WCBLWW84S2', gtagConfig);

const root = createRoot(document.getElementById('app'));

root.render(
  <Provider store={store}>
    <LanguageProvider>
      <RouterProvider router={router} />
    </LanguageProvider>
  </Provider>,
);

if (
  process.env.NODE_ENV === 'development'
  && location.pathname === '/generate-sitemap'
) {
  const sitemap = renderToString(<Sitemap routes={Routers} prettify />);
  document.body.innerHTML = `
  <div id="sitemap" style="background: gray">${sitemap}</div>
`;
}
