import { store } from '../../../configureStore';

export function transformInfo(product) {
  const { currency } = store.getState().app;
  const targetOriginPrice = (
    Number(product.priceRange?.minVariantPrice.amount) * currency.exchangeRate
  ).toFixed(2);
  const targetDiscountPrice = (
    Number(product.discountPrice) * currency.exchangeRate
  ).toFixed(2);

  const compareAtPrice
    = product.variants?.length >= 1
      ? product.variants[0].compareAtPrice?.amount
      : product.compareAtPriceRange?.minVariantPrice.amount;

  const targetComparePrice = compareAtPrice
    ? (Number(compareAtPrice) * currency.exchangeRate).toFixed(2)
    : null;

  return {
    id: product.id,
    title: product.title || '',
    collections: product.collections
      ? product.collections.length === 0
        ? ''
        : product.collections[0].title
      : '',
    shortDescription: product.shortDescription,
    availableForSale: product.availableForSale,
    discountPrice: targetDiscountPrice,
    discount: product.discountInfo,
    originPrice: targetOriginPrice,
    comparePrice: targetComparePrice,
    desc: product.descriptionHtml,
    variantId: product.variantId,
    tags: product.tags,
    productType: product.productType,
    sku: product.sku,
  };
}
