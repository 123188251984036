import React from 'react';
import PropTypes from 'prop-types';
import { CaptionM } from 'components/styles/body';
import { Container } from './style';

export default function Tag({ children, type = 'primary', ...args }) {
  return (
    <Container type={type} {...args}>
      <CaptionM>{children}</CaptionM>
    </Container>
  );
}

Tag.propTypes = {
  type: PropTypes.string,
};
