import { useMsg } from 'hooks/useMessages';

export function useStrategicPartnerMsgs() {
  const fmtMsg = useMsg('keystone.header.partnerships.strategicPartner');

  return Array.from({ length: 2 }).map((_v, i) => ({
    title: fmtMsg(`t${i + 1}.title`),
    desc: fmtMsg(`t${i + 1}.desc`),
  }));
}

export function useAffiliateMsgs() {
  const fmtMsg = useMsg('keystone.header.partnerships.affiliate');
  return {
    title: fmtMsg('title'),
    desc: fmtMsg('desc'),
  };
}
