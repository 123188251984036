import React, { useCallback, useEffect } from 'react';
import useIsMobile from 'hooks/useIsMobile';
import {
  trackCampaignModalClick,
  trackCampaignModalView,
} from 'tracking/click';
import { EVENT_LIST } from 'hooks/useToggle';
import Twitter from 'components/icons/Twitter';
import Link from 'components/Link';
import { LINKS } from 'config/links';
import Cart from 'components/icons/Cart';
import Lottie from 'react-lottie';
import { CountDown } from './CountDown';
import {
  BgRotate,
  CountDownContainer,
  Desc,
  MainImage,
  MainImageContentWrapper,
  ModalContainer,
  ModalContentWrapper,
  PromotionTitle,
  RainbowButton,
  StageDesc,
  StagePromotionTitle,
  StageTitle,
  Star,
} from './style';
import useMessages from './useMessages';
import flow2Lottie from './lottie/flow2.json';
import flow3Lottie from './lottie/flow3.json';
import flow4Lottie from './lottie/flow4.json';

export function Modal({ isOpen, onClose, event }) {
  const messages = useMessages();
  const isMobile = useIsMobile();
  const namePattern = /^(.*)_(\d+)$/;
  const eventName = event?.name?.match(namePattern)?.[1];
  const phase = event?.name?.slice(-1);

  const close = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const isAlmost = event?.name === EVENT_LIST.BLACK_FRIDAY_2024_ALMOST;

  useEffect(() => {
    if (!eventName) {
      return;
    }
    trackCampaignModalView(eventName);
  }, [eventName]);

  if (!event) {
    return null;
  }

  return (
    <ModalContainer
      open={isOpen}
      defaultOpen
      centered
      width={962}
      transition="scale br"
      closeIcon
      onClose={close}
    >
      <ModalContentWrapper>
        <CountDownContainer>
          {isAlmost ? (
            <PromotionTitle>
              {messages.almostHere.promotionTitle}
            </PromotionTitle>
          ) : (
            <>
              <StagePromotionTitle>
                {messages.stage.promotionTitle}
              </StagePromotionTitle>
              <StageTitle>{messages.stage.title}</StageTitle>
              <StageDesc>{messages.campaigns?.[phase - 1]}</StageDesc>
            </>
          )}
          <CountDown
            endTime={event.dateRange.end}
            onComplete={onClose}
            isAlmost={isAlmost}
          />
          {isAlmost && <Desc>{messages.almostHere.desc}</Desc>}
          <Link
            to={isAlmost ? LINKS.twitter.en : LINKS.shop}
            blank={isAlmost}
            arrow={false}
          >
            <RainbowButton
              icon={isAlmost ? Twitter : <Cart color="var(--color-bd-cyan)" />}
              type="rainbow"
              size="medium"
              onClick={() => {
                trackCampaignModalClick(event?.name);
                if (isAlmost) {
                  return;
                }
                close();
              }}
            >
              {isAlmost ? messages.claimDiscount : messages.buyNow}
            </RainbowButton>
          </Link>
        </CountDownContainer>
        <BgRotate />
      </ModalContentWrapper>
      <MainImageContentWrapper>
        <MainImage />
        {!isMobile && (
          <>
            <Lottie
              options={{
                animationData: flow2Lottie,
                loop: true,
                autoplay: true,
              }}
              width="160px"
              height="240px"
              style={{
                position: 'absolute',
                bottom: '8%',
                left: '-7%',
                transform: 'scale(0.9)',
              }}
            />
            <Lottie
              options={{
                animationData: flow3Lottie,
                loop: true,
                autoplay: true,
              }}
              width="160px"
              height="240px"
              speed={1.2}
              style={{
                position: 'absolute',
                top: '9%',
                right: '-8%',
                transform: 'scale(0.9)',
              }}
            />
            <Lottie
              options={{
                animationData: flow4Lottie,
                loop: true,
                autoplay: true,
              }}
              width="280px"
              height="160px"
              style={{
                position: 'absolute',
                bottom: '-13%',
                right: '6%',
                transform: 'scale(0.9)',
              }}
            />
            <Star />
          </>
        )}
      </MainImageContentWrapper>
    </ModalContainer>
  );
}
