import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { removeProduct } from 'api/cart';
import { setCartList } from 'containers/App/actions';
import Quantity from 'components/Quantity';
import { Loading } from 'components/Loading';
import RecommendList from 'components/RecommendList';
import { debounce } from 'lodash';
import { calculateDiscount, transformPrice } from 'utils/commonUtils';
import ListErrorIcon from 'components/icons/ListErrorIcon';
import { Body, Caption } from 'components/styles/body';
import Button from 'components/Button';
import TopNotification from 'components/TopNotification';
import { logger } from 'logger';
import { ProductPreOrderInfoButton } from 'pages/Cart/style';
import Link from 'components/Link';
import ShoppingCart from 'components/icons/ShoppingCart';
import useFeatureToggle from 'hooks/useFeatureToggle';
import { UNDISTRIBUTED_COUNTRIES } from 'pages/Cart/constants';
import useShippingRegionStore from 'stores/shippingRegions';
import useAppearanceStore from 'stores/appearance';
import useCartStore from '../../stores/cart';
import { genernalMsgs as messages, preOrderMsgs } from './messages';
import {
  Backdrop,
  CartCheckoutPage,
  CartContent,
  CartErrorContent,
  CartLoadingContent,
  CartProductPreOrderInfo,
  CartProductPreOrderInfoFooter,
  CartProductPreOrderInfoLink,
  Content,
  ContentBox,
  Empty,
  EmptyDesc,
  EmptyImg,
  LoadingContent,
  PriceSymbol,
  Prices,
  Product,
  ProductContent,
  ProductImg,
  ProductName,
  ProductNameBox,
  ProductOriginPrice,
  ProductPrice,
  ProductPriceContent,
  ProductPriceDiscount,
  ProductPriceDiscountPrice,
  ProductVariantName,
  Subtotal,
  SubtotalOriginPrice,
  SubtotalPrice,
  SubtotalPriceContent,
  SubtotalProductsPrice,
  SubtotalTitle,
  SubtotalTop,
} from './style';
import CartCheckout from './CartCheckout';
import { CartContext } from './ctx';
import { CartHeader } from './component/CartHeader';
import { CartItemLabel } from './component/CartItemLabel';
import CartDiversion from './component/CartDiversion';

export default function Cart({ open, close }) {
  const [cartError, setCartError] = useState(null);
  const [addProductStatusFailed, setAddProductStatusFailed] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [cartCheckout, setCartCheckout] = useState({
    page: 'cart',
    loading: false,
  });

  const { currency } = useSelector((state) => state.app);
  const isPreOrderEnabled = useFeatureToggle('preOrder');

  const {
    list,
    loadList,
    loading: cartLoading,
    cartData,
    addToCart,
    closeCart,
    recommendList,
    updateRecommendList,
    updateCartLineQuantity,
    setLoading: setCartLoading,
    quantity,
  } = useCartStore();
  const { currentCountry, loadCountryList } = useShippingRegionStore();
  const { lang } = useAppearanceStore();

  const cartClick = async (id) => {
    try {
      const info = await addToCart(id);
      if (!info)
        throw new Error('add product get not response');
    } catch (e) {
      logger.error(e);
      setAddProductStatusFailed(true);
      setCartLoading(false);
    }
  };

  useEffect(() => {
    if (!open)
      return;
    setCartError(null);
    loadCountryList();
    updateRecommendList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, lang]);

  const isUndistributedCountry = useMemo(() => {
    return UNDISTRIBUTED_COUNTRIES.map((country) => country.en.value).includes(
      currentCountry,
    );
  }, [currentCountry]);

  const checkoutDisabled = useMemo(() => {
    if (isUndistributedCountry)
      return false;
    return (
      !currentCountry
      || (list
        && (list.length === 0
          || list.some((it) => it.soldOut && !it.couldPresale)))
    );
  }, [currentCountry, list, isUndistributedCountry]);

  const needPresale
    = list?.some((it) => it.soldOut)
    && list?.filter((it) => it.soldOut)?.every((it) => it.couldPresale);

  const replaceToPreOrderProduct = useCallback(
    async (data) => {
      try {
        await addToCart(data.preOrder.variantId, data.quantity);
        await removeProduct(data.id);
      } catch (e) {
        setCartError(e);
      }
    },
    [addToCart],
  );

  const completeCartItemDiscount = useCallback(
    (item) => {
      const itemId = item.id;
      dispatch(
        setCartList(
          list.map((it) => {
            if (it.id === itemId) {
              it.completeDiscount = true;
            }
            return it;
          }),
        ),
      );
    },
    [list, dispatch],
  );

  return (
    <>
      <CartContent open={open} className={open ? 'active-mobile' : ''}>
        <CartHeader cartQuantity={quantity} close={close} />
        {cartError ? (
          <CartErrorContent>
            <ListErrorIcon />
            <Body>
              <FormattedMessage id="keystone.loadingFailed" />
            </Body>
            <Button
              type="primary"
              size="small"
              style={{ marginTop: 48 }}
              onClick={() => loadList()}
            >
              <FormattedMessage id="keystone.retry" />
            </Button>
          </CartErrorContent>
        ) : (
          cartData && (
            <>
              {isUndistributedCountry ? (
                <CartDiversion isCartPageOnDesktop={false} />
              ) : (
                <Content
                  isEmpty={list && list.length === 0}
                  isRecommendEmpty={recommendList && recommendList.length === 0}
                  isSaveInfoShow={cartData.subTotal?.savedAmount > 0}
                >
                  {list
                    && list.length > 0
                    && list.map((item, index) => {
                      const productImage = item.custom?.productImage && (
                        <ProductImg src={item.custom?.productImage} />
                      );

                      return (
                        <Product key={item.id}>
                          {item.custom?.productLink ? (
                            <Link
                              to={item.custom?.productLink}
                              arrow={false}
                              onClick={closeCart}
                            >
                              {productImage}
                            </Link>
                          ) : (
                            productImage
                          )}
                          <ContentBox>
                            <ProductNameBox
                              {...(item.custom?.productLink && {
                                as: Link,
                                to: item.custom?.productLink,
                                arrow: false,
                                onClick: closeCart,
                              })}
                            >
                              <ProductName>
                                {item.custom?.productTitle}
                              </ProductName>
                              <ProductVariantName>
                                {item.custom?.productVariantName}
                              </ProductVariantName>
                            </ProductNameBox>
                            <ProductContent>
                              <ProductPriceContent>
                                {item.custom?.isShowStrikethroughPrice ? (
                                  <ProductPriceDiscount>
                                    <ProductPriceDiscountPrice>
                                      <ProductOriginPrice>
                                        {currency.symbol}
                                        {transformPrice(
                                          item.custom?.strikethroughPrice,
                                          currency.exchangeRate,
                                        )}
                                      </ProductOriginPrice>
                                      <ProductPrice>
                                        <PriceSymbol>
                                          {currency.symbol}
                                        </PriceSymbol>
                                        {calculateDiscount(item, currency)}
                                      </ProductPrice>
                                    </ProductPriceDiscountPrice>
                                  </ProductPriceDiscount>
                                ) : (
                                  <ProductPrice>
                                    <PriceSymbol>{currency.symbol}</PriceSymbol>
                                    {transformPrice(
                                      item.custom?.originPrice,
                                      currency.exchangeRate,
                                    )}
                                  </ProductPrice>
                                )}
                              </ProductPriceContent>
                              {!item.loading ? (
                                <Quantity
                                  className="cart-quantity"
                                  value={item.quantity}
                                  onChange={debounce((number) => {
                                    updateCartLineQuantity(item, number);
                                  }, 300)}
                                />
                              ) : (
                                <Loading />
                              )}
                            </ProductContent>
                          </ContentBox>
                          <CartItemLabel
                            item={item}
                            completeCartItemDiscount={completeCartItemDiscount}
                          />
                          {isPreOrderEnabled
                            && item.preOrder
                            && item.soldOut && (
                            <CartProductPreOrderInfo key="europePreOrder">
                              <Caption>
                                <FormattedMessage {...preOrderMsgs.tip} />
                              </Caption>
                              <CartProductPreOrderInfoFooter>
                                <CartProductPreOrderInfoLink
                                  to={item.preOrder.path}
                                >
                                  <FormattedMessage id="keystone.details" />
                                </CartProductPreOrderInfoLink>
                                <ProductPreOrderInfoButton
                                  type="primary"
                                  onClick={() =>
                                    replaceToPreOrderProduct(item)}
                                >
                                  <ShoppingCart />
                                </ProductPreOrderInfoButton>
                              </CartProductPreOrderInfoFooter>
                            </CartProductPreOrderInfo>
                          )}
                        </Product>
                      );
                    })}
                  {cartLoading ? (
                    <CartLoadingContent>
                      <Loading
                        color="var(--color-fg-muted)"
                        width="84px"
                        height="6px"
                      />
                    </CartLoadingContent>
                  ) : (
                    list?.length === 0 && (
                      <Empty>
                        <EmptyImg src={require('./images/bag.svg')} />
                        <EmptyDesc>
                          <FormattedMessage {...messages.emptyDesc} />
                        </EmptyDesc>
                      </Empty>
                    )
                  )}
                  {recommendList && recommendList.length > 0 && (
                    <RecommendList
                      list={recommendList}
                      cartClick={cartClick}
                      closeCart={closeCart}
                    />
                  )}
                </Content>
              )}
              <CartContext.Provider
                value={{
                  checkout: cartCheckout,
                  setCheckout: setCartCheckout,
                  isCartPage: false,
                }}
              >
                <Subtotal>
                  {list && list.length !== 0 && (
                    <>
                      {cartCheckout.page === 'cart' && (
                        <CartCheckoutPage>
                          {!isUndistributedCountry && (
                            <SubtotalTop>
                              {list.length !== 0 || !cartLoading ? (
                                <SubtotalPriceContent>
                                  <SubtotalProductsPrice>
                                    <SubtotalTitle>
                                      <FormattedMessage
                                        {...messages.subtotalTitle}
                                      />
                                    </SubtotalTitle>
                                    <Prices>
                                      {cartData.subTotal?.subtotalAmount
                                        !== cartData.subTotal?.totalAmount && (
                                        <SubtotalOriginPrice>
                                          {currency.symbol}
                                          {transformPrice(
                                            cartData.subTotal?.subtotalAmount,
                                            currency.exchangeRate,
                                          )}
                                        </SubtotalOriginPrice>
                                      )}
                                      <SubtotalPrice>
                                        {currency.symbol}
                                        {transformPrice(
                                          cartData.subTotal?.totalAmount,
                                          currency.exchangeRate,
                                        )}
                                      </SubtotalPrice>
                                    </Prices>
                                  </SubtotalProductsPrice>
                                </SubtotalPriceContent>
                              ) : (
                                <LoadingContent>
                                  <Loading color="var(--color-fg-muted)" />
                                </LoadingContent>
                              )}
                            </SubtotalTop>
                          )}
                        </CartCheckoutPage>
                      )}
                      <CartCheckout
                        loading={list.length === 0 && cartLoading}
                        disabled={checkoutDisabled}
                        isUndistributedCountry={isUndistributedCountry}
                        needPresale={needPresale}
                      />
                    </>
                  )}
                  {list?.length === 0 && (
                    <CartCheckout
                      loading={list.length === 0 && cartLoading}
                      disabled={checkoutDisabled}
                      isUndistributedCountry={isUndistributedCountry}
                      needPresale={needPresale}
                    />
                  )}
                </Subtotal>
              </CartContext.Provider>
            </>
          )
        )}
      </CartContent>
      <TopNotification
        message={intl.formatMessage({ id: 'keystone.addProductFailed' })}
        visible={addProductStatusFailed}
        onClose={() => setAddProductStatusFailed(false)}
      />
      {open && <Backdrop onClick={close} />}
    </>
  );
}
