/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { DEFAULT_LOCALE } from 'i18n';
import { Loading } from 'components/Loading';
import useAppearanceStore from 'stores/appearance';
import useCartStore from 'stores/cart';

const defaultRichTextElements = {
  br: <br />,
};

function formatTranslationMessages(locale, messages) {
  const defaultFormattedMessages
    = locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, messages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage
      = !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
}

export default function LanguageProvider(props) {
  const { lang } = useAppearanceStore();
  const { loadList } = useCartStore();

  const [messages, setMessages] = useState(null);

  useEffect(() => {
    import(
      /* webpackChunkName: "[request]" */ `../../translations/${lang}.json`
    )
      .then((messages_) => {
        return setMessages(formatTranslationMessages(lang, messages_.default));
      })
      .catch((err) =>
        console.error(`Error loading messages for locale ${lang}: `, err),
      );

    loadList();
  }, [lang, loadList]);

  return !messages ? (
    <Loading />
  ) : (
    <IntlProvider
      locale={lang}
      messages={messages}
      defaultRichTextElements={defaultRichTextElements}
    >
      {props.children}
    </IntlProvider>
  );
}
