import { ProductsConfig } from 'config/products';
import {
  mainMsgs as messages,
  productListMsgs,
  productStatusMsgs,
} from '../messages';

export const PRODUCTS_MENU_GROUP = [
  {
    id: 'hardware',
    title: messages.productsHardware,
    list: [
      {
        id: 'k3Pro',
        href: ProductsConfig.k3Pro.path,
        title: productListMsgs.g3Pro,
        img: require('./images/keystone-3-pro.jpg'),
        blank: false,
      },
      {
        id: 'bundlePack',
        href: ProductsConfig.k3ProBundlePack.path,
        title: productListMsgs.bundlePack,
        img: require('./images/keystone-bundle.jpg'),
        blank: false,
        tags: [
          {
            tagText: productStatusMsgs.dollarSaved,
            tagColor: 'oranger',
            tagVariant: {
              discount: 10,
            },
          },
          {
            tagText: productStatusMsgs.hot,
            tagColor: 'pink',
          },
        ],
      },
      {
        id: 'k3ProLimitedEdition',
        href: ProductsConfig.k3ProLimitedEdition.path,
        title: productListMsgs.g3ProLimitedEdition,
        img: require('./images/keystone-3-pro-limited-edition.jpg'),
        blank: false,
      },
      {
        id: 'k3ProNFTCustomization',
        href: ProductsConfig.k3ProNFTCustomization.path,
        title: productListMsgs.g3ProNFTCustomization,
        img: require('./images/keystone-3-pro-nft-customization.jpg'),
        blank: false,
      },
    ],
  },
  {
    id: 'accessories',
    title: messages.productsAccessories,
    list: [
      {
        id: 'tablet',
        href: ProductsConfig.tablet.path,
        title: productListMsgs.t3,
        tag: false,
        img: require('./images/kt002.jpg'),
        blank: false,
      },
      {
        id: 'tabletPlus',
        href: ProductsConfig.tabletPlus.path,
        title: productListMsgs.t4,
        tag: true,
        img: require('./images/kt003.jpg'),
        blank: false,
      },
      {
        id: 'tabletPunch',
        href: ProductsConfig.tabletPunch.path,
        title: productListMsgs.t5,
        tag: false,
        img: require('./images/kt004.jpg'),
        blank: false,
      },
      {
        id: 'walletPouch',
        href: ProductsConfig.pouch2.path,
        title: productListMsgs.t9,
        tag: true,
        tagColor: 'purple',
        tagText: productStatusMsgs.new,
        img: require('./images/pouch2.jpg'),
        blank: false,
      },
      {
        id: 'tabletPouch',
        href: ProductsConfig.pouch.path,
        title: productListMsgs.t6,
        tag: false,
        img: require('./images/pouch.jpg'),
        blank: false,
      },
    ],
  },
  {
    id: 'all',
    list: [
      {
        to: '/shop',
        title: messages.productsAll,
      },
    ],
  },
];
