import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 'toast theme-dark',
})`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  width: 800px;
  background: var(--color-bg-subtle);
  border: 1px solid var(--color-border-subtle);
  color: var(--color-fg-muted);
  z-index: ${(props) => props.index || 200};
  ${({ place = 'top center', top, bottom, edgeGap = '16px' }) => {
    const [x, y] = place.split(' ');

    let left = 0;
    top = top || 0;
    let right = 0;
    bottom = bottom || 0;

    if (x === 'top') {
      top = top || edgeGap;
    } else if (x === 'bottom') {
      bottom = bottom || edgeGap;
    }
    if (y === 'left') {
      left = edgeGap;
    } else if (y === 'center') {
      left = '50%';
    } else if (y === 'right') {
      right = edgeGap;
    }

    return `
      ${top ? `top: ${top};` : ''}
      ${bottom ? `bottom: ${bottom};` : ''}
      ${left ? `left: ${left};` : ''}
      ${y === 'center' ? 'transform: translateX(-50%);' : ''}
      ${right ? `right: ${right};` : ''}
    `;
  }}
`;
