import React, { useCallback, useState } from 'react';
import { H5, H6 } from 'components/styles/title';
import Modal from 'components/Modal';
import { LINKS } from 'config/links';
import Link from 'components/Link';
import { isNowInDateRange } from 'hooks/useToggle';
import useMessages from './useMessages';
import { ModalButton, ModalContent, ModalText } from './style';

let isClose = false;
const LEDGER_BEGIN_TIME = '2023-12-18 12:00:00';
const LEDGER_END_TIME = '2023-12-23 12:00:00';
const isShowLedger = isNowInDateRange(LEDGER_BEGIN_TIME, LEDGER_END_TIME);

export function LedgerConnectKit() {
  const { title, desc1, desc2, desc3, buyNow } = useMessages();
  const [shouldShowDiscountModal, setShouldShowDiscountModal] = useState(true);

  const close = useCallback(() => {
    setShouldShowDiscountModal(false);
    isClose = true;
  }, []);

  if (!isShowLedger)
    return null;

  return (
    <Modal
      defaultOpen
      open={!isClose && shouldShowDiscountModal}
      centered
      closeIcon
      width={716}
      onClose={close}
      style={{ marginTop: 160 }}
    >
      <ModalContent>
        <H5>{title}</H5>
        <ModalText>{desc1}</ModalText>
        <ModalText>{desc2}</ModalText>
        <ModalText>{desc3}</ModalText>
        <Link to={LINKS.shopProducts.k3Pro} onClick={close} arrow={false}>
          <ModalButton>
            <H6>{buyNow}</H6>
          </ModalButton>
        </Link>
      </ModalContent>
    </Modal>
  );
}
