import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ShoppingCart from 'components/icons/ShoppingCart';
import Copy from 'components/icons/Copy';
import { setBannerModalCode } from 'containers/App/actions';
import useIsMobile from 'hooks/useIsMobile';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import CheckCircle from 'components/icons/CheckCircle';
import { LINKS } from 'config/links';
import { TrackEvent, useTrack } from 'tracking';
import messages from '../messages';
import {
  EasterMageCodeBanner,
  EasterMageCodeCopyCode,
  EasterMageCodeDesc,
  EasterMageCodeTitle,
  EasterMageContainer,
  EasterMageContent,
  EasterMageSection,
  EasterMageSectionCodeButton,
} from './style';

export default function EasterMageCode({ codes, onClose }) {
  const { banner } = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { track } = useTrack();
  const isMobile = useIsMobile();
  const [_copyedValue, copy] = useCopyToClipboard();

  const toShop = useCallback(() => {
    track(TrackEvent.Navigation, {
      to: LINKS.shop,
      dom_id: 'banner-modal-easter-mage-shop',
    });
    navigate(LINKS.shop);
    onClose && onClose();
  }, [navigate, onClose, track]);

  useEffect(() => {
    if (banner.modal.opened === 0)
      return;
    dispatch(setBannerModalCode(codes[banner.modal.opened.length - 1]));
    track(TrackEvent.Modal, {
      progress: banner.modal.opened.length,
      dom_id: 'banner-modal-easter-mage',
    });
  }, [banner.modal.opened, codes, dispatch, track]);

  const bannerImage = useMemo(
    () => require(`./images/banner${isMobile ? '-m' : ''}.png`),
    [isMobile],
  );

  const text = useMemo(() => {
    const index = banner.modal.opened.length - 1;
    if (index < 0)
      return {};
    return {
      title: intl.formatMessage(messages[`codeTitle${index}`], {
        em: (chunk) => <span>{chunk}</span>,
      }),
      desc: intl.formatMessage(messages[`codeDesc${index}`], {
        em: (chunk) => <span>{chunk}</span>,
      }),
    };
  }, [banner.modal.opened.length, intl]);

  const [isCopyedVisible, setIsCopyedVisible] = useState(false);
  const copyCode = useCallback(() => {
    copy(banner.modal.code);
    setIsCopyedVisible(true);
    setTimeout(() => {
      setIsCopyedVisible(false);
    }, 2000);
  }, [banner.modal.code, copy]);

  return (
    <EasterMageContainer>
      <EasterMageContent>
        <EasterMageCodeBanner src={bannerImage} />
        <EasterMageSection>
          <EasterMageCodeTitle>
            <span>{text.title}</span>
          </EasterMageCodeTitle>
          <EasterMageCodeDesc>{text.desc}</EasterMageCodeDesc>
          <EasterMageCodeCopyCode>
            <span>{banner.modal.code}</span>
            {isCopyedVisible
              ? (
                  <CheckCircle
                    width="24"
                    height="24"
                    className="copy_icon-success"
                  />
                )
              : (
                  <Copy onClick={copyCode} className="copy_icon" />
                )}
          </EasterMageCodeCopyCode>
          <EasterMageSectionCodeButton icon={ShoppingCart} onClick={toShop}>
            <FormattedMessage {...messages.codeButton} />
          </EasterMageSectionCodeButton>
        </EasterMageSection>
      </EasterMageContent>
    </EasterMageContainer>
  );
}
