import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import Popover from 'components/Popover';
import Select from 'components/Select';
import ArrowRight from 'components/icons/ArrowRight';
import { useTrack } from 'tracking';
import { setLocaleEn, setLocaleZh } from 'plugins/dayjs';
import useAppearanceStore from 'stores/appearance';
import { useMsg } from 'hooks/useMessages';
import { H7 } from 'components/styles/titleNew';
import { LangButtonContainer, LangContainer } from './index.style';

export const languageOptions = [
  {
    name: 'English',
    value: 'en',
  },
  {
    name: '简体中文',
    value: 'zh',
  },
];

export default function Language() {
  const intl = useIntl();
  const fmtMsg = useMsg();
  const { track } = useTrack();
  const dispatch = useDispatch();
  const { setLang: setAppLang } = useAppearanceStore();

  useEffect(() => {
    if (intl.locale === 'zh') {
      setLocaleZh();
    } else {
      setLocaleEn();
    }
  }, [dispatch, intl.locale, setAppLang]);

  const onSelectChange = useCallback(
    (data) => {
      setAppLang(data.value);
      track('IOCount', {
        dom_id: 'language',
        pos: 'header',
        to: data.value,
      });
      if (intl.locale === 'zh') {
        setLocaleZh();
      } else {
        setLocaleEn();
      }
    },
    [intl.locale, setAppLang, track],
  );

  return (
    <LangContainer>
      <Popover
        placement="bottom"
        transition="slide bottom-10"
        content={(
          <Select
            value={intl.locale}
            options={languageOptions}
            onChange={onSelectChange}
          />
        )}
      >
        <LangButtonContainer>
          <H7>{fmtMsg('language')}</H7>
          <ArrowRight className="right-arrow" />
        </LangButtonContainer>
      </Popover>
    </LangContainer>
  );
}
