import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import { ProductPreOrderInfo, ProductPreOrderInfoLink } from 'pages/Cart/style';
import { FlexBetween, FlexEnd } from 'components/styles/flex';
import { Body, CaptionM, OverlineStyle } from 'components/styles/body';
import Tag from 'components/Tag';
import { BodyPlus, Caption, Overline } from 'components/styles/bodyNew';
import { H7 } from 'components/styles/titleNew';
import { H5, H6 } from '../styles/title';
import Cart from '../icons/Cart';
import Button from '../Button';

export * from './component/CartItemLabel/style';

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1100;
  top: 0;
  right: 0;
`;

export const CartContent = styled.div.attrs({
  className: 'cart_component theme-dark',
})`
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--color-bg-subtle);
  top: 0;
  right: 0;
  width: 542px;
  z-index: 1200;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: ${(props) =>
    props.open === true ? 'translateX(0)' : 'translateX(100%)'};
  transition: transform 0.3s ease-out;
  border: 1px solid var(--color-border-subtle);
  @media ${DEVICE_QUERY_MOBILE} {
    width: 100%;
    left: 0;
    bottom: 0;
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
  }
`;

export const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 14px 32px 0;
  & > div:last-child {
    border: ${(props) => props.isRecommendEmpty && 'none'};
  }
  @media ${DEVICE_QUERY_MOBILE} {
    padding: 14px 20px 0;
  }
`;

export const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 8vh 0;
`;

export const EmptyImg = styled.img`
  width: 80px;
  height: 80px;
`;

export const BaseP = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
`;

export const EmptyDesc = styled(BaseP)`
  margin-top: 24px;
  color: var(--color-fg-subtle);
  font-family: 'Open Sans';
`;

export const Product = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: end;
  height: auto;
  margin-top: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color-border-subtle);
`;

export const ContentBox = styled.div`
  margin-left: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100% - 96px - 20px);
  height: 96px;
  @media ${DEVICE_QUERY_MOBILE} {
    margin-left: 10px;
    width: calc(100% - 80px - 20px);
  }
`;

export const ProductImg = styled.img`
  width: 96px;
  height: 96px;
  @media ${DEVICE_QUERY_MOBILE} {
    width: 80px;
    height: 80px;
  }
`;

export const ProductNameBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductName = styled(H7)`
  width: 100%;
  color: var(--color-fg-default);
  text-align: left;
  cursor: pointer;
`;

export const ProductVariantName = styled(Overline)`
  margin-top: 6px;
  color: var(--color-fg-muted);
`;

export const ProductContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  @media ${DEVICE_QUERY_MOBILE} {
    .cart-quantity {
      width: 78px;
    }
  }
`;

export const ProductPriceContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PriceSymbol = styled(CaptionM).attrs({ as: 'span' })`
  display: inline;
`;

export const ProductPrice = styled(BodyPlus)`
  color: var(--color-bd-pink);
  white-space: nowrap;
`;

export const ProductOriginPrice = styled(Caption)`
  color: var(--color-fg-subtle);
  text-decoration: line-through;
  white-space: nowrap;
`;

export const ProductPriceDiscount = styled(FlexBetween)`
  align-items: end;
  gap: 0 12px;
  @media ${DEVICE_QUERY_MOBILE} {
    gap: 0 8px;
  }
`;

export const CartCheckoutCloseContainer = styled(FlexEnd)`
  width: 100%;
  span {
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const ProductPriceDiscountPrice = styled(FlexBetween)`
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const ProductPriceDiscountSaleOff = styled(Tag).attrs({
  type: 'pink',
})`
  padding: 4px 6px;
  white-space: nowrap;
  .caption {
    font-weight: 400;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    .caption {
      ${OverlineStyle}
    }
  }
`;

export const Recommended = styled.div`
  margin-top: 24px;
`;

export const RecommendedTitle = styled(H6)`
  margin: 24px 0;
  color: var(--color-fg-default);
  font-family: 'Montserrat';
`;

export const RecommendedList = styled.div`
  display: flex;
  justify-content: flex-start;
  & > div:nth-child(2) {
    margin: 0 12px;
  }
`;

export const ReProduct = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 120px;
`;

export const ReProductImg = styled.img`
  width: 120px;
  height: 120px;
  @media ${DEVICE_QUERY_MOBILE} {
    width: 96px;
    height: 96px;
  }
`;

export const ReBaseP = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.04em;
`;

export const ReProductName = styled(ReBaseP)`
  min-height: 44px;
  margin-top: 8px;
  color: var(--color-fg-default);
`;

export const ReProductPriceContent = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ReProductOriginPrice = styled(ReBaseP)`
  margin-right: 8px;
  color: var(--color-fg-subtle);
  text-decoration: line-through;
`;

export const ReProductPrice = styled(ReBaseP)`
  color: var(--color-fg-default);
`;

export const OrangeCartIcon = styled(Cart)`
  color: var(--color-bd-oranger);
`;

export const CartButton = styled(Button)`
  position: relative;
  width: 64px;
  height: 40px;
  margin-top: 16px;
  background: var(--color-bg-default-trans);
  border-image: var(--color-gd-primary) 1;
  & > span:first-child {
    margin-right: 0 !important;
  }
`;

export const Subtotal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: auto;
  padding: 24px 32px;
  border-top: 1px solid var(--color-border-subtle);
  background: var(--color-bg-muted);
  @media ${DEVICE_QUERY_MOBILE} {
    padding: 24px;
  }
`;

export const SubtotalTop = styled.div``;

export const SubtotalTitle = styled(H5)`
  color: var(--color-fg-emphasis);
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const SubtotalPriceContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubtotalProductsPrice = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SubtotalBaseP = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: right;
  letter-spacing: 0.04em;
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Prices = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const SubtotalOriginPrice = styled(H6)`
  margin-right: 8px;
  color: var(--color-fg-subtle);
  text-decoration: line-through;
`;

export const SubtotalPrice = styled(H5)`
  color: var(--color-fg-default);
  line-height: 28px;
`;

export const SubtotalDiscountInfo = styled(BaseP)`
  display: ${(props) => (props.show ? 'block' : 'none')};
  margin-top: 12px;
  color: var(--color-bd-oranger);
  text-align: right;
  @media ${DEVICE_QUERY_MOBILE} {
    margin-top: 8px;
    font-size: 14px;
    line-height: 22px;
  }
`;

export const CartCheckoutPage = styled.div``;

export const SubtotalDesc = styled(ReBaseP).attrs({
  className: 'subtotal-desc',
})`
  margin: 4px 0;
  color: ${(props) =>
    props.isTargetCountryInCustomTip
      ? 'var(--color-bd-oranger)'
      : 'var(--color-fg-subtle)'};
  + .subtotal-desc {
    margin-top: 4px;
    margin-bottom: 24px;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    margin: 4px 0 16px 0;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const CartCheckoutPageTitle = styled(Body)`
  color: var(--color-fg-default);
  + ${SubtotalDesc} {
    margin-bottom: 24px;
  }
`;

export const LoadingContent = styled.div`
  display: flex;
  justify-content: center;
  margin: 12px 0;
`;

export const LoadingItemLabel = styled.div`
  height: 34px;
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CartLoadingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5vh;
}
`;

export const CartErrorContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px 0;
  left: calc(50% - 50px);
  top: calc(50% - 96px);
  .body {
    color: var(--color-fg-subtle);
  }
`;

export const SubtotalLoadingContent = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${(props) => (props.show ? '30px 0' : '0')};
`;

export const CartProductPreOrderInfo = styled(ProductPreOrderInfo)`
  flex-wrap: wrap;
  margin-top: 16px;
  padding: 12px 16px;
  background: var(--color-bg-default-trans);
`;

export const CartProductPreOrderInfoFooter = styled(FlexBetween)`
  align-items: center;
  gap: 12px 0;
  width: 100%;
`;

export const CartProductPreOrderInfoLink = styled(ProductPreOrderInfoLink)`
  margin-top: 0;
`;
