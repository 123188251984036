export const UNDISTRIBUTED_COUNTRIES = [
  {
    en: {
      name: 'China',
      continent: 'Asia',
      value: 'CN',
    },
    zh: {
      name: '中国',
      continent: '亚洲',
      value: 'CN',
    },
  },
  {
    en: {
      name: 'Macau',
      continent: 'Asia',
      value: 'MO',
    },
    zh: {
      name: '澳门',
      continent: '亚洲',
      value: 'MO',
    },
  },
  {
    en: {
      name: 'Taiwan',
      continent: 'Asia',
      value: 'TW',
    },
    zh: {
      name: '台湾',
      continent: '亚洲',
      value: 'TW',
    },
  },
  {
    en: {
      name: 'Korea',
      continent: 'Asia',
      value: 'KR',
    },
    zh: {
      name: '韩国',
      continent: '亚洲',
      value: 'KR',
    },
  },
  {
    en: {
      name: 'Russia',
      continent: 'Asia',
      value: 'RU',
    },
    zh: {
      name: '俄罗斯',
      continent: '亚洲',
      value: 'RU',
    },
  },
  {
    en: {
      name: 'Turkey',
      continent: 'Europe',
      value: 'TR',
    },
    zh: {
      name: '土耳其',
      continent: '欧洲',
      value: 'TR',
    },
  },
];
