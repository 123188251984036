import React, { useCallback } from 'react';
import { useTrack } from 'tracking';
import useAppearanceStore from 'stores/appearance';
import { languageOptions } from '../Language';
import { Submenu } from '../style';
import { Item, List, Main } from './index.style';

function LanguageSubmenu({ open, onClose, ...args }) {
  const { track } = useTrack();
  const { setLang: setAppLang } = useAppearanceStore();

  const onSelectChange = useCallback(
    (data) => {
      setAppLang(data.value);
      track('IOCount', {
        dom_id: 'language',
        pos: 'header',
        to: data.value,
      });
      onClose();
    },
    [setAppLang, track, onClose],
  );

  return (
    <Submenu height={350} className={open ? 'active' : ''} {...args}>
      <Main>
        <List>
          {languageOptions.map((item) => (
            <Item key={item.name} onClick={() => onSelectChange(item)}>
              {item.name}
            </Item>
          ))}
        </List>
      </Main>
    </Submenu>
  );
}

export default LanguageSubmenu;
