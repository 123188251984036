import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import { BodyPlus } from '../../styles/body';
import { Container } from '../../styles/layout';

export const Main = styled(Container)`
  padding: 56px 0;
  color: var(--color-fg-muted);
  display: flex;
  justify-content: center;
  @media ${DEVICE_QUERY_MOBILE} {
    display: block;
    padding: 0;
  }
`;

export const List = styled(BodyPlus)`
  display: flex;
  width: 872px;
  align-items: flex-start;
  gap: 32px 16px;
  flex-wrap: wrap;
  color: var(--color-fg-muted);
  @media ${DEVICE_QUERY_MOBILE} {
    width: auto;
    height: auto;
    gap: 16px;
  }
`;

export const Item = styled.div`
  width: 280px;
  padding: 16px 24px;
  &:hover {
    color: var(--color-fg-emphasis);
    background-color: var(--color-bg-subtle);
    .link-arrow {
      opacity: 1;
      color: var(--color-bd-primary);
      transform: translateX(6px);
    }
    a {
      color: var(--color-fg-emphasis);
    }
    cursor: pointer;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    width: 100%;
    &:hover {
      background: var(--color-bg-slight);
    }
  }
`;

export const ItemIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-right: 12px;
  @media ${DEVICE_QUERY_MOBILE} {
    margin-right: 16px;
  }
`;
