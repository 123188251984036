import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

function sanitizePath(scope, id = '') {
  const parts = `${scope}.${id}`.split('.');
  const cleanParts = parts.filter((part) => part !== '');
  return cleanParts.join('.');
}

export function useMsg(scope = '', descriptorOnly = false) {
  const intl = useIntl();
  const [defaultMessages, setDefaultMessages] = useState(null);

  useEffect(() => {
    if (defaultMessages)
      return;

    import(
      /* webpackChunkName: "default_translation" */ '../translations/en.json'
    )
      .then((messages_) => setDefaultMessages(messages_))
      .catch((err) =>
        console.error('Error loading messages for locale en: ', err),
      );
  }, [defaultMessages]);

  const getMessageDescriptor = useCallback(
    (maybeId, rest) => {
      const defaultMessage = rest.find((obj) =>
        Object.entries(obj).find(([k, _]) => k === 'defaultMessage'),
      )?.defaultMessage;

      const buildDescriptor = () => {
        const descriptor = {};
        if (typeof maybeId === 'string') {
          const path = sanitizePath(scope, maybeId);
          descriptor.id = path;
          descriptor.defaultMessage
            = defaultMessage ?? get(defaultMessages, path);
        } else if (typeof maybeId === 'object') {
          Object.assign(descriptor, maybeId);
        }
        return descriptor;
      };

      return buildDescriptor();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scope, defaultMessages, intl],
  );

  return useCallback(
    (maybeId, ...rest) => {
      const descriptor = getMessageDescriptor(maybeId, rest);
      if (descriptorOnly) {
        return [descriptor, ...rest];
      }
      return intl.formatMessage(descriptor, ...rest);
    },
    [descriptorOnly, intl, getMessageDescriptor],
  );
}

export function useI18nDescriptor(scope = '') {
  const messagesGetter = useMsg(scope, true);
  return useCallback(
    (...args) => messagesGetter(...args)?.[0] ?? {},
    [messagesGetter],
  );
}
