import React from 'react';
import classNames from 'classnames';
import ArrowRight from '../icons/ArrowRight';
import ExitLink from '../icons/ExitLink';
import { LinkWrapper } from './style';

export default function Link({
  arrow = true,
  exit,
  to = '',
  children,
  blank,
  href,
  onClick,
  className,
  ...args
}) {
  const hrefStartWithSlash = href?.startsWith('/');
  const hrefOrToArgs
    = href && !hrefStartWithSlash
      ? { as: 'a', href }
      : hrefStartWithSlash || to
        ? { to: to || href }
        : { as: 'div' };
  const toArgs = {
    ...(!onClick ? hrefOrToArgs : { onClick, ...hrefOrToArgs }),
  };
  return (
    <LinkWrapper
      {...toArgs}
      target={blank ? '_blank' : '_self'}
      className={classNames('link-wrapper', className)}
      {...args}
    >
      <span className="link-text">{children}</span>
      {arrow
        && (exit ? (
          <ExitLink className="link-arrow" />
        ) : (
          <ArrowRight className="link-arrow" />
        ))}
    </LinkWrapper>
  );
}
