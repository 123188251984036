import Button from 'components/Button';
import Link from 'components/Link';
import Egg from 'components/icons/Egg';
import { BodyPlus } from 'components/styles/body';
import { FlexBetween, FlexCenter } from 'components/styles/flex';
import {
  H6,
  H7aStyle,
  MontFont,
  MontserratFont,
  montFontStyle,
} from 'components/styles/title';
import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';

export const EasterMageContainer = styled.div`
  height: 100%;
  background: radial-gradient(
      24.53% 25.49% at 96.22% 0%,
      rgba(80, 114, 233, 0.8) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      33.65% 31.03% at 1.36% 105.98%,
      rgba(27, 241, 255, 0.4) 0%,
      rgba(0, 0, 0, 0) 87.75%
    ),
    #1d1e24;
`;

export const EasterMageContent = styled(FlexCenter)`
  justify-content: space-between;
  align-items: center;
  gap: 0 30px;
  padding: 0 48px;
  height: 100%;
  z-index: 1;
  @media ${DEVICE_QUERY_MOBILE} {
    flex-direction: column;
    padding: 0 30px 12%;
  }
`;

export const EasterMageBgGrid = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  z-index: 0;
`;

export const EasterMageBanner = styled.img`
  width: 58%;
  max-width: 560px;
  aspect-ratio: 1 / 0.938;
  @media ${DEVICE_QUERY_MOBILE} {
    margin-top: 15px;
    width: auto;
    max-width: auto;
    max-height: 230px;
  }
`;

export const EasterMageSection = styled(FlexBetween)`
  align-items: flex-start;
  flex-direction: column;
  max-width: 395px;
  @media ${DEVICE_QUERY_MOBILE} {
    max-width: 100%;
  }
`;

export const EasterMageSectionTitle = styled(MontserratFont)`
  font-size: 32px;
  line-height: 40px;
  font-weight: 800;
  text-align: center;
  span {
    background-image: linear-gradient(267deg, #1be0c6 0%, #1dbbf5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    display: none;
  }
`;

export const EasterMageSectionDesc = styled(MontFont)`
  margin: 16px 0 34px;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: var(--color-fg-muted);
  letter-spacing: 0.03em;
  span {
    color: var(--color-bd-cyan);
  }
  @media ${DEVICE_QUERY_MOBILE} {
    margin: 10px 0 0;
    font-size: 10px;
    line-height: 16px;
  }
`;

export const EasterMageSectionUl = styled.ul`
  @media ${DEVICE_QUERY_MOBILE} {
    margin-top: 10px;
  }
`;

export const EasterMageSectionLi = styled.li`
  padding-left: 30px;
  ${montFontStyle}
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: var(--color-fg-default);
  letter-spacing: 0.05em;
  list-style: none;
  + li {
    margin-top: 24px;
  }
  span {
    color: var(--color-bd-cyan);
  }
  ::before {
    content: '';
    position: absolute;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 32px;
    background: url(${require('./images/star.png')}) no-repeat center center /
      cover;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 14px;
    line-height: 18px;
    padding-left: 0;
    letter-spacing: 0;
    text-align: center;
    + li {
      margin-top: 12px;
    }
    ::before {
      display: none;
    }
  }
`;

export const EasterMageSectionButton = styled(Button).attrs({
  type: 'rainbow',
})`
  margin: 48px auto 0;
  @media ${DEVICE_QUERY_MOBILE} {
    margin-top: 15px;
    height: 36px;
    font-size: 14px;
    line-height: 22px;
  }
`;

export const EasterMageSectionBuy = styled(Link).attrs({ arrow: false })`
  margin: 26px auto 0;
  color: var(--color-bd-cyan);
  text-decoration: underline;
  :hover {
    color: var(--color-fg-muted);
  }
  @media ${DEVICE_QUERY_MOBILE} {
    display: none;
  }
`;

export const EasterMageFooter = styled(BodyPlus)`
  position: absolute;
  left: 50%;
  bottom: 15px;
  transform: translateX(-50%);
  color: var(--color-fg-muted);
  @media ${DEVICE_QUERY_MOBILE} {
    display: none;
  }
`;

export const EasterMageCodeBanner = styled(EasterMageBanner)`
  @media ${DEVICE_QUERY_MOBILE} {
    margin-top: 38px;
    max-height: 136px;
    aspect-ratio: 1.84 / 1;
  }
`;

export const EasterMageCodeTitle = styled(EasterMageSectionTitle)`
  span {
    background: initial;
    color: var(--color-bd-cyan);
    -webkit-text-fill-color: initial;
  }
  text-align: center;
  font-size: 22px;
  line-height: 32px;
  font-weight: 800;
  width: 100%;
  @media ${DEVICE_QUERY_MOBILE} {
    margin-top: 5px;
    display: inline-block;
    font-size: 18px;
    line-height: 32px;
  }
`;

export const EasterMageCodeDesc = styled(EasterMageSectionDesc)`
  font-size: 18px;
  line-height: 28px;
  color: var(--color-fg-default);
  @media ${DEVICE_QUERY_MOBILE} {
    margin: 4px 0 16px;
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
  }
`;

export const EasterMageCodeCopyCode = styled(H6)`
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  background: var(--color-border-subtle);
  border: 1px solid var(--color-border-default);
  color: white;
  width: 100%;
  .copy_icon {
    cursor: pointer;
  }
  .copy_icon-success {
    color: var(--color-bd-cyan);
  }
  :hover {
    .copy_icon {
      svg path {
        transition: var(--transition);
        fill: var(--color-fg-default);
      }
    }
  }
  @media ${DEVICE_QUERY_MOBILE} {
    margin: 0 auto;
    max-width: 200px;
    padding: 6px 12px;
    ${H7aStyle}
    white-space: nowrap;
  }
`;

export const EasterMageSectionCodeButton = styled(EasterMageSectionButton)`
  height: 56px;
  @media ${DEVICE_QUERY_MOBILE} {
    padding: 0 12px;
    height: 36px;
  }
`;

export const MageEgg = styled(Egg)`
  color: var(--color-bd-primary);
  margin-left: 15px;
  cursor: pointer;
  transition: color 0.25s;
  --stop-start-color: #3d71ff;
  --stop-end-color: #93cef5;
  :hover {
    --stop-start-color: #1be0c6;
    --stop-end-color: #1dbbf5;
  }
`;
