import React, { useCallback, useMemo, useState } from 'react';
import useIsMobile from 'hooks/useIsMobile';
import { useDispatch, useSelector } from 'react-redux';
import { setBannerModalOpen } from 'containers/App/actions';
import EasterMage from './EasterMage';
import { FooterButton, ModalContainer } from './style';
import EasterMageCode from './EasterMage/code';
import { useBannerModal } from './EasterMage/hooks';

const CODES = ['S6YJJ68CX1SY', 'N13MJ0TFA03S', 'N13MJ0TFA03S'];

export default function BannerModal({ open, defaultOpen, onClose }) {
  const isMobile = useIsMobile();
  const width = useMemo(
    () => (isMobile ? 'calc(100% - 48px)' : 1080),
    [isMobile],
  );
  const { banner } = useSelector((state) => state.app);
  const [showMainPage, setShowMainPage] = useState(false);
  const dispatch = useDispatch();
  const openModal = useCallback(() => {
    dispatch(setBannerModalOpen(true));
    setShowMainPage(true);
  }, [dispatch]);
  const { isBetweenDate } = useBannerModal();

  const isOpen = useMemo(() => open && isBetweenDate, [open, isBetweenDate]);

  const modalClose = useCallback(() => {
    onClose && onClose();
    setShowMainPage(false);
  }, [onClose]);

  return (
    <>
      <ModalContainer
        open={isOpen}
        defaultOpen={defaultOpen}
        onClose={modalClose}
        width={width}
        centered={isMobile}
        closeIcon
        transition="scale br"
      >
        {showMainPage || banner.modal.opened.length === 0
          ? (
              <EasterMage onClose={modalClose} />
            )
          : (
              <EasterMageCode codes={CODES} onClose={modalClose} />
            )}
      </ModalContainer>
      {isBetweenDate && !banner.modal.open && (
        <FooterButton onClick={openModal}>
          <img src={require('./images/footer-button.png')} />
        </FooterButton>
      )}
    </>
  );
}
