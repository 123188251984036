import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import CountdownNow from 'react-countdown-now';
import dayjs from 'plugins/dayjs';
import {
  calculateDiscountOff,
  filterPlainObjectAndNotIsEmpty,
} from 'utils/commonUtils';
import SaleOff from 'components/icons/SaleOff';
import { TZ } from 'hooks/useCountdown';
import { H7 } from 'components/styles/titleNew';
import { uniqBy } from 'lodash';
import useCartStore from 'stores/cart';
import { genernalMsgs as messages } from '../../messages';
import {
  ProductDiscountInfo,
  ProductDiscountInfoCountdown,
  ProductDiscountInfoLabel,
  ProductDiscountInfoOffAndCountdownLabel,
  ProductDiscountInfoTitle,
  ProductDiscountOff,
  ProductSoldOutInfo,
} from './style';

export function CartItemLabel({ item, completeCartItemDiscount }) {
  const intl = useIntl();
  const { cartData } = useCartStore();

  if (item.soldOut) {
    return (
      <ProductSoldOutInfo key="soldOut">
        <H7>
          {item.needPresale ? (
            <FormattedMessage {...messages.presale} />
          ) : (
            <FormattedMessage {...messages.soldOut} />
          )}
        </H7>
      </ProductSoldOutInfo>
    );
  }

  const allDiscountAllocations = filterPlainObjectAndNotIsEmpty([
    ...(item?.discountAllocations || []),
    ...(cartData?.discountAllocations || []),
  ]);

  const hasDiscountCode
    = cartData?.discountCodes?.length > 0
    && allDiscountAllocations.length === 0
    && item.custom?.discountPrice > item.cost?.amountPerQuantity?.amount;

  if (hasDiscountCode) {
    return cartData?.discountCodes
      .filter((it) => it.applicable)
      .map((param) => (
        <ProductDiscountInfo key={param.code}>
          <ProductDiscountInfoLabel>
            <SaleOff className="sale-off-icon" />
            {param.code}
          </ProductDiscountInfoLabel>
        </ProductDiscountInfo>
      ));
  } else {
    return uniqBy(allDiscountAllocations, (it) => it.title || it.code).map(
      (param) => {
        const hasEndTime = item.product?.discountInfo?.endTime;
        const endTime
          = hasEndTime && dayjs(item.product.discountInfo.endTime).tz(TZ);
        const isShowCountdown = dayjs().tz(TZ).isBefore(endTime);
        const discountOff
          = calculateDiscountOff(item.cost) !== '0'
            ? intl.formatMessage(messages.saleOff, {
              discount: calculateDiscountOff(item.cost),
            })
            : '';

        return (
          <ProductDiscountInfo key={param.title}>
            <ProductDiscountInfoLabel>
              <SaleOff className="sale-off-icon" />
              <ProductDiscountInfoTitle>
                {param.title || param.code}
              </ProductDiscountInfoTitle>
            </ProductDiscountInfoLabel>
            <ProductDiscountInfoOffAndCountdownLabel>
              {discountOff && (
                <ProductDiscountOff>{discountOff}</ProductDiscountOff>
              )}
              {hasEndTime && isShowCountdown && !item.completeDiscount && (
                <ProductDiscountInfoCountdown>
                  <CountdownNow
                    zeroPadLength={2}
                    date={endTime}
                    daysInHours
                    onComplete={() => completeCartItemDiscount(item)}
                    renderer={({ formatted }) => {
                      const ends = intl.formatMessage(messages.ends);
                      return `${formatted.hours}:${formatted.minutes}:${formatted.seconds
                      } ${ends}`;
                    }}
                  />
                </ProductDiscountInfoCountdown>
              )}
            </ProductDiscountInfoOffAndCountdownLabel>
          </ProductDiscountInfo>
        );
      },
    );
  }
}
