import React from 'react';
import builder from 'xmlbuilder';
import PropTypes from 'prop-types';
import { flatMap, has, values } from 'lodash';

export default function Sitemap(props) {
  const hostname = 'https://keyst.one';
  const { routes } = props.routes;
  const sitemap = () => {
    const xml = builder
      .create('urlset', { encoding: 'utf-8' })
      .att('xmlns', 'http://www.sitemaps.org/schemas/sitemap/0.9');

    const paths = flatMap(routes, (obj) => {
      if (has(obj, 'children')) {
        const { children, ...newObj } = obj;
        return [newObj, ...values(children)];
      }
      return obj;
    });

    paths.forEach((route) => {
      const uri = route.path;
      if (route.sitemapIndex && !route.redirect) {
        const item = xml.ele('url');
        item.ele('loc', hostname + uri);
      }
    });
    return xml.end({ pretty: props.prettify });
  };
  return <div style={{ whiteSpace: 'pre-wrap' }}>{sitemap()}</div>;
}

Sitemap.propTypes = {
  prettify: PropTypes.bool,
  routes: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};
