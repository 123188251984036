import React from 'react';
import Format from './Format';
import { IconTypes } from './types';

export default function Github({
  width = '18',
  height = '18',
  color = 'currentColor',
  ...args
}) {
  return (
    <Format {...args}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9 1.5C4.85625 1.5 1.5 4.85625 1.5 9C1.49915 10.5745 1.99411 12.1092 2.91466 13.3865C3.83521 14.6638 5.13461 15.6188 6.6285 16.116C7.0035 16.1813 7.14375 15.9562 7.14375 15.759C7.14375 15.5812 7.134 14.991 7.134 14.3625C5.25 14.7098 4.7625 13.9035 4.6125 13.4813C4.52775 13.2653 4.1625 12.6 3.84375 12.4215C3.58125 12.2812 3.20625 11.934 3.834 11.925C4.425 11.9152 4.8465 12.4687 4.9875 12.6937C5.6625 13.8277 6.741 13.509 7.1715 13.3125C7.2375 12.825 7.434 12.4972 7.65 12.3097C5.98125 12.1222 4.2375 11.475 4.2375 8.60625C4.2375 7.79025 4.52775 7.116 5.00625 6.59025C4.93125 6.40275 4.66875 5.634 5.08125 4.60275C5.08125 4.60275 5.709 4.40625 7.14375 5.37225C7.7543 5.2028 8.38513 5.11751 9.01875 5.11875C9.65625 5.11875 10.2938 5.20275 10.8938 5.3715C12.3278 4.3965 12.9563 4.6035 12.9563 4.6035C13.3688 5.63475 13.1063 6.4035 13.0313 6.591C13.509 7.116 13.8 7.78125 13.8 8.60625C13.8 11.4848 12.0473 12.1222 10.3785 12.3097C10.65 12.5437 10.8848 12.9938 10.8848 13.6973C10.8848 14.7 10.875 15.5063 10.875 15.7598C10.875 15.9563 11.016 16.1902 11.391 16.1152C12.8798 15.6126 14.1734 14.6557 15.0899 13.3794C16.0064 12.103 16.4996 10.5713 16.5 9C16.5 4.85625 13.1438 1.5 9 1.5Z" />
      </svg>
    </Format>
  );
}

Github.propTypes = IconTypes;
