import React from 'react';
import Format from './Format';
import { IconTypes } from './types';

export default function Increment({
  width = '18',
  height = '18',
  color = 'var(--color-fg-muted)',
  ...args
}) {
  return (
    <Format {...args}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.25 8.25V3.75H9.75V8.25H14.25V9.75H9.75V14.25H8.25V9.75H3.75V8.25H8.25Z" />
      </svg>
    </Format>
  );
}

Increment.propTypes = IconTypes;
