import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';

export const AppWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  :has(.not-found-page) {
    .page-header,
    .page-footer {
      display: none;
    }
  }
  :has(.cart-page) {
    .page-footer {
      display: none;
    }
  }
  :has(.cart_component.active-mobile) {
    overflow: hidden;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  background-color: ${(props) => (props.background ? props.background : '#fff')};

  @media ${DEVICE_QUERY_MOBILE} {
    min-width: 320px;
    width: 100%;
    margin-top: 64px;
  }
`;

export const ComponentContainer = styled.div`
  display: flex;
  align-items: ${(props) => (props.align ? props.align : 'center')};
  justify-content: center;
  width: 1440px;
  min-width: 1280px;
  overflow: hidden;
  padding: 0 120px;

  flex-direction: ${(props) => (props.column ? 'column' : 'row')};

  @media ${DEVICE_QUERY_MOBILE} {
    min-width: 320px;
    width: 100%;
    padding: 0 20px;
  }
`;

export const FullScreenContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  width: 100%;
  padding: 220px 0;
  min-height: ${(props) => (props.height ? `${props.height}px` : '620px')};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#F5F9FC'};
  justify-content: center;
  align-items: ${(props) => (props.center ? 'center' : 'initial')};

  @media ${DEVICE_QUERY_MOBILE} {
    min-width: 320px;
    width: 100%;
    height: 100%;
    padding: 0;
    justify-content: center;
    padding: ${(props) => (props.isChief ? '80px' : '60px')} 0 60px 0;
  }
`;

export const HalfScreenContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-end')};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#fff'};

  @media ${DEVICE_QUERY_MOBILE} {
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};
  }
`;

export const Heading = styled.p`
  text-transform: uppercase;
  font-family: 'Montserrat', -apple-system, 'PingFang SC', 'Microsoft Yahei',
    微软雅黑, 'MicrosoftJhengHei', BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: inline-block;
  background: linear-gradient(to right, #5311f1 0%, #3c74ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;

  @media ${DEVICE_QUERY_MOBILE} {
    width: 100%;
    text-align: center;
    margin-bottom: 12px;
  }
`;

export const Title = styled.p`
  font-family: 'Mont', -apple-system, 'PingFang SC', 'Microsoft Yahei', 微软雅黑,
    'MicrosoftJhengHei', BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: ${(props) => (props.white ? '#FFFFFF' : '#111214')};
  font-size: 46px;
  line-height: 52px;
  margin: 20px 0;
  max-width: 880px;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'inherit')};

  @media ${DEVICE_QUERY_MOBILE} {
    font-size: ${(props) => (props.small ? '30px' : '36px')};
    text-align: center;
    width: 100%;
    line-height: 1.2;
    margin-top: 0;
  }
`;

export const Description = styled.div`
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'inherit')};
  font-family: 'Open Sans', -apple-system, 'PingFang SC', 'Microsoft Yahei',
    微软雅黑, 'MicrosoftJhengHei', BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  color: ${(props) => (props.white ? '#FFFFFF' : '#352757')};
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  max-width: 900px;
  letter-spacing: 0.2px;

  @media ${DEVICE_QUERY_MOBILE} {
    width: 100%;
    font-size: 14px;
    text-align: center;
    line-height: 22px;
    margin-bottom: 0;
  }
`;

export const MoreLink = styled.span`
  display: ${(props) => (props.inline ? 'inline' : 'block')};
  font-family: 'Montserrat', -apple-system, 'PingFang SC', 'Microsoft Yahei',
    微软雅黑, 'MicrosoftJhengHei', BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #1f5aff;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  line-height: 20px;
  height: 20px;
  font-weight: bold;
  cursor: pointer;
  letter-spacing: 0.2px;

  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 15px;
  }
`;

export const SecondaryLink = styled(MoreLink)`
  color: #352757;
`;

export const Price = styled.p`
  font-family: Mont, -apple-system, 'PingFang SC', 'Microsoft Yahei', 微软雅黑;
  color: #fe5250;
  font-size: ${(props) => (props.large ? '36px' : '26px')};
  display: ${(props) => (props.inline ? 'inline' : 'block')};

  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 30px;
  }
`;

export const PriceSymbol = styled(Price)`
  font-size: 24px;
  display: inline;

  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 20px;
  }
`;

export const VaultButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  font-size: 14px;
  font-family: 'Montserrat', -apple-system, 'PingFang SC', 'Microsoft Yahei',
    微软雅黑, 'MicrosoftJhengHei', BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #fff;
  height: 34px;
  cursor: pointer;
  background: #2161ff;
  border-radius: 3px;
`;
