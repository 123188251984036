import { defineMessages } from 'react-intl';

export const scope = 'keystone.cart.';

export const genernalMsgs = defineMessages({
  title: {
    id: `${scope}title`,
    defaultMessage: '',
  },
  emptyDesc: {
    id: `${scope}empty.desc`,
    defaultMessage: '',
  },
  recommendedTitle: {
    id: `${scope}recommended.title`,
    defaultMessage: '',
  },
  subtotalTitle: {
    id: `${scope}subtotal.title`,
    defaultMessage: '',
  },
  saleOff: {
    id: 'keystone.saleOff',
    defaultMessage: '',
  },
  soldOut: {
    id: `${scope}soldOut`,
    defaultMessage: '',
  },
  presale: {
    id: `${scope}presale`,
    defaultMessage: '',
  },
  addToCart: {
    id: `${scope}addToCart`,
    defaultMessage: '',
  },
  goToAmazon: {
    id: `${scope}subtotal.goToAmazon`,
    defaultMessage: '',
  },
  stockOut: {
    id: `${scope}subtotal.stockOut`,
    defaultMessage: '',
  },
  discountInfo: {
    id: `${scope}subtotal.discount.info`,
    defaultMessage: '',
  },
  chooseCountry: {
    id: `${scope}chooseCountry`,
    defaultMessage: '',
  },
  ends: {
    id: 'keystone.ends',
    defaultMessage: '',
  },
});

export const subtotalDescMsgs = defineMessages({
  preOrder: {
    id: `${scope}subtotal.desc.preOrder`,
    defaultMessage: '',
  },
  tip: {
    id: `${scope}subtotal.desc.tip`,
    defaultMessage: '',
  },
  JP: {
    id: `${scope}subtotal.desc.JP`,
    defaultMessage: '',
  },
  poboxTip: {
    id: `${scope}subtotal.desc.poboxTip`,
    defaultMessage: '',
  },
  shippingInsurance: {
    id: `${scope}subtotal.desc.shippingInsurance`,
    defaultMessage: '',
  },
});

export const promptMsgs = defineMessages({
  preOrderTitle: {
    id: `${scope}prompt.preOrderTitle`,
    defaultMessage: '',
  },
  addressTitle: {
    id: `${scope}prompt.addressTitle`,
    defaultMessage: '',
  },
  settlementTitle: {
    id: `${scope}prompt.settlementTitle`,
    defaultMessage: '',
  },
  shippingInsurance: {
    id: `${scope}prompt.shippingInsurance`,
    defaultMessage: '',
  },
  settlementTitleTooltip: {
    id: `${scope}prompt.settlementTitleTooltip`,
    defaultMessage: '',
  },
});

export const diversionMsgs = defineMessages({
  title: {
    id: `${scope}diversion.title`,
    defaultMessage: '',
  },
  description: {
    id: `${scope}diversion.description`,
    defaultMessage: '',
  },
});

export const preOrderMsgs = defineMessages({
  tip: {
    id: `${scope}preOrder.tip`,
    defaultMessage: '',
  },
});
