import querystring from 'node:querystring';
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import { logger } from 'logger';
import {
  getQrcodeError,
  getQrcodeSuccess,
  verifyQrcodeError,
  verifyQrcodeSuccess,
} from './actions';
import { GET_QRCODE, VERIFY_QRCODE } from './constants';

export function* getQrcode(action) {
  const { version } = action.payload;
  const requestURL = `https://api.keyst.one/v1/web/web_auth/?version=${version}`;
  try {
    const response = yield call(request, requestURL);
    yield put(
      getQrcodeSuccess({
        qrcode: response.result.qrcode,
        actionId: response.result.action_id,
      }),
    );
  } catch (err) {
    logger.error(err);
    yield put(getQrcodeError(err));
  }
}

export function* verifyQrcode(action) {
  const { version } = action.payload;
  const requestURL = `https://api.keyst.one/v1/web/verify_code/?version=${version}`;
  try {
    const response = yield call(request, requestURL, {
      method: 'POST',
      body: querystring.encode(action.payload),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    if (response.success && response.result.matched) {
      yield put(verifyQrcodeSuccess());
    } else {
      yield put(verifyQrcodeError());
    }
  } catch (err) {
    logger.error(err);
    yield put(verifyQrcodeError());
  }
}

export function* getQrcodeData() {
  yield takeLatest(GET_QRCODE, getQrcode);
}

export function* verifyQrcodeData() {
  yield takeLatest(VERIFY_QRCODE, verifyQrcode);
}
