import { find } from 'lodash';
import { create } from 'zustand';
import { getProducts } from 'api/shop';
import { logger } from 'logger';
import { transformInfo } from 'pages/Shop/shopRequest/productsUtils';
import { ProductsOrder } from 'pages/Shop/Details/productRelevanceList';
import { getLanguage } from 'utils/commonUtils';
import { ProductsConfig } from 'config/products';

const useProductsStore = create((set, get) => ({
  lang: getLanguage(),
  allProducts: [],
  accessoriesList: [],
  bundles: [],
  async getAllProducts() {
    const { initProducts, lang } = get();
    if (lang !== getLanguage())
      await initProducts();

    return get().allProducts;
  },
  async initProducts() {
    try {
      const lang = getLanguage();
      set({ lang });

      let allProducts = await getProducts();
      allProducts = allProducts
        .filter((it) => !it.title.toLowerCase().includes('not sold'))
        .map((item) => {
          const product = find(ProductsConfig, (it) => {
            return it.id === item.id;
          });
          return {
            ...item,
            path: product && product.path,
          };
        });
      set({ allProducts });

      const accessoriesList = allProducts
        .filter((item) => ['KT', 'KVP'].includes(item?.productType))
        .map((product) => ({
          ...product,
          ...transformInfo(product),
          featuredImage: product.featuredImage,
        }))
        .sort(
          (a, b) => ProductsOrder.indexOf(a.id) - ProductsOrder.indexOf(b.id),
        );

      set({ accessoriesList });

      const bundles = allProducts
        .filter((item) => item?.productType === 'Bundle')
        .map((product) => ({
          ...transformInfo(product),
          featuredImage: product.featuredImage,
        }))
        .sort(
          (a, b) => ProductsOrder.indexOf(a.id) - ProductsOrder.indexOf(b.id),
        );

      set({ bundles });

      return allProducts;
    } catch (e) {
      logger.error(e);
      return false;
    }
  },
}));

export default useProductsStore;
