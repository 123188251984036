import React from 'react';
import Format from './Format';
import { IconTypes } from './types';

export default function SaleOff({
  width = '24',
  height = '24',
  color = 'var(--color-bd-oranger)',
  ...args
}) {
  return (
    <Format {...args}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2802 1.71967C10.4209 1.57902 10.6116 1.5 10.8106 1.5H16.1249C16.332 1.5 16.4999 1.66789 16.4999 1.875V7.18935C16.4999 7.38825 16.4209 7.57901 16.2802 7.71968L8.03023 15.9697C7.73732 16.2626 7.26246 16.2626 6.96954 15.9697L2.03021 11.0303C1.73732 10.7374 1.73732 10.2626 2.03022 9.96968L10.2802 1.71967ZM14.9999 4.5C14.9999 5.32841 14.3283 6 13.4999 6C12.6715 6 11.9999 5.32841 11.9999 4.5C11.9999 3.67157 12.6715 3 13.4999 3C14.3283 3 14.9999 3.67157 14.9999 4.5Z"
          fill={color}
        />
        <path
          d="M12.5708 15.7794L10.3945 15.1962L14.6416 10.9492L13.4894 15.249C13.3822 15.6492 12.971 15.8866 12.5708 15.7794Z"
          fill={color}
        />
      </svg>
    </Format>
  );
}

SaleOff.propTypes = IconTypes;
