import styled from 'styled-components';
import Button from 'components/Button';

export const OpenButtonContainer = styled.div`
  position: fixed;
  bottom: 25px;
  right: 80px;
  z-index: 1200;
  width: 120px;
  height: 48px;
`;

export const OpenButton = styled(Button)`
  padding: 8px 16px;
  color: white;
  width: 100%;
  height: 100%;
  overflow: hidden;
  --border-radius: 40px !important;
  background: rgba(0, 0, 0, 0.5);
`;

export const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  background: url(${({ src }) => src}) no-repeat center / cover;
`;
