/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */

require('@formatjs/intl-relativetimeformat/polyfill');
require('@formatjs/intl-relativetimeformat/locale-data/zh');
require('@formatjs/intl-relativetimeformat/locale-data/en');
require('@formatjs/intl-pluralrules/polyfill');
require('@formatjs/intl-pluralrules/locale-data/zh');
require('@formatjs/intl-pluralrules/locale-data/en');

// prettier-ignore
const appLocales = [
  'en',
  'zh',
];

const searchLocaleZh = window.location.search.includes('zh');

const isNavigatorLocaleUseZh = navigator.language.includes('zh');

const DEFAULT_LOCALE = isNavigatorLocaleUseZh || searchLocaleZh ? 'zh' : 'en';

exports.appLocales = appLocales;
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;
