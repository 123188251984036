import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCurrentActiveEvents } from 'hooks/useToggle';
import { track } from 'tracking';
import { LINKS } from 'config/links';
import { BackgroundImage, OpenButton, OpenButtonContainer } from './style';
import { MODAL_ID } from './config';
import { Modal } from './Modal';

const CAMPAIGN_LANDING_PAGE = LINKS.bitcoinHalving;

export default function CampaignModal() {
  const location = useLocation();
  const events = useCurrentActiveEvents();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isOnCampaignLandingPage = location.pathname === CAMPAIGN_LANDING_PAGE;

  const currentEvent = events?.[0];
  const shouldEnabledModal
    = currentEvent?.isInGlobalModal && !isOnCampaignLandingPage;
  const shouldShowModalButton
    = currentEvent && !isModalOpen && shouldEnabledModal;

  const openModal = useCallback(() => {
    setIsModalOpen(true);
    track('Modal', {
      dom_id: MODAL_ID,
    });
  }, []);

  const onClose = useCallback(() => {
    setIsModalOpen(false);
    localStorage.setItem(MODAL_ID, JSON.stringify(currentEvent));
  }, [currentEvent]);

  useEffect(() => {
    if (currentEvent && location.pathname === CAMPAIGN_LANDING_PAGE) {
      onClose();
    }
  }, [location.pathname, currentEvent, onClose]);

  useEffect(() => {
    if (currentEvent) {
      try {
        const storedEvent = localStorage.getItem(MODAL_ID);
        const eventHasBeenSeen
          = JSON.parse(storedEvent)?.name === currentEvent.name;
        if (!eventHasBeenSeen && shouldEnabledModal) {
          openModal();
        }
      } catch (error) {
        localStorage.removeItem(MODAL_ID);
      }
    }
  }, [currentEvent, openModal, shouldEnabledModal]);

  return (
    <>
      <OpenButtonContainer>
        {shouldShowModalButton && (
          <OpenButton onClick={openModal} type="rainbow">
            <BackgroundImage src={require('./assets/button.png')} />
          </OpenButton>
        )}
      </OpenButtonContainer>
      <Modal
        isOpen={isModalOpen && !isOnCampaignLandingPage}
        onClose={onClose}
        event={currentEvent}
      />
    </>
  );
}
