import React from 'react';
import Format from './Format';
import { IconTypes } from './types';

export default function ArrowWireDown({
  width = '18',
  height = '18',
  color = 'currentColor',
  ...args
}) {
  return (
    <Format {...args}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 21"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 48097566">
          <path
            id="Rectangle 32"
            d="M19.3892 10.8968L10.0029 20.0001L0.616668 10.8968"
            stroke="#F5F8FF"
          />
          <path
            id="Vector 30"
            d="M9.99902 0L10.0004 19.3971"
            stroke="#F5F8FF"
          />
        </g>
      </svg>
    </Format>
  );
}

ArrowWireDown.propTypes = IconTypes;
