import { CollapseHeader } from 'components/Collapse/style';
import Link from 'components/Link';
import { Body, BodyPlus, Caption } from 'components/styles/bodyNew';
import { H6 } from 'components/styles/titleNew';
import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 120px;
  padding: 40px 0;
  @media ${DEVICE_QUERY_MOBILE} {
    gap: 30px 0;
    padding: 12px 24px;
    flex-direction: column;

    ${CollapseHeader} {
      color: var(--color-fg-muted);
    }
  }
`;

export const Affiliate = styled.div`
  width: 320px;

  @media ${DEVICE_QUERY_MOBILE} {
    width: 100%;
  }
`;

export const CardLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  .link-wrapper {
    color: var(--color-fg-muted);
    margin-top: 24px;
    display: flex;
  }
  :hover {
    .link-wrapper {
      color: var(--color-fg-default);
    }
    .link-arrow {
      color: var(--color-bd-primary);
      transform: translateX(6px);
    }
  }
`;

export const CardLinkImage = styled.img`
  width: 320px;
  height: 140px;
  background: #000;
  border-radius: 16px;
  @media ${DEVICE_QUERY_MOBILE} {
    width: 100%;
    height: auto;
  }
`;

export const CardLinkTitle = styled(H6)``;

export const CardLinkDesc = styled(Caption)`
  margin-top: 12px;
  color: var(--color-fg-subtle);
`;

export const CardBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px 0;
  width: 320px;
`;

export const NamedBox = styled(CardBox)`
  width: 256px;
`;

export const CardBoxTitle = styled(H6)`
  margin-top: 32px;
  color: var(--color-fg-subtle);
`;

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px 0;
`;

export const NamedList = styled(CardList)`
  gap: 8px 0;
`;

export const FeatureItemContainer = styled.div`
  width: 100%;
`;

export const FeatureTitle = styled(BodyPlus)`
  color: var(--color-fg-muted);
`;

export const FeatureDesc = styled(Body)`
  color: var(--color-fg-subtle);
`;

export const Feature = styled(Link)`
  display: flex;
  padding: 8px;
  color: var(--color-fg-muted);
  :hover {
    color: var(--color-fg-emphasis);
    background-color: var(--color-bg-subtle);
    .link-arrow {
      opacity: 1;
      color: var(--color-bd-primary);
      transform: translateX(6px);
    }
  }
  .link-text {
    display: flex;
    align-items: center;
    height: 48px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .link-arrow {
    opacity: 0;
    color: var(--color-fg-muted);
  }
  @media ${DEVICE_QUERY_MOBILE} {
  }
`;

export const ItemIcon = styled.img`
  width: 64px;
  height: 64px;
`;

export const FeatureInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px 0;
  margin-left: 14px;
`;

export const NamedItemTitle = styled(Body)`
  color: var(--color-fg-muted);
`;

export const NamedItemLink = styled(Link)`
  display: flex;
  padding: 4px 0;
  .link-arrow {
    color: var(--color-fg-muted);
  }
  :hover {
    ${NamedItemTitle} {
      color: var(--color-fg-default);
    }
    .link-arrow {
      color: var(--color-fg-default);
    }
  }
`;
