/*
 *
 * LanguageProvider reducer
 *
 */

import produce from 'immer';

import { DEFAULT_LOCALE } from '../../i18n';
import { CHANGE_LOCALE } from './constants';

export const initialState = {
  locale: DEFAULT_LOCALE,
};

function languageProviderReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case CHANGE_LOCALE:
        draft.locale = action.locale;
        break;
    }
  });
}

export default languageProviderReducer;
