import styled, { css } from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';

export const OpenSansFontStyle = css`
  font-family:
    'Open Sans',
    -apple-system,
    'Microsoft Yahei',
    微软雅黑,
    'MicrosoftJhengHei',
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Helvetica Neue',
    sans-serif;
  font-style: normal;
  font-weight: 400;
`;

export const BodyStyle = css`
  ${OpenSansFontStyle}
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.32px;
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.28px;
  }
`;

export const Body = styled.div`
  ${BodyStyle}
`;

export const BodyPlusStyle = css`
  ${OpenSansFontStyle}
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.36px;
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.32px;
  }
`;

export const BodyPlus = styled.div`
  ${BodyPlusStyle}
`;

export const Caption = styled.div`
  ${OpenSansFontStyle}
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.28px;
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.24px;
  }
`;

export const Overline = styled.div`
  ${OpenSansFontStyle}
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.24px;
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 10px;
    line-height: 16px;
  }
`;
