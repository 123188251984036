import React from 'react';
import { FormattedMessage } from 'react-intl';
import Link from 'components/Link';
import { useSelector } from 'react-redux';
import { getStrikethroughPrice } from 'pages/Shop/utils';
import messages from './messages';
import {
  CartButton,
  CartIcon,
  ReProduct,
  ReProductDesc,
  ReProductImg,
  ReProductName,
  ReProductOriginPrice,
  ReProductPrice,
  ReProductPriceContent,
  Recommended,
  RecommendedList,
  RecommendedTitle,
} from './style';

export default function RecommendList({
  list,
  cartClick,
  isCartPage,
  closeCart,
}) {
  const { currency } = useSelector((state) => state.app);

  return (
    <Recommended>
      <RecommendedTitle>
        <FormattedMessage {...messages.recommendedTitle} />
      </RecommendedTitle>
      <RecommendedList isCartPage={isCartPage}>
        {list.map((item) => {
          const strikethroughPrice = getStrikethroughPrice(
            item.originPrice,
            item.discountPrice,
            item.comparePrice,
          );
          const isShowStrikethroughPrice
            = strikethroughPrice
            && Number(strikethroughPrice) - Number(item.discountPrice) > 0.1;
          return (
            <ReProduct key={item.id}>
              <Link to={item.path} onClick={closeCart} arrow={false}>
                <ReProductImg src={item.featuredImage.url} alt={item.title} />
              </Link>
              <ReProductName
                as={Link}
                arrow={false}
                to={item.path}
                onClick={closeCart}
              >
                {item.title}
              </ReProductName>
              <ReProductDesc>{item.shortDescription}</ReProductDesc>
              <ReProductPriceContent>
                {isShowStrikethroughPrice && (
                  <ReProductOriginPrice>
                    {currency.symbol}
                    {Number(strikethroughPrice)}
                  </ReProductOriginPrice>
                )}
                <ReProductPrice>
                  {currency.symbol}
                  {Number(item.discountPrice)}
                </ReProductPrice>
              </ReProductPriceContent>
              <CartButton
                type="primary"
                icon={CartIcon}
                onClick={() => cartClick(item.variantId)}
              >
                <FormattedMessage {...messages.addToCart} />
              </CartButton>
            </ReProduct>
          );
        })}
      </RecommendedList>
    </Recommended>
  );
}
