import { withScope } from '@sentry/react';
import { getUserId } from 'utils/commonUtils';

export function generateLogger({
  enableReport,
  infoConfig,
  warnConfig,
  errorConfig,
}) {
  return {
    info: (infoError) => {
      console.info(`**********  ${infoError}  **********`);
      const { report, enableInfoReport = false } = infoConfig || {};
      if (enableReport && enableInfoReport) {
        const message
        = infoError instanceof Error ? infoError.message : infoError;
        report && report(message);
      }
    },

    warn: (warnError) => {
      console.warn(`**********  ${warnError}  **********`);
      const { report, enableWarnReport = false } = warnConfig || {};
      if (enableReport && enableWarnReport) {
        const message
        = warnError instanceof Error ? warnError.message : warnError;
        report && report(message);
      }
    },

    error: (e, prefixMsg) => {
      withScope((scope) => {
        console.info('[Error]: ', prefixMsg, e);
        scope.setUser({ id: getUserId() });
        scope.setTransactionName(prefixMsg);

        const { report, enableErrorReport = true } = errorConfig || {};

        if (enableReport && enableErrorReport && !!report) {
          if (e instanceof Error) {
            report(e);
          } else if (e.error_message) {
            report(new Error(e.error_message));
          } else {
            report(new Error(e));
          }
        }
      });
    },
  };
}
