import { logger } from 'logger';

export function extractProductDataLayer(item, type = 'product') {
  if (type === 'cartLine') {
    const product = item.merchandise?.product;
    if (!product) {
      logger.error(
        new Error('trackError extractProductDataLayer: not found product'),
      );
      return null;
    }

    const originalPrice = Number(item.custom.computedStrikethroughPrice);
    const dicountInfo = item.custom.discountAllocations?.[0];
    const discountRelatedInfo
      = dicountInfo && dicountInfo?.discountedAmount
        ? {
            coupon: dicountInfo.title,
            discount: Number(dicountInfo.discountedAmount.amount),
          }
        : {};
    const currentPrice = item.custom?.discountPrice;

    const productId = /\d+$/.exec(product.id)[0];
    const variantId = /\d+$/.exec(item.merchandise.id)[0];
    const itemId = `shopify_US_${productId}_${variantId}`;

    return {
      currency: 'USD',
      value: currentPrice,
      items: [
        {
          item_id: itemId,
          variant_id: variantId,
          item_name: product.title,
          item_category: product.productType,
          price: originalPrice,
          ...discountRelatedInfo,
        },
      ],
    };
  }
  if (type === 'product') {
    const product = item;
    let originalPrice = Number(product.priceRange?.maxVariantPrice?.amount);
    let discountRelatedInfo = product.discountInfo?.title
      ? {
          coupon: product.discountInfo?.title,
          discount: Number((originalPrice - product.discountPrice).toFixed(2)),
        }
      : {};
    let currentPrice = Number(product.discountPrice || originalPrice);

    // product in recommendation list
    if (product.originPrice) {
      originalPrice = Number(product.originPrice);
      discountRelatedInfo = product.discount?.title
        ? {
            coupon: product.discount?.title,
            discount: Number(
              (Number(product.originPrice) - Number(product.price)).toFixed(2),
            ),
          }
        : {};
      currentPrice = Number(product.price || originalPrice);
    }

    const productId = /\d+$/.exec(product.id)[0];
    const variantId = /\d+$/.exec(product.variantId)[0];
    const itemId = `shopify_US_${productId}_${variantId}`;

    return {
      currency: 'USD',
      value: currentPrice,
      items: [
        {
          item_id: itemId,
          variant_id: variantId,
          item_name: product.title,
          item_category: product.productType,
          price: originalPrice,
          ...discountRelatedInfo,
        },
      ],
    };
  }
}
