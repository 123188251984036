import styled, { css } from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import { H7 } from 'components/styles/titleNew';

export const BlockNumber = styled.span`
  width: 156px;
  display: block;
  text-align: center;
  font-family: Mont;
  font-size: 108px;
  font-style: italic;
  font-weight: 600;
  line-height: 144px;
  background: var(--color-gd-primary);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${DEVICE_QUERY_MOBILE} {
    width: 65px;
    font-size: 48px;
    line-height: normal;
  }
`;

export const BlockUnit = styled(H7).attrs({ as: 'span' })`
  display: block;
  text-align: center;
  text-transform: uppercase;

  color: var(--color-bd-cyan);
  font-weight: 600;
  line-height: 26px;

  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 160% */
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Mont';
  width: 175px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 104%;
    transform: translateY(-100%);
    right: 0;
    display: block;
    width: 4.8px;
    height: 4.8px;
    background: #1cffad;
    border-radius: 50%;
    @media ${DEVICE_QUERY_MOBILE} {
      transform: translateY(-30%);
    }
  }

  ${(props) =>
    !props.isAlmost
    && css`
      margin-top: 5px;
      width: 124px;
      ${BlockNumber} {
        font-size: 56px;
        font-weight: 700;
        line-height: 64px;
        background: var(--color-gd-pink);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      ${BlockUnit} {
        color: var(--color-bd-oranger);
        text-align: center;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.56px;
      }
    `}

  @media ${DEVICE_QUERY_MOBILE} {
    width: 68px;
    &::after {
      top: 35%;
      width: 2px;
      height: 2px;
    }

    ${(props) =>
    !props.isAlmost
    && css`
        margin-top: 5px;
        width: 124px;
        ${BlockNumber} {
          font-size: 48px;
          line-height: 48px;
        }
        ${BlockUnit} {
          font-size: 10px;
          line-height: 20px;
          letter-spacing: 0.4px;
        }
      `}
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media ${DEVICE_QUERY_MOBILE} {
    ${(props) =>
    props.isAlmost
    && css`
        margin-top: 33px;
        height: 90px;
      `}
  }

  ${Block} {
    :last-child {
      ::after {
        display: none;
      }
    }
  }
`;
