import React, { useEffect, useState } from 'react';
import PlayShadow from 'components/icons/PlayShadow';
import PlayShadowActived from 'components/icons/PlayShadowActived';
import { H6 } from 'components/styles/titleNew';
import VideoModal from 'components/VideoModal';
import { FormattedMessage } from 'react-intl';
import useIsMobile from 'hooks/useIsMobile';
import { trackHomeLinkClick } from 'tracking/click/home';
import { mainMsgs as messages } from '../messages';
import { VideoButton } from './style';

export default function VideoPlay({ url }) {
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    isOpen && trackHomeLinkClick('videoPlay', url);
  }, [isOpen, url]);

  return (
    <>
      <VideoButton onClick={() => setIsOpen(true)}>
        <PlayShadow className="icon-normal" />
        <PlayShadowActived className="icon-actived" />
        {!isMobile && (
          <H6>
            <FormattedMessage {...messages.productVideo} />
          </H6>
        )}
      </VideoButton>
      <VideoModal open={isOpen} src={url} onClose={() => setIsOpen(false)} />
    </>
  );
}
