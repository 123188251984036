import React from 'react';
import { FormattedMessage } from 'react-intl';
import ArrowWireDown from 'components/icons/ArrowWireDown';
import Bag from 'components/icons/Bag';
import { diversionMsgs } from '../../messages';
import { CartDiversionWrapper, Description, Title } from './style';

export default function CartDiversion({ isCartPageOnDesktop }) {
  return (
    <CartDiversionWrapper isCartPageOnDesktop={isCartPageOnDesktop}>
      <Bag className="bag" width="96px" height="96px" />
      <Title>
        <FormattedMessage {...diversionMsgs.title} />
      </Title>
      <Description>
        <FormattedMessage {...diversionMsgs.description} />
      </Description>
      <ArrowWireDown className="arrow" width="20" height="20" />
    </CartDiversionWrapper>
  );
}
