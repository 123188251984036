import React from 'react';
import Format from './Format';
import { IconTypes } from './types';

export default function Copy({
  width = '24',
  height = '24',
  color = '#BCC0CC',
  ...args
}) {
  return (
    <Format {...args}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 1.34375H5C3.9 1.34375 3 2.24375 3 3.34375V17.3438H5V3.34375H17V1.34375ZM20 5.34375H9C7.9 5.34375 7 6.24375 7 7.34375V21.3438C7 22.4438 7.9 23.3438 9 23.3438H20C21.1 23.3438 22 22.4438 22 21.3438V7.34375C22 6.24375 21.1 5.34375 20 5.34375ZM20 21.3438H9V7.34375H20V21.3438Z"
          fill={color}
        />
      </svg>
    </Format>
  );
}

Copy.propTypes = IconTypes;
