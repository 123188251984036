import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import useIsMobile from 'hooks/useIsMobile';
import { trackHeaderBlogClick } from 'tracking/click/header';
import {
  BlogBox,
  BlogDesc,
  BlogIcon,
  BlogItem,
  BlogLink,
  BlogLinkBox,
  BlogList,
  BlogListBox,
  BlogListTitle,
  Container,
} from './Blog.style';
import useMessages from './messages';
import getBlogData from './blogData';

export default function Blog() {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const [list, setList] = useState([]);

  const msg = useMessages();

  useEffect(() => {
    const blog = getBlogData();
    setList(
      blog.map((it) => ({
        title: intl.locale === 'zh' ? it.zhTitle : it.title,
        link: it.link,
      })),
    );
  }, [intl.locale]);

  return (
    <Container>
      <BlogBox>
        {!isMobile && <BlogIcon src={require('./images/blog.svg')} />}
        <BlogLinkBox
          href={msg.blogLink}
          onClick={() => trackHeaderBlogClick(msg.blogLink)}
          blank
          arrow={false}
        >
          <BlogLink>{msg.title}</BlogLink>
          <BlogDesc>{msg.desc}</BlogDesc>
        </BlogLinkBox>
      </BlogBox>
      <BlogListBox>
        <BlogListTitle>{msg.basicInfomation}</BlogListTitle>
        <BlogList>
          {list.map((it) => (
            <BlogItem
              href={it.link}
              onClick={() => trackHeaderBlogClick(it.link)}
              key={it.link}
            >
              {it.title}
            </BlogItem>
          ))}
        </BlogList>
      </BlogListBox>
    </Container>
  );
}
