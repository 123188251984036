import React, { useMemo } from 'react';
import useIsMobile from 'hooks/useIsMobile';
import { FlexCenter } from 'components/styles/flex';
import { FormattedMessage, useIntl } from 'react-intl';
import { LINKS } from 'config/links';
import { Link } from 'react-router-dom';
import useQueryDiscount from 'stores/queryDiscountCode';
import { trackHeaderTopBannerClick } from 'tracking/click/header';
import { Caption } from 'components/styles/bodyNew';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback } from 'components/ErrorBoundary';
import { bannerDiscountMsgs } from '../messages';
import {
  BannerBgClose,
  BannerBox,
  BannerButton,
  BannerTitle,
} from './index.style';

export default function DiscountCodeBanner({ onClose: close }) {
  const isMobile = useIsMobile();
  const intl = useIntl();
  const isZh = intl.locale === 'zh';
  const closeButtonSize = useMemo(() => (isMobile ? '20' : '30'), [isMobile]);
  const { code, data } = useQueryDiscount();

  const discountData = useMemo(() => {
    if (!data)
      return;

    const isOrderDiscount = data.discount_type === 'order';
    const titles = data.products;

    const productTitles
      = !isOrderDiscount
      && `${titles.slice(0, 2).join(isZh ? '、' : ', ')}${titles.length > 2 ? '...' : ''
      }`;

    return {
      value: data.discount,
      discount: (v) => (
        <>
          {isZh && data.discount.startsWith('$') ? '立减 ' : ''}
          <em>{v}</em>
        </>
      ),
      products: isOrderDiscount
        ? intl.formatMessage(bannerDiscountMsgs.allProducts)
        : productTitles,
    };
  }, [data, intl, isZh]);

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <BannerBox>
        <FlexCenter
          as={Link}
          to={LINKS.shop}
          onClick={() => trackHeaderTopBannerClick('discount', code)}
        >
          <BannerTitle animateEnabled>
            <span>
              <FormattedMessage
                {...bannerDiscountMsgs.title}
                values={discountData}
              />
            </span>
          </BannerTitle>
          <Caption>
            <BannerButton>
              <FormattedMessage {...bannerDiscountMsgs.useDiscount} />
            </BannerButton>
          </Caption>
        </FlexCenter>
        <BannerBgClose
          width={closeButtonSize}
          height={closeButtonSize}
          onClick={close}
        />
      </BannerBox>
    </ErrorBoundary>
  );
}
