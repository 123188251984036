import styled from 'styled-components';
import { H6 } from 'components/styles/titleNew';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';

export const VideoButton = styled.div`
  display: flex;
  align-items: center;
  gap: 0 16px;
  cursor: pointer;
  ${H6} {
    color: var(--color-fg-muted);
  }
  .icon-actived {
    display: none;
  }
  &:hover {
    ${H6} {
      color: var(--color-fg-default);
    }
    .icon-normal {
      display: none;
    }
    .icon-actived {
      display: inline-block;
    }
  }

  @media ${DEVICE_QUERY_MOBILE} {
    svg {
      width: 48px;
      height: 48px;
    }
  }
`;
