import { captureException } from '@sentry/react';
import { generateLogger } from './logger';

const sentryLogger = generateLogger({
  enableReport: process.env.NODE_ENV !== 'development',
  errorConfig: {
    report: captureException,
  },
});

export const logger = sentryLogger;
