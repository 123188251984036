const userAgent
  = typeof window === 'undefined' || !window.navigator.userAgent
    ? null
    : window.navigator.userAgent;

export function versions() {
  const u = userAgent;
  if (!u) {
    return {};
  }
  return {
    trident: u.includes('Trident'), // IE内核
    presto: u.includes('Presto'), // opera内核
    webKit: u.includes('AppleWebKit'), // 苹果、谷歌内核
    gecko: u.includes('Gecko') && !u.includes('KHTML'), // 火狐内核
    mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
    android: u.includes('Android') || u.includes('Adr'), // android终端
    iPhone: u.includes('iPhone'), // 是否为iPhone或者QQHD浏览器
    iPad: u.includes('iPad'), // 是否iPad
    webApp: !u.includes('Safari'), // 是否web应该程序，没有头部与底部
    weixin: u.includes('MicroMessenger'), // 是否微信 （2015-01-22新增）
    qq: u.match(/\sQQ/i) === ' qq', // 是否QQ
  };
}

export function platform() {
  const { platform: pf } = navigator;
  if (versions(userAgent).android) {
    return 'android';
  }
  if (versions(userAgent).iPhone) {
    return 'iphone';
  }
  if (versions(userAgent).iPad) {
    return 'ipad';
  }
  if (
    pf === 'Mac68K'
    || pf === 'MacPPC'
    || pf === 'Macintosh'
    || pf === 'MacIntel'
  ) {
    return 'mac';
  }
  if (pf === 'Win32' || pf === 'Windows') {
    return 'windows';
  }

  return pf;
}

export default {
  versions: versions(),
  platform: platform(),
};

export function isBrowser() {
  return !!(
    typeof window !== 'undefined'
    && window.document
    && window.document.createElement
  );
}
