/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from 'immer';
import { CURRENCY_LOCALE_KEY } from '../../config';
import {
  LOAD_REPOS,
  LOAD_REPOS_ERROR,
  LOAD_REPOS_SUCCESS,
  SET_ACTIVITIES_START,
  SET_BANNER_MODAL_CODE,
  SET_BANNER_MODAL_OPEN,
  SET_BANNER_MODAL_OPENED,
  SET_CART_ID_GETTING,
  SET_CART_LIST,
  SET_CART_QUANTITY,
  SET_CART_SEEL_BP_IS_UPDATING,
  SET_CART_SEEL_BP_VARIANT_ID,
  SET_CART_SHOW,
  SET_CART_SUBTOTAL,
  SET_CURRENCY,
  SET_LANG,
  SET_LOADING,
  SET_SHIPPING_REGIONS_CACHE,
  SET_SHIPPING_REGIONS_COUNTRY_LIST,
  SET_SHIPPING_REGIONS_CURRENT_COUNTRY,
  SET_SHIPPING_REGIONS_LANG,
  SET_SHIPPING_REGIONS_PRODUCT_MAP,
} from './constants';

const SUPPORTED_CURRENCY = {
  USD: {
    symbol: '$',
    exchangeRate: 1,
  },
  EUR: {
    symbol: '€',
    exchangeRate: 1,
  },
  GBP: {
    symbol: '£',
    exchangeRate: 1 / 1.2,
  },
};

const storedCurrency = localStorage.getItem(CURRENCY_LOCALE_KEY) || 'USD';

// The initial state of the App
export const initialState = {
  loading: false,
  error: false,
  currentUser: false,
  userData: {
    repositories: false,
  },
  lang: 'en',
  activitiesIsStart: false,
  cartShow: false,
  cartQuantity: 0,
  firmware: {
    supported: {
      coins: 5500,
      chains: 200,
      wallets: 25,
    },
  },
  gen3: {
    firmware: {
      supported: {
        coins: 5500,
        chains: 200,
        wallets: 30,
      },
    },
  },
  shop: {
    cartSeelBpVariantId: '',
    cartSeelBpIsUpdating: false,
    cartList: [],
    cartIdGetting: false,
    cartSubtotal: {},
    shippingRegions: {
      lang: '',
      cache: {
        time: null,
        data: null,
      },
      currentCountry: '',
      productMap: null,
      countryList: [],
    },
  },
  banner: {
    modal: {
      open: false,
      code: '',
      opened: [],
    },
  },
  currency: {
    name: storedCurrency,
    ...SUPPORTED_CURRENCY[storedCurrency],
  },
};

function appReducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOAD_REPOS:
        draft.loading = true;
        draft.error = false;
        draft.userData.repositories = false;
        break;

      case LOAD_REPOS_SUCCESS:
        draft.userData.repositories = action.repos;
        draft.loading = false;
        draft.currentUser = action.username;
        break;

      case LOAD_REPOS_ERROR:
        draft.error = action.error;
        draft.loading = false;
        break;

      case SET_ACTIVITIES_START:
        draft.activitiesIsStart = action.status;
        break;

      case SET_CART_SHOW:
        draft.cartShow = action.status;
        break;

      case SET_CART_QUANTITY:
        draft.cartQuantity = action.status;
        break;

      case SET_LOADING:
        draft.loading = action.status;
        break;

      case SET_CART_ID_GETTING:
        draft.shop.cartIdGetting = action.status;
        break;

      case SET_SHIPPING_REGIONS_CACHE:
        draft.shop.shippingRegions.cache = action.data;
        break;

      case SET_SHIPPING_REGIONS_LANG:
        draft.shop.shippingRegions.lang = action.data;
        break;

      case SET_SHIPPING_REGIONS_PRODUCT_MAP:
        draft.shop.shippingRegions.productMap = action.data;
        break;

      case SET_SHIPPING_REGIONS_COUNTRY_LIST:
        draft.shop.shippingRegions.countryList = action.data;
        break;

      case SET_SHIPPING_REGIONS_CURRENT_COUNTRY:
        draft.shop.shippingRegions.currentCountry = action.data;
        localStorage.setItem(SET_SHIPPING_REGIONS_CURRENT_COUNTRY, action.data);
        break;

      case SET_CART_LIST:
        draft.shop.cartList = action.data;
        break;

      case SET_CART_SEEL_BP_VARIANT_ID:
        draft.shop.cartSeelBpVariantId = action.data;
        break;

      case SET_CART_SEEL_BP_IS_UPDATING:
        draft.shop.cartSeelBpIsUpdating = action.data;
        break;

      case SET_CART_SUBTOTAL:
        draft.shop.cartSubtotal = action.data;
        break;

      case SET_BANNER_MODAL_OPEN:
        draft.banner.modal.open = action.data;
        localStorage.setItem(SET_BANNER_MODAL_OPEN, action.data);
        break;

      case SET_BANNER_MODAL_CODE:
        draft.banner.modal.code = action.data;
        break;

      case SET_BANNER_MODAL_OPENED:
        draft.banner.modal.opened = action.data;
        localStorage.setItem(SET_BANNER_MODAL_OPENED, action.data);
        break;

      case SET_LANG:
        draft.lang = action.data;
        break;

      case SET_CURRENCY:
        draft.currency = {
          name: action.data,
          ...SUPPORTED_CURRENCY[action.data],
        };
        break;
    }
  });
}

export default appReducer;
