import { create } from 'zustand';

const useQueryDiscount = create((set) => ({
  code: '',
  data: null,
  setCode: (code) => set({ code }),
  setData: (data) => set({ data }),
}));

export default useQueryDiscount;
