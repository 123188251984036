import {
  CLEAR_VERIFY_STATUS,
  GET_QRCODE,
  GET_QRCODE_ERROR,
  GET_QRCODE_SUCCESS,
  VERIFY_QRCODE,
  VERIFY_QRCODE_ERROR,
  VERIFY_QRCODE_SUCCESS,
} from './constants';

export function getQrcode(payload) {
  return {
    type: GET_QRCODE,
    payload,
  };
}

export function getQrcodeError(error) {
  return {
    type: GET_QRCODE_ERROR,
    error,
  };
}

export function getQrcodeSuccess(payload) {
  return {
    type: GET_QRCODE_SUCCESS,
    payload,
  };
}

export function verifyQrcodeSuccess() {
  return {
    type: VERIFY_QRCODE_SUCCESS,
  };
}

export function verifyQrcodeError() {
  return {
    type: VERIFY_QRCODE_ERROR,
  };
}

export function verifyQrcode(payload) {
  return {
    type: VERIFY_QRCODE,
    payload,
  };
}

export function clearVerifyStatus() {
  return {
    type: CLEAR_VERIFY_STATUS,
  };
}
