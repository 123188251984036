import React from 'react';
import Format from './Format';
import { IconTypes } from './types';

export default function Bag({
  width = '24',
  height = '24',
  color = 'currentColor',
  ...args
}) {
  return (
    <Format {...args}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.75 9.1499H83.25V86.8499H12.75V9.1499Z"
          stroke="#6C707A"
          strokeWidth="1.5"
        />
        <mask id="path-2-inside-1_10254_42616" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.5995 18V25.2C33.5995 33.1529 40.0466 39.6 47.9995 39.6C55.9524 39.6 62.3995 33.1529 62.3995 25.2V18H60.8995V25.2C60.8995 32.3245 55.124 38.1 47.9995 38.1C40.875 38.1 35.0995 32.3245 35.0995 25.2V18H33.5995Z"
          />
        </mask>
        <path
          d="M33.5995 18V16.2H31.7995V18H33.5995ZM62.3995 18H64.1995V16.2H62.3995V18ZM60.8995 18V16.2H59.0995V18H60.8995ZM35.0995 18H36.8995V16.2H35.0995V18ZM31.7995 18V25.2H35.3995V18H31.7995ZM31.7995 25.2C31.7995 34.147 39.0525 41.4 47.9995 41.4V37.8C41.0407 37.8 35.3995 32.1588 35.3995 25.2H31.7995ZM47.9995 41.4C56.9465 41.4 64.1995 34.147 64.1995 25.2H60.5995C60.5995 32.1588 54.9583 37.8 47.9995 37.8V41.4ZM64.1995 25.2V18H60.5995V25.2H64.1995ZM62.3995 16.2H60.8995V19.8H62.3995V16.2ZM62.6995 25.2V18H59.0995V25.2H62.6995ZM47.9995 39.9C56.1181 39.9 62.6995 33.3186 62.6995 25.2H59.0995C59.0995 31.3304 54.1299 36.3 47.9995 36.3V39.9ZM33.2995 25.2C33.2995 33.3186 39.8809 39.9 47.9995 39.9V36.3C41.8692 36.3 36.8995 31.3304 36.8995 25.2H33.2995ZM33.2995 18V25.2H36.8995V18H33.2995ZM35.0995 16.2H33.5995V19.8H35.0995V16.2Z"
          fill="#3D71FF"
          mask="url(#path-2-inside-1_10254_42616)"
        />
        <rect x="21.5996" y="70.8" width="52.8" height="1.8" fill="#6C707A" />
        <circle
          cx="34.8004"
          cy="18.0001"
          r="2.4"
          fill="#1F5AFF"
          fillOpacity="0.12"
        />
        <circle
          cx="61.2008"
          cy="18.0001"
          r="2.4"
          fill="#1F5AFF"
          fillOpacity="0.12"
        />
      </svg>
    </Format>
  );
}

Bag.propTypes = IconTypes;
