const data = {
  "nm": "Flow 5",
  "ddd": 0,
  "h": 160,
  "w": 280,
  "meta": {
    "g": "LottieFiles Figma v67"
  },
  "layers": [
    {
      "ty": 0,
      "nm": "tag",
      "sr": 1,
      "st": 0,
      "op": 265,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": true,
      "ao": 0,
      "ks": {
        "a": {
          "a": 1,
          "k": [
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                66,
                66
              ],
              "t": 0
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                66,
                66
              ],
              "t": 12
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                66,
                66
              ],
              "t": 132
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                66,
                66
              ],
              "t": 144
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 1,
                "y": 1
              },
              "s": [
                66,
                66
              ],
              "t": 264
            },
            {
              "s": [
                66,
                66
              ],
              "t": 276
            }
          ]
        },
        "s": {
          "a": 1,
          "k": [
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100,
                100
              ],
              "t": 0
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100,
                100
              ],
              "t": 12
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100,
                100
              ],
              "t": 132
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100,
                100
              ],
              "t": 144
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 1,
                "y": 1
              },
              "s": [
                100,
                100
              ],
              "t": 264
            },
            {
              "s": [
                100,
                100
              ],
              "t": 276
            }
          ]
        },
        "sk": {
          "a": 0,
          "k": 0
        },
        "p": {
          "a": 1,
          "k": [
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                66,
                80
              ],
              "t": 0
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                66,
                80
              ],
              "t": 12
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                140,
                80
              ],
              "t": 132
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                140,
                80
              ],
              "t": 144
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 1,
                "y": 1
              },
              "s": [
                66,
                80
              ],
              "t": 264
            },
            {
              "s": [
                66,
                80
              ],
              "t": 276
            }
          ]
        },
        "r": {
          "a": 1,
          "k": [
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                0
              ],
              "t": 0
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                0
              ],
              "t": 12
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                0
              ],
              "t": 132
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                0
              ],
              "t": 144
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 1,
                "y": 1
              },
              "s": [
                0
              ],
              "t": 264
            },
            {
              "s": [
                0
              ],
              "t": 276
            }
          ]
        },
        "sa": {
          "a": 0,
          "k": 0
        },
        "o": {
          "a": 1,
          "k": [
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100
              ],
              "t": 0
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100
              ],
              "t": 12
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100
              ],
              "t": 132
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100
              ],
              "t": 144
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 1,
                "y": 1
              },
              "s": [
                100
              ],
              "t": 264
            },
            {
              "s": [
                100
              ],
              "t": 276
            }
          ]
        }
      },
      "masksProperties": [
        {
          "nm": "",
          "inv": false,
          "mode": "a",
          "x": {
            "a": 0,
            "k": 0
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "pt": {
            "a": 1,
            "k": [
              {
                "o": {
                  "x": 0,
                  "y": 0
                },
                "i": {
                  "x": 0.7,
                  "y": 0.81
                },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        132,
                        0
                      ],
                      [
                        132,
                        132
                      ],
                      [
                        0,
                        132
                      ],
                      [
                        0,
                        0
                      ]
                    ]
                  }
                ],
                "t": 0
              },
              {
                "o": {
                  "x": 0,
                  "y": 0
                },
                "i": {
                  "x": 0.7,
                  "y": 0.81
                },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        132,
                        0
                      ],
                      [
                        132,
                        132
                      ],
                      [
                        0,
                        132
                      ],
                      [
                        0,
                        0
                      ]
                    ]
                  }
                ],
                "t": 12
              },
              {
                "o": {
                  "x": 0,
                  "y": 0
                },
                "i": {
                  "x": 0.7,
                  "y": 0.81
                },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        132,
                        0
                      ],
                      [
                        132,
                        132
                      ],
                      [
                        0,
                        132
                      ],
                      [
                        0,
                        0
                      ]
                    ]
                  }
                ],
                "t": 132
              },
              {
                "o": {
                  "x": 0,
                  "y": 0
                },
                "i": {
                  "x": 0.7,
                  "y": 0.81
                },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        132,
                        0
                      ],
                      [
                        132,
                        132
                      ],
                      [
                        0,
                        132
                      ],
                      [
                        0,
                        0
                      ]
                    ]
                  }
                ],
                "t": 144
              },
              {
                "o": {
                  "x": 0,
                  "y": 0
                },
                "i": {
                  "x": 1,
                  "y": 1
                },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        132,
                        0
                      ],
                      [
                        132,
                        132
                      ],
                      [
                        0,
                        132
                      ],
                      [
                        0,
                        0
                      ]
                    ]
                  }
                ],
                "t": 264
              },
              {
                "s": [
                  {
                    "c": true,
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        132,
                        0
                      ],
                      [
                        132,
                        132
                      ],
                      [
                        0,
                        132
                      ],
                      [
                        0,
                        0
                      ]
                    ]
                  }
                ],
                "t": 276
              }
            ]
          }
        }
      ],
      "w": 280,
      "h": 160,
      "refId": "1",
      "ind": 1
    }
  ],
  "v": "5.7.0",
  "fr": 60,
  "op": 264,
  "ip": 0,
  "assets": [
    {
      "nm": "[Asset] tag",
      "id": "1",
      "layers": [
        {
          "ty": 4,
          "nm": "Ellipse",
          "sr": 1,
          "st": 0,
          "op": 265,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    4,
                    4
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    4,
                    4
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    4,
                    4
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    4,
                    4
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    4,
                    4
                  ],
                  "t": 264
                },
                {
                  "s": [
                    4,
                    4
                  ],
                  "t": 276
                }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100,
                    100
                  ],
                  "t": 276
                }
              ]
            },
            "sk": {
              "a": 0,
              "k": 0
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    103.03,
                    41.33
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    103.03,
                    41.33
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    103.03,
                    41.33
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    103.03,
                    41.33
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    103.03,
                    41.33
                  ],
                  "t": 264
                },
                {
                  "s": [
                    103.03,
                    41.33
                  ],
                  "t": 276
                }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    135
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    135
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    135
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    135
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    135
                  ],
                  "t": 264
                },
                {
                  "s": [
                    135
                  ],
                  "t": 276
                }
              ]
            },
            "sa": {
              "a": 0,
              "k": 0
            },
            "o": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100
                  ],
                  "t": 276
                }
              ]
            }
          },
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            2.7625,
                            0
                          ],
                          [
                            0,
                            2.7625
                          ],
                          [
                            -2.7625,
                            0
                          ],
                          [
                            0,
                            -2.7625
                          ]
                        ],
                        "o": [
                          [
                            0,
                            2.7625
                          ],
                          [
                            -2.7625,
                            0
                          ],
                          [
                            0,
                            -2.7625
                          ],
                          [
                            2.7625,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            10,
                            5
                          ],
                          [
                            5,
                            10
                          ],
                          [
                            0,
                            5
                          ],
                          [
                            5,
                            0
                          ],
                          [
                            10,
                            5
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            2.7625,
                            0
                          ],
                          [
                            0,
                            2.7625
                          ],
                          [
                            -2.7625,
                            0
                          ],
                          [
                            0,
                            -2.7625
                          ]
                        ],
                        "o": [
                          [
                            0,
                            2.7625
                          ],
                          [
                            -2.7625,
                            0
                          ],
                          [
                            0,
                            -2.7625
                          ],
                          [
                            2.7625,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            10,
                            5
                          ],
                          [
                            5,
                            10
                          ],
                          [
                            0,
                            5
                          ],
                          [
                            5,
                            0
                          ],
                          [
                            10,
                            5
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            2.7625,
                            0
                          ],
                          [
                            0,
                            2.7625
                          ],
                          [
                            -2.7625,
                            0
                          ],
                          [
                            0,
                            -2.7625
                          ]
                        ],
                        "o": [
                          [
                            0,
                            2.7625
                          ],
                          [
                            -2.7625,
                            0
                          ],
                          [
                            0,
                            -2.7625
                          ],
                          [
                            2.7625,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            10,
                            5
                          ],
                          [
                            5,
                            10
                          ],
                          [
                            0,
                            5
                          ],
                          [
                            5,
                            0
                          ],
                          [
                            10,
                            5
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            2.7625,
                            0
                          ],
                          [
                            0,
                            2.7625
                          ],
                          [
                            -2.7625,
                            0
                          ],
                          [
                            0,
                            -2.7625
                          ]
                        ],
                        "o": [
                          [
                            0,
                            2.7625
                          ],
                          [
                            -2.7625,
                            0
                          ],
                          [
                            0,
                            -2.7625
                          ],
                          [
                            2.7625,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            10,
                            5
                          ],
                          [
                            5,
                            10
                          ],
                          [
                            0,
                            5
                          ],
                          [
                            5,
                            0
                          ],
                          [
                            10,
                            5
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            2.7625,
                            0
                          ],
                          [
                            0,
                            2.7625
                          ],
                          [
                            -2.7625,
                            0
                          ],
                          [
                            0,
                            -2.7625
                          ]
                        ],
                        "o": [
                          [
                            0,
                            2.7625
                          ],
                          [
                            -2.7625,
                            0
                          ],
                          [
                            0,
                            -2.7625
                          ],
                          [
                            2.7625,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            10,
                            5
                          ],
                          [
                            5,
                            10
                          ],
                          [
                            0,
                            5
                          ],
                          [
                            5,
                            0
                          ],
                          [
                            10,
                            5
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            2.7625,
                            0
                          ],
                          [
                            0,
                            2.7625
                          ],
                          [
                            -2.7625,
                            0
                          ],
                          [
                            0,
                            -2.7625
                          ]
                        ],
                        "o": [
                          [
                            0,
                            2.7625
                          ],
                          [
                            -2.7625,
                            0
                          ],
                          [
                            0,
                            -2.7625
                          ],
                          [
                            2.7625,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            10,
                            5
                          ],
                          [
                            5,
                            10
                          ],
                          [
                            0,
                            5
                          ],
                          [
                            5,
                            0
                          ],
                          [
                            10,
                            5
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "nm": "",
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      100
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 276
                  }
                ]
              },
              "w": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      2
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      2
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      2
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      2
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      2
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      2
                    ],
                    "t": 276
                  }
                ]
              },
              "c": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.107,
                      0.88,
                      0.777
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.107,
                      0.88,
                      0.777
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.107,
                      0.88,
                      0.777
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.107,
                      0.88,
                      0.777
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0.107,
                      0.88,
                      0.777
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0.107,
                      0.88,
                      0.777
                    ],
                    "t": 276
                  }
                ]
              }
            }
          ],
          "ind": 1
        },
        {
          "ty": 4,
          "nm": "Vector",
          "sr": 1,
          "st": 0,
          "op": 265,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    51.77,
                    56.31
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    51.77,
                    56.31
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    51.77,
                    56.31
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    51.77,
                    56.31
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    51.77,
                    56.31
                  ],
                  "t": 264
                },
                {
                  "s": [
                    51.77,
                    56.31
                  ],
                  "t": 276
                }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100,
                    100
                  ],
                  "t": 276
                }
              ]
            },
            "sk": {
              "a": 0,
              "k": 0
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    67.26,
                    67.65
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    67.26,
                    67.65
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    67.26,
                    67.65
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    67.26,
                    67.65
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    67.26,
                    67.65
                  ],
                  "t": 264
                },
                {
                  "s": [
                    67.26,
                    67.65
                  ],
                  "t": 276
                }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    23.19
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    23.19
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    23.19
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    23.19
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    23.19
                  ],
                  "t": 264
                },
                {
                  "s": [
                    23.19
                  ],
                  "t": 276
                }
              ]
            },
            "sa": {
              "a": 0,
              "k": 0
            },
            "o": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100
                  ],
                  "t": 276
                }
              ]
            }
          },
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -0.03884107938216896,
                            0.8078914760840682
                          ],
                          [
                            0.34956971443952056,
                            0.7300224181482544
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.7088496987245834,
                            0.496415244340813
                          ],
                          [
                            0.8447934765621747,
                            0.1849390125975578
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.7671113177978369,
                            -0.321209863985232
                          ],
                          [
                            0.5146443018137387,
                            -0.6521533602124407
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.1262335079920491,
                            -0.7300224181482544
                          ],
                          [
                            -0.6020367304236188,
                            -0.42827981864697595
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.7282702384156678,
                            0.12653721914569743
                          ],
                          [
                            -0.4272518732038585,
                            0.6132188312445338
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0.41754160335831625,
                            -0.6910878891803476
                          ],
                          [
                            0.03884107938216896,
                            -0.8078914760840682
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.46609295258602745,
                            -0.7300224181482544
                          ],
                          [
                            -0.7088496987245834,
                            -0.496415244340813
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.8253729368710903,
                            -0.08760269017779053
                          ],
                          [
                            -0.7671113177978369,
                            0.321209863985232
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.4272518732038585,
                            0.603485199002557
                          ],
                          [
                            0.1262335079920491,
                            0.7300224181482544
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.6117470002691611,
                            0.42827981864697595
                          ],
                          [
                            0.7282702384156678,
                            -0.12653721914569743
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            99.8409945518653,
                            36.22857920463738
                          ],
                          [
                            100.54013398074434,
                            33.94117562777284
                          ],
                          [
                            100.07404102815832,
                            31.58563662521448
                          ],
                          [
                            86.55734540316351,
                            7.329425078208475
                          ],
                          [
                            84.78036602142929,
                            5.47030131999092
                          ],
                          [
                            82.43048071880807,
                            4.448269934583364
                          ],
                          [
                            55.261145690980875,
                            0.029200896725930178
                          ],
                          [
                            52.84328849944087,
                            0.37961165743709235
                          ],
                          [
                            50.90123453033242,
                            1.8688573904595314
                          ],
                          [
                            0.5049340319681964,
                            74.13134315489475
                          ],
                          [
                            0.03884107938216896,
                            76.22407408691974
                          ],
                          [
                            1.174942651310611,
                            78.03452968392742
                          ],
                          [
                            21.04215475529003,
                            91.97309105443809
                          ],
                          [
                            25.68366374145922,
                            95.18518969429041
                          ],
                          [
                            45.55087584543864,
                            109.12375106480108
                          ],
                          [
                            47.63858386223023,
                            109.58123178017398
                          ],
                          [
                            49.44469405350108,
                            108.43266317562073
                          ],
                          [
                            99.8409945518653,
                            36.22857920463738
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -0.03884107938216896,
                            0.8078914760840682
                          ],
                          [
                            0.34956971443952056,
                            0.7300224181482544
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.7088496987245834,
                            0.496415244340813
                          ],
                          [
                            0.8447934765621747,
                            0.1849390125975578
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.7671113177978369,
                            -0.321209863985232
                          ],
                          [
                            0.5146443018137387,
                            -0.6521533602124407
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.1262335079920491,
                            -0.7300224181482544
                          ],
                          [
                            -0.6020367304236188,
                            -0.42827981864697595
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.7282702384156678,
                            0.12653721914569743
                          ],
                          [
                            -0.4272518732038585,
                            0.6132188312445338
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0.41754160335831625,
                            -0.6910878891803476
                          ],
                          [
                            0.03884107938216896,
                            -0.8078914760840682
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.46609295258602745,
                            -0.7300224181482544
                          ],
                          [
                            -0.7088496987245834,
                            -0.496415244340813
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.8253729368710903,
                            -0.08760269017779053
                          ],
                          [
                            -0.7671113177978369,
                            0.321209863985232
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.4272518732038585,
                            0.603485199002557
                          ],
                          [
                            0.1262335079920491,
                            0.7300224181482544
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.6117470002691611,
                            0.42827981864697595
                          ],
                          [
                            0.7282702384156678,
                            -0.12653721914569743
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            99.8409945518653,
                            36.22857920463738
                          ],
                          [
                            100.54013398074434,
                            33.94117562777284
                          ],
                          [
                            100.07404102815832,
                            31.58563662521448
                          ],
                          [
                            86.55734540316351,
                            7.329425078208475
                          ],
                          [
                            84.78036602142929,
                            5.47030131999092
                          ],
                          [
                            82.43048071880807,
                            4.448269934583364
                          ],
                          [
                            55.261145690980875,
                            0.029200896725930178
                          ],
                          [
                            52.84328849944087,
                            0.37961165743709235
                          ],
                          [
                            50.90123453033242,
                            1.8688573904595314
                          ],
                          [
                            0.5049340319681964,
                            74.13134315489475
                          ],
                          [
                            0.03884107938216896,
                            76.22407408691974
                          ],
                          [
                            1.174942651310611,
                            78.03452968392742
                          ],
                          [
                            21.04215475529003,
                            91.97309105443809
                          ],
                          [
                            25.68366374145922,
                            95.18518969429041
                          ],
                          [
                            45.55087584543864,
                            109.12375106480108
                          ],
                          [
                            47.63858386223023,
                            109.58123178017398
                          ],
                          [
                            49.44469405350108,
                            108.43266317562073
                          ],
                          [
                            99.8409945518653,
                            36.22857920463738
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -0.03884107938216896,
                            0.8078914760840682
                          ],
                          [
                            0.34956971443952056,
                            0.7300224181482544
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.7088496987245834,
                            0.496415244340813
                          ],
                          [
                            0.8447934765621747,
                            0.1849390125975578
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.7671113177978369,
                            -0.321209863985232
                          ],
                          [
                            0.5146443018137387,
                            -0.6521533602124407
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.1262335079920491,
                            -0.7300224181482544
                          ],
                          [
                            -0.6020367304236188,
                            -0.42827981864697595
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.7282702384156678,
                            0.12653721914569743
                          ],
                          [
                            -0.4272518732038585,
                            0.6132188312445338
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0.41754160335831625,
                            -0.6910878891803476
                          ],
                          [
                            0.03884107938216896,
                            -0.8078914760840682
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.46609295258602745,
                            -0.7300224181482544
                          ],
                          [
                            -0.7088496987245834,
                            -0.496415244340813
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.8253729368710903,
                            -0.08760269017779053
                          ],
                          [
                            -0.7671113177978369,
                            0.321209863985232
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.4272518732038585,
                            0.603485199002557
                          ],
                          [
                            0.1262335079920491,
                            0.7300224181482544
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.6117470002691611,
                            0.42827981864697595
                          ],
                          [
                            0.7282702384156678,
                            -0.12653721914569743
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            99.8409945518653,
                            36.22857920463738
                          ],
                          [
                            100.54013398074434,
                            33.94117562777284
                          ],
                          [
                            100.07404102815832,
                            31.58563662521448
                          ],
                          [
                            86.55734540316351,
                            7.329425078208475
                          ],
                          [
                            84.78036602142929,
                            5.47030131999092
                          ],
                          [
                            82.43048071880807,
                            4.448269934583364
                          ],
                          [
                            55.261145690980875,
                            0.029200896725930178
                          ],
                          [
                            52.84328849944087,
                            0.37961165743709235
                          ],
                          [
                            50.90123453033242,
                            1.8688573904595314
                          ],
                          [
                            0.5049340319681964,
                            74.13134315489475
                          ],
                          [
                            0.03884107938216896,
                            76.22407408691974
                          ],
                          [
                            1.174942651310611,
                            78.03452968392742
                          ],
                          [
                            21.04215475529003,
                            91.97309105443809
                          ],
                          [
                            25.68366374145922,
                            95.18518969429041
                          ],
                          [
                            45.55087584543864,
                            109.12375106480108
                          ],
                          [
                            47.63858386223023,
                            109.58123178017398
                          ],
                          [
                            49.44469405350108,
                            108.43266317562073
                          ],
                          [
                            99.8409945518653,
                            36.22857920463738
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -0.03884107938216896,
                            0.8078914760840682
                          ],
                          [
                            0.34956971443952056,
                            0.7300224181482544
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.7088496987245834,
                            0.496415244340813
                          ],
                          [
                            0.8447934765621747,
                            0.1849390125975578
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.7671113177978369,
                            -0.321209863985232
                          ],
                          [
                            0.5146443018137387,
                            -0.6521533602124407
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.1262335079920491,
                            -0.7300224181482544
                          ],
                          [
                            -0.6020367304236188,
                            -0.42827981864697595
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.7282702384156678,
                            0.12653721914569743
                          ],
                          [
                            -0.4272518732038585,
                            0.6132188312445338
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0.41754160335831625,
                            -0.6910878891803476
                          ],
                          [
                            0.03884107938216896,
                            -0.8078914760840682
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.46609295258602745,
                            -0.7300224181482544
                          ],
                          [
                            -0.7088496987245834,
                            -0.496415244340813
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.8253729368710903,
                            -0.08760269017779053
                          ],
                          [
                            -0.7671113177978369,
                            0.321209863985232
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.4272518732038585,
                            0.603485199002557
                          ],
                          [
                            0.1262335079920491,
                            0.7300224181482544
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.6117470002691611,
                            0.42827981864697595
                          ],
                          [
                            0.7282702384156678,
                            -0.12653721914569743
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            99.8409945518653,
                            36.22857920463738
                          ],
                          [
                            100.54013398074434,
                            33.94117562777284
                          ],
                          [
                            100.07404102815832,
                            31.58563662521448
                          ],
                          [
                            86.55734540316351,
                            7.329425078208475
                          ],
                          [
                            84.78036602142929,
                            5.47030131999092
                          ],
                          [
                            82.43048071880807,
                            4.448269934583364
                          ],
                          [
                            55.261145690980875,
                            0.029200896725930178
                          ],
                          [
                            52.84328849944087,
                            0.37961165743709235
                          ],
                          [
                            50.90123453033242,
                            1.8688573904595314
                          ],
                          [
                            0.5049340319681964,
                            74.13134315489475
                          ],
                          [
                            0.03884107938216896,
                            76.22407408691974
                          ],
                          [
                            1.174942651310611,
                            78.03452968392742
                          ],
                          [
                            21.04215475529003,
                            91.97309105443809
                          ],
                          [
                            25.68366374145922,
                            95.18518969429041
                          ],
                          [
                            45.55087584543864,
                            109.12375106480108
                          ],
                          [
                            47.63858386223023,
                            109.58123178017398
                          ],
                          [
                            49.44469405350108,
                            108.43266317562073
                          ],
                          [
                            99.8409945518653,
                            36.22857920463738
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -0.03884107938216896,
                            0.8078914760840682
                          ],
                          [
                            0.34956971443952056,
                            0.7300224181482544
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.7088496987245834,
                            0.496415244340813
                          ],
                          [
                            0.8447934765621747,
                            0.1849390125975578
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.7671113177978369,
                            -0.321209863985232
                          ],
                          [
                            0.5146443018137387,
                            -0.6521533602124407
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.1262335079920491,
                            -0.7300224181482544
                          ],
                          [
                            -0.6020367304236188,
                            -0.42827981864697595
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.7282702384156678,
                            0.12653721914569743
                          ],
                          [
                            -0.4272518732038585,
                            0.6132188312445338
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0.41754160335831625,
                            -0.6910878891803476
                          ],
                          [
                            0.03884107938216896,
                            -0.8078914760840682
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.46609295258602745,
                            -0.7300224181482544
                          ],
                          [
                            -0.7088496987245834,
                            -0.496415244340813
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.8253729368710903,
                            -0.08760269017779053
                          ],
                          [
                            -0.7671113177978369,
                            0.321209863985232
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.4272518732038585,
                            0.603485199002557
                          ],
                          [
                            0.1262335079920491,
                            0.7300224181482544
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.6117470002691611,
                            0.42827981864697595
                          ],
                          [
                            0.7282702384156678,
                            -0.12653721914569743
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            99.8409945518653,
                            36.22857920463738
                          ],
                          [
                            100.54013398074434,
                            33.94117562777284
                          ],
                          [
                            100.07404102815832,
                            31.58563662521448
                          ],
                          [
                            86.55734540316351,
                            7.329425078208475
                          ],
                          [
                            84.78036602142929,
                            5.47030131999092
                          ],
                          [
                            82.43048071880807,
                            4.448269934583364
                          ],
                          [
                            55.261145690980875,
                            0.029200896725930178
                          ],
                          [
                            52.84328849944087,
                            0.37961165743709235
                          ],
                          [
                            50.90123453033242,
                            1.8688573904595314
                          ],
                          [
                            0.5049340319681964,
                            74.13134315489475
                          ],
                          [
                            0.03884107938216896,
                            76.22407408691974
                          ],
                          [
                            1.174942651310611,
                            78.03452968392742
                          ],
                          [
                            21.04215475529003,
                            91.97309105443809
                          ],
                          [
                            25.68366374145922,
                            95.18518969429041
                          ],
                          [
                            45.55087584543864,
                            109.12375106480108
                          ],
                          [
                            47.63858386223023,
                            109.58123178017398
                          ],
                          [
                            49.44469405350108,
                            108.43266317562073
                          ],
                          [
                            99.8409945518653,
                            36.22857920463738
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -0.03884107938216896,
                            0.8078914760840682
                          ],
                          [
                            0.34956971443952056,
                            0.7300224181482544
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.7088496987245834,
                            0.496415244340813
                          ],
                          [
                            0.8447934765621747,
                            0.1849390125975578
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.7671113177978369,
                            -0.321209863985232
                          ],
                          [
                            0.5146443018137387,
                            -0.6521533602124407
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.1262335079920491,
                            -0.7300224181482544
                          ],
                          [
                            -0.6020367304236188,
                            -0.42827981864697595
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.7282702384156678,
                            0.12653721914569743
                          ],
                          [
                            -0.4272518732038585,
                            0.6132188312445338
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0.41754160335831625,
                            -0.6910878891803476
                          ],
                          [
                            0.03884107938216896,
                            -0.8078914760840682
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.46609295258602745,
                            -0.7300224181482544
                          ],
                          [
                            -0.7088496987245834,
                            -0.496415244340813
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.8253729368710903,
                            -0.08760269017779053
                          ],
                          [
                            -0.7671113177978369,
                            0.321209863985232
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -0.4272518732038585,
                            0.603485199002557
                          ],
                          [
                            0.1262335079920491,
                            0.7300224181482544
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0.6117470002691611,
                            0.42827981864697595
                          ],
                          [
                            0.7282702384156678,
                            -0.12653721914569743
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            99.8409945518653,
                            36.22857920463738
                          ],
                          [
                            100.54013398074434,
                            33.94117562777284
                          ],
                          [
                            100.07404102815832,
                            31.58563662521448
                          ],
                          [
                            86.55734540316351,
                            7.329425078208475
                          ],
                          [
                            84.78036602142929,
                            5.47030131999092
                          ],
                          [
                            82.43048071880807,
                            4.448269934583364
                          ],
                          [
                            55.261145690980875,
                            0.029200896725930178
                          ],
                          [
                            52.84328849944087,
                            0.37961165743709235
                          ],
                          [
                            50.90123453033242,
                            1.8688573904595314
                          ],
                          [
                            0.5049340319681964,
                            74.13134315489475
                          ],
                          [
                            0.03884107938216896,
                            76.22407408691974
                          ],
                          [
                            1.174942651310611,
                            78.03452968392742
                          ],
                          [
                            21.04215475529003,
                            91.97309105443809
                          ],
                          [
                            25.68366374145922,
                            95.18518969429041
                          ],
                          [
                            45.55087584543864,
                            109.12375106480108
                          ],
                          [
                            47.63858386223023,
                            109.58123178017398
                          ],
                          [
                            49.44469405350108,
                            108.43266317562073
                          ],
                          [
                            99.8409945518653,
                            36.22857920463738
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "nm": "",
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      100
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 276
                  }
                ]
              },
              "w": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      3
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      3
                    ],
                    "t": 276
                  }
                ]
              },
              "c": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.6784,
                      0.576,
                      0.96
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.6784,
                      0.576,
                      0.96
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.6784,
                      0.576,
                      0.96
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.6784,
                      0.576,
                      0.96
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0.6784,
                      0.576,
                      0.96
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0.6784,
                      0.576,
                      0.96
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "nm": "",
              "c": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 276
                  }
                ]
              },
              "r": 2,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      100
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 276
                  }
                ]
              }
            }
          ],
          "ind": 2
        },
        {
          "ty": 4,
          "nm": "tag Bg",
          "sr": 1,
          "st": 0,
          "op": 265,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 264
                },
                {
                  "s": [
                    66,
                    66
                  ],
                  "t": 276
                }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100,
                    100
                  ],
                  "t": 276
                }
              ]
            },
            "sk": {
              "a": 0,
              "k": 0
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 264
                },
                {
                  "s": [
                    66,
                    66
                  ],
                  "t": 276
                }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    0
                  ],
                  "t": 264
                },
                {
                  "s": [
                    0
                  ],
                  "t": 276
                }
              ]
            },
            "sa": {
              "a": 0,
              "k": 0
            },
            "o": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100
                  ],
                  "t": 276
                }
              ]
            }
          },
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            132,
                            0
                          ],
                          [
                            132,
                            132
                          ],
                          [
                            0,
                            132
                          ],
                          [
                            0,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            132,
                            0
                          ],
                          [
                            132,
                            132
                          ],
                          [
                            0,
                            132
                          ],
                          [
                            0,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            132,
                            0
                          ],
                          [
                            132,
                            132
                          ],
                          [
                            0,
                            132
                          ],
                          [
                            0,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            132,
                            0
                          ],
                          [
                            132,
                            132
                          ],
                          [
                            0,
                            132
                          ],
                          [
                            0,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            132,
                            0
                          ],
                          [
                            132,
                            132
                          ],
                          [
                            0,
                            132
                          ],
                          [
                            0,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            132,
                            0
                          ],
                          [
                            132,
                            132
                          ],
                          [
                            0,
                            132
                          ],
                          [
                            0,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            }
          ],
          "ind": 3
        }
      ]
    }
  ]
}
module.exports = data;