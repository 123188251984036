import React from 'react';
import Format from './Format';
import { IconTypes } from './types';

export default function ListErrorIcon({
  width = '96',
  height = '96',
  ...args
}) {
  return (
    <Format {...args}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12 26.1973H82.9859" stroke="#6C707A" strokeWidth="2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 25.7761L21.6566 14H74.3434L82 25.7761V82H42.4225V84H82H84V82V25.1831L75.4286 12H20.5714L12 25.1831V54.5915H14V25.7761Z"
          fill="#6C707A"
        />
        <path
          d="M56.7781 51.9673L72.3344 36.4109"
          stroke="#E0516E"
          strokeWidth="2"
        />
        <path
          d="M72.3347 51.9673L56.7784 36.4109"
          stroke="#E0516E"
          strokeWidth="2"
        />
        <path d="M12 61H36" stroke="#6C707A" strokeWidth="2" />
        <path d="M18 71H36" stroke="#6C707A" strokeWidth="2" />
        <path d="M24 81H36" stroke="#6C707A" strokeWidth="2" />
      </svg>
    </Format>
  );
}

ListErrorIcon.propTypes = IconTypes;
