import styled from 'styled-components';
import Modal from 'components/Modal';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import Button from 'components/Button';

export const ModalContainer = styled(Modal)`
  > .modal-container {
    height: 720px;
    aspect-ratio: 4 / 3;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    > .modal-container {
      height: calc(100vh - var(--header-computed-height) - 75px);
      max-height: 430px;
    }
  }
`;

export const FooterButton = styled(Button).attrs({ type: 'rainbow' })`
  position: fixed !important;
  bottom: 25px;
  right: 90px;
  width: 120px;
  height: 48px;
  --border-radius: 30px !important;
  z-index: 1000 !important;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
`;
