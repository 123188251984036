export const SENTRY_DSN = process.env.SENTRY_DSN;
export const CRISP_ID = process.env.CRISP_ID;

export const INTL_LOCALE_KEY = 'intl-locale';
export const CURRENCY_LOCALE_KEY = 'keystone-currency-key';

export const keystoneDeviceId = {
  productId: 12289,
  vendorId: 4617,
};

export const initialFeatures = [
  { name: 'livechat', isActive: true },
  { name: 'growthbook', isActive: true },
  { name: 'mm', isActive: true },
  { name: 'preOrder', isActive: false },
  { name: 'gen3Upgrade', isActive: true },
  { name: 'youzanInCart', isActive: true },
  { name: 'btc-only', isActive: false },
];

export const isProd = process.env.ENV === 'production';
export const isStaging = process.env.ENV === 'staging';
export const isDev = process.env.ENV === 'development';
export const isNonProd = !isProd;

const webProdCredential = {
  id: '5KlATX00pTzfSVoK6IQJG4vcWJ4zWl5T',
  secret: 'CnZaA33ZClH54SlwySaohCpWNp0t61XL',
};

const webStagingCredential = {
  id: '5KlATX00pTzfSVoK6IQJG4vcWJ4zWl5T',
  secret: 'CnZaA33ZClH54SlwySaohCpWNp0t61XL',
};

export const webCredential = isProd ? webProdCredential : webStagingCredential;
