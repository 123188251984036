import Plus from 'components/icons/Plus';
import { BodyStyle } from 'components/styles/body';
import { FlexBetween } from 'components/styles/flex';
import { H5Style } from 'components/styles/title';
import styled, { css } from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';

export const CollapseContainer = styled.div`
  width: 100%;
`;

export const CollapseItem = styled.div`
  cursor: pointer;
  border-bottom: ${(props) =>
    props.hideBorder ? 0 : '1px solid var(--color-border-subtle)'};
  .ReactCollapse--collapse {
    transition: height ease-in-out 0.25s;
  }
`;

export const CollapseHeader = styled(FlexBetween).attrs({ as: 'h4' })`
  padding: 25px 34px 25px 0;
  color: var(--color-fg-default);
  text-transform: none;
  ${H5Style}
  @media ${DEVICE_QUERY_MOBILE} {
    padding-right: 0px;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ExpandIcon = styled(Plus)`
  color: var(--color-fg-muted);
  transition: var(--transition);
  transform: rotate(45deg);
  ${(props) =>
    props.open
      ? css`
          transform: rotate(0deg);
        `
      : ''}
`;

export const CollapseContent = styled.div`
  padding-bottom: 10px;
  color: var(--color-fg-subtle);
  ${BodyStyle}
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 14px;
    line-height: 22px;
  }
`;
