import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE, DEVICE_QUERY_MOBILE_1360 } from 'utils/constants';
import Button from 'components/Button';
import Link from 'components/Link';
import { CaptionStyle, OverlineStyle } from 'components/styles/body';
import Cart from 'components/icons/Cart';
import { FlexCenter, FlexStart } from 'components/styles/flex';
import { H2, H3, H4, H5, H7 } from 'components/styles/title';

export const Page = styled.div.attrs({
  className: 'theme-dark',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 228px;
  background: var(--color-bg-default);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  @media ${DEVICE_QUERY_MOBILE_1360} {
    padding: 0 24px;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    min-width: 360px;
    padding: 0;
  }
`;

export const RateBox = styled(FlexStart)`
  margin-top: 16px;
`;

export const SaleExpireCountdown = styled(FlexCenter)`
  margin-left: 8px;
  padding: 4px 12px;
  background: rgba(62, 62, 62, 0.4);
  backdrop-filter: blur(4px);
  border-radius: 32px;
  > .caption {
    color: #f0a247;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    > .caption {
      ${OverlineStyle}
    }
  }
`;

export const CartIcon = styled(Cart)`
  color: var(--color-bd-cyan);
`;

export const BaseButton = styled(Button)`
  position: relative;
  background: var(--color-bg-default-trans);
  border-image: var(--color-gd-primary) 1;
`;

export const CartButton = styled(BaseButton)`
  margin-right: 24px;
  & > span:first-child {
    margin-right: 0 !important;
    svg {
      fill: ${(props) => props.disabled && 'var(--color-fg-subtle)'};
    }
  }
  @media ${DEVICE_QUERY_MOBILE} {
    padding: 11px 14px;
    height: 40px;
  }
`;

export const DetailsButton = styled(BaseButton)`
  color: var(--color-fg-default);
  .btn-icon-arrow {
    color: var(--color-bd-contiguous);
  }
  @media ${DEVICE_QUERY_MOBILE} {
    padding: 11px 14px;
    height: 40px;
    font-size: 12px;
    line-height: 20px;
  }
`;

export const ProductTitle = styled(H3)`
  color: var(--color-fg-default);
  font-family: 'Mont';
  z-index: 1;
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const ProductDesc = styled.p`
  margin-bottom: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  color: var(--color-fg-muted);
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.04em;
  width: 60%;
  z-index: 1;
  @media ${DEVICE_QUERY_MOBILE} {
    margin: 16px 0 24px;
  }
`;

export const WalletsCompare = styled.div`
  margin-top: 64px;
  > div > div,
  h3,
  p,
  a {
    background: var(--color-bg-subtle);
  }
  @media ${DEVICE_QUERY_MOBILE} {
    margin-top: 64px;
    background: var(--color-bg-subtle);
  }
`;

export const CompareContent = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: calc(50% - 9px) calc(50% - 9px);
  grid-gap: 0 18px;
  padding: 0 18px;
  justify-content: space-between;
  text-align: center;
  @media ${DEVICE_QUERY_MOBILE} {
    margin-top: 64px;
  }
`;

export const CompareImgContent = styled.div`
  width: auto;
  height: auto;
  padding-top: 48px;
  @media ${DEVICE_QUERY_MOBILE} {
    height: auto;
    padding-top: 40px;
  }
`;

export const CompareImg = styled.img`
  width: 240px;
  height: auto;
  @media ${DEVICE_QUERY_MOBILE} {
    max-width: ${(params) =>
    ({ wallet: '26vw', tablet: '44vw' })[params.type] || '44vw'};
  }
`;

export const CompareProductTitle = styled(ProductTitle)`
  padding-top: 64px;
  @media ${DEVICE_QUERY_MOBILE} {
    padding-top: 24px;
  }
`;

export const CompareProductDesc = styled(ProductDesc)`
  margin: 0;
  padding: 32px 40px 24px;
  width: 100%;
  text-align: center;
  @media ${DEVICE_QUERY_MOBILE} {
    display: block;
    padding: 24px 0 32px;
    margin: 0;
  }
`;

export const CompareProductPriceBox = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 32px 0;
  gap: 0 8px;
  @media ${DEVICE_QUERY_MOBILE} {
    padding: 0 0 32px;
  }
`;

export const CompareProductPriceBoxPrice = styled(H4)`
  color: var(--color-bd-pink);
`;

export const CompareProductPriceBoxOriginPrice = styled(H4)`
  color: var(--color-fg-subtle);
  text-decoration: line-through;
`;

export const LearnMoreButton = styled(BaseButton)`
  position: relative;
  margin-bottom: 68px;
  color: var(--color-fg-default);
  @media ${DEVICE_QUERY_MOBILE} {
    margin-bottom: 40px;
    padding: 10px 14px;
    font-size: 12px;
    line-height: 20px;
  }
`;

export const ParamsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 40px 6px;
  @media ${DEVICE_QUERY_MOBILE} {
    padding: 0;
  }
`;

export const ParamsImg = styled.img`
  width: 36px;
  height: 36px;
`;

export const ParamsTitle = styled(H7)`
  margin-top: 18px;
  color: var(--color-fg-muted);
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 12px;
    line-height: 20px;
    text-align: center;
  }
`;

export const ParamsDesc = styled.p`
  margin: 4px 0 42px;
  color: var(--color-fg-subtle);
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.04em;
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 12px;
    line-height: 16px;
  }
`;

const ProductCategoryContainer = styled.div`
  width: 100%;
  margin-top: 160px;
  @media ${DEVICE_QUERY_MOBILE} {
    margin-top: 128px;
  }
`;

export const Accessories = styled(ProductCategoryContainer)``;

export const Bundles = styled(ProductCategoryContainer)`
  div > img {
    height: 100%;
  }
`;

export const HardwareWallet = styled(ProductCategoryContainer)``;

export const CategoryTitle = styled(H2)`
  color: var(--color-fg-default);
  font-family: 'Mont';
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 32px;
    line-height: 48px;
    text-align: center;
  }
`;

export const AccessoriesContent = styled.div`
  display: grid;
  gap: 80px 40px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 40px;
  @media ${DEVICE_QUERY_MOBILE_1360} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    grid-template-columns: 1fr;
    margin-top: 64px;
    padding: 0 24px;
  }
`;

export const BundlesContent = styled.div`
  display: grid;
  gap: 80px 40px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 40px;
  @media ${DEVICE_QUERY_MOBILE_1360} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    grid-template-columns: 1fr;
    margin-top: 64px;
    padding: 0 24px;
  }
`;

export const Bundle = styled.div``;

export const AccessoriesProduct = styled.div`
  width: 100%;
  height: 484px;
  border: 1px solid var(--color-border-slight);
  @media ${DEVICE_QUERY_MOBILE} {
    width: 100%;
    max-width: none;
    height: auto;
  }
`;

export const AccessoriesProductImgBox = styled.div`
  width: 100%;
  height: 264px;
  padding: 0 20px;
  background: var(--color-bg-muted);
  @media ${DEVICE_QUERY_MOBILE} {
    height: 237px;
  }
`;

export const AccessoriesProductImg = styled.img`
  display: block;
  height: 100%;
  margin: 0 auto;
`;

export const AccessoriesProductContent = styled.div`
  padding: 0 32px;
  @media ${DEVICE_QUERY_MOBILE} {
    padding: 0 16px;
  }
`;

export const AccessoriesProductTitle = styled(H4).attrs({ as: 'h4' })`
  margin-top: 32px;
  color: var(--color-fg-default);
  font-family: 'Mont';
  @media ${DEVICE_QUERY_MOBILE} {
    margin-top: 24px;
    letter-spacing: 0;
  }
`;

export const AccessoriesProductDesc = styled.h5`
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: var(--color-fg-subtle);
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.04em;
`;

export const AccessoriesProductPriceContent = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 16px;
  @media ${DEVICE_QUERY_MOBILE} {
    margin-bottom: 42px;
  }
`;

export const AccessoriesProductPrice = styled(H5)`
  color: var(--color-bd-pink);
  font-family: 'Montserrat';
`;

export const AccessoriesProductOriginPrice = styled(H7)`
  margin-left: 8px;
  color: var(--color-fg-subtle);
  text-decoration: line-through;
`;

export const AccessoriesButtonBox = styled.div`
  position: absolute;
  display: flex;
  left: 32px;
  bottom: -24px;
  padding-left: 16px;
  @media ${DEVICE_QUERY_MOBILE} {
    left: 0;
    bottom: -20px;
  }
`;

export const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
`;

export const CartLoadingContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - var(--height-header));
  & > div {
    width: 84px;
    height: 6px;
    ::after {
      background: var(--color-fg-muted);
    }
  }
`;

export const RateReview = styled(Link)`
  display: flex;
  ${CaptionStyle};
  margin-left: 24px;
  margin-top: -2px;
  white-space: nowrap;
  color: var(--color-fg-muted);
  @media ${DEVICE_QUERY_MOBILE} {
    margin-left: 12px;
    ${OverlineStyle}
  }
`;
