import React from 'react';
import propTypes from 'prop-types';
import { Iframe, Video, VideoBoxModal } from './style';

export default function VideoModal({ open, onClose, src, ...args }) {
  return (
    <VideoBoxModal
      open={open}
      width="75vw"
      closeIcon
      onClose={onClose}
      style={{ border: 'none' }}
      centered
      {...args}
    >
      {src?.includes('youtube')
        ? (
            <Iframe
              title="video"
              src={`${src}?autoplay=1`}
              style={{ border: 0 }}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )
        : (
            <Video src={src} autoPlay controls />
          )}
    </VideoBoxModal>
  );
}

VideoModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  src: propTypes.string,
};
