import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import { Overline } from 'components/styles/bodyNew';
import { H6, H7Style, H8mStyle } from '../styles/title';
import Cart from '../icons/Cart';
import Button from '../Button';

export const Recommended = styled.div`
  margin: 32px 0;
  @media ${DEVICE_QUERY_MOBILE} {
    margin: 24px 0;
  }
`;

export const RecommendedTitle = styled(H6)`
  margin: 24px 0;
  color: var(--color-fg-default);
  font-family: 'Montserrat';
  @media ${DEVICE_QUERY_MOBILE} {
    margin: 16px 0;
    font-size: 14px;
    height: 22px;
  }
`;

export const RecommendedList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, calc(33.333% - 7px));
  gap: 10px;
`;

export const ReProduct = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const ReProductImg = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
`;

export const ReBaseP = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.04em;
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const ReProductName = styled(ReBaseP)`
  margin-top: 13px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-fg-default);

  @media ${DEVICE_QUERY_MOBILE} {
    margin-top: 8px;
  }
`;

export const ReProductDesc = styled(Overline)`
  margin-top: 8px;
  color: var(--color-fg-muted);
  min-height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const ReProductPriceContent = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
`;

export const ReProductOriginPrice = styled(ReBaseP)`
  margin-right: 8px;
  color: var(--color-fg-subtle);
  text-decoration: line-through;
`;

export const ReProductPrice = styled(ReBaseP)`
  color: var(--color-bd-pink);
`;

export const CartIcon = styled(Cart)`
  color: var(--color-bd-cyan);
`;

export const CartButton = styled(Button)`
  position: relative;
  margin-top: 12px;
  width: 100%;
  height: 40px;
  background: var(--color-bg-default-trans);
  border-image: var(--color-gd-primary) 1;
  padding: 8px 16px;
  ${H7Style}
  @media ${DEVICE_QUERY_MOBILE} {
    margin-top: 8px;
    padding: 6px 12px;
    height: 32px;
    ${H8mStyle}
  }
`;
