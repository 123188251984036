import { defineMessages } from 'react-intl';

export const scope = 'keystone.cart.';

export default defineMessages({
  recommendedTitle: {
    id: `${scope}recommended.title`,
    defaultMessage: '',
  },
  addToCart: {
    id: `${scope}addToCart`,
    defaultMessage: '',
  },
});
