import styled from 'styled-components';

export const SelectContainer = styled.div`
  display: inline-block;
  position: relative;
  border: 2px solid var(--color-fg-default);
  color: var(--color-fg-default);
  cursor: pointer;
  max-width: 90%;
  .icon-arrow {
    position: absolute;
    display: flex;
    top: 50%;
    transform: translateY(-70%);
    right: 16px;
    color: var(--color-fg-subtle);
    width: 18px;
    height: 18px;
    pointer-events: none;
    transition: var(--transition);
  }

  :hover {
    .icon-arrow {
      transition: var(--transition);
      color: var(--color-bd-pink);
    }
  }

  &::disabled {
    .icon-arrow {
      color: var(--color-bd-contiguous);
    }
  }
`;

export const Container = styled.select`
  width: 100%;
  height: 48px;
  padding: 10px 24px;
  appearance: none;
  outline: none;
  background: transparent;
  margin: 0;
  cursor: pointer;
  color: var(--color-fg-emphasis);
  border: none;
`;

export const ListItem = styled.option.attrs({
  className: 'list-item',
})`
  background: var(--color-bg-default);
`;

export const RequiredSpan = styled.span`
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(calc(-50% + 2px));
  color: var(--color-bd-pink);
`;
