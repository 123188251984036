import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import useIsMobile from 'hooks/useIsMobile';
import { logger } from 'logger';
import { applyUrlParamsDiscountCodesToCart } from 'utils/cartUtils';
import TopNotification from 'components/TopNotification';
import useHeaderTopBanner from 'stores/headerTopBanner';
import useCartStore from 'stores/cart';
import useProductsStore from 'stores/products';
import useShippingRegionStore from 'stores/shippingRegions';
import useActivityBanner from 'hooks/useActivityBanner';
import Globe from 'components/icons/Globe';
import CampaignModal from 'components/CampaignModal';
import { ErrorBoundaryFallback } from 'components/ErrorBoundary';
import { ErrorBoundary } from 'react-error-boundary';
import MenuIcon from '../icons/Menu';
import CloseIcon from '../icons/Close';
import CartIcon from '../icons/CartBag';
import { LINKS } from '../../config/links';
import { mainMsgs as messages } from './messages';
import Products from './Products';
import Learn from './Learn';
import {
  BuyButton,
  CartIconBox,
  Container,
  DropIcon,
  Entries,
  HeaderBox,
  HeaderStickyBox,
  Logo,
  LogoBox,
  LogoLink,
  Menu,
  MenuBackMobile,
  MenuEntryMobile,
  MenuTitle,
  Menus,
  ProductQuantity,
  SubmenuTitle,
} from './style';
import RfsnBanner from './RfsnBanner';
import Partnerships from './Partnerships';
import Language from './Language';
import LanguageSubmenu from './LanguageSubmenu';
import ActivityBanner from './ActivityBanner';
import DiscountCodeBanner from './DiscountCodeBanner';
import CoinsAndFeatures from './CoinsAndFeatures';

const darkToLightPages = [
  '/cookie-policy',
  '/keystone-terms-of-use',
  '/privacy-policy',
  '/shipping-policy',
  '/refund-policy',
  '/sales-terms-and-conditions',
  '/website-terms-of-use',
  '/get-started/',
  '/supported-wallets-and-assets',
  '/bug-bounty-program',
  '/keystone-hardware-wallet-product-comparison',
  '/companion-app',
  '/metamask',
];

function Header() {
  const intl = useIntl();
  const location = useLocation();
  const isMobile = useIsMobile();
  const [activeMenu, setActiveMenu] = useState();
  const [isHover, setIsHover] = useState();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState();
  const [isDarkToLight, setIsDarkToLight] = useState();
  const [rfsnId, setRfsnId] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const { openCart, loadList, cartData, quantity } = useCartStore();
  const { initProducts } = useProductsStore();
  const { checkCurrentCountry } = useShippingRegionStore();

  const rfsnBannerDistance = useMemo(() => (isMobile ? 36 : 40), [isMobile]);
  const bannerDistance = useMemo(() => (isMobile ? 48 : 64), [isMobile]);
  const distance = 64;

  const dom = useRef();
  const bannerDom = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isOpened: isOpenedActivityBanner,
    close: closeActivityBanner,
    activityBannerData,
  } = useActivityBanner();
  const { isOpen: headerTopIsOpen, setIsOpen: setHeaderTopIsOpen }
    = useHeaderTopBanner();

  const buyButtonCondition = ['shop', 'product', 'cart'];

  const checkSearchQuery = useCallback(() => {
    const params = new URLSearchParams(location.search);
    const refId = params.get('rfsn');
    if (refId) {
      setRfsnId(refId.slice(0, refId.indexOf('.')));
      setHeaderTopIsOpen('rfsn');
    }
  }, [location.search, setHeaderTopIsOpen]);

  useEffect(() => {
    checkSearchQuery();
  }, [checkSearchQuery]);

  const onScroll = useCallback(() => {
    try {
      const { scrollTop, style } = document.documentElement;
      const per = (scrollTop > distance ? distance : scrollTop) / distance;
      dom.current.style.setProperty(
        '--background-color',
        `rgba(17, 18, 20, ${isDarkToLight ? 1 - (1 - 0.88) * per : 0.88 * per})`,
      );
      dom.current.style.setProperty('--backdrop-filter', `blur(${8 * per}px)`);

      if (isOpenedActivityBanner || headerTopIsOpen) {
        const bannerHeight = bannerDom.current?.clientHeight || 0;
        style.setProperty(
          '--header-computed-height',
          `${bannerHeight + distance}px`,
        );
      }
    } catch (err) {
      logger.error(err);
    }
  }, [isDarkToLight, isOpenedActivityBanner, headerTopIsOpen, bannerDom]);

  const leaveTimer = useRef(null);
  const enterTimer = useRef(null);
  const menuTypes = {
    products: 'products',
    walletsAndAssets: 'walletsAndAssets',
    coinsAndFeatures: 'coinsAndFeatures',
    resources: 'resources',
    partnerships: 'partnerships',
    learn: 'learn',
    language: 'language',
  };

  const menuList = [
    {
      name: menuTypes.products,
      title: messages.products,
    },
    {
      name: menuTypes.coinsAndFeatures,
      title: messages.coinsAndFeatures,
    },
    {
      name: menuTypes.learn,
      title: messages.learn,
    },
    {
      name: menuTypes.partnerships,
      title: messages.partnerships,
    },
    {
      name: menuTypes.language,
      title: messages.currentLanguage,
      onlyMobile: true,
      className: 'menu-language',
      icon: <Globe />,
    },
  ];

  const handleOnMouseEnter = useCallback(
    (menu) => {
      if (isMobile)
        return;
      clearTimeout(enterTimer.current);
      clearTimeout(leaveTimer.current);
      enterTimer.current = setTimeout(() => {
        setActiveMenu(menu);
      }, 300);
    },
    [isMobile],
  );

  const handleOnMouseLeave = useCallback(() => {
    leaveTimer.current = setTimeout(() => {
      closeSubmenu();
    }, 300);
  }, []);
  const handleClickMenu = (menu) => {
    if (!isMobile)
      return;
    setActiveMenu(menu);
  };
  const closeSubmenu = () => {
    setActiveMenu(null);
    setIsMobileMenuOpen(false);
    setIsHover(false);
  };

  useEffect(() => {
    onScroll();
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  useEffect(() => {
    if (!isMobile) {
      setIsMobileMenuOpen(false);
    }
    if (isMobile && activeMenu) {
      setIsMobileMenuOpen(true);
    }
  }, [activeMenu, isMobile]);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add('menu-header-mobile');
    } else {
      document.body.classList.remove('menu-header-mobile');
    }
  }, [isMobileMenuOpen]);

  useEffect(() => {
    closeSubmenu();

    if (location.pathname !== '/') {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
    } else {
      setTimeout(() => {
        const tempY = window.scrollY;
        window.scrollTo(0, 0);
        window.scrollTo({
          top: tempY,
          left: 0,
          behavior: 'smooth',
        });
      }, 200);
    }

    setIsDarkToLight(
      darkToLightPages.some(
        (e) => e === location.pathname.substring(0, e.length),
      ),
    );
  }, [location.pathname]);

  const queryCartInfo = useCallback(async () => {
    try {
      await initProducts();
      await loadList();
      await checkCurrentCountry();
      await applyUrlParamsDiscountCodesToCart(cartData);
    } catch (err) {
      if (err.message?.includes('Failed to add discount code')) {
        const params = new URLSearchParams(window.location.search);
        const discountCode = params.get('discount');
        setErrorMessage(
          intl.formatMessage(messages.cartApplyCodeFailed, {
            code: discountCode,
          }),
        );
      }
      logger.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData, dispatch, intl]);

  useEffect(() => {
    queryCartInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnMouseArgs = useCallback(
    (name) => {
      return (
        !isMobile && {
          onMouseEnter: () => handleOnMouseEnter(name),
          onMouseLeave: handleOnMouseLeave,
        }
      );
    },
    [handleOnMouseEnter, handleOnMouseLeave, isMobile],
  );

  const logoSrc = useMemo(() => {
    let logos = [
      require('./images/logo.png'),
      require('../Footer/images/logo.svg'),
    ];

    const btcLogos = [
      require('./images/keystone-logo-btc.png'),
      require('../Footer/images/keystone-logo-btc.svg'),
    ];

    const isUseBtcLogo = [LINKS.btcOnly, LINKS.bitcoinHalving].includes(
      location.pathname,
    );

    if (isUseBtcLogo) {
      logos = btcLogos;
    }

    return logos[isMobile ? 1 : 0];
  }, [isMobile, location.pathname]);

  const closeHeaderTopBanner = useCallback(() => {
    setHeaderTopIsOpen(null);
  }, [setHeaderTopIsOpen]);

  return (
    <>
      {headerTopIsOpen === 'rfsn' && rfsnId && (
        <RfsnBanner
          rfsnId={rfsnId}
          height={rfsnBannerDistance}
          onClose={() => closeHeaderTopBanner()}
        />
      )}
      {headerTopIsOpen === 'discount' && (
        <DiscountCodeBanner onClose={() => closeHeaderTopBanner()} />
      )}
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <HeaderStickyBox>
          {isOpenedActivityBanner && (
            <ActivityBanner
              ref={bannerDom}
              height={bannerDistance}
              onClose={() => closeActivityBanner()}
              bannerData={activityBannerData}
            />
          )}
          <HeaderBox
            ref={dom}
            className={classNames('page-header theme-dark', {
              active: isHover || activeMenu,
              'active-mobile': isMobileMenuOpen,
              'active-menu': activeMenu,
            })}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <nav>
              <Container>
                <LogoBox>
                  <LogoLink
                    to={isMobile && activeMenu ? window.location.pathname : '/'}
                  >
                    <Logo
                      className={`${isMobile ? 'header-logo-mobile' : ''}`}
                      src={logoSrc}
                      title={intl.formatMessage(messages.home)}
                    />
                  </LogoLink>
                  <MenuBackMobile
                    onClick={() => {
                      setActiveMenu(null);
                    }}
                  />
                </LogoBox>
                <Menus>
                  {menuList.map((it) => {
                    const isShowInMobile
                      = (it.onlyMobile && isMobile)
                      || it.onlyMobile === undefined;
                    const isShowInPC = !it.onlyMobile && !isMobile;
                    return (
                      (isShowInMobile || isShowInPC) && (
                        <Menu
                          className={it.className || ''}
                          key={it.name}
                          onClick={() => handleClickMenu(it.name)}
                          {...handleOnMouseArgs(it.name)}
                        >
                          {it.icon}
                          <MenuTitle
                            className={activeMenu === it.name ? 'active' : ''}
                          >
                            <FormattedMessage {...it.title} />
                            <DropIcon />
                          </MenuTitle>
                        </Menu>
                      )
                    );
                  })}
                </Menus>
                <Entries>
                  <CartIconBox onClick={openCart}>
                    <CartIcon />
                    {quantity > 0 && quantity <= 99 && (
                      <ProductQuantity>{quantity}</ProductQuantity>
                    )}
                    {quantity > 99 && <ProductQuantity>99+</ProductQuantity>}
                  </CartIconBox>
                  <Language />
                  <BuyButton
                    type="primary"
                    data-mixpanel-id="Buy"
                    data-mixpanel-pos="header"
                    data-mixpanel-to="/shop"
                    size="mini"
                    show={buyButtonCondition.some((item) =>
                      location.pathname.includes(item),
                    )}
                    onClick={() => navigate('/shop')}
                  >
                    <FormattedMessage {...messages.buy} />
                  </BuyButton>
                </Entries>
                <SubmenuTitle>
                  {activeMenu && activeMenu === menuTypes.products && (
                    <FormattedMessage {...messages.products} />
                  )}
                  {activeMenu && activeMenu === menuTypes.coinsAndFeatures && (
                    <FormattedMessage {...messages.coinsAndFeatures} />
                  )}
                  {activeMenu && activeMenu === menuTypes.partnerships && (
                    <FormattedMessage {...messages.partnerships} />
                  )}
                  {activeMenu && activeMenu === menuTypes.learn && (
                    <FormattedMessage {...messages.learn} />
                  )}
                  {activeMenu && activeMenu === menuTypes.language && (
                    <FormattedMessage {...messages.language} />
                  )}
                </SubmenuTitle>
                <MenuEntryMobile>
                  <CartIconBox onClick={openCart}>
                    <CartIcon />
                    {quantity > 0 && quantity <= 99 && (
                      <ProductQuantity>{quantity}</ProductQuantity>
                    )}
                    {quantity > 99 && <ProductQuantity>99+</ProductQuantity>}
                  </CartIconBox>
                  {isMobileMenuOpen ? (
                    <CloseIcon
                      className="menu-mobile-icon"
                      onClick={() => closeSubmenu()}
                    />
                  ) : (
                    <MenuIcon
                      className="menu-mobile-icon"
                      onClick={() => setIsMobileMenuOpen(true)}
                    />
                  )}
                </MenuEntryMobile>
              </Container>
            </nav>
            <Products
              open={activeMenu === menuTypes.products}
              {...handleOnMouseArgs(menuTypes.products)}
            />
            <CoinsAndFeatures
              open={activeMenu === menuTypes.coinsAndFeatures}
              {...handleOnMouseArgs(menuTypes.coinsAndFeatures)}
            />
            <Learn
              open={activeMenu === menuTypes.learn}
              {...handleOnMouseArgs(menuTypes.learn)}
            />
            <Partnerships
              open={activeMenu === menuTypes.partnerships}
              {...handleOnMouseArgs(menuTypes.partnerships)}
            />
            <LanguageSubmenu
              open={activeMenu === menuTypes.language}
              onClose={closeSubmenu}
              {...handleOnMouseArgs(menuTypes.language)}
            />
          </HeaderBox>
        </HeaderStickyBox>
        <TopNotification
          visible={errorMessage}
          message={errorMessage}
          duration={0}
          style={{ width: 430 }}
        />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <CampaignModal />
      </ErrorBoundary>
    </>
  );
}

export default Header;
