import Link from 'components/Link';
import propTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { LINKS } from 'config/links';
import Divider from 'components/Divider';
import useIsMobile from 'hooks/useIsMobile';
import { mainMsgs as messages } from './../messages';
import { Item, ItemIcon, List, Main, ResourceLink } from './index.style';
import { Submenu } from './../style';
import Blog from './Blog';

export const menuList = [
  {
    id: 'getStarted',
    to: '/get-started',
    icon: require('./images/get-started.svg'),
    title: messages.resourcesStart,
  },
  {
    id: 'tutorial',
    href: messages.resourcesTutorialsLink,
    icon: require('./images/tutorials.svg'),
    title: messages.resourcesTutorials,
    blank: true,
  },
  {
    id: 'expertReviews',
    to: LINKS.expertReviews,
    icon: require('./images/expert-reviews.svg'),
    title: messages.resourcesExpertReviews,
  },
];

export default function Learn({ open, ...args }) {
  const intl = useIntl();
  const isMobile = useIsMobile();

  const list = menuList.map((it) => ({
    ...it,
    href: it.href && it.href.id ? intl.formatMessage(it.href) : it.href,
    title: intl.formatMessage(it.title),
  }));

  return (
    <Submenu height={437} className={open ? 'active' : ''} {...args}>
      <Main>
        <List>
          {list.map((item) => (
            <Item key={item.title}>
              <ResourceLink
                as={Link}
                to={item.to}
                href={item.href}
                target={item.blank ? '_blank' : null}
                data-mixpanel-id={`resource ${item.id}`}
                data-mixpanel-pos="header"
                data-mixpanel-to={item.href}
              >
                <ItemIcon src={item.icon} loading="lazy" />
                {item.title}
              </ResourceLink>
            </Item>
          ))}
        </List>
        {!isMobile && <Divider gap="80px" style={{ height: 240 }} />}
        <Blog />
      </Main>
    </Submenu>
  );
}

Learn.propTypes = {
  open: propTypes.bool,
};
