import { trackEvent } from './base';
import { EVENT_CATEGORY, EVENT_TYPE } from './type';

export function trackHeaderProductClick(product) {
  trackEvent(EVENT_TYPE.BUTTON_CLICK, {
    event_category: EVENT_CATEGORY.Product,
    event_label: 'header products link',
    event_value: product.id,
  });
}

export function trackHeaderBlogClick(blog) {
  trackEvent(EVENT_TYPE.BUTTON_CLICK, {
    event_label: 'header blog link',
    event_value: blog,
  });
}

export function trackHeaderTopBannerClick(category, value) {
  trackEvent(EVENT_TYPE.BUTTON_CLICK, {
    event_category: category,
    event_label: 'header top Banner',
    event_value: value,
  });
}
