import { isFunction, isPlainObject } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { CONTEXT, includeExpand } from './context';

/**
 * track a event
 * @param {keyof TrackEvent} event track event
 * @param {object} data external data
 * @param {{
 *   includeExpand: boolean
 *   intl: react intl
 * }} config track config
 */
export function track(event, data, config = {}) {
  mixpanel.track(event, {
    ...CONTEXT,
    ...(config.includeExpand
    && config.intl
    && includeExpand(config.intl.locale)),
    ...(data && data),
  });
}

/**
 * track a event
 * @param {Array} selector elements selector
 * @param {keyof TrackEvent} event track event
 * @param {object | Function} setData external data
 * @param {{
 *   includeExpand: boolean
 *   intl: react intl
 * }} config track config
 */
export function trackLinks(selector, event, setData, config = {}) {
  mixpanel.track_links(selector, event, (el) => {
    let data = {};
    if (isFunction(setData)) {
      data = setData(el);
    } else if (isPlainObject(setData)) {
      data = setData;
    }
    return {
      ...CONTEXT,
      ...(config.includeExpand
      && config.intl
      && includeExpand(config.intl.locale)),
      ...data,
    };
  });
}
