export const EVENT_LIST = {
  PIZZA_DAY_1: 'PIZZA_DAY_1',
  PIZZA_DAY_2: 'PIZZA_DAY_2',
  BLACK_FRIDAY_2024_ALMOST: 'BLACK_FRIDAY_2024_ALMOST',
  BLACK_FRIDAY_2024_1: 'BLACK_FRIDAY_2024_1',
  BLACK_FRIDAY_2024_2: 'BLACK_FRIDAY_2024_2',
  BLACK_FRIDAY_2024_3: 'BLACK_FRIDAY_2024_3',
  BLACK_FRIDAY_2024_4: 'BLACK_FRIDAY_2024_4',
  BLACK_FRIDAY_2024_5: 'BLACK_FRIDAY_2024_5',
  BLACK_FRIDAY_2024_6: 'BLACK_FRIDAY_2024_6',
  BLACK_FRIDAY_2024_7: 'BLACK_FRIDAY_2024_7',
};

export const initialEvents = {
  events: [
    {
      name: EVENT_LIST.PIZZA_DAY_1,
      dateRange: {
        start: '2024-05-20T20:00:00',
        end: '2024-05-23T23:59:59',
      },
      isInGlobalModal: true,
    },
    {
      name: EVENT_LIST.PIZZA_DAY_2,
      dateRange: {
        start: '2024-05-24T00:00:00',
        end: '2024-05-26T00:00:00',
      },
      isInGlobalModal: true,
    },
    {
      name: EVENT_LIST.BLACK_FRIDAY_2024_ALMOST,
      dateRange: {
        start: '2024-11-14T23:00:00',
        end: '2024-11-21T23:00:00',
      },
      isInGlobalModal: true,
    },
    {
      name: EVENT_LIST.BLACK_FRIDAY_2024_1,
      dateRange: {
        start: '2024-11-21T23:00:00',
        end: '2024-11-23T23:00:00',
      },
      isInGlobalModal: true,
    },
    {
      name: EVENT_LIST.BLACK_FRIDAY_2024_2,
      dateRange: {
        start: '2024-11-23T23:00:00',
        end: '2024-11-25T23:00:00',
      },
      isInGlobalModal: true,
    },
    {
      name: EVENT_LIST.BLACK_FRIDAY_2024_3,
      dateRange: {
        start: '2024-11-25T23:00:00',
        end: '2024-11-27T23:00:00',
      },
      isInGlobalModal: true,
    },
    {
      name: EVENT_LIST.BLACK_FRIDAY_2024_4,
      dateRange: {
        start: '2024-11-27T23:00:00',
        end: '2024-11-29T23:00:00',
      },
      isInGlobalModal: true,
    },
    {
      name: EVENT_LIST.BLACK_FRIDAY_2024_5,
      dateRange: {
        start: '2024-11-29T23:00:00',
        end: '2024-12-01T23:00:00',
      },
      isInGlobalModal: true,
    },
    {
      name: EVENT_LIST.BLACK_FRIDAY_2024_6,
      dateRange: {
        start: '2024-12-01T23:00:00',
        end: '2024-12-03T23:00:00',
      },
      isInGlobalModal: true,
    },
    {
      name: EVENT_LIST.BLACK_FRIDAY_2024_7,
      dateRange: {
        start: '2024-12-03T23:00:00',
        end: '2024-12-06T23:00:00',
      },
      isInGlobalModal: true,
    },
  ],
};
