import {
  trackAddToCartInGA,
  trackAddToCartInTwitter,
  trackItemViewInGA,
  trackItemViewInTwitter,
} from './base';

export function trackItemView(product) {
  trackItemViewInGA(product);
  trackItemViewInTwitter(product);
}

export function trackAddToCart(product, type) {
  trackAddToCartInGA(product, type);
  trackAddToCartInTwitter(product, type);
}
