import React from 'react';
import CountdownNow from 'react-countdown-now';
import dayjs from 'plugins/dayjs';
import { TZ } from 'hooks/useCountdown';
import { useIntl } from 'react-intl';
import useMessages from './useMessages';
import { Block, BlockNumber, BlockUnit, Container } from './style';

export function CountDown({ endTime, onComplete, isAlmost }) {
  const intl = useIntl();
  const messages = useMessages();

  return (
    <CountdownNow
      zeroPadLength={2}
      date={dayjs.tz(endTime, TZ).utc()}
      onComplete={onComplete}
      renderer={({ formatted }) => {
        return (
          <Container isAlmost={isAlmost}>
            {['days', 'hours', 'minutes', 'seconds'].map((it) => (
              <Block key={it} isAlmost={isAlmost}>
                <BlockNumber>{formatted[it]}</BlockNumber>
                <BlockUnit>
                  {intl.locale === 'en'
                    && [0, 1].includes(Number(formatted[it]))
                    ? messages[it]?.slice(0, -1)
                    : messages[it]}
                </BlockUnit>
              </Block>
            ))}
          </Container>
        );
      }}
    />
  );
}
