/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';

import webAuthReducer from 'pages/Resources/WebAuthentication/Authentication/reducer';
import global from 'containers/OverviewPage/reducer';
import languageProviderReducer from 'components/LanguageProvider/reducer';
import AppReducer from 'containers/App/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    app: AppReducer,
    overview: global,
    webAuth: webAuthReducer,
    language: languageProviderReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
