import { createContext } from 'react';

export const CHECKOUT_PAGES = {
  Cart: 'cart',
  Prompt: 'prompt',
};

export const CartContext = createContext({
  checkout: {
    page: CHECKOUT_PAGES.Cart,
    loading: false,
    isCartPage: false,
  },
  setCheckout: () => {},
});
