import { ProductsConfig } from 'config/products';
import { find } from 'lodash';

export function getStrikethroughPrice(
  originPrice,
  discountPrice,
  comparePrice,
) {
  if (Number(originPrice) - Number(discountPrice) >= 0.1) {
    return Number(comparePrice) ? comparePrice : originPrice;
  }
  return comparePrice;
}

export function getProductPathByVariantId(variantId) {
  const data = find(ProductsConfig, (item) => item.variantId === variantId);
  if (data) {
    return data.path;
  }
  return '';
}
