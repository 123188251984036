import { Body } from 'components/styles/bodyNew';
import { H6 } from 'components/styles/titleNew';
import styled, { css } from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';

export const CartDiversionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => (props.isCartPageOnDesktop ? '52px' : '100px')};
  padding: 14px 32px 0;
  height: calc(100% - 304px);
  .bag {
    margin-bottom: 24px;
    @media ${DEVICE_QUERY_MOBILE} {
      width: 64px;
      height: 64px;
    }
  }
  ${(props) =>
    props.isCartPageOnDesktop
    && css`
      .arrow {
        transform: rotate(-90deg);
      }
    `};
`;

export const Title = styled(H6)`
  text-align: center;
  color: var(--color-fg-emphasis);
  margin-bottom: 20px;
`;

export const Description = styled(Body)`
  margin-bottom: 32px;
  text-align: center;
  color: var(--color-fg-muted);
`;
