import React from 'react';
import { Alert, AlertActions } from './style';

export function ErrorBoundaryFallback({ error, resetErrorBoundary }) {
  return (
    <Alert role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <details>
        <summary>Stack trace</summary>
        <pre>{error.stack}</pre>
      </details>
      <AlertActions>
        <a href="/">Go To Home</a>
        <button type="button" onClick={resetErrorBoundary}>
          Try again
        </button>
      </AlertActions>
    </Alert>
  );
}
