import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowDown from 'components/icons/ArrowDown';
import { useMsg } from 'hooks/useMessages';
import { Container, ListItem, RequiredSpan, SelectContainer } from './style';

function NativeSelect({
  value = '',
  options = [],
  multiple = false,
  onChange,
  placeholder = '',
  required,
  disabled,
  className,
  ...args
}) {
  const messages = useMsg('keystone.components.nativeSelect');
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    onChange && onChange(selectedValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <SelectContainer disabled={disabled} className={className}>
      {required && !value && <RequiredSpan>*</RequiredSpan>}
      <Container
        value={selectedValue}
        onChange={(ev) => setSelectedValue(ev.target.value)}
        required={required}
        {...args}
      >
        <ListItem key="_placeholder" value="" disabled>
          {placeholder || messages('placeholder')}
        </ListItem>
        {options.map((it) => (
          <ListItem key={it.value} value={it.value} disabled={it.diabled}>
            {it.name}
          </ListItem>
        ))}
      </Container>
      <ArrowDown className="icon-arrow" />
    </SelectContainer>
  );
}

NativeSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
  multiple: PropTypes.bool,
};

export default NativeSelect;
