import React, { useEffect, useState } from 'react';
import { Link as BaseLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Link from 'components/Link';
import messages from './messages';
import { Desc, ToastPrivacy } from './style';

const AGREE_POLICY = 'agreePolicy';

export default function CookiePrivacyNotification() {
  const [toastVisible, setToastVisible] = useState(false);

  useEffect(() => {
    const agreedPolicy = localStorage.getItem(AGREE_POLICY);

    if (!agreedPolicy) {
      setToastVisible(true);
    }
  }, []);

  function onAgreePolicy() {
    localStorage.setItem(AGREE_POLICY, false);
    setToastVisible(false);
  }

  return (
    toastVisible && (
      <ToastPrivacy place="bottom center">
        <Desc>
          <FormattedMessage
            {...messages.desc}
            values={{
              cookiePrivacy: (chunk) => (
                <Link
                  as={BaseLink}
                  color="var(--color-bd-pink)"
                  to="/cookie-policy"
                  arrow={false}
                >
                  {chunk}
                </Link>
              ),
            }}
          />
        </Desc>
        <Button
          style={{ whiteSpace: 'nowrap' }}
          size="small"
          type="primary"
          onClick={onAgreePolicy}
        >
          <FormattedMessage {...messages.agree} />
        </Button>
      </ToastPrivacy>
    )
  );
}
