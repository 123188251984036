import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import { H5, H5Style, H7Style, H8mStyle } from 'components/styles/title';
import { Body } from 'components/Modal/style';
import { CaptionStyle } from 'components/styles/body';

export const Container = styled.div`
  width: 100%;
  height: 96px;
  margin-top: 120px;
  background: linear-gradient(
      269deg,
      rgba(79, 17, 180, 0.2) 0.7%,
      rgba(0, 0, 0, 0) 74.68%
    ),
    #1a1b1f;

  @media ${DEVICE_QUERY_MOBILE} {
    margin: 0 auto 48px;
    height: 64px;
  }
`;

export const Content = styled.div`
  margin: 18px 24px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${DEVICE_QUERY_MOBILE} {
    align-items: center;
    margin: 15px 22px;
    .ledger-icon,
    .desc-non-highlight {
      display: none;
    }
  }
`;

export const Description = styled(H5).attrs({ as: 'span' })`
  display: inline-block;
  margin-left: 24px;
  color: var(--color-fg-default);

  @media ${DEVICE_QUERY_MOBILE} {
    margin-left: 0;
    ${H7Style};
  }
`;

export const DescriptionHighLight = styled(Description)`
  margin-left: 0;
  color: var(--color-bd-purple);
`;

export const DiscountButton = styled.button`
  border: none;
  height: 44px;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 12px;
  border-radius: 32px;
  background: var(
    --color-bd-purple,
    linear-gradient(270deg, #a585ff 0%, #9d5cff 100%)
  );
  white-space: nowrap;
  :hover {
    cursor: pointer;
  }
  color: white;
  @media ${DEVICE_QUERY_MOBILE} {
    height: 34px;
    h6 {
      ${H8mStyle};
    }
  }

  span {
    transform: rotate(270deg);
    @media ${DEVICE_QUERY_MOBILE} {
      display: none;
    }
  }
`;

export const ModalContent = styled.div`
  margin: 51px 64px 43px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h5 {
    margin-bottom: 30px;
    color: var(--color-bd-purple);
    text-align: center;
  }

  @media ${DEVICE_QUERY_MOBILE} {
    margin: 24px 16px;
    h5 {
      ${H5Style};
      margin-bottom: 10px;
    }
    .link-wrapper {
      width: 100%;
    }
  }
`;

export const ModalText = styled(Body).attrs({ as: 'p' })`
  color: white;
  margin-bottom: 16px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.72px;

  @media ${DEVICE_QUERY_MOBILE} {
    ${CaptionStyle};
    margin-bottom: 10px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    letter-spacing: 0.52px;
    color: var(--color-fg-muted);
  }
`;

export const ModalTextHighLight = styled.span`
  color: var(--color-bd-purple);
`;

export const ModalTextWarning = styled.span`
  color: var(--color-bd-pink);
`;

export const ModalTextEmail = styled.a`
  color: var(--color-bd-purple);
`;

export const ModalButton = styled.div`
  margin-top: 16px;
  width: 240px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  color: var(--color-fg-default);
  border: 2px solid;
  border-image: var(--color-gd-purple) 1 !important;
  background: var(--color-bg-default);

  :hover {
    cursor: pointer;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    margin-top: 0;
    width: 100%;
    height: 40px;
    h6 {
      ${H7Style};
    }
  }
`;
