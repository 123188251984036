import produce from 'immer';

// The initial state of the App
const initialState = {
  isShowBanner: false,
};

function global(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'CLOSE_BANNER':
        draft.isShowBanner = false;
        break;
    }
  });
}

export default global;
