import React from 'react';
import Format from './Format';
import { IconTypes } from './types';

export default function Cart({
  width = '18',
  height = '18',
  color = 'currentColor',
  ...args
}) {
  return (
    <Format {...args}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 1H1V3H3V5V10V12H5H15L16.1667 5L16.5 3H14.4724H7V5H14.1391L13.3057 10H5V6V5V3V1ZM5.5 13.5H3V16H5.5V13.5ZM16 13.5H13.5V16H16V13.5Z"
        />
      </svg>
    </Format>
  );
}

Cart.propTypes = IconTypes;
