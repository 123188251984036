import Link from 'components/Link';
import { BodyPlusStyle, Caption } from 'components/styles/bodyNew';
import { H5Style, H6 } from 'components/styles/titleNew';
import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  gap: 0 60px;

  @media ${DEVICE_QUERY_MOBILE} {
    flex-direction: column;
    margin: 24px 24px 0;
  }
`;

export const BlogBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 274px;
  gap: 40px 0;
`;

export const BlogIcon = styled.img`
  margin-top: 16px;
  margin-left: -10px;
  width: 66px;
  height: 66px;
`;

export const BlogLink = styled(Link)`
  ${H5Style}

  .link-arrow {
    color: var(--color-bd-pink);
  }
`;
export const BlogLinkBox = styled(Link)`
  color: var(--color-fg-muted);
  :hover {
    ${BlogLink} {
      color: var(--color-fg-emphasis);
    }
  }
`;

export const BlogDesc = styled(Caption)`
  margin-top: 8px;
  color: var(--color-fg-subtle);
`;

export const BlogListBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px 0;

  @media ${DEVICE_QUERY_MOBILE} {
    margin-top: 24px;
  }
`;

export const BlogListTitle = styled(H6)`
  color: var(--color-fg-subtle);
`;

export const BlogList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  color: var(--color-fg-muted);
`;

export const BlogItem = styled(Link)`
  ${BodyPlusStyle}
  :hover {
    color: var(--color-fg-default);
  }
`;
