import React from 'react';
import { Container } from './style';

export default function Toast({ children, place = 'top center', ...args }) {
  return (
    <Container place={place} {...args}>
      {children}
    </Container>
  );
}
