import React, { lazy, useEffect, useMemo } from 'react';
import aos from 'aos';
import Head from 'components/Head';
import { useI18nDescriptor } from 'hooks/useMessages';
import 'aos/dist/aos.css';
import { LedgerConnectKit } from 'components/LedgerConnectKit';
import Banner from './Banner';
import { Page } from './style';

const BlowTheFold = lazy(
  () =>
    import(/* webpackChunkName: "Home-BlowTheFold" */ 'pages/Home/BlowTheFold'),
);

export default function Home() {
  const seoMessages = useI18nDescriptor('keystone.home.seo');

  useEffect(() => {
    aos.init();
  }, []);

  return (
    <Page>
      <Head
        title={seoMessages('title')}
        description={seoMessages('desc')}
        keywords={seoMessages('keywords')}
        canonical="/"
      />
      <LedgerConnectKit />
      <Banner />
      {useMemo(
        () => (
          <BlowTheFold />
        ),
        [],
      )}
    </Page>
  );
}
