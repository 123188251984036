import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Link from 'components/Link';
import { ProductsConfig } from 'config/products';
import { LINKS } from 'config/links';
import { CartIcon } from 'components/RecommendList/style';
import { useMsg } from 'hooks/useMessages';
import { RateReview } from 'pages/Shop/style';
import Rate from 'components/Rate';
import useIsMobile from 'hooks/useIsMobile';
import {
  trackHomeBannerNewsTip,
  trackHomeLinkClick,
} from 'tracking/click/home';
import ArrowRight from 'components/icons/ArrowRight';
import useTrustpilotStore from 'stores/trustpilot';
import { motion } from 'framer-motion';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
  feature1Msgs,
  feature2Msgs,
  feature3Msgs,
  mainMsgs as messages,
  newsTipMsgs,
} from './messages';
import {
  AnimatedRateBox,
  BackImg,
  BannerBgImage,
  BannerClickBox,
  BgBox,
  ButtonBox,
  Container,
  Desc,
  Feature,
  FeatureDesc,
  FeatureIcon,
  FeatureTitle,
  Features,
  ImgMaskBottom,
  ImgMaskTop,
  Main,
  NewsTip,
  NewsTipAffix,
  NewsTipContent,
  NewsTipPrefix,
  RainbowButton,
  SubTitle,
  Title,
} from './style';
import { animDefaultConfig, useBannerAnimation } from './useBannerAnimation';
import VideoPlay from './components/VideoPlay';

export default function Banner() {
  const intl = useIntl();
  const messagesGetter = useMsg();
  const isMobile = useIsMobile();
  const { isOpen: trustpilotIsOpen, trustpilot } = useTrustpilotStore();
  const IS_SHOW_HOME_TIP = true;
  const { isShowNewsTip } = useBannerAnimation();
  const isUseNewBannerImage = useFeatureIsOn('new-home-banner-image');

  const bannerMessagesGetter = useMsg('keystone.home.banner');

  const featuresData = useMemo(() => {
    return [
      {
        icon: require('./images/device.secure.svg'),
        title: intl.formatMessage(feature1Msgs.title),
        desc: intl.formatMessage(feature1Msgs.desc),
      },
      {
        icon: require('./images/qrcode.svg'),
        title: intl.formatMessage(feature2Msgs.title),
        desc: intl.formatMessage(feature2Msgs.desc),
      },
      {
        icon: require('./images/3phrases.svg'),
        title: intl.formatMessage(feature3Msgs.title),
        desc: intl.formatMessage(feature3Msgs.desc),
      },
    ];
  }, [intl]);

  return (
    <BgBox>
      <BackImg>
        <BannerBgImage
          role="img"
          alt="Keystone Cold Wallet Banner"
          isUseNewBannerImage={isUseNewBannerImage}
        />
      </BackImg>
      <ImgMaskTop />
      <ImgMaskBottom />
      <Container>
        <Main>
          <BannerClickBox
            to={LINKS.shop}
            arrow={false}
            onClick={() => trackHomeLinkClick('banner', LINKS.shop)}
          />
          {IS_SHOW_HOME_TIP && isShowNewsTip && (
            <motion.div
              initial={animDefaultConfig.initial}
              animate={animDefaultConfig.animate}
              transition={{ duration: 0.5, delay: 0.2 }}
              style={{ width: isMobile ? '100%' : 'auto' }}
            >
              <NewsTip
                arrow={false}
                href={bannerMessagesGetter('newsTip.link')}
                onClick={() => {
                  trackHomeBannerNewsTip(bannerMessagesGetter('newsTip.link'));
                }}
              >
                <NewsTipPrefix>
                  <FormattedMessage {...newsTipMsgs.prefix} />
                </NewsTipPrefix>
                <NewsTipContent>
                  <span>
                    <FormattedMessage {...newsTipMsgs.content} />
                  </span>
                </NewsTipContent>
                <NewsTipAffix>
                  <ArrowRight />
                </NewsTipAffix>
              </NewsTip>
            </motion.div>
          )}
          <SubTitle
            initial={animDefaultConfig.initial}
            animate={animDefaultConfig.animate}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            {bannerMessagesGetter('subtitle')}
          </SubTitle>
          <Title
            isZh={intl.locale === 'zh'}
            initial={animDefaultConfig.initial}
            animate={animDefaultConfig.animate}
            transition={{ duration: 0.5, delay: 0.7 }}
          >
            {bannerMessagesGetter('title')}
          </Title>
          <Desc
            initial={animDefaultConfig.initial}
            animate={animDefaultConfig.animate}
            transition={{ duration: 0.6, delay: 0.65 }}
          >
            {bannerMessagesGetter('desc', {
              em: (chunk) => <em>{chunk}</em>,
            })}
          </Desc>
          <ButtonBox
            initial={animDefaultConfig.initial}
            animate={animDefaultConfig.animate}
            transition={{ duration: 0.7, delay: 0.8 }}
          >
            <Link
              to={ProductsConfig.k3Pro.path}
              arrow={false}
              data-mixpanel-id="buy-now"
              data-mixpanel-to={ProductsConfig.k3Pro.path}
              data-mixpanel-pos="content"
            >
              <RainbowButton type="rainbow" icon={CartIcon}>
                {messagesGetter('keystone.buyNow')}
              </RainbowButton>
            </Link>
            <VideoPlay url={LINKS.videos.productVideo} />
          </ButtonBox>
          {trustpilotIsOpen && (
            <AnimatedRateBox
              initial={animDefaultConfig.initial}
              animate={animDefaultConfig.animate}
              transition={{ duration: 0.7, delay: 0.85 }}
            >
              <Rate value={trustpilot} size={isMobile ? 16 : 20} />
              <RateReview href={LINKS.trustpilotRate} blank exit>
                <FormattedMessage {...messages.rateReviews} />
              </RateReview>
            </AnimatedRateBox>
          )}
          <Features>
            {featuresData.map((it) => (
              <Feature key={it.title}>
                <>
                  <FeatureIcon src={it.icon} />
                  <div>
                    <FeatureTitle>{it.title}</FeatureTitle>
                    <FeatureDesc>{it.desc}</FeatureDesc>
                  </div>
                </>
              </Feature>
            ))}
          </Features>
        </Main>
      </Container>
    </BgBox>
  );
}
