import React from 'react';
import { FormattedMessage } from 'react-intl';
import { genernalMsgs as messages } from '../../messages';
import { CartIconBox, ProductQuantity } from '../../../Header/style';
import CartBag from '../../../icons/CartBag';
import { CloseIcon, Head, RightBox, Title } from './style';

export function CartHeader({ cartQuantity, close }) {
  return (
    <Head>
      <Title>
        <FormattedMessage {...messages.title} />
      </Title>
      <RightBox>
        <CartIconBox>
          <CartBag />
          {cartQuantity > 0 && cartQuantity <= 99 && (
            <ProductQuantity>{cartQuantity}</ProductQuantity>
          )}
          {cartQuantity > 99 && <ProductQuantity>99+</ProductQuantity>}
        </CartIconBox>
        <CloseIcon onClick={close} />
      </RightBox>
    </Head>
  );
}
