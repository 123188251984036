import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import Button from 'components/Button';
import { H3, H4, H5, H7Style, H8mStyle } from 'components/styles/title';
import { FlexBetween, FlexCenter } from 'components/styles/flex';
import { BodyPlusStyle, OverlineMStyle } from 'components/styles/body';
import Link from 'components/Link';

export const Page = styled.div.attrs({
  className: 'theme-dark',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--color-bg-default);
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1280px;
  margin-top: var(--height-header);
  padding-top: 80px;
  @media ${DEVICE_QUERY_MOBILE} {
    flex-direction: column;
    padding-top: 40px;
  }
`;

export const BaseP = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.04em;
`;

export const ReBaseP = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.04em;
`;

export const CartItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  height: auto;
  @media ${DEVICE_QUERY_MOBILE} {
    width: 100%;
    padding: 0 24px;
  }
`;

export const CartTitle = styled(H3)`
  padding-bottom: 24px;
  border-bottom: 1px solid var(--color-border-subtle);
  color: var(--color-fg-default);
  font-family: 'Mont';
`;

export const Product = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: end;
  height: auto;
  margin-top: 32px;
  padding-bottom: 32px;
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 120px - 20px);
  height: 100%;
  margin-left: 20px;
`;

export const ProductImg = styled.img`
  width: 120px;
  height: 120px;
`;

export const ProductName = styled(BaseP)`
  width: 100%;
  color: var(--color-fg-default);
  text-align: left;
  ${BodyPlusStyle}
  @media ${DEVICE_QUERY_MOBILE} {
    max-width: 124px;
  }
`;

export const ProductContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

export const SummaryContent = styled.div`
  width: 30%;
  height: auto;
  padding-top: 12px;
  .mall-tip {
    margin: 0 0 40px;
    width: 100%;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    width: 100%;
    padding: 0 24px 60px;
  }
`;

export const SummaryTitle = styled(H4)`
  padding-bottom: 24px;
  border-bottom: 1px solid var(--color-border-subtle);
  color: var(--color-fg-default);
  font-family: 'Mont';
`;

export const ProductContentLeft = styled(FlexBetween)`
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 55px 0;
`;

export const ProductPriceContent = styled.div`
  & > div:first-child {
    display: flex;
    justify-content: flex-end;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    & > div {
      flex-direction: column;
    }
  }
`;

export const ProductTips = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ProductPrice = styled(BaseP)`
  color: var(--color-bd-pink);
  text-align: right;
`;

export const ProductOriginPrice = styled(BaseP)`
  margin-right: 8px;
  @media ${DEVICE_QUERY_MOBILE} {
    margin-right: 0;
  }
  color: var(--color-fg-subtle);
  text-decoration: line-through;
`;

export const ProductDiscountInfo = styled(FlexBetween)`
  margin-top: 8px;
  padding: 5px 12px;
  color: var(--color-bd-oranger);
  ${H7Style}
  text-align: left;
  background: var(--color-bd-oranger-trans10);
  border-radius: 8px;
  gap: 0 64px;
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 12px;
  }
`;

export const ProductSoldOutInfo = styled(ProductDiscountInfo)`
  margin-top: 16px;
  color: var(--color-bd-pink);
  width: 100%;
  background: var(--color-bd-pink-trans);
`;

export const ProductPreOrderInfo = styled(ProductDiscountInfo).attrs({
  as: 'div',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  padding: 16px 24px;
  gap: 12px 0;
  width: 100%;
  max-width: 100%;
  color: var(--color-bd-cyan);
  background: var(--color-bg-muted);
  @media ${DEVICE_QUERY_MOBILE} {
    .caption {
      ${OverlineMStyle}
      font-weight: 400;
    }
  }
`;

export const ProductPreOrderInfoColumn = styled(FlexCenter)`
  align-items: flex-start;
  flex-direction: column;
`;

export const ProductPreOrderInfoLink = styled(Link)`
  margin-top: 16px;
  display: flex;
  color: var(--color-bd-primary);
  ${H7Style}
  @media ${DEVICE_QUERY_MOBILE} {
    ${H8mStyle}
  }
`;

export const ProductPreOrderInfoButton = styled(Button)`
  width: 80px;
  height: 40px;
  border-image: var(--color-gd-primary) 1;
  @media ${DEVICE_QUERY_MOBILE} {
    width: 64px;
    height: 32px;
  }
`;

export const ContinueShop = styled.p`
  max-width: 180px;
  padding: 32px 0 128px;
  color: var(--color-bd-pink);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.04em;
  :hover {
    svg {
      transform: translateX(-6px);
      transition: var(--transition);
    }
  }
  :not(:hover) {
    svg {
      transform: translateX(0);
      transition: var(--transition);
    }
  }
`;

export const Subtotal = styled.div`
  width: 100%;
  height: auto;
  margin-top: 40px;
`;

export const SubtotalTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SubtotalTitle = styled(H5)`
  color: var(--color-fg-default);
  font-family: 'Montserrat';
`;

export const SubtotalPriceContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SubtotalBaseP = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: right;
  letter-spacing: 0.04em;
`;

export const SubtotalOriginPrice = styled(SubtotalBaseP)`
  margin-right: 8px;
  color: var(--color-fg-subtle);
  text-decoration: line-through;
`;

export const SubtotalPrice = styled(SubtotalBaseP)`
  color: var(--color-fg-default);
`;

export const SubtotalDiscountInfo = styled(BaseP)`
  display: ${(props) => (props.show ? 'block' : 'none')};
  margin-top: 12px;
  color: var(--color-bd-oranger);
  text-align: right;
`;

export const SubtotalDesc = styled(ReBaseP)`
  margin: 4px 0 24px;
  color: ${(props) =>
    props.isTargetCountryInCustomTip
      ? 'var(--color-bd-oranger)'
      : 'var(--color-fg-subtle)'};
`;

export const Checkout = styled(Button)`
  margin-top: 16px;
  width: 100%;
  color: var(--color-fg-default);
  border: 2px solid var(--color-border-default);
  border-image: var(--color-gd-primary) 1;
  .btn-icon-arrow {
    color: var(--color-bd-contiguous);
  }
`;

export const UpdateCart = styled(ReBaseP)`
  margin-top: 32px;
  color: var(--color-fg-muted);
  text-align: center;
  cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};
  :hover {
    color: ${(props) =>
    props.disabled ? 'var(--color-fg-muted)' : 'var(--color-fg-default)'};
  }
`;

export const LoadingContent = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 16px 0;
`;

export const ProductLoadingContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 160px 0;
`;

export const SubtotalLoadingContent = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${(props) => (props.show ? '32px 0' : '0')};
`;

export const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 64px 0;
`;

export const EmptyImg = styled.img`
  width: 80px;
  height: 80px;
`;

export const EmptyDesc = styled(BaseP)`
  margin-top: 24px;
  color: var(--color-fg-subtle);
  font-family: 'Open Sans';
`;
