import { request } from 'api/request';
import { getLanguage } from 'utils/commonUtils';

export async function getProductDetails(productId) {
  const version = 'v1';
  const url = '/shop_product/';
  const productDetails = await request(
    url,
    { id: productId, locale: getLanguage() },
    { version },
  );
  return productDetails;
}
