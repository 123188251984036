import styled from 'styled-components';

export const Alert = styled.div`
  padding: 20px;
  background: #333;
  color: #000;
  border-radius: 5px;
`;

export const AlertActions = styled.div`
  display: flex;
  gap: 0 20px;
  margin-top: 20px;

  a,
  button {
    padding: 10px 20px;
    border: 1px solid #222;
    background: #ccc;
    cursor: pointer;
    &:hover {
      background: #999;
    }
  }
`;
