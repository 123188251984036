import React, { forwardRef, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import CountdownNow from 'react-countdown-now';
import useIsMobile from 'hooks/useIsMobile';
import { ErrorBoundary } from 'react-error-boundary';
import dayjs from 'plugins/dayjs';
import { TZ } from 'hooks/useCountdown';
import {
  trackCampaignBannerClick,
  trackCampaignBannerTitleClick,
} from 'tracking/click';
import { LINKS } from 'config/links';
import HeaderBannerBuy from 'components/icons/HeaderBannerBuy';
import Link from 'components/Link';
import { ErrorBoundaryFallback } from 'components/ErrorBoundary';
import { dateFullMsgs } from '../messages';
import { splitByLnToReactNode } from './utils';
import {
  BannerBgClose,
  BannerBox,
  BannerContainer,
  BannerIconImg,
  BannerLeftBox,
  BannerLink,
  BannerMore,
  BannerRightBox,
  BannerSubTitle,
  BannerTitle,
  BannerTitleBox,
  TimeBlock,
  TimeBlockCont,
  TimeBlockContainer,
  TimeBlockLabel,
} from './index.style';

const productLinkMap = {
  Keystone3Pro: LINKS.shopProducts.k3Pro,
  KeystoneBundlePack: LINKS.shopProducts.bundlePack,
};

const ActivityBanner = forwardRef(
  ({ height, bannerData, onClose: close }, ref) => {
    const isMobile = useIsMobile();
    const intl = useIntl();

    const dates = useMemo(() => {
      if (isMobile) {
        const gteOneDay
          = dayjs
            .tz(bannerData.currentRange?.end_time, TZ)
            .utc()
            .diff(dayjs().utc(), 'd') >= 1;
        return [
          gteOneDay && 'days',
          'hours',
          'minutes',
          !gteOneDay && 'seconds',
        ].filter(Boolean);
      }
      return ['days', 'hours', 'minutes', 'seconds'];
    }, [isMobile, bannerData.currentRange?.end_time]);

    const closeButtonSize = isMobile ? '20' : '30';
    const isZh = intl.locale === 'zh';

    const Countdown = useMemo(
      () =>
        bannerData?.currentRange?.end_time && (
          <CountdownNow
            zeroPadLength={1}
            key={bannerData.currentRange?.end_time}
            date={dayjs.tz(bannerData.currentRange?.end_time, TZ).format('')}
            renderer={({ formatted }) => (
              <TimeBlockContainer>
                {dates.map((it) => (
                  <TimeBlock key={it}>
                    <TimeBlockCont>{formatted[it]}</TimeBlockCont>
                    <TimeBlockLabel>
                      <FormattedMessage {...dateFullMsgs[it]} />
                    </TimeBlockLabel>
                  </TimeBlock>
                ))}
              </TimeBlockContainer>
            )}
            onComplete={() => { }}
          />
        ),
      [bannerData.currentRange?.end_time, dates],
    );

    const handleClick = () => {
      trackCampaignBannerClick(bannerData.name);
    };

    const bannerDescription = useMemo(() => {
      const description = isZh
        ? bannerData.description.zh
        : bannerData.description.en;
      const currLangDescription = isMobile
        ? description.mobile
        : description.desktop;

      const title
        = currLangDescription?.title
        && splitByLnToReactNode(currLangDescription?.title);
      const discount
        = currLangDescription?.discount
        && splitByLnToReactNode(currLangDescription?.discount);
      return {
        title,
        discount,
        layout: description.layout,
      };
    }, [isMobile, isZh, bannerData.description.zh, bannerData.description.en]);

    const href = bannerData.link;
    const to
      = (bannerData.product && productLinkMap[bannerData.product])
      || LINKS.shopProducts.k3Pro;

    const bannerLinkArgs = {
      ...(href
        ? {
            href,
            blank: true,
          }
        : {
            to,
          }),
    };

    if (!bannerData)
      return null;

    return (
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <BannerBox
          ref={ref}
          height={height}
          themeColor={bannerData.theme}
          layout={bannerDescription.layout}
          sticky
          onClick={handleClick}
        >
          <BannerLink {...bannerLinkArgs} arrow={false}>
            <BannerContainer>
              <BannerLeftBox>
                {bannerData.icon_url && (
                  <BannerIconImg src={bannerData.icon_url} />
                )}
                <BannerTitleBox themeColor={bannerData.theme}>
                  {bannerData.title_link ? (
                    <Link
                      href={bannerData.title_link}
                      blank={!bannerData?.title_link?.startsWith('/')}
                      arrow={false}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        trackCampaignBannerTitleClick(bannerData.name);
                      }}
                    >
                      <BannerTitle>{bannerDescription?.title}</BannerTitle>
                    </Link>
                  ) : (
                    <BannerTitle>{bannerDescription?.title}</BannerTitle>
                  )}
                  {bannerDescription?.discount && (
                    <BannerSubTitle>
                      {bannerDescription.discount}
                    </BannerSubTitle>
                  )}
                </BannerTitleBox>
              </BannerLeftBox>
              <BannerRightBox>
                {Countdown}
                {!isMobile && (
                  <BannerMore>
                    <HeaderBannerBuy />
                  </BannerMore>
                )}
              </BannerRightBox>
            </BannerContainer>
          </BannerLink>
          <BannerBgClose
            width={closeButtonSize}
            height={closeButtonSize}
            onClick={() => close()}
          />
        </BannerBox>
      </ErrorBoundary>
    );
  },
);

ActivityBanner.displayName = 'ActivityBanner';

export default ActivityBanner;
