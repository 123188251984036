import { css, keyframes } from 'styled-components';

export const FadeUp = css`
  visibility: hidden;
  transform: translateY(10px);
  opacity: 0;
  transition: var(--transition);
`;

export const FadeUpActive = css`
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
`;

export const GradientKeyframes = keyframes`
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 100%;
  }
  75% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export function SpinKeyframes(transformProps) {
  return keyframes`
  from {
    transform: ${transformProps} rotate(0);
  }
  to {
    transform: ${transformProps} rotate(360deg);
  }
`;
}

export const infiniteTranslateX = keyframes`
  0% {
    transform: translateX(0%);
  }
  5% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export const shakeVertical = keyframes`
  0%,
  100% {
            transform: translateY(0);
  }
  35% {
            transform: translateY(-20px);
  }
  75% {
            transform: translateY(20px);
  }
`;

export const shakeHorizontal = keyframes`
  0%,
  100% {
    transform: translateX(0);
  }
  35% {
    transform: translateX(-20px);
  }
  75% {
    transform: translateX(20px);
  }
`;

export const shakeLeft = keyframes`
  0%,100% {
    transform: rotate(0deg);transform-origin: 0 50%;
  }
  10% {
    transform: rotate(2deg);
  }
  20%,40%,60% {
    transform: rotate(-4deg);
  }
  30%,50%,70% {
    transform: rotate(4deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  90% {
    transform: rotate(2deg);
  }
`;

export const ripple = keyframes`
  0% {
    transform: translateX(-80%);
    opacity: 0.5;
  }
  100% {
    transform: translateX(180%);
    opacity: 0;
  }
`;
