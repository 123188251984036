import React from 'react';
import { InlineFlexCenter } from 'components/styles/flex';
import styled from 'styled-components';
import propTypes from 'prop-types';
import Format from './Format';

const StarContainer = styled(InlineFlexCenter)`
  position: relative;
`;
const StarFillSvgBox = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
`;

const StarSvg = styled.svg`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
`;

function mapRange(value, inMin, inMax, outMin, outMax) {
  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

function mapToPercent(value) {
  return mapRange(value, 0, 100, 30, 100);
}

export default function Star({
  width = 20,
  height = 20,
  color = 'currentColor',
  rate = 100,
  ...args
}) {
  return (
    <Format {...args}>
      <StarContainer style={{ width, height }}>
        <StarSvg
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 1.61804L11.7696 7.06434L11.8819 7.40983H12.2451H17.9717L13.3388 10.7758L13.0449 10.9894L13.1572 11.3348L14.9268 16.7812L10.2939 13.4152L10 13.2016L9.70611 13.4152L5.0732 16.7812L6.84282 11.3348L6.95507 10.9894L6.66118 10.7758L2.02828 7.40983H7.75486H8.11813L8.23039 7.06434L10 1.61804Z"
            stroke={color}
          />
        </StarSvg>
        <StarFillSvgBox style={{ width: `${mapToPercent(rate)}%` }}>
          <StarSvg
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 0L12.2451 6.90983H19.5106L13.6327 11.1803L15.8779 18.0902L10 13.8197L4.12215 18.0902L6.36729 11.1803L0.489435 6.90983H7.75486L10 0Z"
              fill={color}
            />
          </StarSvg>
        </StarFillSvgBox>
      </StarContainer>
    </Format>
  );
}

Star.propTypes = {
  width: propTypes.number,
  height: propTypes.number,
  color: propTypes.string,
};
