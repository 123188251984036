import propTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { trackHeaderProductClick } from 'tracking/click/header';
import { AnimatePresence, motion } from 'framer-motion';
import Img from 'components/Img';
import { Submenu } from '../style';
import {
  AllProducts,
  AllProductsLink,
  Container,
  FooterMask,
  Group,
  Groups,
  Item,
  List,
  Preview,
  ProductLink,
  Tag,
  Title,
} from './index.style';
import { PRODUCTS_MENU_GROUP } from './menu';

export const menuGroups = PRODUCTS_MENU_GROUP;

const variants = {
  initial: { opacity: 0, y: '10%' },
  enter: { opacity: 1, y: '0%' },
  exit: { opacity: 0, y: '10%' },
};

export default function Products({ open, ...args }) {
  const intl = useIntl();
  const [product, setProduct] = useState('k3Pro');

  const groups = menuGroups
    .filter((it) => it.id !== 'all')
    .map((it) => ({
      ...it,
      ...(it.list && {
        list: it.list.map((lIt) => ({
          ...lIt,
          title: intl.formatMessage(lIt.title),
        })),
      }),
      title: intl.formatMessage(it.title),
    }));

  const products = groups
    .map((it) => it.list.map((c) => c))
    .flat()
    .map((it) => ({
      id: it.id,
      img: it.img,
      title: it.title,
    }));

  const footAllProduct = menuGroups
    .find((it) => it.id === 'all')
    .list.map((it) => ({
      ...it,
      title: intl.formatMessage(it.title),
    }))[0];

  const trackClick = useCallback((product) => {
    trackHeaderProductClick(product);
  }, []);

  return (
    <Submenu height={400} className={open ? 'active' : ''} {...args}>
      <Container>
        <Groups>
          {groups.map((group) => (
            <Group key={group.id}>
              <Title>{group.title}</Title>
              <List>
                {group.list.map((item) => (
                  <Item key={item.title}>
                    <ProductLink
                      disabled={item.disabled}
                      to={item.href}
                      onClick={() => trackClick(item)}
                      data-mixpanel-id={`products ${group.id} ${item.title}`}
                      data-mixpanel-pos="header"
                      data-mixpanel-to={item.href}
                      arrow
                      onMouseEnter={() => setProduct(item.id)}
                    >
                      {item.title}
                      {item?.tags?.map((tag) => (
                        <Tag key={tag.tagText.id} color={tag.tagColor}>
                          {intl.formatMessage(tag.tagText, tag?.tagVariant)}
                        </Tag>
                      ))}
                    </ProductLink>
                  </Item>
                ))}
              </List>
            </Group>
          ))}
        </Groups>
        <AllProducts>
          <AllProductsLink
            to={footAllProduct.to}
            arrow
            color="var(--color-fg-subtle)"
            hoverColor="var(--color-bd-pink)"
            data-mixpanel-id="products footAllProducts"
            data-mixpanel-pos="header"
            data-mixpanel-to={footAllProduct.href}
          >
            {footAllProduct.title}
          </AllProductsLink>
        </AllProducts>
      </Container>
      <Preview>
        <AnimatePresence>
          {products
            .filter((p) => p.id === product)
            .map((p) => (
              <motion.div
                key={p.id}
                initial="initial"
                animate="enter"
                exit="exit"
                variants={variants}
                transition={{
                  duration: 0.4,
                  type: 'spring',
                  stiffness: 120,
                  damping: 26,
                }}
                style={{
                  position: 'absolute',
                  top: 0,
                  width: 480,
                  height: '100%',
                }}
              >
                {p.img && <Img src={p.img} alt={p.title} />}
              </motion.div>
            ))}
        </AnimatePresence>
        <FooterMask />
      </Preview>
    </Submenu>
  );
}

Products.propTypes = {
  open: propTypes.bool,
};
