import { request } from 'api/request';

export async function getCurrentActivities() {
  const url = '/current_activities/';
  const result = await request(url, {}, { version: 'v1' });

  if (result instanceof Error) {
    return [];
  }

  return result;
}
