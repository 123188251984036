import React from 'react';
import Format from './Format';

export default function PlayShadowActived({
  width = '56',
  height = '56',
  ...args
}) {
  return (
    <Format {...args}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_13815_74649)">
          <path
            d="M36.875 26.4845C38.0417 27.158 38.0417 28.842 36.875 29.5155L25.625 36.0107C24.4583 36.6843 23 35.8423 23 34.4952L23 21.5048C23 20.1577 24.4583 19.3157 25.625 19.9893L36.875 26.4845Z"
            fill="#3D71FF"
          />
          <g filter="url(#filter0_b_13815_74649)">
            <circle cx="28" cy="28" r="27" stroke="#3D71FF" strokeWidth="2" />
          </g>
          <g filter="url(#filter1_b_13815_74649)">
            <circle
              cx="28"
              cy="28"
              r="28"
              fill="url(#paint0_linear_13815_74649)"
            />
          </g>
          <path
            d="M36.875 26.4845C38.0417 27.158 38.0417 28.842 36.875 29.5155L25.625 36.0107C24.4583 36.6843 23 35.8423 23 34.4952L23 21.5048C23 20.1577 24.4583 19.3157 25.625 19.9893L36.875 26.4845Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_b_13815_74649"
            x="-3.11111"
            y="-3.11111"
            width="62.2222"
            height="62.2222"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.55556" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_13815_74649"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_13815_74649"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_b_13815_74649"
            x="-4"
            y="-4"
            width="64"
            height="64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_13815_74649"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_13815_74649"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_13815_74649"
            x1="28"
            y1="0"
            x2="28"
            y2="56"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.57" />
            <stop offset="1" stopColor="#03060D" stopOpacity="0.01" />
          </linearGradient>
          <clipPath id="clip0_13815_74649">
            <rect width="56" height="56" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Format>
  );
}
