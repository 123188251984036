import { useMsg } from 'hooks/useMessages';

export default function useMessages() {
  const keystoneGetter = useMsg('keystone');
  const getMessage = useMsg('keystone.cart');

  return {
    checkout: getMessage('subtotal.checkout'),
    stockOut: getMessage('subtotal.stockOut'),
    preOrder: getMessage('subtotal.preOrder'),
    chooseCountry: getMessage('chooseCountry'),
    next: keystoneGetter('next'),
    tips: {
      CN: keystoneGetter('cart.tip.cn'),
      HK: keystoneGetter('cart.tip.hk'),
      MO: keystoneGetter('cart.tip.mo'),
      TW: keystoneGetter('cart.tip.tw'),
      KR: keystoneGetter('cart.tip.kr'),
      RU: keystoneGetter('cart.tip.ru'),
    },
    stores: {
      youzan: keystoneGetter('stores.youzan'),
      amazon: keystoneGetter('stores.amazon'),
      naver: keystoneGetter('stores.naver'),
      sunscrypt: keystoneGetter('stores.sunscrypt'),
    },
  };
}
