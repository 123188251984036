import Info from 'components/icons/Info';
import React, { useEffect, useState } from 'react';
import { Desc, ToastNotification } from './style';

export default function TopNotification({
  visible = false,
  message = '',
  onClose,
  duration = 5000,
  type,
  ...args
}) {
  const [toastVisible, setToastVisible] = useState(visible);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    setToastVisible(visible);
  }, [visible]);

  useEffect(() => {
    if (!toastVisible)
      return;
    clearTimeout(timer);
    if (duration) {
      setTimer(
        setTimeout(() => {
          setToastVisible(false);
          onClose && onClose();
        }, duration),
      );
    }
    return () => {
      timer && clearTimeout(timer);
      onClose && onClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastVisible, duration]);

  return toastVisible ? (
    <ToastNotification place="top center" type={type} {...args}>
      <Info className="icon" />
      <Desc>{message}</Desc>
    </ToastNotification>
  ) : null;
}
