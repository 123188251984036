import styled, { css } from 'styled-components';
import NativeSelect from 'components/NativeSelect';
import { H6Style, H7Style } from 'components/styles/title';
import Button from '../Button';
import { DEVICE_QUERY_MOBILE } from '../../utils/constants';

export const CheckoutContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;

  @media ${DEVICE_QUERY_MOBILE} {
    margin-top: 12px;
    gap: 8px;
  }
`;

export const CheckoutSelectCountry = styled(NativeSelect)`
  width: auto;
  border: 0;
  > select {
    padding: 0 32px 0 0;
    height: 40px;
    ${H7Style}
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  > .icon-arrow {
    right: 8px;
  }

  @media ${DEVICE_QUERY_MOBILE} {
    & > select {
      max-width: 120px;
    }
  }
`;

export const Checkout = styled(Button).attrs({
  className: 'checkout-info',
})`
  &.btn-main {
    flex: 1;
    min-width: 50%;
    ${(props) =>
    props.warnning
      ? css`
            background: var(--color-gd-orange);
          `
      : css`
            background: var(--color-gd-red);
          `}
    color: var(--color-fg-default);
    ${H6Style}
    .btn-icon-arrow {
      color: var(--color-fg-default);
    }
  }
`;
