import React, { useCallback, useContext } from 'react';
import { LINKS } from 'config/links';
import { useTrack } from 'tracking';
import { trackCartCheckout } from 'tracking/click/cart';
import useCartStore from 'stores/cart';
import useShippingRegionStore from 'stores/shippingRegions';
import { CartContext } from './ctx';
import useMessages from './hooks/useMessages';
import {
  Checkout,
  CheckoutContainer,
  CheckoutSelectCountry,
} from './CartCheckout.style';

export default function CartCheckout({
  loading,
  disabled,
  isUndistributedCountry,
  needPresale,
}) {
  const messages = useMessages();
  const { id: cartId, cartData, refreshCurrentCountry } = useCartStore();
  const { countryList, currentCountry, setCurrentCountry }
    = useShippingRegionStore();
  const { checkout, setCheckout, isCartPage } = useContext(CartContext);
  const { track } = useTrack();

  const getCheckOutButtonMessage = useCallback(() => {
    const countryMessage = {
      CN: messages.stores.youzan,
      MO: messages.stores.amazon,
      TW: messages.stores.amazon,
      KR: messages.stores.naver,
      RU: messages.stores.sunscrypt,
      TR: messages.stores.amazon,
    }[currentCountry];
    const defaultMessage = messages.checkout;

    if (needPresale) {
      return messages.preOrder;
    }

    return countryMessage || defaultMessage;
  }, [currentCountry, messages, needPresale]);

  const cartCheckout = useCallback(async () => {
    let openTarget = '_self';
    setCheckout((data) => ({ ...data, loading: true }));
    let checkoutUrl = cartData.checkoutUrl;

    if (isUndistributedCountry) {
      openTarget = '_blank';
      checkoutUrl = LINKS.mall[currentCountry];
    }

    if (!isCartPage && checkout.page === 'cart' && !isUndistributedCountry) {
      setCheckout((data) => ({
        ...data,
        loading: false,
      }));
    }

    if (window.REFERSION) {
      const rfsn = {
        cart: cartId.split('/').pop(),
        id: localStorage.getItem('rfsn_v4_id'),
        url: checkoutUrl,
        aid: localStorage.getItem('rfsn_v4_aid'),
        cs: localStorage.getItem('rfsn_v4_cs'),
      };
      window.REFERSION.sendCheckoutEvent(
        rfsn.cart,
        rfsn.id,
        rfsn.url,
        rfsn.aid,
        rfsn.cs,
      );
    }

    track('Checkout', {
      to: checkoutUrl,
    });
    trackCartCheckout({
      url: checkoutUrl,
      country: currentCountry,
    });
    setCheckout((data) => ({
      ...data,
      loading: false,
      page: 'cart',
    }));
    open(checkoutUrl, openTarget);
  }, [
    cartId,
    setCheckout,
    cartData.checkoutUrl,
    isUndistributedCountry,
    isCartPage,
    checkout.page,
    track,
    currentCountry,
  ]);

  const onCountyChange = useCallback(
    async (data) => {
      if (!data)
        return;
      setCurrentCountry(data);
      await refreshCurrentCountry();
    },
    [refreshCurrentCountry, setCurrentCountry],
  );

  return (
    <CheckoutContainer>
      <CheckoutSelectCountry
        value={currentCountry}
        onChange={onCountyChange}
        options={countryList}
        placeholder={messages.chooseCountry}
        required
      />
      <Checkout
        type="main"
        loading={checkout.loading || loading}
        onClick={cartCheckout}
        disabled={disabled}
        warnning={needPresale}
      >
        {getCheckOutButtonMessage()}
      </Checkout>
    </CheckoutContainer>
  );
}
