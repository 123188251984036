import mixpanel from 'mixpanel-browser';
import { trackEvent } from './init';
import { CONTEXT, constructContext } from './context';

export const Event = {
  PageView: 'PV',
  BuyClick: 'User_Click_Buy',
  OrderClick: 'User_Click_Order',
  CompareKeystoneClick: 'User_Click_Comp',
  CompareOrderClick: 'User_Comp_Order',
  CompareCoinsClick: 'User_Comp_Coins',
  NavClick: 'User_Navigation',
};

export const trackPageView = trackEvent(Event.PageView);

export const trackBuyClick = trackEvent(Event.BuyClick);

export const trackOrderClick = trackEvent(Event.OrderClick);

export const trackCompareKeystoneClick = trackEvent(Event.CompareKeystoneClick);

export const trackCompareOrderClick = trackEvent(Event.CompareOrderClick);

export const trackCompareCoinsClick = trackEvent(Event.CompareCoinsClick);

export function trackAllNavsClick(language) {
  const NAV_LINK_SELECTOR = 'li.rc-menu-item a';

  mixpanel.track_links(NAV_LINK_SELECTOR, Event.NavClick, (navElement) => ({
    ...CONTEXT,
    ...constructContext(language),
    nav_title: navElement.dataset.title,
  }));
}

/**
 * @typedef {object} TrackEvent
 * @prop {string} PV
 * @prop {string} Navigation
 * @prop {string} Order
 * @prop {string} File
 * @prop {string} Modal
 * @prop {string} Search
 * @prop {string} IOCount
 * @property
 */

/**
 * @type {TrackEvent}
 */
export const TrackEvent = {
  PV: 'PV',
  Navigation: 'Navigation',
  Order: 'Order',
  File: 'File',
  Modal: 'Modal',
  Search: 'Search',
  IOCount: 'IOCount',
};

export const IOCountType = {
  UsbUpgrade: 'UsbUpgrade',
};

export const TrackFileEventType = {
  Download: 'Download',
  Play: 'Play',
};

export const OrderEventType = {
  Add: 'Add',
  Checkout: 'Checkout',
  Payment: 'Payment',
  Refund: 'Refund',
};
