import { defineMessages } from 'react-intl';

export const scope = 'keystone.home.banner.';

export const mainMsgs = defineMessages({
  subtitle: {
    id: `${scope}subtitle`,
    defaultMessage: '',
  },
  title: {
    id: `${scope}title`,
    defaultMessage: '',
  },
  desc: {
    id: `${scope}desc`,
    defaultMessage: '',
  },
  rateReviews: {
    id: 'keystone.shop.product.rateReviews',
    defaultMessage: '',
  },
  productVideo: {
    id: `${scope}productVideo`,
    defaultMessage: '',
  },
});

export const newsTipMsgs = defineMessages({
  prefix: {
    id: `${scope}newsTip.prefix`,
    defaultMessage: '',
  },
  content: {
    id: `${scope}newsTip.content`,
    defaultMessage: '',
  },
  link: {
    id: `${scope}newsTip.link`,
    defaultMessage: '',
  },
});

export const ctaMsgs = defineMessages({
  title1: {
    id: `${scope}cta.title1`,
    defaultMessage: '',
  },
  link1: {
    id: `${scope}cta.link1`,
    defaultMessage: '',
  },
  title2: {
    id: `${scope}cta.title2`,
    defaultMessage: '',
  },
  link2: {
    id: `${scope}cta.link2`,
    defaultMessage: '',
  },
});

export const feature1Msgs = defineMessages({
  title: {
    id: `${scope}feature1.title`,
    defaultMessage: '',
  },
  desc: {
    id: `${scope}feature1.desc`,
    defaultMessage: '',
  },
});

export const feature2Msgs = defineMessages({
  title: {
    id: `${scope}feature2.title`,
    defaultMessage: '',
  },
  desc: {
    id: `${scope}feature2.desc`,
    defaultMessage: '',
  },
});

export const feature3Msgs = defineMessages({
  title: {
    id: `${scope}feature3.title`,
    defaultMessage: '',
  },
  desc: {
    id: `${scope}feature3.desc`,
    defaultMessage: '',
  },
});
