import React, { useEffect, useState } from 'react';
import Decrement from 'components/icons/Decrement';
import Increment from 'components/icons/Increment';
import { Button, Content, QuantityContent } from './style';

export default function Quantity(props) {
  const [quantity, setQuantity] = useState(props.value);
  const [disabled, setDisabled] = useState(false);

  const calculate = (type) => {
    if (type === 'decrease') {
      const minusValue = Number(quantity) - 1;
      if (minusValue < 1) {
        props.onChange && props.onChange(minusValue);
        return;
      }
      if (props.limit && quantity === 1) {
        return;
      }
      setQuantity(minusValue);
      props.onChange && props.onChange(minusValue);
    }
    if (type === 'increase') {
      const plusValue = Number(quantity) + 1;
      setQuantity(plusValue);
      props.onChange && props.onChange(plusValue);
    }
  };

  useEffect(() => {
    setQuantity(props.value);
  }, [props.value]);

  useEffect(() => {
    setDisabled(props.limit && quantity === 1);
  }, [props.limit, quantity]);

  return (
    <Content large={props.large} className={props.className}>
      <Button
        onClick={() => calculate('decrease')}
        large={props.large}
        disabled={disabled}
      >
        <Decrement />
      </Button>

      <QuantityContent large={props.large}>{quantity}</QuantityContent>

      <Button onClick={() => calculate('increase')} large={props.large}>
        <Increment />
      </Button>
    </Content>
  );
}
