import { useEffect, useState } from 'react';
import dayjs from 'plugins/dayjs';

export const TZ = 'Asia/Shanghai';

function useCountdown(stage) {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    percentage: 0,
  });

  const safeDate = (date) => {
    if (date) {
      return dayjs.tz(date, TZ).utc();
    }
    return dayjs()
      .tz(TZ)
      .utc();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const now = dayjs().utc();
      const start = safeDate(stage.startTime);
      const end = safeDate(stage.endTime);
      const totalDuration = end.diff(start, 'second');
      const elapsedDuration = now.diff(start, 'second');
      const remainingDuration = Math.max(0, totalDuration - elapsedDuration);

      if (remainingDuration === 0) {
        clearInterval(interval);
      }

      const days = Math.floor(remainingDuration / 86400);
      const hours = Math.floor((remainingDuration % 86400) / 3600);
      const minutes = Math.floor((remainingDuration % 3600) / 60);
      const seconds = remainingDuration % 60;
      const percentage = Number.parseFloat(
        ((elapsedDuration / totalDuration) * 100).toFixed(2),
      );

      setCountdown({
        days,
        hours,
        minutes,
        seconds,
        percentage: percentage < 100 ? percentage : 100,
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [stage]);

  return countdown;
}

export default useCountdown;
