const data = {
  "nm": "Flow 3",
  "ddd": 0,
  "h": 240,
  "w": 160,
  "meta": {
    "g": "LottieFiles Figma v67"
  },
  "layers": [
    {
      "ty": 0,
      "nm": "bag",
      "sr": 1,
      "st": 0,
      "op": 265,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": true,
      "ao": 0,
      "ks": {
        "a": {
          "a": 1,
          "k": [
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                66,
                66
              ],
              "t": 0
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                66,
                66
              ],
              "t": 12
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                66,
                66
              ],
              "t": 132
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                66,
                66
              ],
              "t": 144
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 1,
                "y": 1
              },
              "s": [
                66,
                66
              ],
              "t": 264
            },
            {
              "s": [
                66,
                66
              ],
              "t": 276
            }
          ]
        },
        "s": {
          "a": 1,
          "k": [
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100,
                100
              ],
              "t": 0
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100,
                100
              ],
              "t": 12
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100,
                100
              ],
              "t": 132
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100,
                100
              ],
              "t": 144
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 1,
                "y": 1
              },
              "s": [
                100,
                100
              ],
              "t": 264
            },
            {
              "s": [
                100,
                100
              ],
              "t": 276
            }
          ]
        },
        "sk": {
          "a": 0,
          "k": 0
        },
        "p": {
          "a": 1,
          "k": [
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                80,
                72
              ],
              "t": 0
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                80,
                72
              ],
              "t": 12
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                80,
                132
              ],
              "t": 132
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                80,
                132
              ],
              "t": 144
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 1,
                "y": 1
              },
              "s": [
                80,
                72
              ],
              "t": 264
            },
            {
              "s": [
                80,
                72
              ],
              "t": 276
            }
          ]
        },
        "r": {
          "a": 1,
          "k": [
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                0
              ],
              "t": 0
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                0
              ],
              "t": 12
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                0
              ],
              "t": 132
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                0
              ],
              "t": 144
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 1,
                "y": 1
              },
              "s": [
                0
              ],
              "t": 264
            },
            {
              "s": [
                0
              ],
              "t": 276
            }
          ]
        },
        "sa": {
          "a": 0,
          "k": 0
        },
        "o": {
          "a": 1,
          "k": [
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100
              ],
              "t": 0
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100
              ],
              "t": 12
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100
              ],
              "t": 132
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100
              ],
              "t": 144
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 1,
                "y": 1
              },
              "s": [
                100
              ],
              "t": 264
            },
            {
              "s": [
                100
              ],
              "t": 276
            }
          ]
        }
      },
      "masksProperties": [
        {
          "nm": "",
          "inv": false,
          "mode": "a",
          "x": {
            "a": 0,
            "k": 0
          },
          "o": {
            "a": 0,
            "k": 100
          },
          "pt": {
            "a": 1,
            "k": [
              {
                "o": {
                  "x": 0,
                  "y": 0
                },
                "i": {
                  "x": 0.7,
                  "y": 0.81
                },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        132,
                        0
                      ],
                      [
                        132,
                        132
                      ],
                      [
                        0,
                        132
                      ],
                      [
                        0,
                        0
                      ]
                    ]
                  }
                ],
                "t": 0
              },
              {
                "o": {
                  "x": 0,
                  "y": 0
                },
                "i": {
                  "x": 0.7,
                  "y": 0.81
                },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        132,
                        0
                      ],
                      [
                        132,
                        132
                      ],
                      [
                        0,
                        132
                      ],
                      [
                        0,
                        0
                      ]
                    ]
                  }
                ],
                "t": 12
              },
              {
                "o": {
                  "x": 0,
                  "y": 0
                },
                "i": {
                  "x": 0.7,
                  "y": 0.81
                },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        132,
                        0
                      ],
                      [
                        132,
                        132
                      ],
                      [
                        0,
                        132
                      ],
                      [
                        0,
                        0
                      ]
                    ]
                  }
                ],
                "t": 132
              },
              {
                "o": {
                  "x": 0,
                  "y": 0
                },
                "i": {
                  "x": 0.7,
                  "y": 0.81
                },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        132,
                        0
                      ],
                      [
                        132,
                        132
                      ],
                      [
                        0,
                        132
                      ],
                      [
                        0,
                        0
                      ]
                    ]
                  }
                ],
                "t": 144
              },
              {
                "o": {
                  "x": 0,
                  "y": 0
                },
                "i": {
                  "x": 1,
                  "y": 1
                },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        132,
                        0
                      ],
                      [
                        132,
                        132
                      ],
                      [
                        0,
                        132
                      ],
                      [
                        0,
                        0
                      ]
                    ]
                  }
                ],
                "t": 264
              },
              {
                "s": [
                  {
                    "c": true,
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        132,
                        0
                      ],
                      [
                        132,
                        132
                      ],
                      [
                        0,
                        132
                      ],
                      [
                        0,
                        0
                      ]
                    ]
                  }
                ],
                "t": 276
              }
            ]
          }
        }
      ],
      "w": 160,
      "h": 240,
      "refId": "1",
      "ind": 1
    }
  ],
  "v": "5.7.0",
  "fr": 60,
  "op": 264,
  "ip": 0,
  "assets": [
    {
      "nm": "[Asset] bag",
      "id": "1",
      "layers": [
        {
          "ty": 4,
          "nm": "Vector",
          "sr": 1,
          "st": 0,
          "op": 265,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    2.21,
                    9.25
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    2.21,
                    9.25
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    2.21,
                    9.25
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    2.21,
                    9.25
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    2.21,
                    9.25
                  ],
                  "t": 264
                },
                {
                  "s": [
                    2.21,
                    9.25
                  ],
                  "t": 276
                }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100,
                    100
                  ],
                  "t": 276
                }
              ]
            },
            "sk": {
              "a": 0,
              "k": 0
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    68.71,
                    14.25
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    68.71,
                    14.25
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    68.71,
                    14.25
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    68.71,
                    14.25
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    68.71,
                    14.25
                  ],
                  "t": 264
                },
                {
                  "s": [
                    68.71,
                    14.25
                  ],
                  "t": 276
                }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    0
                  ],
                  "t": 264
                },
                {
                  "s": [
                    0
                  ],
                  "t": 276
                }
              ]
            },
            "sa": {
              "a": 0,
              "k": 0
            },
            "o": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100
                  ],
                  "t": 276
                }
              ]
            }
          },
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -0.97,
                            0.23
                          ],
                          [
                            3.44,
                            18.73
                          ],
                          [
                            5.39,
                            18.27
                          ],
                          [
                            0.97,
                            -0.23
                          ],
                          [
                            -0.97,
                            0.23
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -0.97,
                            0.23
                          ],
                          [
                            3.44,
                            18.73
                          ],
                          [
                            5.39,
                            18.27
                          ],
                          [
                            0.97,
                            -0.23
                          ],
                          [
                            -0.97,
                            0.23
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -0.97,
                            0.23
                          ],
                          [
                            3.44,
                            18.73
                          ],
                          [
                            5.39,
                            18.27
                          ],
                          [
                            0.97,
                            -0.23
                          ],
                          [
                            -0.97,
                            0.23
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -0.97,
                            0.23
                          ],
                          [
                            3.44,
                            18.73
                          ],
                          [
                            5.39,
                            18.27
                          ],
                          [
                            0.97,
                            -0.23
                          ],
                          [
                            -0.97,
                            0.23
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -0.97,
                            0.23
                          ],
                          [
                            3.44,
                            18.73
                          ],
                          [
                            5.39,
                            18.27
                          ],
                          [
                            0.97,
                            -0.23
                          ],
                          [
                            -0.97,
                            0.23
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -0.97,
                            0.23
                          ],
                          [
                            3.44,
                            18.73
                          ],
                          [
                            5.39,
                            18.27
                          ],
                          [
                            0.97,
                            -0.23
                          ],
                          [
                            -0.97,
                            0.23
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "nm": "",
              "c": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 276
                  }
                ]
              },
              "r": 1,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      100
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 276
                  }
                ]
              }
            }
          ],
          "ind": 1
        },
        {
          "ty": 4,
          "nm": "Vector",
          "sr": 1,
          "st": 0,
          "op": 265,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    19.5,
                    45.75
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    19.5,
                    45.75
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    19.5,
                    45.75
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    19.5,
                    45.75
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    19.5,
                    45.75
                  ],
                  "t": 264
                },
                {
                  "s": [
                    19.5,
                    45.75
                  ],
                  "t": 276
                }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100,
                    100
                  ],
                  "t": 276
                }
              ]
            },
            "sk": {
              "a": 0,
              "k": 0
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    51,
                    82.25
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    51,
                    82.25
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    51,
                    82.25
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    51,
                    82.25
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    51,
                    82.25
                  ],
                  "t": 264
                },
                {
                  "s": [
                    51,
                    82.25
                  ],
                  "t": 276
                }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    0
                  ],
                  "t": 264
                },
                {
                  "s": [
                    0
                  ],
                  "t": 276
                }
              ]
            },
            "sa": {
              "a": 0,
              "k": 0
            },
            "o": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100
                  ],
                  "t": 276
                }
              ]
            }
          },
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            0.53,
                            0.23
                          ],
                          [
                            19.03,
                            77.73
                          ],
                          [
                            20.97,
                            77.27
                          ],
                          [
                            2.47,
                            -0.23
                          ],
                          [
                            0.53,
                            0.23
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            0.53,
                            0.23
                          ],
                          [
                            19.03,
                            77.73
                          ],
                          [
                            20.97,
                            77.27
                          ],
                          [
                            2.47,
                            -0.23
                          ],
                          [
                            0.53,
                            0.23
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            0.53,
                            0.23
                          ],
                          [
                            19.03,
                            77.73
                          ],
                          [
                            20.97,
                            77.27
                          ],
                          [
                            2.47,
                            -0.23
                          ],
                          [
                            0.53,
                            0.23
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            0.53,
                            0.23
                          ],
                          [
                            19.03,
                            77.73
                          ],
                          [
                            20.97,
                            77.27
                          ],
                          [
                            2.47,
                            -0.23
                          ],
                          [
                            0.53,
                            0.23
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            0.53,
                            0.23
                          ],
                          [
                            19.03,
                            77.73
                          ],
                          [
                            20.97,
                            77.27
                          ],
                          [
                            2.47,
                            -0.23
                          ],
                          [
                            0.53,
                            0.23
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            0.53,
                            0.23
                          ],
                          [
                            19.03,
                            77.73
                          ],
                          [
                            20.97,
                            77.27
                          ],
                          [
                            2.47,
                            -0.23
                          ],
                          [
                            0.53,
                            0.23
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            19.5,
                            76.63
                          ],
                          [
                            -0.5,
                            88.13
                          ],
                          [
                            0.5,
                            89.87
                          ],
                          [
                            20.5,
                            78.37
                          ],
                          [
                            19.5,
                            76.63
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            19.5,
                            76.63
                          ],
                          [
                            -0.5,
                            88.13
                          ],
                          [
                            0.5,
                            89.87
                          ],
                          [
                            20.5,
                            78.37
                          ],
                          [
                            19.5,
                            76.63
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            19.5,
                            76.63
                          ],
                          [
                            -0.5,
                            88.13
                          ],
                          [
                            0.5,
                            89.87
                          ],
                          [
                            20.5,
                            78.37
                          ],
                          [
                            19.5,
                            76.63
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            19.5,
                            76.63
                          ],
                          [
                            -0.5,
                            88.13
                          ],
                          [
                            0.5,
                            89.87
                          ],
                          [
                            20.5,
                            78.37
                          ],
                          [
                            19.5,
                            76.63
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            19.5,
                            76.63
                          ],
                          [
                            -0.5,
                            88.13
                          ],
                          [
                            0.5,
                            89.87
                          ],
                          [
                            20.5,
                            78.37
                          ],
                          [
                            19.5,
                            76.63
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            19.5,
                            76.63
                          ],
                          [
                            -0.5,
                            88.13
                          ],
                          [
                            0.5,
                            89.87
                          ],
                          [
                            20.5,
                            78.37
                          ],
                          [
                            19.5,
                            76.63
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            19.41,
                            78.31
                          ],
                          [
                            38.41,
                            92.31
                          ],
                          [
                            39.59,
                            90.69
                          ],
                          [
                            20.59,
                            76.69
                          ],
                          [
                            19.41,
                            78.31
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            19.41,
                            78.31
                          ],
                          [
                            38.41,
                            92.31
                          ],
                          [
                            39.59,
                            90.69
                          ],
                          [
                            20.59,
                            76.69
                          ],
                          [
                            19.41,
                            78.31
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            19.41,
                            78.31
                          ],
                          [
                            38.41,
                            92.31
                          ],
                          [
                            39.59,
                            90.69
                          ],
                          [
                            20.59,
                            76.69
                          ],
                          [
                            19.41,
                            78.31
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            19.41,
                            78.31
                          ],
                          [
                            38.41,
                            92.31
                          ],
                          [
                            39.59,
                            90.69
                          ],
                          [
                            20.59,
                            76.69
                          ],
                          [
                            19.41,
                            78.31
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            19.41,
                            78.31
                          ],
                          [
                            38.41,
                            92.31
                          ],
                          [
                            39.59,
                            90.69
                          ],
                          [
                            20.59,
                            76.69
                          ],
                          [
                            19.41,
                            78.31
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            19.41,
                            78.31
                          ],
                          [
                            38.41,
                            92.31
                          ],
                          [
                            39.59,
                            90.69
                          ],
                          [
                            20.59,
                            76.69
                          ],
                          [
                            19.41,
                            78.31
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "nm": "",
              "c": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 276
                  }
                ]
              },
              "r": 1,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      100
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 276
                  }
                ]
              }
            }
          ],
          "ind": 2
        },
        {
          "ty": 4,
          "nm": "Rectangle",
          "sr": 1,
          "st": 0,
          "op": 265,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    16.01,
                    20
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    16.01,
                    20
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    16.01,
                    20
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    16.01,
                    20
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    16.01,
                    20
                  ],
                  "t": 264
                },
                {
                  "s": [
                    16.01,
                    20
                  ],
                  "t": 276
                }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100,
                    100
                  ],
                  "t": 276
                }
              ]
            },
            "sk": {
              "a": 0,
              "k": 0
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    92.98,
                    54.37
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    92.98,
                    54.37
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    92.98,
                    54.37
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    92.98,
                    54.37
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    92.98,
                    54.37
                  ],
                  "t": 264
                },
                {
                  "s": [
                    92.98,
                    54.37
                  ],
                  "t": 276
                }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    -30.75
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    -30.75
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    -30.75
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    -30.75
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    -30.75
                  ],
                  "t": 264
                },
                {
                  "s": [
                    -30.75
                  ],
                  "t": 276
                }
              ]
            },
            "sa": {
              "a": 0,
              "k": 0
            },
            "o": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100
                  ],
                  "t": 276
                }
              ]
            }
          },
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0.83,
                            0
                          ],
                          [
                            0,
                            -0.83
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            -0.83
                          ],
                          [
                            -0.83,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            1.5,
                            0
                          ],
                          [
                            0,
                            -1.5
                          ],
                          [
                            -1.5,
                            0
                          ],
                          [
                            1.5,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0.83,
                            0
                          ],
                          [
                            0,
                            -0.83
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            -0.83
                          ],
                          [
                            -0.83,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            1.5,
                            0
                          ],
                          [
                            0,
                            -1.5
                          ],
                          [
                            -1.5,
                            0
                          ],
                          [
                            1.5,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0.83,
                            0
                          ],
                          [
                            0,
                            -0.83
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            -0.83
                          ],
                          [
                            -0.83,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            1.5,
                            0
                          ],
                          [
                            0,
                            -1.5
                          ],
                          [
                            -1.5,
                            0
                          ],
                          [
                            1.5,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0.83,
                            0
                          ],
                          [
                            0,
                            -0.83
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            -0.83
                          ],
                          [
                            -0.83,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            1.5,
                            0
                          ],
                          [
                            0,
                            -1.5
                          ],
                          [
                            -1.5,
                            0
                          ],
                          [
                            1.5,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0.83,
                            0
                          ],
                          [
                            0,
                            -0.83
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            -0.83
                          ],
                          [
                            -0.83,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            1.5,
                            0
                          ],
                          [
                            0,
                            -1.5
                          ],
                          [
                            -1.5,
                            0
                          ],
                          [
                            1.5,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0.83,
                            0
                          ],
                          [
                            0,
                            -0.83
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            -0.83
                          ],
                          [
                            -0.83,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            1.5,
                            0
                          ],
                          [
                            0,
                            -1.5
                          ],
                          [
                            -1.5,
                            0
                          ],
                          [
                            1.5,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0.83,
                            0
                          ],
                          [
                            0,
                            -0.83
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            -0.83
                          ],
                          [
                            -0.83,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            33.52,
                            0
                          ],
                          [
                            32.02,
                            -1.5
                          ],
                          [
                            30.52,
                            0
                          ],
                          [
                            33.52,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0.83,
                            0
                          ],
                          [
                            0,
                            -0.83
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            -0.83
                          ],
                          [
                            -0.83,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            33.52,
                            0
                          ],
                          [
                            32.02,
                            -1.5
                          ],
                          [
                            30.52,
                            0
                          ],
                          [
                            33.52,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0.83,
                            0
                          ],
                          [
                            0,
                            -0.83
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            -0.83
                          ],
                          [
                            -0.83,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            33.52,
                            0
                          ],
                          [
                            32.02,
                            -1.5
                          ],
                          [
                            30.52,
                            0
                          ],
                          [
                            33.52,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0.83,
                            0
                          ],
                          [
                            0,
                            -0.83
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            -0.83
                          ],
                          [
                            -0.83,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            33.52,
                            0
                          ],
                          [
                            32.02,
                            -1.5
                          ],
                          [
                            30.52,
                            0
                          ],
                          [
                            33.52,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0.83,
                            0
                          ],
                          [
                            0,
                            -0.83
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            -0.83
                          ],
                          [
                            -0.83,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            33.52,
                            0
                          ],
                          [
                            32.02,
                            -1.5
                          ],
                          [
                            30.52,
                            0
                          ],
                          [
                            33.52,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0.83,
                            0
                          ],
                          [
                            0,
                            -0.83
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            -0.83
                          ],
                          [
                            -0.83,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            33.52,
                            0
                          ],
                          [
                            32.02,
                            -1.5
                          ],
                          [
                            30.52,
                            0
                          ],
                          [
                            33.52,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            30.52,
                            0
                          ],
                          [
                            30.52,
                            23.99
                          ],
                          [
                            33.52,
                            23.99
                          ],
                          [
                            33.52,
                            0
                          ],
                          [
                            30.52,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            30.52,
                            0
                          ],
                          [
                            30.52,
                            23.99
                          ],
                          [
                            33.52,
                            23.99
                          ],
                          [
                            33.52,
                            0
                          ],
                          [
                            30.52,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            30.52,
                            0
                          ],
                          [
                            30.52,
                            23.99
                          ],
                          [
                            33.52,
                            23.99
                          ],
                          [
                            33.52,
                            0
                          ],
                          [
                            30.52,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            30.52,
                            0
                          ],
                          [
                            30.52,
                            23.99
                          ],
                          [
                            33.52,
                            23.99
                          ],
                          [
                            33.52,
                            0
                          ],
                          [
                            30.52,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            30.52,
                            0
                          ],
                          [
                            30.52,
                            23.99
                          ],
                          [
                            33.52,
                            23.99
                          ],
                          [
                            33.52,
                            0
                          ],
                          [
                            30.52,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            30.52,
                            0
                          ],
                          [
                            30.52,
                            23.99
                          ],
                          [
                            33.52,
                            23.99
                          ],
                          [
                            33.52,
                            0
                          ],
                          [
                            30.52,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            1.5,
                            23.99
                          ],
                          [
                            1.5,
                            0
                          ],
                          [
                            -1.5,
                            0
                          ],
                          [
                            -1.5,
                            23.99
                          ],
                          [
                            1.5,
                            23.99
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            1.5,
                            23.99
                          ],
                          [
                            1.5,
                            0
                          ],
                          [
                            -1.5,
                            0
                          ],
                          [
                            -1.5,
                            23.99
                          ],
                          [
                            1.5,
                            23.99
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            1.5,
                            23.99
                          ],
                          [
                            1.5,
                            0
                          ],
                          [
                            -1.5,
                            0
                          ],
                          [
                            -1.5,
                            23.99
                          ],
                          [
                            1.5,
                            23.99
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            1.5,
                            23.99
                          ],
                          [
                            1.5,
                            0
                          ],
                          [
                            -1.5,
                            0
                          ],
                          [
                            -1.5,
                            23.99
                          ],
                          [
                            1.5,
                            23.99
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            1.5,
                            23.99
                          ],
                          [
                            1.5,
                            0
                          ],
                          [
                            -1.5,
                            0
                          ],
                          [
                            -1.5,
                            23.99
                          ],
                          [
                            1.5,
                            23.99
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            1.5,
                            23.99
                          ],
                          [
                            1.5,
                            0
                          ],
                          [
                            -1.5,
                            0
                          ],
                          [
                            -1.5,
                            23.99
                          ],
                          [
                            1.5,
                            23.99
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            8.01
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -9.67,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            -8.01,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            9.67
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            16.01,
                            38.5
                          ],
                          [
                            1.5,
                            23.99
                          ],
                          [
                            -1.5,
                            23.99
                          ],
                          [
                            16.01,
                            41.5
                          ],
                          [
                            16.01,
                            38.5
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            8.01
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -9.67,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            -8.01,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            9.67
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            16.01,
                            38.5
                          ],
                          [
                            1.5,
                            23.99
                          ],
                          [
                            -1.5,
                            23.99
                          ],
                          [
                            16.01,
                            41.5
                          ],
                          [
                            16.01,
                            38.5
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            8.01
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -9.67,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            -8.01,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            9.67
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            16.01,
                            38.5
                          ],
                          [
                            1.5,
                            23.99
                          ],
                          [
                            -1.5,
                            23.99
                          ],
                          [
                            16.01,
                            41.5
                          ],
                          [
                            16.01,
                            38.5
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            8.01
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -9.67,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            -8.01,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            9.67
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            16.01,
                            38.5
                          ],
                          [
                            1.5,
                            23.99
                          ],
                          [
                            -1.5,
                            23.99
                          ],
                          [
                            16.01,
                            41.5
                          ],
                          [
                            16.01,
                            38.5
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            8.01
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -9.67,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            -8.01,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            9.67
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            16.01,
                            38.5
                          ],
                          [
                            1.5,
                            23.99
                          ],
                          [
                            -1.5,
                            23.99
                          ],
                          [
                            16.01,
                            41.5
                          ],
                          [
                            16.01,
                            38.5
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            8.01
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -9.67,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            -8.01,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            9.67
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            16.01,
                            38.5
                          ],
                          [
                            1.5,
                            23.99
                          ],
                          [
                            -1.5,
                            23.99
                          ],
                          [
                            16.01,
                            41.5
                          ],
                          [
                            16.01,
                            38.5
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            8.01,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            9.67
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            8.01
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            9.67,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            30.52,
                            23.99
                          ],
                          [
                            16.01,
                            38.5
                          ],
                          [
                            16.01,
                            41.5
                          ],
                          [
                            33.52,
                            23.99
                          ],
                          [
                            30.52,
                            23.99
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            8.01,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            9.67
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            8.01
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            9.67,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            30.52,
                            23.99
                          ],
                          [
                            16.01,
                            38.5
                          ],
                          [
                            16.01,
                            41.5
                          ],
                          [
                            33.52,
                            23.99
                          ],
                          [
                            30.52,
                            23.99
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            8.01,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            9.67
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            8.01
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            9.67,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            30.52,
                            23.99
                          ],
                          [
                            16.01,
                            38.5
                          ],
                          [
                            16.01,
                            41.5
                          ],
                          [
                            33.52,
                            23.99
                          ],
                          [
                            30.52,
                            23.99
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            8.01,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            9.67
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            8.01
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            9.67,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            30.52,
                            23.99
                          ],
                          [
                            16.01,
                            38.5
                          ],
                          [
                            16.01,
                            41.5
                          ],
                          [
                            33.52,
                            23.99
                          ],
                          [
                            30.52,
                            23.99
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            8.01,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            9.67
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            8.01
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            9.67,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            30.52,
                            23.99
                          ],
                          [
                            16.01,
                            38.5
                          ],
                          [
                            16.01,
                            41.5
                          ],
                          [
                            33.52,
                            23.99
                          ],
                          [
                            30.52,
                            23.99
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            8.01,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            9.67
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            8.01
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            9.67,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            30.52,
                            23.99
                          ],
                          [
                            16.01,
                            38.5
                          ],
                          [
                            16.01,
                            41.5
                          ],
                          [
                            33.52,
                            23.99
                          ],
                          [
                            30.52,
                            23.99
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "nm": "",
              "c": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.8785,
                      0.3177,
                      0.4275
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.8785,
                      0.3177,
                      0.4275
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.8785,
                      0.3177,
                      0.4275
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.8785,
                      0.3177,
                      0.4275
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0.8785,
                      0.3177,
                      0.4275
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0.8785,
                      0.3177,
                      0.4275
                    ],
                    "t": 276
                  }
                ]
              },
              "r": 1,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      100
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 276
                  }
                ]
              }
            }
          ],
          "ind": 3
        },
        {
          "ty": 4,
          "nm": "Ellipse",
          "sr": 1,
          "st": 0,
          "op": 265,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    4,
                    4
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    4,
                    4
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    4,
                    4
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    4,
                    4
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    4,
                    4
                  ],
                  "t": 264
                },
                {
                  "s": [
                    4,
                    4
                  ],
                  "t": 276
                }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100,
                    100
                  ],
                  "t": 276
                }
              ]
            },
            "sk": {
              "a": 0,
              "k": 0
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    97.52,
                    26.52
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    97.52,
                    26.52
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    97.52,
                    26.52
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    97.52,
                    26.52
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    97.52,
                    26.52
                  ],
                  "t": 264
                },
                {
                  "s": [
                    97.52,
                    26.52
                  ],
                  "t": 276
                }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    -32.25
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    -32.25
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    -32.25
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    -32.25
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    -32.25
                  ],
                  "t": 264
                },
                {
                  "s": [
                    -32.25
                  ],
                  "t": 276
                }
              ]
            },
            "sa": {
              "a": 0,
              "k": 0
            },
            "o": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100
                  ],
                  "t": 276
                }
              ]
            }
          },
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ]
                        ],
                        "o": [
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            8,
                            4
                          ],
                          [
                            4,
                            8
                          ],
                          [
                            0,
                            4
                          ],
                          [
                            4,
                            0
                          ],
                          [
                            8,
                            4
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ]
                        ],
                        "o": [
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            8,
                            4
                          ],
                          [
                            4,
                            8
                          ],
                          [
                            0,
                            4
                          ],
                          [
                            4,
                            0
                          ],
                          [
                            8,
                            4
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ]
                        ],
                        "o": [
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            8,
                            4
                          ],
                          [
                            4,
                            8
                          ],
                          [
                            0,
                            4
                          ],
                          [
                            4,
                            0
                          ],
                          [
                            8,
                            4
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ]
                        ],
                        "o": [
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            8,
                            4
                          ],
                          [
                            4,
                            8
                          ],
                          [
                            0,
                            4
                          ],
                          [
                            4,
                            0
                          ],
                          [
                            8,
                            4
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ]
                        ],
                        "o": [
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            8,
                            4
                          ],
                          [
                            4,
                            8
                          ],
                          [
                            0,
                            4
                          ],
                          [
                            4,
                            0
                          ],
                          [
                            8,
                            4
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ]
                        ],
                        "o": [
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            8,
                            4
                          ],
                          [
                            4,
                            8
                          ],
                          [
                            0,
                            4
                          ],
                          [
                            4,
                            0
                          ],
                          [
                            8,
                            4
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "nm": "",
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      100
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 276
                  }
                ]
              },
              "w": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      2
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      2
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      2
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      2
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      2
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      2
                    ],
                    "t": 276
                  }
                ]
              },
              "c": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 276
                  }
                ]
              }
            }
          ],
          "ind": 4
        },
        {
          "ty": 4,
          "nm": "Ellipse",
          "sr": 1,
          "st": 0,
          "op": 265,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    4,
                    4
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    4,
                    4
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    4,
                    4
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    4,
                    4
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    4,
                    4
                  ],
                  "t": 264
                },
                {
                  "s": [
                    4,
                    4
                  ],
                  "t": 276
                }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100,
                    100
                  ],
                  "t": 276
                }
              ]
            },
            "sk": {
              "a": 0,
              "k": 0
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    69.52,
                    43.25
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    69.52,
                    43.25
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    69.52,
                    43.25
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    69.52,
                    43.25
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    69.52,
                    43.25
                  ],
                  "t": 264
                },
                {
                  "s": [
                    69.52,
                    43.25
                  ],
                  "t": 276
                }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    -32.25
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    -32.25
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    -32.25
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    -32.25
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    -32.25
                  ],
                  "t": 264
                },
                {
                  "s": [
                    -32.25
                  ],
                  "t": 276
                }
              ]
            },
            "sa": {
              "a": 0,
              "k": 0
            },
            "o": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100
                  ],
                  "t": 276
                }
              ]
            }
          },
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ]
                        ],
                        "o": [
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            8,
                            4
                          ],
                          [
                            4,
                            8
                          ],
                          [
                            0,
                            4
                          ],
                          [
                            4,
                            0
                          ],
                          [
                            8,
                            4
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ]
                        ],
                        "o": [
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            8,
                            4
                          ],
                          [
                            4,
                            8
                          ],
                          [
                            0,
                            4
                          ],
                          [
                            4,
                            0
                          ],
                          [
                            8,
                            4
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ]
                        ],
                        "o": [
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            8,
                            4
                          ],
                          [
                            4,
                            8
                          ],
                          [
                            0,
                            4
                          ],
                          [
                            4,
                            0
                          ],
                          [
                            8,
                            4
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ]
                        ],
                        "o": [
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            8,
                            4
                          ],
                          [
                            4,
                            8
                          ],
                          [
                            0,
                            4
                          ],
                          [
                            4,
                            0
                          ],
                          [
                            8,
                            4
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ]
                        ],
                        "o": [
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            8,
                            4
                          ],
                          [
                            4,
                            8
                          ],
                          [
                            0,
                            4
                          ],
                          [
                            4,
                            0
                          ],
                          [
                            8,
                            4
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ]
                        ],
                        "o": [
                          [
                            0,
                            2.21
                          ],
                          [
                            -2.21,
                            0
                          ],
                          [
                            0,
                            -2.21
                          ],
                          [
                            2.21,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            8,
                            4
                          ],
                          [
                            4,
                            8
                          ],
                          [
                            0,
                            4
                          ],
                          [
                            4,
                            0
                          ],
                          [
                            8,
                            4
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "nm": "",
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      100
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 276
                  }
                ]
              },
              "w": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      2
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      2
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      2
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      2
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      2
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      2
                    ],
                    "t": 276
                  }
                ]
              },
              "c": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 276
                  }
                ]
              }
            }
          ],
          "ind": 5
        },
        {
          "ty": 4,
          "nm": "Vector",
          "sr": 1,
          "st": 0,
          "op": 265,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    48.25,
                    17
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    48.25,
                    17
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    48.25,
                    17
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    48.25,
                    17
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    48.25,
                    17
                  ],
                  "t": 264
                },
                {
                  "s": [
                    48.25,
                    17
                  ],
                  "t": 276
                }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100,
                    100
                  ],
                  "t": 276
                }
              ]
            },
            "sk": {
              "a": 0,
              "k": 0
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    56.25,
                    20
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    56.25,
                    20
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    56.25,
                    20
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    56.25,
                    20
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    56.25,
                    20
                  ],
                  "t": 264
                },
                {
                  "s": [
                    56.25,
                    20
                  ],
                  "t": 276
                }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    0
                  ],
                  "t": 264
                },
                {
                  "s": [
                    0
                  ],
                  "t": 276
                }
              ]
            },
            "sa": {
              "a": 0,
              "k": 0
            },
            "o": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100
                  ],
                  "t": 276
                }
              ]
            }
          },
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            0,
                            34
                          ],
                          [
                            41,
                            33
                          ],
                          [
                            96.5,
                            0
                          ],
                          [
                            58,
                            2.5
                          ],
                          [
                            0,
                            34
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            0,
                            34
                          ],
                          [
                            41,
                            33
                          ],
                          [
                            96.5,
                            0
                          ],
                          [
                            58,
                            2.5
                          ],
                          [
                            0,
                            34
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            0,
                            34
                          ],
                          [
                            41,
                            33
                          ],
                          [
                            96.5,
                            0
                          ],
                          [
                            58,
                            2.5
                          ],
                          [
                            0,
                            34
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            0,
                            34
                          ],
                          [
                            41,
                            33
                          ],
                          [
                            96.5,
                            0
                          ],
                          [
                            58,
                            2.5
                          ],
                          [
                            0,
                            34
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            0,
                            34
                          ],
                          [
                            41,
                            33
                          ],
                          [
                            96.5,
                            0
                          ],
                          [
                            58,
                            2.5
                          ],
                          [
                            0,
                            34
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            0,
                            34
                          ],
                          [
                            41,
                            33
                          ],
                          [
                            96.5,
                            0
                          ],
                          [
                            58,
                            2.5
                          ],
                          [
                            0,
                            34
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "nm": "",
              "lc": 1,
              "lj": 2,
              "ml": 4,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      100
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 276
                  }
                ]
              },
              "w": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      3
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      3
                    ],
                    "t": 276
                  }
                ]
              },
              "c": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "nm": "",
              "c": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 276
                  }
                ]
              },
              "r": 1,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      100
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 276
                  }
                ]
              }
            }
          ],
          "ind": 6
        },
        {
          "ty": 4,
          "nm": "Vector",
          "sr": 1,
          "st": 0,
          "op": 265,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    37.75,
                    62.75
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    37.75,
                    62.75
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    37.75,
                    62.75
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    37.75,
                    62.75
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    37.75,
                    62.75
                  ],
                  "t": 264
                },
                {
                  "s": [
                    37.75,
                    62.75
                  ],
                  "t": 276
                }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100,
                    100
                  ],
                  "t": 276
                }
              ]
            },
            "sk": {
              "a": 0,
              "k": 0
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    86.75,
                    65.75
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    86.75,
                    65.75
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    86.75,
                    65.75
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    86.75,
                    65.75
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    86.75,
                    65.75
                  ],
                  "t": 264
                },
                {
                  "s": [
                    86.75,
                    65.75
                  ],
                  "t": 276
                }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    0
                  ],
                  "t": 264
                },
                {
                  "s": [
                    0
                  ],
                  "t": 276
                }
              ]
            },
            "sa": {
              "a": 0,
              "k": 0
            },
            "o": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100
                  ],
                  "t": 276
                }
              ]
            }
          },
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            22,
                            125.5
                          ],
                          [
                            75.5,
                            88
                          ],
                          [
                            55.5,
                            0
                          ],
                          [
                            0,
                            33
                          ],
                          [
                            22,
                            125.5
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            22,
                            125.5
                          ],
                          [
                            75.5,
                            88
                          ],
                          [
                            55.5,
                            0
                          ],
                          [
                            0,
                            33
                          ],
                          [
                            22,
                            125.5
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            22,
                            125.5
                          ],
                          [
                            75.5,
                            88
                          ],
                          [
                            55.5,
                            0
                          ],
                          [
                            0,
                            33
                          ],
                          [
                            22,
                            125.5
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            22,
                            125.5
                          ],
                          [
                            75.5,
                            88
                          ],
                          [
                            55.5,
                            0
                          ],
                          [
                            0,
                            33
                          ],
                          [
                            22,
                            125.5
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            22,
                            125.5
                          ],
                          [
                            75.5,
                            88
                          ],
                          [
                            55.5,
                            0
                          ],
                          [
                            0,
                            33
                          ],
                          [
                            22,
                            125.5
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            22,
                            125.5
                          ],
                          [
                            75.5,
                            88
                          ],
                          [
                            55.5,
                            0
                          ],
                          [
                            0,
                            33
                          ],
                          [
                            22,
                            125.5
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "nm": "",
              "lc": 1,
              "lj": 2,
              "ml": 4,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      100
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 276
                  }
                ]
              },
              "w": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      3
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      3
                    ],
                    "t": 276
                  }
                ]
              },
              "c": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "nm": "",
              "c": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 276
                  }
                ]
              },
              "r": 1,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      100
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 276
                  }
                ]
              }
            }
          ],
          "ind": 7
        },
        {
          "ty": 4,
          "nm": "Vector",
          "sr": 1,
          "st": 0,
          "op": 265,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    31.5,
                    46.25
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    31.5,
                    46.25
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    31.5,
                    46.25
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    31.5,
                    46.25
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    31.5,
                    46.25
                  ],
                  "t": 264
                },
                {
                  "s": [
                    31.5,
                    46.25
                  ],
                  "t": 276
                }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100,
                    100
                  ],
                  "t": 276
                }
              ]
            },
            "sk": {
              "a": 0,
              "k": 0
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    39.5,
                    82.25
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    39.5,
                    82.25
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    39.5,
                    82.25
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    39.5,
                    82.25
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    39.5,
                    82.25
                  ],
                  "t": 264
                },
                {
                  "s": [
                    39.5,
                    82.25
                  ],
                  "t": 276
                }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    0
                  ],
                  "t": 264
                },
                {
                  "s": [
                    0
                  ],
                  "t": 276
                }
              ]
            },
            "sa": {
              "a": 0,
              "k": 0
            },
            "o": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100
                  ],
                  "t": 276
                }
              ]
            }
          },
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            63,
                            92.5
                          ],
                          [
                            41,
                            0
                          ],
                          [
                            0,
                            1
                          ],
                          [
                            23.5,
                            89.5
                          ],
                          [
                            63,
                            92.5
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            63,
                            92.5
                          ],
                          [
                            41,
                            0
                          ],
                          [
                            0,
                            1
                          ],
                          [
                            23.5,
                            89.5
                          ],
                          [
                            63,
                            92.5
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            63,
                            92.5
                          ],
                          [
                            41,
                            0
                          ],
                          [
                            0,
                            1
                          ],
                          [
                            23.5,
                            89.5
                          ],
                          [
                            63,
                            92.5
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            63,
                            92.5
                          ],
                          [
                            41,
                            0
                          ],
                          [
                            0,
                            1
                          ],
                          [
                            23.5,
                            89.5
                          ],
                          [
                            63,
                            92.5
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            63,
                            92.5
                          ],
                          [
                            41,
                            0
                          ],
                          [
                            0,
                            1
                          ],
                          [
                            23.5,
                            89.5
                          ],
                          [
                            63,
                            92.5
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            63,
                            92.5
                          ],
                          [
                            41,
                            0
                          ],
                          [
                            0,
                            1
                          ],
                          [
                            23.5,
                            89.5
                          ],
                          [
                            63,
                            92.5
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "nm": "",
              "lc": 1,
              "lj": 2,
              "ml": 4,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      100
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 276
                  }
                ]
              },
              "w": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      3
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      3
                    ],
                    "t": 276
                  }
                ]
              },
              "c": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0.953,
                      0.404,
                      0.1216
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "nm": "",
              "c": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 276
                  }
                ]
              },
              "r": 1,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      100
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 276
                  }
                ]
              }
            }
          ],
          "ind": 8
        },
        {
          "ty": 4,
          "nm": "bag Bg",
          "sr": 1,
          "st": 0,
          "op": 265,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 264
                },
                {
                  "s": [
                    66,
                    66
                  ],
                  "t": 276
                }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100,
                    100
                  ],
                  "t": 276
                }
              ]
            },
            "sk": {
              "a": 0,
              "k": 0
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    66,
                    66
                  ],
                  "t": 264
                },
                {
                  "s": [
                    66,
                    66
                  ],
                  "t": 276
                }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    0
                  ],
                  "t": 264
                },
                {
                  "s": [
                    0
                  ],
                  "t": 276
                }
              ]
            },
            "sa": {
              "a": 0,
              "k": 0
            },
            "o": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100
                  ],
                  "t": 276
                }
              ]
            }
          },
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            132,
                            0
                          ],
                          [
                            132,
                            132
                          ],
                          [
                            0,
                            132
                          ],
                          [
                            0,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            132,
                            0
                          ],
                          [
                            132,
                            132
                          ],
                          [
                            0,
                            132
                          ],
                          [
                            0,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            132,
                            0
                          ],
                          [
                            132,
                            132
                          ],
                          [
                            0,
                            132
                          ],
                          [
                            0,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            132,
                            0
                          ],
                          [
                            132,
                            132
                          ],
                          [
                            0,
                            132
                          ],
                          [
                            0,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            132,
                            0
                          ],
                          [
                            132,
                            132
                          ],
                          [
                            0,
                            132
                          ],
                          [
                            0,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            132,
                            0
                          ],
                          [
                            132,
                            132
                          ],
                          [
                            0,
                            132
                          ],
                          [
                            0,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            }
          ],
          "ind": 9
        }
      ]
    }
  ]
}
module.exports = data;