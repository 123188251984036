import { trace } from 'firebase/performance';
import { perf } from 'plugins/firebase';

function combineElementWithIdAndClass(element) {
  if (!element?.id)
    return '';
  const idName = element.id ? `#${element.id}` : '';
  const className = element.className
    ? `.${element.className.split(' ').join('.')}`
    : '';
  const imageAlt = element.tagName === 'IMG' && element.alt;
  return `${element.tagName.toLowerCase()}${idName}${className}${
    imageAlt ? `[alt="${imageAlt}"]` : ''
  }`;
}

/**
 * Extract element names from list of elements
 * @param {HTMLElement[]|HTMLElement} sources
 * @return {string} element name - e.g. div#id.classname,img#hero
 */
function extractElementNames(sources) {
  if (Array.isArray(sources)) {
    return sources
      .map((source) => combineElementWithIdAndClass(source.node))
      .filter(Boolean)
      .join(', ');
  }
  return combineElementWithIdAndClass(sources);
}

export function sendToFirebase({ name, delta, entries }) {
  const metricNameMap = {
    CLS: 'Cumulative Layout Shift',
    LCP: 'Largest Contentful Paint',
    FID: 'First Input Delay',
    TTFB: 'Time To First Byte',
  };

  const startTime = Date.now();
  const value = Math.round(name === 'CLS' ? delta * 1000 : delta);

  entries.forEach(({ element, target, sources }) => {
    const elements = element || target || sources; // LCP, FID, CLS
    const elementName = elements ? extractElementNames(elements) : '';
    const attributes = elementName ? { element: elementName } : {};

    trace(perf, metricNameMap[name]).record(startTime, value, attributes);
  });
}
