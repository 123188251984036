import styled, { css, keyframes } from 'styled-components';
import CloseIcon from 'components/icons/Close';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import Link from 'components/Link';
import { FlexCenter } from 'components/styles/flex';
import { MontFont } from 'components/styles/title';
import { Container } from 'components/styles/layout';

const gradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const BannerBox = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  height: ${(props) => `${props.height}px`};
  z-index: 1200;
  background-size: 400% 400%;
  animation: ${gradient} 10s ease-in-out infinite;
  --bgColorTheme1: linear-gradient(
    270deg,
    #fed0b6 -56.76%,
    #ff7324 -2.68%,
    #ffd5ab 45.92%,
    #ff7c3f 100%
  );
  --bgColorTheme2: linear-gradient(
    270deg,
    #5ddacb -45.62%,
    #a02be8 4.83%,
    #de89f4 47.48%,
    #37eab4 100%
  );
  --bgColorTheme3: linear-gradient(
    270deg,
    #96c5fc -89.31%,
    #1d45d1 -38.8%,
    #c98ff6 30.78%,
    #6f32ff 100%
  );
  --bgColorTheme4: linear-gradient(
    270deg,
    #023ed8 -79.3%,
    #d56def -19.23%,
    #65dcc0 45.31%,
    #d4f258 100%
  );
  ${(props) => {
    const { layout } = props;

    return {
      horizontalLayout: css`
        color: blue;
      `,
      verticalLayout: css`
        color: #09f;
        .banner-title-box {
          flex-direction: column;
          align-items: flex-start;
          .banner-title {
            max-width: 100%;
            font-size: 28px;
            line-height: 32px;
          }
          .banner-subtitle {
            font-size: 16px;
            line-height: 20px;
          }
          @media ${DEVICE_QUERY_MOBILE} {
            .banner-title {
              font-size: 12px;
              line-height: 18px;
            }
            .banner-subtitle {
              font-size: 8px;
              line-height: 12px;
            }
          }
        }
      `,
      hiddenCountdown: css`
        .time-block-container {
          display: none;
        }
        .banner-title-box {
          .banner-title {
            max-width: 100%;
            font-size: 24px;
            line-height: 24px;
          }
          .banner-subtitle {
            font-size: 26px;
            line-height: 30px;
          }
          @media ${DEVICE_QUERY_MOBILE} {
            margin-right: 30px;
            .banner-title {
              font-size: 12px;
              line-height: 16px;
            }
            .banner-subtitle {
              font-size: 16px;
              line-height: 16px;
            }
          }
        }
      `,
      pcHorizontalLayoutAndMobileVerticalLayout: css`
        @media ${DEVICE_QUERY_MOBILE} {
          .banner-title-box {
            display: block;
            .banner-title {
              display: inline;
              max-width: 100%;
              font-size: 12px;
              line-height: 18px;
            }
            .banner-subtitle {
              margin-left: 4px;
              display: inline;
              float: right;
              font-size: 14px;
              line-height: 23px;
            }
          }
        }
      `,
    }[layout];
  }}
  ${(props) => {
    const { themeColor } = props;
    const blackTitleAndWhiteSubtitle = css`
      .banner-title {
        color: black;
      }
      .banner-subtitle {
        color: white;
      }
    `;
    const whiteTitleAndBlackSubtitle = css`
      .banner-title {
        color: white;
      }
      .banner-subtitle {
        color: black;
      }
    `;
    return {
      theme1: css`
        --bgColor: var(--bgColorTheme1);
        ${whiteTitleAndBlackSubtitle};
      `,
      theme2: css`
        --bgColor: var(--bgColorTheme2);
        ${blackTitleAndWhiteSubtitle}
      `,
      theme3: css`
        --bgColor: var(--bgColorTheme3);
        ${whiteTitleAndBlackSubtitle};
      `,
      theme4: css`
        --bgColor: var(--bgColorTheme4);
        ${whiteTitleAndBlackSubtitle};
      `,
    }[themeColor];
  }}
  background: var(--bgColor);
  background-size: 400% 400%;
  animation: ${gradient} 10s ease-in-out infinite;
`;

export const BannerBgClose = styled(CloseIcon)`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--color-fg-subtle, #83868f);
  @media ${DEVICE_QUERY_MOBILE} {
    right: 8px;
  }
`;

export const BannerLink = styled(Link)`
  display: inline-block;
  width: 100%;
  height: 100%;
`;

export const BannerContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  overflow: hidden;
  @media ${DEVICE_QUERY_MOBILE} {
    max-width: 520px;
    min-width: auto;
    width: 100%;
  }
`;

export const BannerLeftBox = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  @media ${DEVICE_QUERY_MOBILE} {
    margin-left: 4px;
  }
`;

export const BannerIconImg = styled.img`
  width: 64px;
  height: 64px;
  @media ${DEVICE_QUERY_MOBILE} {
    width: 40px;
    height: 40px;
  }
`;

export const BannerTitleBox = styled.div.attrs({
  className: 'banner-title-box',
})`
  display: flex;
  align-items: center;
  margin-left: 8px;
  gap: 0 8%;
  flex: 1;

  ${(props) => {
    const { themeColor } = props;
    const blackTitleHover = css`
      color: white;
    `;
    const whiteTitleHover = css`
      color: black;
    `;

    const hoverColor = {
      theme1: css`
        --bgColor: var(--bgColorTheme1);
        ${whiteTitleHover};
      `,
      theme2: css`
        --bgColor: var(--bgColorTheme2);
        ${blackTitleHover}
      `,
      theme3: css`
        --bgColor: var(--bgColorTheme3);
        ${whiteTitleHover};
      `,
      theme4: css`
        --bgColor: var(--bgColorTheme4);
        ${whiteTitleHover};
      `,
    }[themeColor];

    return css`
      & > a {
        p {
          transition: color 0.25s;
        }
        &:hover {
          p {
            color: ${hoverColor};
          }
        }
      }
    `;
  }}
`;

export const BannerTitle = styled.div.attrs({
  className: 'banner-title',
})`
  display: inline;
  max-width: 78%;
  font-family:
    'Titillium Web',
    'Montserrat',
    -apple-system,
    'Microsoft Yahei',
    微软雅黑,
    'MicrosoftJhengHei',
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  @media ${DEVICE_QUERY_MOBILE} {
    max-width: 100%;
    font-size: 10px;
    line-height: 14px;
  }
`;

export const BannerSubTitle = styled.div.attrs({
  className: 'banner-subtitle',
})`
  font-family:
    'Titillium Web',
    'Montserrat',
    -apple-system,
    'Microsoft Yahei',
    微软雅黑,
    'MicrosoftJhengHei',
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.02em;
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 10px;
    line-height: 14px;
  }
`;

export const BannerRightBox = styled.div`
  display: flex;
  align-items: center;
`;

export const BannerMore = styled.div`
  margin-right: 0;
  height: 100%;
  transition: var(--transition);
  svg path {
    transition: var(--transition);
  }
  :hover {
    background: white;
  }
  ${(props) => props.css}
`;

export const TimeBlockContainer = styled(FlexCenter).attrs({
  className: 'time-block-container',
})`
  margin-right: 16px;
  width: 260px;
  height: calc(100% - 8px);
  gap: 0 10px;
  @media ${DEVICE_QUERY_MOBILE} {
    margin-right: 30px;
    width: 130px;
  }
  ${(props) => props.css};
`;

export const TimeBlock = styled(FlexCenter).attrs({
  className: 'time-block',
})`
  flex-direction: column;
  width: 56px;
  height: 56px;
  background: black;
  @media ${DEVICE_QUERY_MOBILE} {
    aspect-ratio: auto;
    width: 34px;
    height: 40px;
  }
`;

const timeColor = css`
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: var(--bgColor);
  background-size: 400% 400%;
  animation: ${gradient} 10s ease-in-out infinite;
  font {
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: var(--bgColor);
    background-size: 400% 400%;
  }
`;

export const TimeBlockCont = styled(MontFont)`
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
  ${timeColor}
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 17.5px;
    line-height: 20px;
  }
`;

export const TimeBlockLabel = styled(MontFont)`
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  text-transform: uppercase;
  ${timeColor}
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 6.25px;
    line-height: 10px;
  }
`;
