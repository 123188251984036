import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import { H5, H6Style } from 'components/styles/title';
import Close from 'components/icons/Close';

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 32px;
  border-bottom: 1px solid var(--color-border-subtle);
  @media ${DEVICE_QUERY_MOBILE} {
    padding: 16px 24px;
  }
`;

export const Title = styled(H5)`
  position: relative;
  height: 28px;
  padding: 0;
  border: none;
  background: none;
  color: var(--color-fg-default);
  font-family: 'Montserrat';
  span {
    margin-left: 4px;
    svg {
      fill: var(--color-fg-slight);
    }
  }
  &:hover {
    span > svg {
      transform: translateX(5px);
      transition: var(--transition);
    }
  }
  &:not(hover) {
    span > svg {
      transform: translateX(0px);
      transition: var(--transition);
    }
  }
  @media ${DEVICE_QUERY_MOBILE} {
    ${H6Style}
    height: auto;
    cursor: no-drop;
    span > svg {
      display: none;
    }
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  svg {
    fill: var(--color-fg-muted);
  }
  :hover {
    svg {
      fill: var(--color-fg-default);
    }
  }
`;

export const RightBox = styled.div`
  display: flex;
  gap: 0 12px;
`;
