import React from 'react';
import Format from './Format';
import { IconTypes } from './types';

export default function Twitter({
  width = '24',
  height = '24',
  color = '#fff',
  ...args
}) {
  return (
    <Format {...args}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 27 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.958 4.22662H21.9346L15.4315 11.6592L23.0819 21.7732H17.0917L12.4 15.6391L7.03167 21.7732H4.05325L11.0089 13.8233L3.66992 4.22662H9.81213L14.053 9.83343L18.958 4.22662ZM17.9133 19.9916H19.5626L8.9159 5.9147H7.14595L17.9133 19.9916Z"
          fill={color}
        />
      </svg>
    </Format>
  );
}

Twitter.propTypes = IconTypes;
