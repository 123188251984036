const VERSION = 'v2';

const prodUrl = 'https://api.keyst.one';
const staginUrl = 'https://api-staging.keyst.one';
const devUrl = 'http://localhost:8000';

const urlPrefix
  = {
    staging: staginUrl,
    production: prodUrl,
    development: devUrl,
  }[process.env.ENV] || staginUrl;

export async function request(
  url,
  params,
  { version, ...options } = {},
  type,
  formData,
) {
  const SEARCH_ASSETS_API_BASE_URL = `${urlPrefix}/${version || VERSION}/web`;

  if (type === 'POST') {
    const reqUrl = SEARCH_ASSETS_API_BASE_URL + url;
    const requestOptions = {
      method: type,
      body: formData,
      ...options,
    };

    return fetch(reqUrl, requestOptions)
      .then((response) => {
        if (!response.ok)
          throw new Error('fetch data error');
        return response.json();
      })
      .then((response) => (response.success ? response.result : response))
      .catch((error) => {
        throw new Error(error);
      });
  }

  const reqUrl = new URL(SEARCH_ASSETS_API_BASE_URL + url);

  Object.keys(params).forEach((key) =>
    reqUrl.searchParams.append(key, params[key]),
  );

  return fetch(reqUrl)
    .then((response) => {
      if (!response.ok)
        throw new Error('fetch data error');
      return response.json();
    })
    .then((response) => {
      if (!response.success)
        throw new Error(response.error_description, { cause: response });
      return response.result;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
