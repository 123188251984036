import { getLanguage } from 'utils/commonUtils';
import { logger } from 'logger';
import { request } from './request';

const version = 'v1';

export async function getDiscountCodeData(code) {
  const url = '/discount_code_data/';
  const res = await request(url, { code, locale: getLanguage() }, { version });
  return res;
}

export async function getProducts() {
  const url = '/shop_products/';
  const products = await request(url, { locale: getLanguage() }, { version });

  if (!Array.isArray(products)) {
    logger.error(products);
    throw new Error('products get failed');
  }

  return products;
}

export async function getCountry() {
  const url = '/get_country/';
  const countryCode = await request(url, {}, { version });

  return countryCode;
}

export async function getCollections(query, first = 5) {
  const url = '/shop_collections/';
  const res = await request(url, { query, first }, { version });

  return res;
}
