import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { DEFAULT_LOCALE } from 'i18n';

const useAppearanceStore = create(
  persist(
    (set, get) => ({
      lang: DEFAULT_LOCALE,
      setLang: (lang) => {
        set({ lang });
      },
    }),
    {
      name: 'appearance-store',
    },
  ),
);

export default useAppearanceStore;
