import { useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'plugins/dayjs';
import { getCurrentActivities } from 'api/banner';

export const TZ = 'Asia/Shanghai';

function useActivityBanner() {
  const [isOpened, setIsOpened] = useState(false);
  const [isManualClosed, setIsManualClosed] = useState(false);

  const [bannerData, setBannerData] = useState();
  const [bannerList, setBannerList] = useState([]);
  const loadNextList = useRef();

  const loadList = useCallback(async () => {
    const list = await getCurrentActivities();
    setBannerList(list);

    const lastActivity = list.at(-1);
    if (!lastActivity)
      return;
    const lastActivityEndTime = dayjs.tz(
      lastActivity.phases.at(-1)?.end_time,
      TZ,
    );

    if (lastActivityEndTime) {
      const diffTime = dayjs.tz(lastActivityEndTime, TZ).diff(dayjs(), 's');
      loadNextList.current = setTimeout(() => {
        loadList();
        clearTimeout(loadNextList.current);
      }, diffTime * 1000);
    }
  }, []);

  useEffect(() => {
    loadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const close = () => {
    setIsOpened(false);
    setIsManualClosed(true);
  };

  const safeDate = (date) => {
    if (date) {
      return dayjs.tz(date, TZ).utc();
    }
    return dayjs().tz(TZ).utc();
  };

  const getCurrentBannerData = useCallback(
    (now) => {
      const currentBannerData = bannerList.find((it) =>
        it.phases.some((phase) =>
          now.isBetween(phase.start_time, phase.end_time),
        ),
      );
      if (!currentBannerData)
        return;
      const currentRange = currentBannerData.phases.find((phase) =>
        now.isBetween(phase.start_time, phase.end_time),
      );
      return {
        ...currentBannerData,
        currentRange,
      };
    },
    [bannerList],
  );

  const intervalTimer = useRef();
  const refresh = useCallback(() => {
    if (isManualClosed) {
      clearInterval(intervalTimer.current);
      setBannerData(null);
      return;
    }
    const now = dayjs().utc();
    const currentBanner = getCurrentBannerData(now);
    setBannerData(currentBanner);
    setIsOpened(!!currentBanner);

    if (!currentBanner)
      return;
    const start = safeDate(currentBanner.currentRange.start_time);
    const end = safeDate(currentBanner.currentRange.end_time);
    const totalDuration = end.diff(start, 'second');
    const elapsedDuration = now.diff(start, 'second');
    const remainingDuration = Math.max(0, totalDuration - elapsedDuration);

    if (remainingDuration === 0) {
      clearInterval(intervalTimer.current);
    }
  }, [isManualClosed, getCurrentBannerData]);

  useEffect(() => {
    if (!bannerList?.length || isManualClosed) {
      setBannerData(null);
      return;
    }

    refresh();
    intervalTimer.current = setInterval(() => refresh(), 1000);
    return () => clearInterval(intervalTimer.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerList, isManualClosed]);

  return {
    isOpened,
    close,
    activityBannerData: bannerData,
  };
}

export default useActivityBanner;
