/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  LOAD_REPOS,
  LOAD_REPOS_ERROR,
  LOAD_REPOS_SUCCESS,
  SET_ACTIVITIES_START,
  SET_BANNER_MODAL_CODE,
  SET_BANNER_MODAL_OPEN,
  SET_BANNER_MODAL_OPENED,
  SET_CART_ID_GETTING,
  SET_CART_LIST,
  SET_CART_QUANTITY,
  SET_CART_SEEL_BP_IS_UPDATING,
  SET_CART_SEEL_BP_VARIANT_ID,
  SET_CART_SHOW,
  SET_CART_SUBTOTAL,
  SET_CURRENCY,
  SET_LANG,
  SET_LOADING,
  SET_SHIPPING_REGIONS_CACHE,
  SET_SHIPPING_REGIONS_COUNTRY_LIST,
  SET_SHIPPING_REGIONS_CURRENT_COUNTRY,
  SET_SHIPPING_REGIONS_LANG,
  SET_SHIPPING_REGIONS_PRODUCT_MAP,
} from './constants';

/**
 * Load the repositories, this action starts the request saga
 *
 * @return {object} An action object with a type of LOAD_REPOS
 */
export function loadRepos() {
  return {
    type: LOAD_REPOS,
  };
}

/**
 * Dispatched when the repositories are loaded by the request saga
 *
 * @param  {Array} repos The repository data
 * @param  {string} username The current username
 *
 * @return {object}      An action object with a type of LOAD_REPOS_SUCCESS passing the repos
 */
export function reposLoaded(repos, username) {
  return {
    type: LOAD_REPOS_SUCCESS,
    repos,
    username,
  };
}

/**
 * Dispatched when loading the repositories fails
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of LOAD_REPOS_ERROR passing the error
 */
export function repoLoadingError(error) {
  return {
    type: LOAD_REPOS_ERROR,
    error,
  };
}

export function setActivitiesStart(status) {
  return {
    type: SET_ACTIVITIES_START,
    status,
  };
}

export function setCartShow(status) {
  return {
    type: SET_CART_SHOW,
    status,
  };
}

export function setCartQuantity(status) {
  return {
    type: SET_CART_QUANTITY,
    status,
  };
}

export function setLoading(status) {
  return {
    type: SET_LOADING,
    status,
  };
}

export function setCartIdIsGetting(status) {
  return {
    type: SET_CART_ID_GETTING,
    status,
  };
}

/**
 * type in cache|productMap|countryList|currentCountry
 */
export function setShippingRegions(type, data) {
  return {
    type: {
      cache: SET_SHIPPING_REGIONS_CACHE,
      productMap: SET_SHIPPING_REGIONS_PRODUCT_MAP,
      countryList: SET_SHIPPING_REGIONS_COUNTRY_LIST,
      currentCountry: SET_SHIPPING_REGIONS_CURRENT_COUNTRY,
      lang: SET_SHIPPING_REGIONS_LANG,
    }[type],
    data,
  };
}

export function setBannerModalOpen(data) {
  return {
    type: SET_BANNER_MODAL_OPEN,
    data,
  };
}

export function setBannerModalCode(data) {
  return {
    type: SET_BANNER_MODAL_CODE,
    data,
  };
}

export function setBannerModalOpened(data) {
  return {
    type: SET_BANNER_MODAL_OPENED,
    data,
  };
}

export function setLang(data) {
  return {
    type: SET_LANG,
    data,
  };
}

export function setCurrency(data) {
  return {
    type: SET_CURRENCY,
    data,
  };
}

export function setCartList(data) {
  return {
    type: SET_CART_LIST,
    data,
  };
}

export function setCartSeelBpVariantId(data) {
  return {
    type: SET_CART_SEEL_BP_VARIANT_ID,
    data,
  };
}

export function setCartSeelBpIsUpdating(data) {
  return {
    type: SET_CART_SEEL_BP_IS_UPDATING,
    data,
  };
}

export function setCartSubtotal(data) {
  return {
    type: SET_CART_SUBTOTAL,
    data,
  };
}
