import { filter, find } from 'lodash';
import { ProductsConfig } from 'config/products';
import { getProductDetails } from 'pages/Shop/shopRequest/productDetails';
import useShippingRegionStore from 'stores/shippingRegions';
import { getStrikethroughPrice } from 'pages/Shop/utils';

export async function updateCartItemsSoldOutByStore(cartData) {
  const { currentCountry, getProductMap } = useShippingRegionStore.getState();

  if (!cartData?.lines?.edges)
    return [];
  if (!currentCountry)
    return cartData.lines?.edges?.map((it) => it.node);

  const productMap = await getProductMap();
  const countryProducts = productMap.find((it) => it.code === currentCountry);

  const result = await Promise.all(
    cartData.lines.edges
      .map((it) => it.node)
      .map(async (it) => {
        const orderData = find(
          ProductsConfig,
          (pIt) => pIt.id === it.merchandise.product.id,
        );
        const preOrderId = orderData && orderData.preOrderId;
        const preOrderData = find(
          ProductsConfig,
          (pIt) => pIt.id === preOrderId,
        );
        const detail = preOrderId && (await getProductDetails(preOrderId));

        const soldOut
          = countryProducts
          && !countryProducts.list.some(
            (lIt) => lIt.id === it.merchandise.product.id,
          );
        const couldPresale = false;

        return {
          ...it,
          preOrder: preOrderData && {
            id: preOrderId,
            variantId: detail && detail.variantId,
            path: preOrderData.path,
          },
          loading: false,
          soldOut,
          couldPresale,
        };
      }),
  );

  return result;
}

export function getCartLineToPath(cartLine) {
  const data = find(
    ProductsConfig,
    (product) => product.id === cartLine.merchandise.product.id,
  );
  return data?.path;
}

export function updateCartListPrice(cartList) {
  return cartList.map((item) => {
    const originPrice = item.cost.amountPerQuantity.amount;
    const discountPrice = item.cost.totalAmount.amount / item.quantity;
    const comparePrice = item.cost.compareAtAmountPerQuantity?.amount;
    const strikethroughPrice = getStrikethroughPrice(
      originPrice,
      discountPrice,
      comparePrice,
    );
    const isShowStrikethroughPrice
      = Number(strikethroughPrice) - Number(discountPrice) > 0.1;

    const computedStrikethroughPrice = isShowStrikethroughPrice
      ? strikethroughPrice
      : originPrice;

    const productLink = getCartLineToPath(item);
    const line = item.merchandise;
    const productImage = line?.image?.url;

    const productTitle = line?.product?.title;
    const productVariantName = `${line?.title !== 'Default Title' && line.title ? line.title : ''
    }`;

    return {
      ...item,
      custom: {
        discountPrice,
        computedStrikethroughPrice,
        isShowStrikethroughPrice,
        strikethroughPrice,
        originPrice,
        comparePrice,
        productLink,
        productImage,
        productTitle,
        productVariantName,
      },
    };
  });
}
export function filterPreOrderList(recommend) {
  return filter(recommend, (it) => {
    const product = find(ProductsConfig, (fIt) => fIt.id === it.id);
    return product && !product.originId;
  });
}
