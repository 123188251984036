import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { isString } from 'lodash';
import { useMsg } from 'hooks/useMessages';

function Head({ title, description, keywords, canonical, intl }) {
  const siteTitle = intl.formatMessage({ id: 'keystone.seo.title' });
  const siteTitleString = useMsg('keystone.seo');

  const formatMsg = useCallback(
    (msg, props) => {
      return isString(msg)
        ? msg.replace('{siteTitle}', siteTitleString('title'))
        : intl.formatMessage(msg, props);
    },
    [intl, siteTitleString],
  );

  const genCanonicalUrl = () => {
    if (!canonical)
      return canonical;
    const canonicalWithoutTrailingSlash = canonical.replace(/\/$/, '');
    const { protocol, hostname } = window.location;
    return `${protocol}//${hostname}${canonicalWithoutTrailingSlash}`;
  };

  return (
    <Helmet>
      <title>{formatMsg(title, { siteTitle })}</title>
      <meta name="description" content={formatMsg(description)} />
      <meta name="keywords" content={formatMsg(keywords)} />
      {canonical && <link rel="canonical" href={genCanonicalUrl()} />}
    </Helmet>
  );
}

const propTypeMessage = PropTypes.shape({
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string,
});

Head.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, propTypeMessage]),
  description: PropTypes.oneOfType([PropTypes.string, propTypeMessage]),
  keywords: PropTypes.oneOfType([PropTypes.string, propTypeMessage]),
};

export default injectIntl(Head);
