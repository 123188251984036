import { logger } from 'logger';
import { applyDiscountCodesToCart } from 'api/cart';
import useQueryDiscount from 'stores/queryDiscountCode';
import useHeaderTopBanner from 'stores/headerTopBanner';
import { getDiscountCodeData } from 'api/shop';
import useCartStore from 'stores/cart';
import { isDiscountCodesHasCode } from './commonUtils';

export async function applyUrlParamsDiscountCodesToCart() {
  try {
    const params = new URLSearchParams(window.location.search);
    const discountCode = params.get('discount');
    const rfsnId = params.get('rfsn');
    if (!discountCode)
      return null;

    useQueryDiscount.setState({ code: discountCode });

    const discountCodes = [discountCode];
    const { cartData, loadList } = useCartStore.getState();
    const originDiscountCodes = cartData.discountCodes.map((it) => it.code);

    if (isDiscountCodesHasCode(originDiscountCodes, discountCode)) {
      if (rfsnId)
        return;
      await setHeaderDiscountCodeBanner(discountCode);
      return;
    }

    discountCodes.push(...originDiscountCodes);

    const res = await applyDiscountCodesToCart(discountCodes);

    loadList();

    if (rfsnId)
      return;
    if (res) {
      await setHeaderDiscountCodeBanner(discountCode);
    }
    if (res.data?.userErrors?.length) {
      throw new Error('Failed to add discount code');
    }
    return res;
  } catch (err) {
    logger.error(err);
    throw err;
  }
}

async function setHeaderDiscountCodeBanner(code) {
  try {
    const data = await getDiscountCodeData(code);
    if (!data)
      return;
    useQueryDiscount.setState({ data });
    useHeaderTopBanner.setState({ isOpen: 'discount' });
  } catch (e) {
    logger.error(e);
    throw e;
  }
}
