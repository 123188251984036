import {
  setBannerModalOpen,
  setBannerModalOpened,
} from 'containers/App/actions';
import { compact, uniq } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'plugins/dayjs';

const TZ = 'Asia/Shanghai';

export function useBannerModal() {
  const { banner } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const open = useCallback(
    (name) => {
      dispatch(
        setBannerModalOpened(uniq(compact([...banner.modal.opened, name]))),
      );
      dispatch(setBannerModalOpen(true));
    },
    [banner.modal.opened, dispatch],
  );

  const [isBetweenDate, setIsBetweenDate] = useState(false);

  useEffect(() => {
    const startDateTime = dayjs.tz('2023-04-09 00:00:00', TZ);
    const endDateTime = dayjs.tz('2023-04-16 23:59:59', TZ);
    const isBetween = dayjs().isBetween(startDateTime, endDateTime);
    setIsBetweenDate(isBetween);
  }, []);

  return {
    open,
    isBetweenDate,
  };
}
