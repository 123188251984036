import styled, { css } from 'styled-components';

export const montFontStyle = css`
  font-style: normal;
  font-family: 'Mont', -apple-system, 'Microsoft Yahei', '微软雅黑',
    'MicrosoftJhengHei', BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;

export const montserratFontStyle = css`
  font-style: normal;
  font-family: 'Montserrat', -apple-system, 'Microsoft Yahei', 微软雅黑,
    'MicrosoftJhengHei', BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;

export const MontFont = styled.div`
  ${montFontStyle}
  letter-spacing: 0.02em;
`;

export const MontserratFont = styled.div`
  ${montserratFontStyle}
  letter-spacing: 0.02em;
`;

export const H0Style = css`
  font-weight: 600;
  font-size: 56px;
  line-height: 68px;
  ${montFontStyle}
`;

export const H0 = styled.div.attrs({
  className: 'h0',
})`
  ${H0Style}
`;

export const H1Style = css`
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  ${montFontStyle}
`;

export const H1 = styled.h1`
  ${H1Style}
`;

export const H1a = styled(H1)`
  text-transform: uppercase;
`;

export const H2Style = css`
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  ${montFontStyle}
`;

export const H2 = styled.h2`
  ${H2Style}
`;

export const H2a = styled(H2)`
  text-transform: uppercase;
`;

export const H3Style = css`
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  ${montFontStyle}
`;

export const H3 = styled.h3`
  ${H3Style}
`;

export const H4Style = css`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  ${montFontStyle}
  letter-spacing: 0.04em;
`;

export const H4 = styled.h4`
  ${H4Style}
`;

export const H4a = styled.h4`
  ${H4Style}
  text-transform: uppercase;
`;

export const H4m = styled.h4`
  ${H4Style}
  font-weight: 700;
`;

export const H5Style = css`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.04em;
  ${montserratFontStyle}
`;

export const H5 = styled.h5`
  ${H5Style}
`;

export const H5PlusStyle = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  ${montserratFontStyle}
`;

export const H5Plus = styled.h5`
  ${H5PlusStyle}
`;

export const H5a = styled(H5)`
  text-transform: uppercase;
`;

export const H6Style = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  ${montserratFontStyle}
`;

export const H6 = styled.h6`
  ${H6Style}
`;

export const H7Style = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.04em;
  ${montserratFontStyle}
`;
export const H7 = styled.div`
  ${H7Style}
`;

export const H7aStyle = css`
  ${H7Style}
  text-transform: uppercase;
`;

export const H7a = styled.div`
  ${H7aStyle}
`;

export const H8mStyle = css`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.04em;
  ${montserratFontStyle}
`;

export const H8 = styled.div`
  ${H8mStyle}
`;

export const H8m = styled.div`
  ${H8mStyle}
`;

export const H8aStyle = css`
  ${H8mStyle}
  text-transform: uppercase;
`;
