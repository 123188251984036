import { defineMessages } from 'react-intl';

const scope = 'keystone.cookiePrivacyNotification.';

export default defineMessages({
  desc: {
    id: `${scope}desc`,
    defaultMessage: '',
  },
  agree: {
    id: `${scope}agree`,
    defaultMessage: '',
  },
});
