import React, { useCallback, useEffect, useRef, useState } from 'react';
import { compact } from 'lodash';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import CookiePrivacyNotification from 'components/CookiePrivacyNotification';
import { useTrack } from 'tracking';
import { useDispatch, useSelector } from 'react-redux';
import { Crisp } from 'crisp-sdk-web';
import { Outlet, useLocation } from 'react-router-dom';
import { onCLS, onFID, onLCP } from 'web-vitals/attribution';
import {
  setBannerModalOpen,
  setBannerModalOpened,
  setShippingRegions,
} from 'containers/App/actions';
import Cart from 'components/Cart';
import BannerModal from 'components/BannerModal';
import { initGrowthbook } from 'plugins/growthbook';
import { getGaClientId, getUserId } from 'utils/commonUtils';
import useFeatureToggle from 'hooks/useFeatureToggle';
import { SuspenseComponent } from 'components/Suspense';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { AppWrapper } from 'components/common/style';
import { Loading } from 'components/Loading';
import { FlexCenter } from 'components/styles/flex';
import { EventToggleContext, initialEvents } from 'hooks/useToggle';
import { initCrisp } from 'plugins/crisp';
import { sendToFirebase } from 'utils/firebase';
import useCartStore from 'stores/cart';
import { isProd } from 'config';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback } from 'components/ErrorBoundary';
import {
  SET_BANNER_MODAL_OPEN,
  SET_BANNER_MODAL_OPENED,
  SET_SHIPPING_REGIONS_CURRENT_COUNTRY,
} from './constants';

export default function App() {
  const { setupAttrubiteMode } = useTrack();
  const footerRef = useRef(null);
  const [growthbook, setGrowthbook] = useState(null);
  const [events, setEvents] = useState(initialEvents);
  const { banner } = useSelector((state) => state.app);

  const { isOpen: cartIsOpen, closeCart } = useCartStore();

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    setupAttrubiteMode();
    initCrisp();
    Crisp.load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isProd)
      return;
    onCLS(sendToFirebase);
    onFID(sendToFirebase);
    onLCP(sendToFirebase);
  }, [location.pathname]);

  const isActiveGrowthbook = useFeatureToggle('growthbook');

  useEffect(() => {
    if (isActiveGrowthbook) {
      setGrowthbook(initGrowthbook());
    }
  }, [isActiveGrowthbook]);

  useEffect(() => {
    if (!growthbook)
      return;
    growthbook.loadFeatures({ autoRefresh: true });
    const id = getUserId();
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      id,
      userId: id,
      clientId: getGaClientId(),
    });
  }, [growthbook]);

  const onBannerModalClose = useCallback(() => {
    dispatch(setBannerModalOpen(false));
  }, [dispatch]);

  useEffect(() => {
    const currentCountry = localStorage.getItem(
      SET_SHIPPING_REGIONS_CURRENT_COUNTRY,
    );
    if (currentCountry) {
      dispatch(setShippingRegions('currentCountry', currentCountry));
    }

    const bannerModalOpened = compact(
      (localStorage.getItem(SET_BANNER_MODAL_OPENED) || '').split(','),
    );
    const bannerModalOpen = JSON.parse(
      localStorage.getItem(SET_BANNER_MODAL_OPEN),
    );

    dispatch(setBannerModalOpened(bannerModalOpened));
    const setOpenValue = bannerModalOpen === null || bannerModalOpen;
    dispatch(setBannerModalOpen(setOpenValue));
  }, [dispatch]);

  return (
    <GrowthBookProvider growthbook={isActiveGrowthbook && growthbook}>
      <EventToggleContext.Provider value={{ ...events, setEvents }}>
        <AppWrapper>
          <Header />
          <SuspenseComponent
            loading={(
              <FlexCenter className="theme-dark" style={{ height: '100vh' }}>
                <Loading
                  width="84px"
                  height="6px"
                  color="var(--color-fg-muted)"
                />
              </FlexCenter>
            )}
          >
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
              <Outlet />
            </ErrorBoundary>
            <SuspenseComponent>
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <Footer ref={footerRef} />
              </ErrorBoundary>
            </SuspenseComponent>
          </SuspenseComponent>
          <CookiePrivacyNotification />
          <Cart open={cartIsOpen} close={closeCart} />
          <BannerModal
            open={banner.modal.open}
            defaultOpen={false}
            onClose={onBannerModalClose}
          />
        </AppWrapper>
      </EventToggleContext.Provider>
    </GrowthBookProvider>
  );
}
