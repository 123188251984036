import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 20px;
  color: ${(props) => `var(--color-bd-${props.type})`};
  background: ${(props) => `var(--color-bd-${props.type}-trans)`};

  > .suffix-icon {
    margin: 0 -5px 0 -2px;
  }
`;
