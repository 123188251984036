import { getLanguage } from 'utils/commonUtils';
import { logger } from 'logger';
import { uniq } from 'lodash';
import throttle from 'lodash/throttle';
import useCartStore from 'stores/cart';
import { request } from './request';

const version = 'v1';

export function transformSubtotalInfo(info) {
  if (!info?.items)
    return null;

  const isNoEntireOrderDiscount
    = info.items.some((item) => item.discountAllocations.length > 0)
    && info.discountAllocations.length === 0
    && info.discountCodes.length === 0;

  const subtotalWithNoEntireOrderDiscount
    = isNoEntireOrderDiscount
    && info.items
      .reduce(
        (sum, current) =>
          sum
          + Number(current.product.priceRange.maxVariantPrice.amount)
          * current.quantity,
        0,
      )
      .toFixed(2);

  const savedWithNoEntireOrderDiscount
    = isNoEntireOrderDiscount
    && info.items
      .reduce(
        (sum, current) =>
          sum
          + (Number(current.discountAllocations?.[0]?.discountedAmount?.amount)
            || 0),
        0,
      )
      .toFixed(2);

  const subtotalAmount = isNoEntireOrderDiscount
    ? subtotalWithNoEntireOrderDiscount
    : info.cost?.subtotalAmount?.amount;

  const sumItemsSaved = info.items
    .reduce(
      (sum, current) =>
        sum
        + (Number(current.cost.subtotalAmount.amount)
          - Number(current.cost.totalAmount.amount)),
      0,
    )
    .toFixed(2);

  const savedAmount = isNoEntireOrderDiscount
    ? savedWithNoEntireOrderDiscount
    : sumItemsSaved;

  return {
    savedAmount,
    subtotalAmount,
    totalAmount: info.cost?.totalAmount?.amount,
  };
}

export async function cartCreate() {
  const url = '/shop_create_cart/';
  const cartID = await request(
    url,
    { locale: getLanguage() },
    { version },
    'POST',
  );
  return cartID;
}

async function createShoppingCart() {
  const { isIdGetting, setIdIsGetting } = useCartStore.getState();
  if (isIdGetting)
    return 'undefined';
  try {
    setIdIsGetting(true);
    const cartData = await cartCreate();
    setIdIsGetting(false);
    return cartData.id;
  } catch (error) {
    setIdIsGetting(false);
    throw error;
  }
}

export const createShopCart = throttle(createShoppingCart, 1000);

export async function getShopId() {
  const { id: cartId } = useCartStore.getState();
  if (!cartId || cartId === 'undefined') {
    try {
      return await createShopCart();
    } catch (e) {
      logger.error(e);
      throw e;
    }
  } else {
    return cartId;
  }
}

export async function queryCart(cartId) {
  try {
    const url = '/shop_cart/';
    const cartDetails = await request(
      url,
      { id: cartId, locale: getLanguage() },
      { version: 'v2' },
    );
    if (cartDetails.error_code)
      throw cartDetails;

    return cartDetails;
  } catch (e) {
    logger.error(e);
    throw e;
  }
}

export async function getShippingRegions(lang = 'en') {
  const url = '/shipping_info/';
  try {
    return await request(url, { locale: lang }, { version: 'v2' });
  } catch (e) {
    logger.error(e);
  }
}

export async function addProduct(productVariantId, quantity) {
  try {
    const { getCartId } = useCartStore.getState();
    const cartId = await getCartId();
    const url = '/add_products_to_cart/';
    const formData = new FormData();
    if (!cartId) {
      throw new Error('[cartId] get cartId failed');
    }
    formData.append('cart_id', cartId);
    formData.append(
      'products',
      JSON.stringify([
        {
          product_id: productVariantId,
          quantity: quantity || 1,
        },
      ]),
    );
    formData.append('locale', getLanguage());
    const cartDetails = await request(
      url,
      {},
      { version: 'v2' },
      'POST',
      formData,
    );
    if (!cartDetails)
      throw new Error('[shop] add product not found details');
    return cartDetails;
  } catch (e) {
    logger.error(e);
  }
}

export async function updateProduct(idInCart, productID, num) {
  const { getCartId } = useCartStore.getState();
  const cartId = await getCartId();
  const url = '/update_products_in_cart/';
  const formData = new FormData();
  formData.append('cart_id', cartId);
  formData.append(
    'products',
    JSON.stringify([
      {
        id: idInCart,
        product_id: productID,
        quantity: num,
      },
    ]),
  );
  formData.append('locale', getLanguage());

  const cartData = await request(url, {}, { version: 'v2' }, 'POST', formData);
  if (!cartData)
    throw new Error('update failed');

  return cartData;
}

export async function removeProduct(id) {
  const { getCartId } = useCartStore.getState();
  const cartId = await getCartId();
  const url = '/remove_products_in_cart/';
  const formData = new FormData();
  formData.append('cart_id', cartId);
  formData.append('item_ids', JSON.stringify(id));
  formData.append('locale', getLanguage());
  const cartDetails = await request(
    url,
    {},
    { version: 'v2' },
    'POST',
    formData,
  );

  return cartDetails;
}

export async function applyDiscountCodesToCart(discountCodes) {
  const { getCartId } = useCartStore.getState();
  const cartId = await getCartId();
  const url = '/apply_discount_codes_to_cart/';
  const formData = new FormData();
  formData.append('cart_id', cartId);
  formData.append('discount_codes', uniq(discountCodes));
  formData.append('locale', getLanguage());
  const res = await request(url, {}, { version }, 'POST', formData);

  return res;
}

export async function updateCartAttributes(attributes) {
  const { getCartId } = useCartStore.getState();
  const cartId = await getCartId();
  const url = '/update_cart_attributes/';
  const formData = new FormData();
  formData.append('cart_id', cartId);
  formData.append(
    'attributes',
    JSON.stringify(
      Object.entries(attributes).map(([key, value]) => ({ key, value })),
    ),
  );
  const res = await request(url, {}, { version }, 'POST', formData);
  return res;
}
