import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { UNDISTRIBUTED_COUNTRIES } from 'pages/Cart/constants';
import { getShippingRegions } from 'api/cart';
import { compact, sortBy, uniqBy } from 'lodash';
import dayjs from 'plugins/dayjs';
import { getCountry } from 'api/shop';
import { logger } from 'logger';
import useAppearanceStore from './appearance';

const useShippingRegionStore = create(
  persist(
    (set, get) => ({
      shippingRegions: {
        lang: '',
        data: null,
        cacheTime: null,
      },
      currentCountry: '',
      productMap: null,
      countryList: [],
      setCurrentCountry: (currentCountry) => set({ currentCountry }),
      getShippingRegions: async () => {
        const { lang: appLang } = useAppearanceStore.getState();
        const { shippingRegions } = get();
        const isExpiredCached
          = shippingRegions.data
          && dayjs().diff(shippingRegions.cacheTime, 'h') < 1;

        const langChanged = shippingRegions.lang !== appLang;
        if (isExpiredCached && !langChanged)
          return shippingRegions.data;

        const data = await getShippingRegions(appLang);
        set({
          shippingRegions: {
            lang: appLang,
            data,
            cacheTime: new Date(),
          },
        });
        return data;
      },
      async getProductMap() {
        if (get().productMap)
          return get().productMap;

        const countryList = await get().loadCountryList();
        const shippingRegions = await get().getShippingRegions();
        const productMapData = countryList.map((it) => ({
          code: it.value,
          continent: it.continent,
          list: compact(
            shippingRegions
              .filter((cIt) => cIt.country.code === it.value)
              .map((cIt) => cIt.products)
              .flat(),
          ),
        }));
        set({
          productMap: productMapData,
        });

        return productMapData;
      },
      async loadCountryList() {
        const { lang } = useAppearanceStore.getState();
        const undistributedCountries = UNDISTRIBUTED_COUNTRIES.map(
          (country) => country[lang],
        );

        const regions = await get().getShippingRegions();
        let countryList = uniqBy(regions, 'country.code')
          .map((it) => ({
            name: it.country.name,
            continent: it.continent,
            value: it.country.code,
          }))
          .filter((it) => it.value !== 'CN')
          .concat(undistributedCountries);
        countryList = sortBy(countryList, 'name');
        set({ countryList });
        return countryList;
      },
      async checkCurrentCountry() {
        try {
          const existCountryCode = get().currentCountry;
          if (existCountryCode)
            return;

          const countryCode = await getCountry();
          const countryList = await get().loadCountryList();
          const hasCountryCode = countryList.some(
            (it) => it.value === countryCode,
          );
          if (!hasCountryCode)
            return;

          set({ currentCountry: countryCode });
        } catch (e) {
          logger.error(e);
        }
      },
    }),
    {
      name: 'shipping-regions-store',
    },
  ),
);

export default useShippingRegionStore;
