import { createContext, useContext, useEffect, useState } from 'react';
import dayjs from 'plugins/dayjs';
import { isEqual } from 'lodash';

export { EVENT_LIST, initialEvents } from './events';

const TZ = 'Asia/Shanghai';

export const EventToggleContext = createContext();

/**
 * Helper function to check if now is within a date range
 */
export function isNowInDateRange(start = '1900-01-01', end = '9999-12-31') {
  const now = dayjs().utc();
  return (
    now.isAfter(dayjs.tz(start, TZ).utc())
    && now.isBefore(dayjs.tz(end, TZ).utc())
  );
}

/**
 * useEventToggle is a hook that checks if a specific event is currently
 * active based on its start and end times.
 */
export function useEventToggle(name) {
  const target = useTargetEvent(name);
  const [isActive, setIsActive] = useState(
    () =>
      target && isNowInDateRange(target.dateRange.start, target.dateRange.end),
  );

  useEffect(() => {
    if (!target) {
      setIsActive(false);
      return;
    }

    const interval = setInterval(() => {
      setIsActive(
        isNowInDateRange(target.dateRange.start, target.dateRange.end),
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [target]);

  return isActive;
}

/**
 * ToggleVisibility is a component that conditionally renders its children
 * based on whether a specific event is currently active.
 */
export function ToggleVisibility({ children, name }) {
  const isActive = useEventToggle(name);
  return isActive ? children : null;
}

/**
 * useTargetEvent is a hook that retrieves the data for a specific event
 */
export function useTargetEvent(name) {
  const { events } = useContext(EventToggleContext) ?? {};
  return events?.find?.((e) => e.name === name) ?? null;
}

/**
 * useEventCountdown is a hook that provides a countdown timer for a specific event.
 */
export function useEventCountdown(name) {
  const initialCountdown = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    percentage: 0,
  };
  const target = useTargetEvent(name);
  const [countdown, setCountdown] = useState(initialCountdown);

  const safeDate = (date) => {
    if (date) {
      return dayjs.tz(date, TZ).utc();
    }
    return dayjs()
      .tz(TZ)
      .utc();
  };

  useEffect(() => {
    if (!target) {
      return;
    }

    const dateRange = target?.dateRange ?? {};
    const interval = setInterval(() => {
      const now = dayjs().utc();
      const start = safeDate(dateRange.start);
      const end = safeDate(dateRange.end);
      const totalDuration = end.diff(start, 'second');
      const elapsedDuration = now.diff(start, 'second');
      const remainingDuration = Math.max(0, totalDuration - elapsedDuration);

      if (remainingDuration === 0) {
        clearInterval(interval);
      }

      const days = Math.floor(remainingDuration / 86400);
      const hours = Math.floor((remainingDuration % 86400) / 3600);
      const minutes = Math.floor((remainingDuration % 3600) / 60);
      const seconds = remainingDuration % 60;
      const percentage = Number.parseFloat(
        ((elapsedDuration / totalDuration) * 100).toFixed(2),
      );

      setCountdown({
        days,
        hours,
        minutes,
        seconds,
        percentage: percentage < 100 ? percentage : 100,
      });
    }, 200);

    return () => clearInterval(interval);
  }, [target]);

  return countdown;
}

/**
 * useCurrentActiveEvents is a hook that returns all events that are currently active.
 */
export function useCurrentActiveEvents() {
  const [events, setEvents] = useState([]);
  const { events: allEvents } = useContext(EventToggleContext);

  useEffect(() => {
    let prevActiveEvents = [];
    const updateEvents = () => {
      const activeEvents = allEvents.filter((e) =>
        isNowInDateRange(e.dateRange.start, e.dateRange.end),
      );
      if (!isEqual(activeEvents, prevActiveEvents)) {
        setEvents(activeEvents);
        prevActiveEvents = activeEvents;
      }
    };

    updateEvents();
    const interval = setInterval(updateEvents, 1000);

    return () => clearInterval(interval);
  }, [allEvents]);

  return events;
}
