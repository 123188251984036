import React from 'react';
import { useI18nDescriptor, useMsg } from 'hooks/useMessages';
import { useIntl } from 'react-intl';

export default function useMessages() {
  const intl = useIntl();
  const rootGetter = useMsg('keystone');
  const messagesGetter = useMsg('keystone.campaignModal');
  const currMsgGetter = useMsg('keystone.campaignModal.blackFriday');
  const currDescriptorMsgGetter = useI18nDescriptor(
    'keystone.campaignModal.blackFriday',
  );

  return {
    title: messagesGetter('modal.title'),
    subtitle: messagesGetter('modal.subtitle'),
    button: messagesGetter('modal.button'),
    claimDiscount: messagesGetter('modal.claimDiscount'),
    buyNow: rootGetter('buyNow'),
    almostHere: {
      promotionTitle: currMsgGetter('almostHere.promotionTitle'),
      desc: currMsgGetter('almostHere.desc'),
    },
    stage: {
      promotionTitle: currMsgGetter('stage.promotionTitle'),
      title: intl.formatMessage(currDescriptorMsgGetter('stage.title'), {
        em: (str) => <em>{str}</em>,
      }),
    },
    campaigns: Array.from({ length: 7 }).map((_, idx) =>
      messagesGetter(`campaigns.${idx + 1}.title`),
    ),
  };
}
