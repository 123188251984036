export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const DEVICE_SIZE_MOBILE = 1000;
export const DEVICE_QUERY_MOBILE_SMALL = '(max-width: 800px)';
export const DEVICE_QUERY_MOBILE = `(max-width:${DEVICE_SIZE_MOBILE}px)`;
export const DEVICE_QUERY_MOBILE_800 = `(max-height: 800px) and (min-width: ${DEVICE_SIZE_MOBILE}px)`;
export const DEVICE_QUERY_MOBILE_1360 = `(min-width: ${DEVICE_SIZE_MOBILE}px) and (max-width: 1360px)`;
export const DEVICE_QUERY_MOBILE_1440 = `(min-width: ${DEVICE_SIZE_MOBILE}px) and (max-width: 1440px)`;
