import React from 'react';
import PropTypes from 'prop-types';
import Star from 'components/icons/Star';
import { Container } from './style';

function Rate({ value = 5, len = 5, color = '#F0A247', size = 20, ...args }) {
  const int = Math.trunc(value);
  const decimal = value - int;

  return (
    <Container {...args}>
      {Array.from({ length: len }).map((_a, idx) => {
        const rate = Number(int) === idx ? decimal * 100 : int > idx ? 100 : 0;
        return (
          <Star
            key={idx}
            style={{ color }}
            rate={rate}
            width={size}
            height={size}
          />
        );
      })}
    </Container>
  );
}

Rate.propTypes = {
  value: PropTypes.number,
  len: PropTypes.number,
  color: PropTypes.string,
  size: PropTypes.number,
};

export default Rate;
