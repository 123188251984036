import { extractProductDataLayer } from '../util/extractProductDataLayer';

function extractProductContext(product, type) {
  const dataLayer = extractProductDataLayer(product, type);

  const contents = dataLayer.items.map((item) => {
    return {
      content_type: item.item_category,
      content_id: item.variant_id,
      content_name: item.item_name,
      content_price: item.price,
      num_items: 1,
    };
  });

  return {
    value: dataLayer.value,
    currency: dataLayer.currency,
    contents,
  };
}

export function trackItemView(product) {
  const context = extractProductContext(product);
  window.twq('event', 'tw-on1j8-on1k7', context);
}

export function trackAddToCart(product, type) {
  const context = extractProductContext(product, type);
  window.twq('event', 'tw-on1j8-on1k8', context);
}
