import { GrowthBook } from '@growthbook/growthbook-react';
import { getGaClientId, getUserId } from 'utils/commonUtils';

export function initGrowthbook() {
  const clientId = getGaClientId();
  const userId = getUserId();
  return new GrowthBook({
    apiHost: 'https://cdn.growthbook.io',
    clientKey: process.env.GROWTHBOOK_CLIENT_KEY,
    enableDevMode: process.env.NODE_ENV !== 'production',
    attributes: {
      id: userId,
      clientId,
      userId,
    },
    trackingCallback: (experiment, result) => {
      if ('gtag' in window) {
        window.gtag('event', 'experiment_viewed', {
          event_category: 'experiment',
          experiment_id: experiment.key,
          variation_id: result.variationId,
          gb_user_id: userId,
        });
      } else {
        console.warn('no gtag');
      }
    },
  });
}
