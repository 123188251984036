import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { LINKS } from 'config/links';
import Link from 'components/Link';
import useIsMobile from 'hooks/useIsMobile';
import { Submenu } from '../style';
import {
  CardLinkContainer,
  CardLinkImage,
  CardLinkTitle,
  Container,
  Feature,
  FeatureItemContainer,
  FeatureTitle,
  Features,
  ItemIcon,
  WalletsAndCoins,
} from './index.style';
import { mainMsgs as messages } from './../messages';

function CardLink({ imageUrl, link, title }) {
  const isMobile = useIsMobile();
  return (
    <Link href={link} arrow={false}>
      <CardLinkContainer>
        {!isMobile && <CardLinkImage src={imageUrl}></CardLinkImage>}
        <Link>
          <CardLinkTitle>{title}</CardLinkTitle>
        </Link>
      </CardLinkContainer>
    </Link>
  );
}

function FeatureItem({ href, title, icon }) {
  return (
    <FeatureItemContainer>
      <Feature href={href} blank={!href.startsWith('/')}>
        <ItemIcon src={icon} loading="lazy" />
        <FeatureTitle>
          <FormattedMessage {...title} />
        </FeatureTitle>
      </Feature>
    </FeatureItemContainer>
  );
}

export default function CoinsAndFeatures({ open, ...args }) {
  const intl = useIntl();
  const isMobile = useIsMobile();

  const menuList = [
    {
      id: 'firmware',
      href: '/firmware',
      icon: require('./images/download.svg'),
      title: messages.resourcesUpgrade,
    },
    {
      id: 'btc-only',
      href: LINKS.btcOnly,
      icon: require('./images/btc-only.svg'),
      title: messages.resourcesBtcOnly,
    },
    {
      id: 'nft',
      href: LINKS.nft,
      icon: require('./images/NFT.svg'),
      title: messages.resourcesNFTLockscreen,
    },
    {
      id: 'roadmap',
      href: LINKS.roadmap,
      icon: require('./images/roadmap.svg'),
      title: messages.resourcesRoadmap,
    },
    {
      id: 'authentication',
      href: '/authentication',
      icon: require('./images/device-verification.svg'),
      title: messages.resourcesAuth,
    },
    {
      id: 'suggestAssets',
      href: LINKS.suggestWalletAssets,
      icon: require('./images/firmware.svg'),
      title: messages.suggestAssets,
    },
  ];

  const CardLinkComponent = useMemo(
    () => (
      <WalletsAndCoins>
        <CardLink
          imageUrl={require('./images/coins.jpg')}
          title={intl.formatMessage(messages.supportedWalletsAndCoins)}
          link={LINKS.supportAssetsAndWallets}
        />
      </WalletsAndCoins>
    ),
    [intl],
  );

  return (
    <Submenu height={340} className={open ? 'active' : ''} {...args}>
      <Container>
        {!isMobile && CardLinkComponent}
        <Features>
          {menuList.map((it) => (
            <FeatureItem {...it} key={it.id}></FeatureItem>
          ))}
        </Features>
        {isMobile && CardLinkComponent}
      </Container>
    </Submenu>
  );
}
