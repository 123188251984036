import { versions } from '../utils/browser';

const VERSION = '2.0';
const ENVIRONMENT = process.env.ENV || 'development';

export const CONTEXT = {
  version: VERSION,
  env: ENVIRONMENT,
};

export function constructContext(locale) {
  const isMobile = versions(window.userAgent).mobile;
  return {
    language: locale,
    platform: isMobile ? 'mobile' : 'web',
  };
}

export function includeExpand(locale) {
  const isMobile = versions(window.userAgent).mobile;
  return {
    language: locale,
    platform: isMobile ? 'mobile' : 'web',
  };
}
