import React from 'react';
import Format from './Format';
import { IconTypes } from './types';

export default function Plus({
  width = '36',
  height = '36',
  color = 'currentColor',
  ...args
}) {
  return (
    <Format {...args}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9393 17.9996L10.3986 24.5403L11.4593 25.601L18 19.0602L24.5407 25.601L25.6014 24.5403L19.0607 17.9996L25.6014 11.4588L24.5407 10.3982L18 16.9389L11.4593 10.3982L10.3986 11.4588L16.9393 17.9996Z"
          fill={color}
        />
      </svg>
    </Format>
  );
}

Plus.propTypes = IconTypes;
