import React, {
  Fragment,
  Suspense,
  createElement,
  isValidElement,
} from 'react';
import { Loading } from 'components/Loading';
import { FlexCenter } from 'components/styles/flex';
import { genUniqueKey } from 'utils/commonUtils';
import DelayedFallback from './DelayFallback';

export const CenteredLoading = (
  <FlexCenter className="theme-dark centered-loading">
    <Loading width="84px" height="6px" color="var(--color-fg-muted)" />
  </FlexCenter>
);

export function withSuspense(component, fallback, delay = 200) {
  return (
    <Suspense
      fallback={<DelayedFallback delay={delay}>{fallback}</DelayedFallback>}
    >
      {component}
    </Suspense>
  );
}

export function SuspenseComponent({
  children,
  loading = CenteredLoading,
  delay = 200,
}) {
  return withSuspense(children, loading, delay);
}

export function NestedSuspense({ components, themeColor }) {
  return components.reverse().reduce(
    (p, n) =>
      createElement(
        SuspenseComponent,
        {
          key: n.key || genUniqueKey(),
        },
        createElement(
          Fragment,
          {},
          isValidElement(n)
            ? n
            : createElement(n, {
              key: n.key || genUniqueKey(),
              themeColor,
            }),
          p,
        ),
      ),
    null,
  );
}
