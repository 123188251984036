import { trackEvent } from './base';
import { EVENT_CATEGORY, EVENT_TYPE } from './type';

export function trackCampaignBannerClick(campaignName) {
  trackEvent(EVENT_TYPE.BUTTON_CLICK, {
    event_category: EVENT_CATEGORY.Campaign,
    event_label: 'banner',
    title: campaignName,
  });
}

export function trackCampaignBannerTitleClick(campaignName) {
  trackEvent(EVENT_TYPE.BUTTON_CLICK, {
    event_category: EVENT_CATEGORY.CampaignTitle,
    event_label: 'banner',
    title: campaignName,
  });
}

export function trackCampaignModalView(campaignName) {
  trackEvent(EVENT_TYPE.BUTTON_CLICK, {
    event_category: EVENT_CATEGORY.Campaign,
    event_label: 'modal_view',
    title: campaignName,
  });
}

export function trackCampaignModalClick(campaignName) {
  trackEvent(EVENT_TYPE.BUTTON_CLICK, {
    event_category: EVENT_CATEGORY.Campaign,
    event_label: 'modal_click',
    title: campaignName,
  });
}

export function trackCampaignModalAddToCart(campaignName, variantId) {
  trackEvent(EVENT_TYPE.BUTTON_CLICK, {
    event_category: EVENT_CATEGORY.Campaign,
    event_label: 'modal_add_to_cart',
    title: campaignName,
    value: variantId,
  });
}
