import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import { H7 } from 'components/styles/title';
import { FlexBetween, FlexStart } from 'components/styles/flex';
import Cart from 'components/icons/Cart';
import { Overline } from 'components/styles/bodyNew';

export const ProductDiscountInfo = styled(FlexBetween)`
  width: 100%;
  margin-top: 16px;
  padding: 6px 12px;
  gap: 0 10px;
  color: var(--color-bd-oranger);
  background: var(--color-bd-oranger-trans10);
  border-radius: 8px;
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const ProductSoldOutInfo = styled(ProductDiscountInfo)`
  margin-top: 16px;
  color: var(--color-bd-pink);
  width: 100%;
  background: var(--color-bd-pink-trans);
`;

export const CartIcon = styled(Cart)`
  color: var(--color-bd-cyan);
`;

export const ProductDiscountInfoLabel = styled(FlexStart)`
  color: var(--color-bd-oranger);
  gap: 0 8px;
  .sale-off-icon {
    width: 18px;
    height: 18px;
  }
`;

export const ProductDiscountInfoTitle = styled(Overline)`
  color: var(--color-bd-oranger);
  font-weight: 600;
`;

export const ProductDiscountInfoCountdown = styled(Overline)`
  color: var(--color-bd-oranger);
  font-weight: 600;
`;

export const ProductDiscountInfoOffAndCountdownLabel = styled.div`
  display: flex;
  gap: 0 8px;
`;

export const ProductDiscountOff = styled(H7)`
  color: var(--color-bd-oranger);
  font-weight: 600;
`;
