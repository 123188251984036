import { Caption } from 'components/styles/body';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import styled from 'styled-components';
import Toast from 'components/Toast';

export const ToastPrivacy = styled(Toast)`
  z-index: 10000;
  @media ${DEVICE_QUERY_MOBILE} {
    width: 86%;
    flex-direction: column;
    padding: 16px;
    button {
      height: 40px;
      padding: 8px 16px;
      font-size: 12px;
      line-height: 24px;
    }
  }
`;

export const Desc = styled(Caption)`
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 16px;
  }
  max-width: 640px;
`;
