import React from 'react';
import Format from './Format';
import { IconTypes } from './types';

export default function Egg({
  width = '24',
  height = '24',
  color = 'currentColor',
  ...args
}) {
  return (
    <Format {...args}>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M41 27.7242C41 37.0187 33.3489 45.2273 24 45.2273C14.6511 45.2273 7 37.0187 7 27.7242C7 23.0424 8.9425 16.6647 12.1254 11.4563C15.3411 6.19408 19.5811 2.5 24 2.5C28.4189 2.5 32.6589 6.19408 35.8746 11.4563C39.0575 16.6647 41 23.0424 41 27.7242Z"
          fill="url(#paint0_linear_1179_7412)"
          stroke="white"
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.854 28.5125H39.6802H39.3426L39.0741 28.7171L34.4789 32.2187L29.8836 28.7171L29.2775 28.2553L28.6714 28.7171L24.0762 32.2187L19.4809 28.7171L18.8748 28.2553L18.2688 28.7171L13.6735 32.2187L9.07824 28.7171L8.80973 28.5125H8.47216H7.14591C7.10946 29.0193 7.09088 29.513 7.09088 29.9906C7.09088 30.165 7.09335 30.3389 7.09827 30.5125H8.13458L13.0674 34.2713L13.6735 34.7331L14.2796 34.2713L18.8748 30.7697L23.4701 34.2713L24.0762 34.7331L24.6823 34.2713L29.2775 30.7697L33.8728 34.2713L34.4789 34.7331L35.085 34.2713L40.0178 30.5125H40.9017C40.9066 30.3389 40.9091 30.165 40.9091 29.9906C40.9091 29.513 40.8905 29.0193 40.854 28.5125Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.4813 11.4091H35.6337L35.3046 11.4091L35.0398 11.6046L31.7746 14.0149L28.5094 11.6046L27.9155 11.1662L27.3216 11.6046L24.0565 14.0149L20.7913 11.6046L20.1974 11.1662L19.6035 11.6046L16.3384 14.0149L13.0732 11.6046L12.8084 11.4091H12.4793H11.5187C11.4763 11.8725 11.4546 12.3217 11.4546 12.7528C11.4546 12.9724 11.4602 13.1912 11.4713 13.4091H12.1502L15.7445 16.0624L16.3384 16.5008L16.9323 16.0624L20.1974 13.6521L23.4626 16.0624L24.0565 16.5008L24.6504 16.0624L27.9155 13.6521L31.1807 16.0624L31.7746 16.5008L32.3685 16.0624L35.9628 13.4091H36.5287C36.5398 13.1912 36.5455 12.9724 36.5455 12.7528C36.5455 12.3217 36.5237 11.8725 36.4813 11.4091Z"
          fill="white"
        />
        <circle
          cx="13.6364"
          cy="25.5002"
          r="1.18182"
          stroke="#E07F3F"
          strokeWidth="2"
        />
        <circle
          cx="24.5453"
          cy="25.5002"
          r="1.18182"
          stroke="#AD93F5"
          strokeWidth="2"
        />
        <circle
          cx="35.4545"
          cy="25.5002"
          r="1.18182"
          stroke="#E07F3F"
          strokeWidth="2"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1179_7412"
            x1="6"
            y1="23.8636"
            x2="42"
            y2="23.8636"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="var(--stop-start-color)" />
            <stop offset="1" stopColor="var(--stop-end-color)" />
          </linearGradient>
        </defs>
      </svg>
    </Format>
  );
}

Egg.propTypes = IconTypes;
