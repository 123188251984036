import { extractProductDataLayer } from '../util/extractProductDataLayer';

export function trackItemView(product) {
  const dataLayer = extractProductDataLayer(product);
  window.gtag('event', 'view_item_k', dataLayer);
}

export function trackAddToCart(item, type) {
  const dataLayer = extractProductDataLayer(item, type);
  window.gtag('event', 'add_to_cart_k', dataLayer);
}
