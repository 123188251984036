const data = {
  "nm": "Flow 2",
  "ddd": 0,
  "h": 240,
  "w": 160,
  "meta": {
    "g": "LottieFiles Figma v67"
  },
  "layers": [
    {
      "ty": 0,
      "nm": "Group 1142813196",
      "sr": 1,
      "st": 0,
      "op": 265,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": {
          "a": 1,
          "k": [
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                67.86,
                77.44
              ],
              "t": 0
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                67.86,
                77.44
              ],
              "t": 12
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.62,
                "y": 0.75
              },
              "s": [
                67.86,
                77.44
              ],
              "t": 132
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.62,
                "y": 0.75
              },
              "s": [
                67.86,
                77.44
              ],
              "t": 144
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 1,
                "y": 1
              },
              "s": [
                67.86,
                77.44
              ],
              "t": 264
            },
            {
              "s": [
                67.86,
                77.44
              ],
              "t": 276
            }
          ]
        },
        "s": {
          "a": 1,
          "k": [
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100,
                100
              ],
              "t": 0
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100,
                100
              ],
              "t": 12
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.62,
                "y": 0.75
              },
              "s": [
                100,
                100
              ],
              "t": 132
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.62,
                "y": 0.75
              },
              "s": [
                100,
                100
              ],
              "t": 144
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 1,
                "y": 1
              },
              "s": [
                100,
                100
              ],
              "t": 264
            },
            {
              "s": [
                100,
                100
              ],
              "t": 276
            }
          ]
        },
        "sk": {
          "a": 0,
          "k": 0
        },
        "p": {
          "a": 1,
          "k": [
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                91.86,
                58.44
              ],
              "t": 0
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                91.86,
                58.44
              ],
              "t": 12
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.62,
                "y": 0.75
              },
              "s": [
                91.86,
                120.44
              ],
              "t": 132
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.62,
                "y": 0.75
              },
              "s": [
                91.86,
                120.44
              ],
              "t": 144
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 1,
                "y": 1
              },
              "s": [
                91.86,
                58.44
              ],
              "t": 264
            },
            {
              "s": [
                91.86,
                58.44
              ],
              "t": 276
            }
          ]
        },
        "r": {
          "a": 1,
          "k": [
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                0
              ],
              "t": 0
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                0
              ],
              "t": 12
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.62,
                "y": 0.75
              },
              "s": [
                0
              ],
              "t": 132
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.62,
                "y": 0.75
              },
              "s": [
                0
              ],
              "t": 144
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 1,
                "y": 1
              },
              "s": [
                0
              ],
              "t": 264
            },
            {
              "s": [
                0
              ],
              "t": 276
            }
          ]
        },
        "sa": {
          "a": 0,
          "k": 0
        },
        "o": {
          "a": 1,
          "k": [
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100
              ],
              "t": 0
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.7,
                "y": 0.81
              },
              "s": [
                100
              ],
              "t": 12
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.62,
                "y": 0.75
              },
              "s": [
                100
              ],
              "t": 132
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 0.62,
                "y": 0.75
              },
              "s": [
                100
              ],
              "t": 144
            },
            {
              "o": {
                "x": 0,
                "y": 0
              },
              "i": {
                "x": 1,
                "y": 1
              },
              "s": [
                100
              ],
              "t": 264
            },
            {
              "s": [
                100
              ],
              "t": 276
            }
          ]
        }
      },
      "w": 160,
      "h": 240,
      "refId": "1",
      "ind": 1
    }
  ],
  "v": "5.7.0",
  "fr": 60,
  "op": 264,
  "ip": 0,
  "assets": [
    {
      "nm": "[Asset] Group 1142813196",
      "id": "1",
      "layers": [
        {
          "ty": 4,
          "nm": "Ellipse",
          "sr": 1,
          "st": 0,
          "op": 265,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    33.48,
                    66.14
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    33.48,
                    66.14
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    33.48,
                    66.14
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    33.48,
                    66.14
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    33.48,
                    66.14
                  ],
                  "t": 264
                },
                {
                  "s": [
                    33.48,
                    66.14
                  ],
                  "t": 276
                }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100,
                    100
                  ],
                  "t": 276
                }
              ]
            },
            "sk": {
              "a": 0,
              "k": 0
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    75.15,
                    75.51
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    75.15,
                    75.51
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    75.15,
                    75.51
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    75.15,
                    75.51
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    75.15,
                    75.51
                  ],
                  "t": 264
                },
                {
                  "s": [
                    75.15,
                    75.51
                  ],
                  "t": 276
                }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    -30
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    -30
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    -30
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    -30
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    -30
                  ],
                  "t": 264
                },
                {
                  "s": [
                    -30
                  ],
                  "t": 276
                }
              ]
            },
            "sa": {
              "a": 0,
              "k": 0
            },
            "o": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100
                  ],
                  "t": 276
                }
              ]
            }
          },
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            -27.13039527844743
                          ],
                          [
                            -12.245587177894544,
                            -13.359960499149004
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            -11.997236735909164,
                            13.447919273466738
                          ],
                          [
                            0,
                            27.462683981425535
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            19.963554759594068,
                            0
                          ],
                          [
                            0,
                            64.38582280058057
                          ],
                          [
                            20.41249594318303,
                            129.2700786556283
                          ],
                          [
                            63.9597907513119,
                            64.38582280058057
                          ],
                          [
                            19.963554759594068,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            -27.13039527844743
                          ],
                          [
                            -12.245587177894544,
                            -13.359960499149004
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            -11.997236735909164,
                            13.447919273466738
                          ],
                          [
                            0,
                            27.462683981425535
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            19.963554759594068,
                            0
                          ],
                          [
                            0,
                            64.38582280058057
                          ],
                          [
                            20.41249594318303,
                            129.2700786556283
                          ],
                          [
                            63.9597907513119,
                            64.38582280058057
                          ],
                          [
                            19.963554759594068,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            -27.13039527844743
                          ],
                          [
                            -12.245587177894544,
                            -13.359960499149004
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            -11.997236735909164,
                            13.447919273466738
                          ],
                          [
                            0,
                            27.462683981425535
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            19.963554759594068,
                            0
                          ],
                          [
                            0,
                            64.38582280058057
                          ],
                          [
                            20.41249594318303,
                            129.2700786556283
                          ],
                          [
                            63.9597907513119,
                            64.38582280058057
                          ],
                          [
                            19.963554759594068,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            -27.13039527844743
                          ],
                          [
                            -12.245587177894544,
                            -13.359960499149004
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            -11.997236735909164,
                            13.447919273466738
                          ],
                          [
                            0,
                            27.462683981425535
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            19.963554759594068,
                            0
                          ],
                          [
                            0,
                            64.38582280058057
                          ],
                          [
                            20.41249594318303,
                            129.2700786556283
                          ],
                          [
                            63.9597907513119,
                            64.38582280058057
                          ],
                          [
                            19.963554759594068,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            -27.13039527844743
                          ],
                          [
                            -12.245587177894544,
                            -13.359960499149004
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            -11.997236735909164,
                            13.447919273466738
                          ],
                          [
                            0,
                            27.462683981425535
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            19.963554759594068,
                            0
                          ],
                          [
                            0,
                            64.38582280058057
                          ],
                          [
                            20.41249594318303,
                            129.2700786556283
                          ],
                          [
                            63.9597907513119,
                            64.38582280058057
                          ],
                          [
                            19.963554759594068,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            -27.13039527844743
                          ],
                          [
                            -12.245587177894544,
                            -13.359960499149004
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            -11.997236735909164,
                            13.447919273466738
                          ],
                          [
                            0,
                            27.462683981425535
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            19.963554759594068,
                            0
                          ],
                          [
                            0,
                            64.38582280058057
                          ],
                          [
                            20.41249594318303,
                            129.2700786556283
                          ],
                          [
                            63.9597907513119,
                            64.38582280058057
                          ],
                          [
                            19.963554759594068,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "nm": "",
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      100
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      100
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      100
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 276
                  }
                ]
              },
              "w": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      3
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      3
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      3
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      3
                    ],
                    "t": 276
                  }
                ]
              },
              "c": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.1084,
                      1,
                      0.679
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.1084,
                      1,
                      0.679
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      0.1084,
                      1,
                      0.679
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      0.1084,
                      1,
                      0.679
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0.1084,
                      1,
                      0.679
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0.1084,
                      1,
                      0.679
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "nm": "",
              "c": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 276
                  }
                ]
              },
              "r": 1,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      100
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      100
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      100
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "gf",
              "bm": 0,
              "hd": false,
              "nm": "",
              "e": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      67.4,
                      19.55
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      67.4,
                      19.55
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      67.4,
                      19.55
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      67.4,
                      19.55
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      67.4,
                      19.55
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      67.4,
                      19.55
                    ],
                    "t": 276
                  }
                ]
              },
              "g": {
                "p": 2,
                "k": {
                  "a": 1,
                  "k": [
                    {
                      "o": {
                        "x": 0,
                        "y": 0
                      },
                      "i": {
                        "x": 0.7,
                        "y": 0.81
                      },
                      "s": [
                        0,
                        0.4078823529411765,
                        0.8000392156862745,
                        0.502,
                        1,
                        0.3882745098039216,
                        0.7961176470588235,
                        0.4863137254901961
                      ],
                      "t": 0
                    },
                    {
                      "o": {
                        "x": 0,
                        "y": 0
                      },
                      "i": {
                        "x": 0.7,
                        "y": 0.81
                      },
                      "s": [
                        0,
                        0.4078823529411765,
                        0.8000392156862745,
                        0.502,
                        1,
                        0.3882745098039216,
                        0.7961176470588235,
                        0.4863137254901961
                      ],
                      "t": 12
                    },
                    {
                      "o": {
                        "x": 0,
                        "y": 0
                      },
                      "i": {
                        "x": 0.62,
                        "y": 0.75
                      },
                      "s": [
                        0,
                        0.4078823529411765,
                        0.8000392156862745,
                        0.502,
                        1,
                        0.3882745098039216,
                        0.7961176470588235,
                        0.4863137254901961
                      ],
                      "t": 132
                    },
                    {
                      "o": {
                        "x": 0,
                        "y": 0
                      },
                      "i": {
                        "x": 0.62,
                        "y": 0.75
                      },
                      "s": [
                        0,
                        0.4078823529411765,
                        0.8000392156862745,
                        0.502,
                        1,
                        0.3882745098039216,
                        0.7961176470588235,
                        0.4863137254901961
                      ],
                      "t": 144
                    },
                    {
                      "o": {
                        "x": 0,
                        "y": 0
                      },
                      "i": {
                        "x": 1,
                        "y": 1
                      },
                      "s": [
                        0,
                        0.4078823529411765,
                        0.8000392156862745,
                        0.502,
                        1,
                        0.3882745098039216,
                        0.7961176470588235,
                        0.4863137254901961
                      ],
                      "t": 264
                    },
                    {
                      "s": [
                        0,
                        0.4078823529411765,
                        0.8000392156862745,
                        0.502,
                        1,
                        0.3882745098039216,
                        0.7961176470588235,
                        0.4863137254901961
                      ],
                      "t": 276
                    }
                  ]
                }
              },
              "t": 1,
              "a": {
                "a": 0,
                "k": 0
              },
              "h": {
                "a": 0,
                "k": 0
              },
              "s": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      1.17,
                      18.26
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      1.17,
                      18.26
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      1.17,
                      18.26
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      1.17,
                      18.26
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      1.17,
                      18.26
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      1.17,
                      18.26
                    ],
                    "t": 276
                  }
                ]
              },
              "r": 1,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      0
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      0
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0
                    ],
                    "t": 276
                  }
                ]
              }
            }
          ],
          "ind": 1
        },
        {
          "ty": 4,
          "nm": "Union",
          "sr": 1,
          "st": 0,
          "op": 265,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    51.26,
                    65.92
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    51.26,
                    65.92
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    51.26,
                    65.92
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    51.26,
                    65.92
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    51.26,
                    65.92
                  ],
                  "t": 264
                },
                {
                  "s": [
                    51.26,
                    65.92
                  ],
                  "t": 276
                }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100,
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100,
                    100
                  ],
                  "t": 276
                }
              ]
            },
            "sk": {
              "a": 0,
              "k": 0
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    52.76,
                    90.45
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    52.76,
                    90.45
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    52.76,
                    90.45
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    52.76,
                    90.45
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    52.76,
                    90.45
                  ],
                  "t": 264
                },
                {
                  "s": [
                    52.76,
                    90.45
                  ],
                  "t": 276
                }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    0
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    0
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    0
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    0
                  ],
                  "t": 264
                },
                {
                  "s": [
                    0
                  ],
                  "t": 276
                }
              ]
            },
            "sa": {
              "a": 0,
              "k": 0
            },
            "o": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 0
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.7,
                    "y": 0.81
                  },
                  "s": [
                    100
                  ],
                  "t": 12
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    100
                  ],
                  "t": 132
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 0.62,
                    "y": 0.75
                  },
                  "s": [
                    100
                  ],
                  "t": 144
                },
                {
                  "o": {
                    "x": 0,
                    "y": 0
                  },
                  "i": {
                    "x": 1,
                    "y": 1
                  },
                  "s": [
                    100
                  ],
                  "t": 264
                },
                {
                  "s": [
                    100
                  ],
                  "t": 276
                }
              ]
            }
          },
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            13.638843684334498,
                            23.78616202089161
                          ],
                          [
                            -3.8732374591099394,
                            17.785872998365953
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -17.40530016086246,
                            -5.3064445264355555
                          ],
                          [
                            -13.473818529435077,
                            -23.492988290149313
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            28.821157935081228,
                            0
                          ],
                          [
                            99.51987576640374,
                            33.25567352386777
                          ],
                          [
                            93.42365239717807,
                            111.71873632819755
                          ],
                          [
                            65.8935736151335,
                            128.84008220354764
                          ],
                          [
                            15.706511801603714,
                            83.09520775005807
                          ],
                          [
                            1.3007865150895537,
                            17.131118333041492
                          ],
                          [
                            28.821157935081228,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            13.638843684334498,
                            23.78616202089161
                          ],
                          [
                            -3.8732374591099394,
                            17.785872998365953
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -17.40530016086246,
                            -5.3064445264355555
                          ],
                          [
                            -13.473818529435077,
                            -23.492988290149313
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            28.821157935081228,
                            0
                          ],
                          [
                            99.51987576640374,
                            33.25567352386777
                          ],
                          [
                            93.42365239717807,
                            111.71873632819755
                          ],
                          [
                            65.8935736151335,
                            128.84008220354764
                          ],
                          [
                            15.706511801603714,
                            83.09520775005807
                          ],
                          [
                            1.3007865150895537,
                            17.131118333041492
                          ],
                          [
                            28.821157935081228,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            13.638843684334498,
                            23.78616202089161
                          ],
                          [
                            -3.8732374591099394,
                            17.785872998365953
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -17.40530016086246,
                            -5.3064445264355555
                          ],
                          [
                            -13.473818529435077,
                            -23.492988290149313
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            28.821157935081228,
                            0
                          ],
                          [
                            99.51987576640374,
                            33.25567352386777
                          ],
                          [
                            93.42365239717807,
                            111.71873632819755
                          ],
                          [
                            65.8935736151335,
                            128.84008220354764
                          ],
                          [
                            15.706511801603714,
                            83.09520775005807
                          ],
                          [
                            1.3007865150895537,
                            17.131118333041492
                          ],
                          [
                            28.821157935081228,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            13.638843684334498,
                            23.78616202089161
                          ],
                          [
                            -3.8732374591099394,
                            17.785872998365953
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -17.40530016086246,
                            -5.3064445264355555
                          ],
                          [
                            -13.473818529435077,
                            -23.492988290149313
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            28.821157935081228,
                            0
                          ],
                          [
                            99.51987576640374,
                            33.25567352386777
                          ],
                          [
                            93.42365239717807,
                            111.71873632819755
                          ],
                          [
                            65.8935736151335,
                            128.84008220354764
                          ],
                          [
                            15.706511801603714,
                            83.09520775005807
                          ],
                          [
                            1.3007865150895537,
                            17.131118333041492
                          ],
                          [
                            28.821157935081228,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            13.638843684334498,
                            23.78616202089161
                          ],
                          [
                            -3.8732374591099394,
                            17.785872998365953
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -17.40530016086246,
                            -5.3064445264355555
                          ],
                          [
                            -13.473818529435077,
                            -23.492988290149313
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            28.821157935081228,
                            0
                          ],
                          [
                            99.51987576640374,
                            33.25567352386777
                          ],
                          [
                            93.42365239717807,
                            111.71873632819755
                          ],
                          [
                            65.8935736151335,
                            128.84008220354764
                          ],
                          [
                            15.706511801603714,
                            83.09520775005807
                          ],
                          [
                            1.3007865150895537,
                            17.131118333041492
                          ],
                          [
                            28.821157935081228,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            13.638843684334498,
                            23.78616202089161
                          ],
                          [
                            -3.8732374591099394,
                            17.785872998365953
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -17.40530016086246,
                            -5.3064445264355555
                          ],
                          [
                            -13.473818529435077,
                            -23.492988290149313
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            28.821157935081228,
                            0
                          ],
                          [
                            99.51987576640374,
                            33.25567352386777
                          ],
                          [
                            93.42365239717807,
                            111.71873632819755
                          ],
                          [
                            65.8935736151335,
                            128.84008220354764
                          ],
                          [
                            15.706511801603714,
                            83.09520775005807
                          ],
                          [
                            1.3007865150895537,
                            17.131118333041492
                          ],
                          [
                            28.821157935081228,
                            0
                          ]
                        ]
                      }
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "nm": "",
              "lc": 1,
              "lj": 1,
              "ml": 4,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      100
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      100
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      100
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 276
                  }
                ]
              },
              "w": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      3
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      3
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      3
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      3
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      3
                    ],
                    "t": 276
                  }
                ]
              },
              "c": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.1084,
                      1,
                      0.679
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0.1084,
                      1,
                      0.679
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      0.1084,
                      1,
                      0.679
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      0.1084,
                      1,
                      0.679
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0.1084,
                      1,
                      0.679
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0.1084,
                      1,
                      0.679
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "nm": "",
              "c": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0,
                      0,
                      0
                    ],
                    "t": 276
                  }
                ]
              },
              "r": 1,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      100
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      100
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      100
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      100
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 276
                  }
                ]
              }
            },
            {
              "ty": "gf",
              "bm": 0,
              "hd": false,
              "nm": "",
              "e": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      32.93,
                      139.77
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      32.93,
                      139.77
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      32.93,
                      139.77
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      32.93,
                      139.77
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      32.93,
                      139.77
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      32.93,
                      139.77
                    ],
                    "t": 276
                  }
                ]
              },
              "g": {
                "p": 3,
                "k": {
                  "a": 1,
                  "k": [
                    {
                      "o": {
                        "x": 0,
                        "y": 0
                      },
                      "i": {
                        "x": 0.7,
                        "y": 0.81
                      },
                      "s": [
                        0,
                        0.41964705882352943,
                        0.698078431372549,
                        0.4745490196078432,
                        0.5070539116859436,
                        0.000039215686274509805,
                        0.5137647058823529,
                        0.22749019607843138,
                        1,
                        0.15298039215686274,
                        0.5608235294117647,
                        0.2863137254901961
                      ],
                      "t": 0
                    },
                    {
                      "o": {
                        "x": 0,
                        "y": 0
                      },
                      "i": {
                        "x": 0.7,
                        "y": 0.81
                      },
                      "s": [
                        0,
                        0.41964705882352943,
                        0.698078431372549,
                        0.4745490196078432,
                        0.5070539116859436,
                        0.000039215686274509805,
                        0.5137647058823529,
                        0.22749019607843138,
                        1,
                        0.15298039215686274,
                        0.5608235294117647,
                        0.2863137254901961
                      ],
                      "t": 12
                    },
                    {
                      "o": {
                        "x": 0,
                        "y": 0
                      },
                      "i": {
                        "x": 0.62,
                        "y": 0.75
                      },
                      "s": [
                        0,
                        0.41964705882352943,
                        0.698078431372549,
                        0.4745490196078432,
                        0.5070539116859436,
                        0.000039215686274509805,
                        0.5137647058823529,
                        0.22749019607843138,
                        1,
                        0.15298039215686274,
                        0.5608235294117647,
                        0.2863137254901961
                      ],
                      "t": 132
                    },
                    {
                      "o": {
                        "x": 0,
                        "y": 0
                      },
                      "i": {
                        "x": 0.62,
                        "y": 0.75
                      },
                      "s": [
                        0,
                        0.41964705882352943,
                        0.698078431372549,
                        0.4745490196078432,
                        0.5070539116859436,
                        0.000039215686274509805,
                        0.5137647058823529,
                        0.22749019607843138,
                        1,
                        0.15298039215686274,
                        0.5608235294117647,
                        0.2863137254901961
                      ],
                      "t": 144
                    },
                    {
                      "o": {
                        "x": 0,
                        "y": 0
                      },
                      "i": {
                        "x": 1,
                        "y": 1
                      },
                      "s": [
                        0,
                        0.41964705882352943,
                        0.698078431372549,
                        0.4745490196078432,
                        0.5070539116859436,
                        0.000039215686274509805,
                        0.5137647058823529,
                        0.22749019607843138,
                        1,
                        0.15298039215686274,
                        0.5608235294117647,
                        0.2863137254901961
                      ],
                      "t": 264
                    },
                    {
                      "s": [
                        0,
                        0.41964705882352943,
                        0.698078431372549,
                        0.4745490196078432,
                        0.5070539116859436,
                        0.000039215686274509805,
                        0.5137647058823529,
                        0.22749019607843138,
                        1,
                        0.15298039215686274,
                        0.5608235294117647,
                        0.2863137254901961
                      ],
                      "t": 276
                    }
                  ]
                }
              },
              "t": 1,
              "a": {
                "a": 0,
                "k": 0
              },
              "h": {
                "a": 0,
                "k": 0
              },
              "s": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      -41.07,
                      30.27
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      -41.07,
                      30.27
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      -41.07,
                      30.27
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      -41.07,
                      30.27
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      -41.07,
                      30.27
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      -41.07,
                      30.27
                    ],
                    "t": 276
                  }
                ]
              },
              "r": 1,
              "o": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0
                    ],
                    "t": 0
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.7,
                      "y": 0.81
                    },
                    "s": [
                      0
                    ],
                    "t": 12
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      0
                    ],
                    "t": 132
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 0.62,
                      "y": 0.75
                    },
                    "s": [
                      0
                    ],
                    "t": 144
                  },
                  {
                    "o": {
                      "x": 0,
                      "y": 0
                    },
                    "i": {
                      "x": 1,
                      "y": 1
                    },
                    "s": [
                      0
                    ],
                    "t": 264
                  },
                  {
                    "s": [
                      0
                    ],
                    "t": 276
                  }
                ]
              }
            }
          ],
          "ind": 2
        }
      ]
    }
  ]
}
module.exports = data;