import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTrack } from 'tracking';
import Button from 'components/Button';
import Mail from 'components/icons/Mail';
import ArrowRight from 'components/icons/ArrowRight';
import Popover from 'components/Popover';
import Select from 'components/Select';
import { H4 } from '../styles/title';
import { Body } from '../styles/body';
import Modal from '../Modal';
import { ContactButton } from './Contact.style';
import messages from './messages';
import { RegisteredInfoContainer } from './style';

export default function Contact() {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const { track } = useTrack();
  const [showRegisteredInfo, setShowRegisteredInfo] = useState(false);

  const onChange = useCallback((data) => {
    setOpen(data);
  }, []);

  const onSelectChange = useCallback(
    (data) => {
      if (data.onClick) {
        data.onClick();
        return;
      }

      track('Navigation', {
        dom_id: 'contractUs',
        pos: 'footer',
        to: data.value,
      });
      const windowRef = window.open(data.value, '_blank');
      windowRef.focus();
      setTimeout(() => {
        if (!windowRef.document.hasFocus()) {
          windowRef.close();
        }
      }, 500);
    },
    [track],
  );

  const options = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.contactUs.menu.supportTeam),
        value: 'mailto:support@keyst.one',
      },
      {
        name: intl.formatMessage(messages.contactUs.menu.forCollabs),
        value: 'mailto:affiliate@keyst.one',
      },
      {
        name: intl.formatMessage(messages.contactUs.menu.raiseATicket),
        value:
          'https://keystonewallet.atlassian.net/servicedesk/customer/portal/1',
      },
    ],
    [intl],
  );

  return (
    <ContactButton target="_blank" popoverIsOpen={open}>
      <Popover
        placement="top"
        transition="slide bottom-10"
        trigger="click"
        onChange={onChange}
        content={(
          <Select
            value={1}
            options={options}
            onChange={onSelectChange}
            tooltip
          />
        )}
      >
        <Button size="small" icon={<Mail color="var(--color-bd-pink)" />}>
          <FormattedMessage {...messages.contactUs} />
          <ArrowRight className="right-arrow" />
        </Button>
      </Popover>
      <Modal
        open={showRegisteredInfo}
        centered
        closeIcon
        width={510}
        onClose={() => {
          setShowRegisteredInfo(false);
        }}
      >
        <RegisteredInfoContainer>
          <H4>Registered Info</H4>
          <Body as="p">Registered name：CYPHERVERSE LTD</Body>
          <Body as="p">Registered number：14447777</Body>
          <Body as="p">
            Address：Level 1, Devonshire House, 1 Mayfair Place, London,
            England, W1J 8AJ, UK
          </Body>
        </RegisteredInfoContainer>
      </Modal>
    </ContactButton>
  );
}
