import {
  getCurrentActiveFeatureNames,
  isActiveFeatureName,
} from '@paralleldrive/feature-toggles';
import { initialFeatures } from 'config';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function useFeatureToggle(name) {
  const location = useLocation();

  const currentFeatures = useMemo(
    () =>
      getCurrentActiveFeatureNames({
        initialFeatures,
        req: { query: location.search },
      }),
    [location],
  );

  const isEnabledFeature = useMemo(
    () => isActiveFeatureName(name, currentFeatures),
    [currentFeatures, name],
  );

  return isEnabledFeature;
}

export default useFeatureToggle;
