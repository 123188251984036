import { useMsg } from 'hooks/useMessages';

const timeKeys = 'keystone.date.complete';

export default function useMessages() {
  const timeMessagesGetter = useMsg(timeKeys);

  return {
    days: timeMessagesGetter('days'),
    hours: timeMessagesGetter('hours'),
    minutes: timeMessagesGetter('minutes'),
    seconds: timeMessagesGetter('seconds'),
  };
}
