import { useMsg } from 'hooks/useMessages';

export default function useMessages() {
  const rootGetter = useMsg('keystone');
  const msgGetter = useMsg('keystone.header.learn');
  return {
    blogLink: rootGetter('blog'),
    title: msgGetter('blog.title'),
    desc: msgGetter('blog.desc'),
    basicInfomation: msgGetter('blog.basicInfomation'),
  };
}
