import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { LINKS } from 'config/links';
import { TrackEvent, useTrack } from 'tracking';
import messages from '../messages';
import {
  EasterMageBanner,
  EasterMageBgGrid,
  EasterMageContainer,
  EasterMageContent,
  EasterMageFooter,
  EasterMageSection,
  EasterMageSectionButton,
  EasterMageSectionBuy,
  EasterMageSectionDesc,
  EasterMageSectionLi,
  EasterMageSectionTitle,
  EasterMageSectionUl,
} from './style';

export default function EasterMage({ onClose }) {
  const navigate = useNavigate();
  const { track } = useTrack();
  const toRouter = useCallback(
    ({ link, domId }) => {
      track(TrackEvent.Navigation, {
        to: link,
        dom_id: domId,
      });
      navigate(link);
      closeModal();
    },
    [closeModal, navigate, track],
  );

  const closeModal = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  return (
    <EasterMageContainer>
      <EasterMageContent>
        <EasterMageBanner src={require('./images/banner.png')} />
        <EasterMageSection>
          <EasterMageSectionTitle>
            <FormattedMessage
              {...messages.title}
              values={{
                em: (chunk) => <span>{chunk}</span>,
              }}
            />
          </EasterMageSectionTitle>
          <EasterMageSectionDesc>
            <FormattedMessage {...messages.desc} />
          </EasterMageSectionDesc>
          <EasterMageSectionUl>
            {['t1', 't2'].map((it) => (
              <EasterMageSectionLi key={it}>
                <FormattedMessage
                  {...messages[it]}
                  values={{
                    em: (chunk) => <span>{chunk}</span>,
                  }}
                />
              </EasterMageSectionLi>
            ))}
          </EasterMageSectionUl>
          <EasterMageSectionButton
            onClick={() =>
              toRouter({
                link: '/',
                domId: 'banner-modal-easter-mage-start',
              })}
          >
            <FormattedMessage {...messages.button} />
          </EasterMageSectionButton>
          <EasterMageSectionBuy
            to={LINKS.shop}
            onClick={() =>
              toRouter({
                link: '/shop',
                domId: 'banner-modal-easter-mage-buy',
              })}
          >
            <FormattedMessage {...messages.buy} />
          </EasterMageSectionBuy>
        </EasterMageSection>
      </EasterMageContent>
      <EasterMageFooter>
        <FormattedMessage {...messages.footer} />
      </EasterMageFooter>
      <EasterMageBgGrid src={require('./images/bg-grid.svg')} />
    </EasterMageContainer>
  );
}
