import React from 'react';
import Format from './Format';
import { IconTypes } from './types';

export default function CheckCircle({
  width = '18',
  height = '18',
  color = 'currentColor',
  ...args
}) {
  return (
    <Format {...args}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.5 1.5C5.36 1.5 2 4.86 2 9C2 13.14 5.36 16.5 9.5 16.5C13.64 16.5 17 13.14 17 9C17 4.86 13.64 1.5 9.5 1.5ZM9.5 15C6.1925 15 3.5 12.3075 3.5 9C3.5 5.6925 6.1925 3 9.5 3C12.8075 3 15.5 5.6925 15.5 9C15.5 12.3075 12.8075 15 9.5 15ZM12.9425 5.685L8 10.6275L6.0575 8.6925L5 9.75L8 12.75L14 6.75L12.9425 5.685Z"
          fill={color}
        />
      </svg>
    </Format>
  );
}

CheckCircle.propTypes = IconTypes;
