import { Caption } from 'components/styles/body';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import styled from 'styled-components';
import Toast from 'components/Toast';

export const ToastNotification = styled(Toast)`
  justify-content: center;
  top: calc(var(--header-computed-height) + 5px);
  transition: top var(--transition-duration);
  padding: 6px 16px;
  width: auto;
  gap: 0 8px;
  z-index: 1250;
  color: var(
    ${(props) =>
    ({
      warning: '--color-bd-oranger',
      error: '--color-bd-pink',
    }[props.type])}
  );
  .icon {
    color: var(
      ${(props) =>
    ({
      warning: '--color-bd-oranger',
      error: '--color-bd-pink',
    }[props.type])}
    );
  }
  @media ${DEVICE_QUERY_MOBILE} {
    width: 80% !important;
  }
`;

export const Desc = styled(Caption)`
  @media ${DEVICE_QUERY_MOBILE} {
    font-size: 12px;
    line-height: 16px;
  }
  max-width: 640px;
`;
