import React from 'react';
import Format from './Format';
import { IconTypes } from './types';

export default function ArrowUp({
  width = '24',
  height = '24',
  color = 'currentColor',
  ...args
}) {
  return (
    <Format {...args}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.5 15L12 9.5L17.5 15"
          stroke={color || '#3D71FF'}
          stroke-width="1.5"
        />
      </svg>
    </Format>
  );
}

ArrowUp.propTypes = IconTypes;
