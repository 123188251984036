import { EVENT_CATEGORY, EVENT_TYPE } from './type';
import { trackEvent } from './base';

export function trackCartCheckout(data) {
  trackEvent(EVENT_TYPE.BUTTON_CLICK, {
    event_category: EVENT_CATEGORY.Cart,
    event_label: 'checkout',
    event_value: data.url,
    cart_country: data.country,
  });
}
