import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { SENTRY_DSN } from 'config';

export function initSentry() {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],
  });
}
