import { useEffect, useState } from 'react';

function getTransitionName(transition) {
  const [anim, dir] = transition.split(' ');
  return [`${anim}-in-${dir}`, `${anim}-out-${dir}`];
}

export function useVisibleTransition({
  open = false,
  transition = 'slide bottom',
  containerRef,
  onClose,
  defaultVisible = false,
}) {
  const [visible, setVisible] = useState(defaultVisible);

  const [className, setClassName] = useState('');

  const [transAnimsClassName, setTransAnimsClassName] = useState([]);

  useEffect(() => {
    setTransAnimsClassName(getTransitionName(transition));
  }, [transition]);

  useEffect(() => {
    if (!Array.isArray(transAnimsClassName))
      return;

    const setClass = transAnimsClassName[open ? 0 : 1];

    setClassName(setClass);

    if (open) {
      setVisible(true);
    } else if (!defaultVisible) {
      if (!containerRef.current)
        return;
      const { animationDuration } = getComputedStyle(containerRef.current);
      const delay = Number.parseFloat(animationDuration, 10) * 1000;
      setTimeout(() => {
        setVisible(false);
        onClose && onClose();
      }, delay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return {
    visible,
    setVisible,
    className,
  };
}
