import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import Modal from 'components/Modal';

export const VideoBoxModal = styled(Modal)`
  @media ${DEVICE_QUERY_MOBILE} {
    & > div:first-child {
      width: 100vw !important;
      & > div:first-child {
        top: calc(100% + 20px);
        right: calc(50% - 20px);
        svg {
          fill: var(--color-fg-default);
        }
      }
    }
  }
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  border: none;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
`;
