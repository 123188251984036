import React, { lazy } from 'react';
import App from 'containers/App';
import Home from 'pages/Home';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { transferedVariantsProducts } from 'config/products';

const NotFound = lazy(() =>
  import(/* webpackChunkName: "NotFound" */ 'pages/NotFound'),
);
const WhyKeystone = lazy(() =>
  import(/* webpackChunkName: "WhyKeystone" */ 'pages/NewWhy'),
);
const Review = lazy(() =>
  import(/* webpackChunkName: "Review" */ 'pages/Why/Review'),
);
const Shop = lazy(() => import(/* webpackChunkName: "Shop" */ 'pages/Shop'));
const Details = lazy(() =>
  import(/* webpackChunkName: "ShopDetails" */ 'pages/Shop/Details'),
);
const CartPage = lazy(() =>
  import(/* webpackChunkName: "Cart" */ 'pages/Cart'),
);
const ShippingRegions = lazy(() =>
  import(/* webpackChunkName: "ShippingRegions" */ 'pages/ShippingRegions'),
);
const NFTGen2Landing = lazy(() =>
  import(/* webpackChunkName: "Landing" */ 'pages/Resources/NFTGen2/Landing'),
);
const NFTGen2List = lazy(() =>
  import(/* webpackChunkName: "NFTList" */ 'pages/Resources/NFTGen2/List'),
);
const NFTLanding = lazy(() =>
  import(/* webpackChunkName: "Landing" */ 'pages/Resources/NFT/Landing'),
);
const NFTList = lazy(() =>
  import(/* webpackChunkName: "NFTList" */ 'pages/Resources/NFT/List'),
);
const WebAuthenticationPage = lazy(() =>
  import(
    /* webpackChunkName: "WebAuthentication" */ 'pages/Resources/WebAuthentication'
  ),
);
const WebUsbUpgradePage = lazy(() =>
  import(/* webpackChunkName: "WebUsbUpgrade" */ 'pages/WebUsbUpgrade'),
);
const GetStartedWelcome = lazy(() =>
  import(
    /* webpackChunkName: "Welcome" */ 'pages/Resources/GetStarted/Welcome'
  ),
);
const GetStartedGen3Page = lazy(() =>
  import(
    /* webpackChunkName: "GetStartedGen3" */ 'pages/Resources/GetStartedGen3'
  ),
);
const FirmwareUpgrade = lazy(() =>
  import(/* webpackChunkName: "Firmware" */ 'pages/Resources/Firmware'),
);
const PrivacyPromises = lazy(() =>
  import(/* webpackChunkName: "PrivacyPromises" */ 'pages/PrivacyPromises'),
);
const LegalPage = lazy(() =>
  import(/* webpackChunkName: "Legal" */ 'pages/Legal'),
);
const BountyProgram = lazy(() =>
  import(
    /* webpackChunkName: "BountyProgram" */ 'pages/Partnership/BountyProgram'
  ),
);
const SupportedWalletsAndCoinsPage = lazy(() =>
  import(
    /* webpackChunkName: "SupportedWalletsAndCoins" */ 'pages/SupportedWalletsAndCoins'
  ),
);
const GetStartedStep = lazy(() =>
  import(
    /* webpackChunkName: "GetStarted" */ 'pages/Resources/GetStarted/Step'
  ),
);
const ResellersPage = lazy(() =>
  import(/* webpackChunkName: "Resellers" */ 'pages/Resellers'),
);
const AffiliatesPage = lazy(() =>
  import(/* webpackChunkName: "Affiliates" */ 'pages/Affiliates'),
);
const MetaMaskPage = lazy(() =>
  import(/* webpackChunkName: "MetaMask" */ 'pages/MetaMask'),
);
const OkxPage = lazy(() => import(/* webpackChunkName: "OKX" */ 'pages/Okx'));
const OkxBrandPage = lazy(() =>
  import(/* webpackChunkName: "OKXBrand" */ 'pages/OkxBrand'),
);
const CoBrandedPage = lazy(() =>
  import(/* webpackChunkName: "CoBranded" */ 'pages/CoBranded'),
);
const FromLedgerToKeystonePage = lazy(() =>
  import(
    /* webpackChunkName: "FromLedgerToKeystone" */ 'pages/FromLedgerToKeystone'
  ),
);
const ContactUsPage = lazy(() =>
  import(/* webpackChunkName: "ContactUsPage" */ './containers/ContactUsPage'),
);
const Redirect = lazy(() =>
  import(/* webpackChunkName: "Redirect" */ './containers/Redirect'),
);
const Roadmap = lazy(() =>
  import(/* webpackChunkName: "Roadmap" */ 'pages/Roadmap'),
);
const BtcOnly = lazy(() =>
  import(/* webpackChunkName: "BtcOnly" */ 'pages/BtcOnly'),
);
const BtcHalving = lazy(() =>
  import(/* webpackChunkName: "BTCHalving" */ 'pages/BTCHalving'),
);

const policyPagesUrl = [
  '/keystone-terms-of-use',
  '/privacy-policy',
  '/website-terms-of-use',
  '/sales-terms-and-conditions',
  '/cookie-policy',
  '/shipping-policy',
  '/refund-policy',
];

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const { createBrowserRouter } = require('react-router-dom/dist');

const products = transferedVariantsProducts().map((it) =>
  it.redirect
    ? {
        path: it.path,
        element: (
          <Navigate to={it.redirect + location.search} key={it.id} replace />
        ),
      }
    : {
        path: it.path,
        element: <Details key={it.id} />,
        sitemapIndex: true,
      },
);

const getStartSteps = Array.from({ length: 6 }).map((_, idx) => ({
  path: `/get-started/step-${idx + 1}`,
  element: <GetStartedStep step={idx} />,
}));

const policyPages = policyPagesUrl.map((url) => ({
  path: url,
  element: <LegalPage />,
}));

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    sitemapIndex: true,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: '/why-keystone',
        element: <WhyKeystone />,
        sitemapIndex: true,
      },
      {
        path: '/expert-reviews',
        element: <Review />,
        sitemapIndex: true,
      },
      {
        path: '/shop',
        element: <Shop />,
        sitemapIndex: true,
      },
      {
        path: '/shop/cart',
        element: <CartPage />,
        sitemapIndex: true,
      },
      {
        path: '/shipping-regions',
        element: <ShippingRegions />,
        sitemapIndex: true,
      },
      {
        path: '/authentication',
        element: <WebAuthenticationPage />,
        sitemapIndex: true,
      },
      {
        path: '/usb-upgrade',
        element: <WebUsbUpgradePage />,
        sitemapIndex: true,
      },
      {
        path: '/nft',
        element: <NFTLanding />,
        sitemapIndex: true,
      },
      {
        path: '/nfts',
        element: <NFTList />,
      },
      {
        path: '/nft-gen2',
        element: <NFTGen2Landing />,
        sitemapIndex: true,
      },
      {
        path: '/nfts-gen2',
        element: <NFTGen2List />,
      },
      {
        path: '/firmware',
        element: <FirmwareUpgrade />,
        sitemapIndex: true,
      },
      {
        path: '/privacy-promises',
        element: <PrivacyPromises />,
        sitemapIndex: true,
      },
      {
        path: '/get-started',
        element: <GetStartedGen3Page />,
        sitemapIndex: true,
      },
      {
        path: '/get-started-gen2',
        element: <GetStartedWelcome />,
        sitemapIndex: true,
      },
      {
        path: '/supported-wallets-and-assets',
        element: <SupportedWalletsAndCoinsPage />,
        sitemapIndex: true,
      },
      {
        path: '/privacy-promises',
        element: <PrivacyPromises />,
        sitemapIndex: true,
      },
      {
        path: '/bug-bounty-program',
        element: <BountyProgram />,
        sitemapIndex: true,
      },
      {
        path: '/resellers',
        element: <ResellersPage />,
        sitemapIndex: true,
      },
      {
        path: '/affiliates',
        element: <AffiliatesPage />,
        sitemapIndex: true,
      },
      {
        path: '/metamask',
        element: <MetaMaskPage />,
        sitemapIndex: true,
      },
      {
        path: '/okx',
        element: <OkxPage />,
        sitemapIndex: true,
      },
      {
        path: '/okx-collabs',
        element: <OkxBrandPage />,
        sitemapIndex: true,
      },
      {
        path: '/co-branded',
        element: <CoBrandedPage />,
        sitemapIndex: true,
      },
      {
        path: '/from-ledger-to-keystone',
        element: <FromLedgerToKeystonePage />,
        sitemapIndex: true,
      },
      {
        path: '/keystone-hardware-wallet-product-comparison',
        element: <Navigate to="/shop" replace />,
      },
      {
        path: '/contact-us',
        element: <ContactUsPage />,
      },
      {
        path: '/why4letters',
        element: <Redirect />,
      },
      {
        path: '/kt',
        element: <Redirect />,
      },
      {
        path: '/hardware-design',
        element: <Navigate to="/why-keystone" replace />,
      },
      {
        path: '/security-schemes',
        element: <Navigate to="/why-keystone" replace />,
      },
      {
        path: '/web3-compatible',
        element: <Navigate to="/why-keystone" replace />,
      },
      {
        path: '/eliminate-blind-signing',
        element: <Navigate to="/why-keystone" replace />,
      },
      {
        path: '/keystone-supported-crypto-assets',
        element: <Navigate to="supported-wallets-and-assets" replace />,
      },
      {
        path: '/companion-app',
        element: <Navigate to="/" replace />,
      },
      {
        path: '/why-choose-keystone-hardware-wallet',
        element: <Navigate to="/why-keystone" replace />,
      },
      {
        path: '/keystone-hardware-wallet-privacy-promises',
        element: <Navigate to="/privacy-promises" replace />,
      },
      {
        path: '/keystone-hardware-wallet-expert-reviews',
        element: <Navigate to="expert-reviews" replace />,
      },
      ...products,
      ...getStartSteps,
      ...policyPages,
      {
        path: '/roadmap',
        element: <Roadmap />,
        sitemapIndex: true,
      },
      {
        path: '/btc-only',
        element: <BtcOnly />,
        sitemapIndex: true,
      },
      {
        path: '/bitcoin-halving',
        element: <BtcHalving />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);

export default router;
