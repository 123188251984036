import { useEffect, useState } from 'react';
import dayjs from '../../../plugins/dayjs';

export const animDefaultConfig = {
  initial: {
    opacity: 0,
    y: 80,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
};

export function useBannerAnimation() {
  const [isShowNewsTip, setIsShowNewsTip] = useState(false);

  useEffect(() => {
    const TZ = 'Asia/Shanghai';
    const currTime = dayjs();
    const startTime = dayjs.tz('2024-10-08 08:00:00', TZ);
    const endTime = startTime.add(2, 'w');
    if (currTime.isBetween(startTime, endTime)) {
      setIsShowNewsTip(true);
    }
  }, []);

  return {
    isShowNewsTip,
  };
}
