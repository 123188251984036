/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const LOAD_REPOS = 'boilerplate/App/LOAD_REPOS';
export const LOAD_REPOS_SUCCESS = 'boilerplate/App/LOAD_REPOS_SUCCESS';
export const LOAD_REPOS_ERROR = 'boilerplate/App/LOAD_REPOS_ERROR';
export const SET_ACTIVITIES_START = 'activities/isStart';
export const SET_BLACK_FRIDAY_START = 'blackFriday/isStart';
export const SET_CHRISTMAS_START = 'christmas/isStart';
export const SET_CART_SHOW = 'cart/show';
export const SET_CART_QUANTITY = 'cart/totalQuantity';
export const SET_LOADING = 'cart/loading';
export const SET_CART_ID_GETTING = 'cart/id/getting';
export const SET_SHIPPING_REGIONS_CACHE = 'cart/shippingRegions/cache';
export const SET_SHIPPING_REGIONS_PRODUCT_MAP
  = 'cart/shippingRegions/productMap';
export const SET_SHIPPING_REGIONS_COUNTRY_LIST
  = 'cart/shippingRegions/countryList';
export const SET_SHIPPING_REGIONS_CURRENT_COUNTRY
  = 'cart/shippingRegions/currentCountry';
export const SET_SHIPPING_REGIONS_LANG = 'cart/shippingRegions/lang';
export const SET_BANNER_MODAL_OPEN = 'banner/modal/open';
export const SET_BANNER_MODAL_OPENED = 'banner/modal/opened';
export const SET_BANNER_MODAL_CODE = 'banner/modal/code';
export const SET_LANG = 'lang';
export const SET_CURRENCY = 'currency/update';
export const SET_CART_LIST = 'cart/list';
export const SET_CART_SUBTOTAL = 'cart/subTotal';
export const SET_CART_SEEL_BP_VARIANT_ID = 'cart/seelBp/variantId';
export const SET_CART_SEEL_BP_IS_UPDATING = 'cart/seelBp/isUpdating';
