const list = [
  {
    title: 'What is a Crypto Wallet',
    zhTitle: '什么是加密货币钱包',
    link: 'https://blog.keyst.one/understanding-crypto-wallets-a-guide-for-beginners',
  },
  {
    title: 'Why Air-Gapped',
    zhTitle: '为什么需要完全离网',
    link: 'https://blog.keyst.one/what-is-air-gapped-crypto-wallet-why-air-gapped',
  },
  {
    title: 'Why Open Source',
    zhTitle: '为什么说开源才是真安全',
    link: 'https://blog.keyst.one/the-role-of-open-source-tech-in-keystones-development-4dd77faea752',
  },
  {
    title: 'Our Journey',
    zhTitle: '我们的愿景',
    link: 'https://blog.keyst.one/keystones-journey-embracing-open-source-and-self-custody-in-the-web3-world-a-letter-from-our-77192ca8e107',
  },
];

export default function getBlogData() {
  return list;
}
