import React, { forwardRef, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import useIsMobile from 'hooks/useIsMobile';
import { LINKS } from 'config/links';
import { Container } from '../styles/layout';
import ArrowUp from '../icons/ArrowUp';
import {
  Categories,
  Category,
  CategoryTitle,
  Copyright,
  FooterBox,
  FooterLinkItem,
  Github,
  Link,
  LinkIcon,
  Logo,
  Main,
  MainLeft,
  Media,
  MediaList,
  Slogan,
  Top,
  UtilsArea,
  UtilsAreaLeft,
} from './style';
import messages from './messages';
import Contact from './Contact';

export const menuList = [
  [
    {
      type: 'title',
      title: 'Keystone',
      name: 'keystone',
      index: 1,
    },
    {
      id: 'BtcOnly',
      type: 'link',
      link: LINKS.btcOnly,
      linkUse: 'to',
      text: messages.btcOnly,
    },
    {
      id: 'Blog',
      type: 'link',
      target: '_blank',
      link: messages.blog.link,
      text: messages.blog,
    },
    {
      id: 'PrivacyPromises',
      type: 'link',
      linkUse: 'to',
      link: '/privacy-promises',
      text: messages.privacyPromises,
    },
    {
      id: 'Support',
      type: 'link',
      target: '_blank',
      link: messages.support.link,
      text: messages.support,
    },
    {
      id: 'Roadmap',
      type: 'link',
      linkUse: 'to',
      link: 'roadmap',
      text: messages.roadmap,
    },
    {
      id: 'SuggestWalletOrAssets',
      type: 'link',
      target: '_blank',
      link: LINKS.suggestWalletAssets,
      text: messages.suggestWalletOrAssets,
    },
    {
      id: 'nft',
      type: 'link',
      linkUse: 'to',
      link: '/nft',
      text: messages.nftLockscreen,
    },
    {
      id: 'GitHubRepo',
      type: 'link',
      target: '_blank',
      link: 'https://github.com/KeystoneHQ',
      text: messages.githubRepo,
      icon: <Github />,
    },
    {
      id: 'ENSDatabase',
      type: 'link',
      link: LINKS.ensDatabase,
      target: '_blank',
      text: messages.ensDatabase,
    },
    {
      id: 'ABIPack',
      type: 'link',
      link: LINKS.abiPack,
      target: '_blank',
      text: messages.abiPack,
    },
  ],
  [
    {
      type: 'title',
      name: 'legal',
      title: messages.legal,
      index: 5,
    },
    {
      id: 'SalesTermsAndConditons',
      type: 'link',
      linkUse: 'to',
      link: '/sales-terms-and-conditions',
      text: messages.termsAndConditions,
    },
    {
      id: 'WebsiteTermsofUse',
      type: 'link',
      linkUse: 'to',
      link: '/website-terms-of-use',
      text: messages.website,
    },
    {
      id: 'KeystoneTermsOfUse',
      type: 'link',
      linkUse: 'to',
      link: '/keystone-terms-of-use',
      text: messages.appTerms,
    },
    {
      id: 'PrivacyPolicy',
      type: 'link',
      linkUse: 'to',
      link: '/privacy-policy',
      text: messages.privacyPolicy,
    },
    {
      id: 'CookiePolicy',
      type: 'link',
      linkUse: 'to',
      link: '/cookie-policy',
      text: messages.cookiePolicy,
    },
    {
      id: 'ShippingPolicy',
      type: 'link',
      linkUse: 'to',
      link: '/shipping-policy',
      text: messages.shippingPolicy,
    },
    {
      id: 'RefundPolicy',
      type: 'link',
      linkUse: 'to',
      link: '/refund-policy',
      text: messages.refundPolicy,
    },
  ],
  [
    {
      type: 'title',
      name: 'partnership',
      title: messages.partnership,
      index: 10,
    },
    {
      id: 'Resellers',
      type: 'link',
      linkUse: 'to',
      link: '/resellers',
      text: messages.resellers,
    },
    {
      id: 'Affiliate',
      type: 'link',
      linkUse: 'to',
      link: '/affiliates',
      text: messages.affiliates,
    },
    {
      id: 'BountyProgram',
      type: 'link',
      linkUse: 'to',
      link: '/bug-bounty-program',
      text: messages.bountyProgram,
    },
    {
      id: 'MediaKit',
      type: 'link',
      target: '_blank',
      link: 'https://drive.google.com/drive/folders/1MqffY73AbWE3ItSE6s_YP8SD7k1Gbz8r',
      text: messages.mediaKit,
    },
    {
      id: 'MetaMask',
      type: 'link',
      link: LINKS.metamask,
      linkUse: 'to',
      text: messages.metamask,
    },
  ],
];

const desktopMenuOrder = ['keystone', 'legal', 'partnership'];
const mobileMenuOrder = ['keystone', 'partnership', 'legal'];

const Footer = forwardRef((_props, _ref) => {
  const intl = useIntl();
  const isZh = useMemo(() => intl.locale === 'zh', [intl.locale]);
  const isBtcOnly = useLocation().pathname.includes(LINKS.btcOnly);
  const twitterLink = isBtcOnly
    ? 'https://twitter.com/KeystoneForBTC'
    : isZh
      ? 'https://twitter.com/KeystoneCN'
      : 'https://twitter.com/KeystoneWallet';
  const icons = [
    {
      title: 'Discord',
      icon: require('./images/discord.png'),
      activeIcon: require('./images/discord_c.png'),
      link: 'https://keyst.one/discord',
    },
    {
      title: 'Twitter',
      icon: require('./images/x.png'),
      activeIcon: require('./images/x_c.png'),
      link: twitterLink,
    },
    {
      title: 'YouTube',
      icon: require('./images/youtube.png'),
      activeIcon: require('./images/youtube_c.png'),
      link: 'https://www.youtube.com/channel/UCaReIdawwYPPcyWGoNunF7g',
    },
    {
      title: 'Telegram',
      icon: require('./images/telegram.png'),
      activeIcon: require('./images/telegram_c.png'),
      link: isZh
        ? 'https://t.me/KeystoneWalletCN'
        : 'https://t.me/KeystoneWallet',
    },
    {
      title: 'Reddit',
      icon: require('./images/reddit.png'),
      activeIcon: require('./images/reddit_c.png'),
      link: 'https://www.reddit.com/r/KeystoneWallet/',
    },
    {
      title: 'Warpcast',
      icon: require('./images/warpcast.png'),
      activeIcon: require('./images/warpcast_c.png'),
      link: 'https://warpcast.com/keystonewallet',
    },
    {
      title: 'Primal',
      icon: require('./images/primal.png'),
      activeIcon: require('./images/primal_c.png'),
      link: 'https://primal.net/p/npub16s4cnphx2k7ndmlt9qmurkdrrulcammg3jxnz0f9c7dhepqg38zqpp4p24',
    },
    {
      title: 'Facebook',
      icon: require('./images/facebook.png'),
      activeIcon: require('./images/facebook_c.png'),
      link: 'https://www.facebook.com/people/Keystone-Wallet/61558817937104/',
    },
  ];

  const isMobile = useIsMobile();
  const menu = useMemo(() => {
    const orderByKeys = isMobile ? mobileMenuOrder : desktopMenuOrder;
    return orderByKeys.map((key) => menuList.find((it) => it[0].name === key));
  }, [isMobile]);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const medias = icons.map((e) => (
    <Media
      target="_blank"
      href={e.link}
      key={e.title}
      className={`media-${e.title}`}
      data-background-color="var(--color-bg-slight)"
      data-text-color="var(--color-fg-default)"
      data-mixpanel-id={e.title}
      data-mixpanel-pos="footer"
      data-mixpanel-to={e.link}
    >
      <img src={e.icon} className="default" alt={e.title} loading="lazy" />
      <img src={e.activeIcon} className="active" alt={e.title} loading="lazy" />
      <Tooltip anchorSelect={`.media-${e.title}`} content={e.title} />
    </Media>
  ));

  const categories = menu.map((it) =>
    it.map((col) => ({
      ...col,
      ...(col.title && col.title.id
        ? {
            title: intl.formatMessage(col.title),
          }
        : col.title),
      ...(col.link && col.link.id
        ? {
            link: intl.formatMessage(col.link),
          }
        : col.link),
      ...(col.text && {
        text: intl.formatMessage(col.text),
      }),
    })),
  );

  return (
    <FooterBox className="page-footer theme-dark">
      <Container>
        <Main>
          <MainLeft>
            <Logo src={require('./images/logo.png')} loading="lazy" />
            <Slogan>
              <FormattedMessage {...messages.slogan} />
            </Slogan>
            <Contact />
          </MainLeft>
          <nav>
            <Categories>
              {categories.map((col) => (
                <Category key={col[0].title}>
                  <CategoryTitle>{col[0].title}</CategoryTitle>
                  <ul>
                    {col.slice(1).map((row) => {
                      const linkUse = {
                        [!row.linkUse ? 'href' : row.linkUse]: row.link,
                      };
                      return (
                        <li
                          key={row.id}
                          style={{ listStyleType: 'none', marginBottom: 8 }}
                        >
                          <Link
                            {...linkUse}
                            data-mixpanel-id={row.id}
                            data-mixpanel-pos="footer"
                            data-mixpanel-to={row.link}
                            target={row.target}
                          >
                            {row.text}
                            {row.icon && row.icon}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </Category>
              ))}
            </Categories>
          </nav>
        </Main>
        <UtilsArea>
          <UtilsAreaLeft>
            <FooterLinkItem as={Link} to={LINKS.shippingRegions}>
              <LinkIcon src={require('./images/ship.svg')} />
              <FormattedMessage {...messages.shippingRegions} />
            </FooterLinkItem>
            <FooterLinkItem as={Link} href={LINKS.trackOrder} blank>
              <LinkIcon src={require('./images/orderTrack.svg')} />
              <FormattedMessage {...messages.orderTrack} />
            </FooterLinkItem>
          </UtilsAreaLeft>
          <MediaList>{medias}</MediaList>
        </UtilsArea>
        <Copyright>
          Keystone © All rights reserved 2018 -
          {' '}
          {new Date().getFullYear()}
        </Copyright>
      </Container>
      <Top className="active" onClick={scrollTop}>
        <ArrowUp />
      </Top>
    </FooterBox>
  );
});

Footer.displayName = 'Footer';

export default Footer;
