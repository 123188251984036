import { defineMessages } from 'react-intl';

export const scope = 'keystone.header';
export const dateFullScope = 'keystone.date.full';

export const dateFullMsgs = defineMessages({
  days: {
    id: `${dateFullScope}.day`,
    defaultMessage: '',
  },
  hours: {
    id: `${dateFullScope}.hours`,
    defaultMessage: '',
  },
  minutes: {
    id: `${dateFullScope}.minutes`,
    defaultMessage: '',
  },
  seconds: {
    id: `${dateFullScope}.seconds`,
    defaultMessage: '',
  },
});

export const productListMsgs = defineMessages({
  t1: {
    id: `${scope}.products.list.t1`,
    defaultMessage: '',
  },
  t2: {
    id: `${scope}.products.list.t2`,
    defaultMessage: '',
  },
  t3: {
    id: `${scope}.products.list.t3`,
    defaultMessage: '',
  },
  bundlePack: {
    id: `${scope}.products.list.bundlePack`,
    defaultMessage: '',
  },
  t4: {
    id: `${scope}.products.list.t4`,
    defaultMessage: '',
  },
  t5: {
    id: `${scope}.products.list.t5`,
    defaultMessage: '',
  },
  t6: {
    id: `${scope}.products.list.t6`,
    defaultMessage: '',
  },
  t7: {
    id: `${scope}.products.list.t7`,
    defaultMessage: '',
  },
  t8: {
    id: `${scope}.products.list.t8`,
    defaultMessage: '',
  },
  t9: {
    id: `${scope}.products.list.t9`,
    defaultMessage: '',
  },
  g3: {
    id: `${scope}.products.list.g3`,
    defaultMessage: '',
  },
  g3Pro: {
    id: `${scope}.products.list.g3Pro`,
    defaultMessage: '',
  },
  g3ProLimitedEdition: {
    id: `${scope}.products.list.g3ProLimitedEdition`,
    defaultMessage: '',
  },
  g3ProNFTCustomization: {
    id: `${scope}.products.list.g3ProNFTCustomization`,
    defaultMessage: '',
  },
});

export const mainMsgs = defineMessages({
  currentLanguage: {
    id: 'language',
    defaultMessage: '',
  },
  language: {
    id: `${scope}.language`,
    defaultMessage: '',
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: '',
  },
  partnerships: {
    id: `${scope}.partnerships`,
    defaultMessage: '',
  },
  learn: {
    id: `${scope}.learn`,
    defaultMessage: '',
  },
  supportedWalletsAndCoins: {
    id: `${scope}.supportedWalletsAndCoins`,
    defaultMessage: '',
  },
  partnershipsMetaMaskName: {
    id: `${scope}.partnerships.metaMask.name`,
    defaultMessage: '',
  },
  partnershipsMetaMaskDescription: {
    id: `${scope}.partnerships.metaMask.description`,
    defaultMessage: '',
  },
  partnershipsOKXName: {
    id: `${scope}.partnerships.OKX.name`,
    defaultMessage: '',
  },
  partnershipsOKXDescription: {
    id: `${scope}.partnerships.OKX.description`,
    defaultMessage: '',
  },
  partnershipsLink: {
    id: `${scope}.partnerships.link`,
    defaultMessage: '',
  },
  partnershipsStrategicPartner: {
    id: `${scope}.partnerships.strategicPartner`,
    defaultMessage: '',
  },
  partnershipsLimitedEditions: {
    id: `${scope}.partnerships.limitedEditions`,
    defaultMessage: '',
  },
  whyDesign: {
    id: `${scope}.whyKeystone.design`,
    defaultMessage: '',
  },
  whySecurity: {
    id: `${scope}.whyKeystone.security`,
    defaultMessage: '',
  },
  whyWeb3: {
    id: `${scope}.whyKeystone.web3`,
    defaultMessage: '',
  },
  whyBlindSign: {
    id: `${scope}.whyKeystone.blindSign`,
    defaultMessage: '',
  },
  whyReviews: {
    id: `${scope}.whyKeystone.reviews`,
    defaultMessage: '',
  },
  products: {
    id: `${scope}.products`,
    defaultMessage: '',
  },
  productsRecommend: {
    id: `${scope}.products.recommend`,
    defaultMessage: '',
  },
  productsHardware: {
    id: `${scope}.products.hardware`,
    defaultMessage: '',
  },
  productsAccessories: {
    id: `${scope}.products.accessories`,
    defaultMessage: '',
  },
  productsBundle: {
    id: `${scope}.products.bundle`,
    defaultMessage: '',
  },
  productsAll: {
    id: `${scope}.products.all`,
    defaultMessage: '',
  },
  walletsAndCoins: {
    id: `${scope}.walletsAndCoins`,
    defaultMessage: '',
  },
  coinsAndFeatures: {
    id: `${scope}.coinsAndFeatures`,
    defaultMessage: '',
  },
  resources: {
    id: `${scope}.resources`,
    defaultMessage: '',
  },
  resourcesStart: {
    id: `${scope}.resources.start`,
    defaultMessage: '',
  },
  resourcesDesc1: {
    id: `${scope}.resources.desc1`,
    defaultMessage: '',
  },
  resourcesDesc2: {
    id: `${scope}.resources.desc2`,
    defaultMessage: '',
  },
  resourcesUpgrade: {
    id: `${scope}.resources.upgrade`,
    defaultMessage: '',
  },
  resourcesRoadmap: {
    id: `${scope}.resources.roadmap`,
    defaultMessage: '',
  },
  resourcesNFT: {
    id: `${scope}.resources.NFT`,
    defaultMessage: '',
  },
  resourcesAuth: {
    id: `${scope}.resources.auth`,
    defaultMessage: '',
  },
  suggestAssets: {
    id: `${scope}.resources.suggestAssets`,
    defaultMessage: '',
  },
  resourcesFromLedgerToKeystone: {
    id: `${scope}.resources.fromLedgerToKeystone`,
    defaultMessage: '',
  },
  resourcesBlog: {
    id: `${scope}.resources.blog`,
    defaultMessage: '',
  },
  resourcesBlogLink: {
    id: 'keystone.blog',
    defaultMessage: '',
  },
  resourcesTutorials: {
    id: `${scope}.resources.tutorials`,
    defaultMessage: '',
  },
  resourcesTutorialsLink: {
    id: 'keystone.support',
    defaultMessage: '',
  },
  resourcesExpertReviews: {
    id: `${scope}.resources.expertReviews`,
    defaultMessage: '',
  },
  resourcesNFTLockscreen: {
    id: `${scope}.resources.nftLockscreen`,
    defaultMessage: '',
  },
  resourcesBtcOnly: {
    id: `${scope}.resources.btcOnly`,
    defaultMessage: '',
  },
  buy: {
    id: `${scope}.buy`,
    defaultMessage: '',
  },
  keystoneAppLink: {
    id: `${scope}.keystoneApp`,
    defaultMessage: '',
  },
  cartApplyCodeFailed: {
    id: `${scope}.cart.applyCode.failed.message`,
    defaultMessage: '',
  },
});

export const bannerExpiredMsgs = defineMessages({
  title: {
    id: `${scope}.banner.expired.title`,
    defaultMessage: '',
  },
  desc: {
    id: `${scope}.banner.expired.desc`,
    defaultMessage: '',
  },
});

export const bannerRfsnMsgs = defineMessages({
  title: {
    id: `${scope}.banner.rfsn.title`,
    defaultMessage: '',
  },
  mobileTitle: {
    id: `${scope}.banner.rfsn.mobile.title`,
    defaultMessage: '',
  },
  button: {
    id: `${scope}.banner.rfsn.button`,
    defaultMessage: '',
  },
});

export const bannerDiscountMsgs = defineMessages({
  title: {
    id: `${scope}.banner.discount.title`,
    defaultMessage: '',
  },
  useDiscount: {
    id: `${scope}.banner.discount.useDiscount`,
    defaultMessage: '',
  },
  allProducts: {
    id: `${scope}.banner.discount.allProducts`,
    defaultMessage: '',
  },
});
export const bannerBlackFridayMsgs = defineMessages({
  title: {
    id: 'keystone.campaignModal.modal.title',
    defaultMessage: '',
  },
});

export const bannerMsgs = defineMessages({
  desc: {
    id: `${scope}.banner.desc`,
    defaultMessage: '',
  },
  buy: {
    id: `${scope}.banner.buy`,
    defaultMessage: '',
  },
});

export const productStatusMsgs = defineMessages({
  hot: {
    id: 'keystone.hot',
    defaultMessage: '',
  },
  new: {
    id: 'keystone.new',
    defaultMessage: '',
  },
  comingSoon: {
    id: 'keystone.comingSoon',
    defaultMessage: '',
  },
  saleOff: {
    id: 'keystone.saleOff',
    defaultMessage: '',
  },
  dollarOff: {
    id: 'keystone.dollarOff',
    defaultMessage: '',
  },
  dollarSaved: {
    id: 'keystone.dollarSaved',
    defaultMessage: '',
  },
});
