/**
 *
 * Img.js
 *
 * Renders an image, enforcing the usage of the alt="" tag
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FlexCenter } from 'components/styles/flex';
import { motion } from 'framer-motion';
import { useImageLoaded } from 'hooks/useImageLoaded';

function Img({ src, alt = ' ', imgStyle = {}, ...args }) {
  const [ref, loaded, onLoad] = useImageLoaded();

  return (
    <FlexCenter
      {...args}
      style={{ position: 'absolute', width: '100%', height: '100%' }}
    >
      <motion.img
        ref={ref}
        src={src}
        style={{
          position: 'absolute',
          top: 0,
          opacity: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          ...imgStyle,
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: loaded ? 1 : 0 }}
        transition={{ duration: 1 }}
        alt={alt}
        onLoad={onLoad}
      />
    </FlexCenter>
  );
}

// We require the use of src and alt, only enforced by react in dev mode
Img.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Img;
