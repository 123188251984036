import React from 'react';
import Format from './Format';
import { IconTypes } from './types';

export default function ArrowDown({
  width = '18',
  height = '18',
  color = 'currentColor',
  ...args
}) {
  return (
    <Format {...args}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5 9.5L12 15L6.5 9.5"
          stroke={color || '#3D71FF'}
          stroke-width="1.5"
        />
      </svg>
    </Format>
  );
}

ArrowDown.propTypes = IconTypes;
