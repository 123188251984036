import styled from 'styled-components';
import { DEVICE_QUERY_MOBILE } from 'utils/constants';
import Check from '../../icons/Check';
import Drop from '../../icons/Drop';
import { Caption, CaptionStyle } from '../../styles/body';
import { FlexBetween, FlexCenter } from '../../styles/flex';

export const LangContainer = styled.div`
  position: relative;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 32px;
  transition: var(--transition);
  z-index: 1200;
  color: var(--color-fg-muted);
  .globe-icon svg {
    width: 24px;
    height: 24px;
    color: var(--color-fg-muted);
  }
  .right-arrow {
    color: var(--color-fg-subtle);
    margin-left: 2px;
    transform: rotate(${(props) => (props.popoverIsOpen ? 270 : 90)}deg);
    transition: all 0.25s;
  }
  :hover {
    background: var(--color-bg-slight);
    transition: var(--transition);
    .right-arrow {
      color: var(--color-bd-pink);
      transition: var(--transition);
    }
  }
  .popover-container {
    z-index: 1200;
  }
  .popover-card-container {
    --bg-color: var(--color-bg-subtle);
    background: var(--bg-color);
    overflow: initial;
    top: 35px;
    right: 0;
    padding: 16px 16px 8px;
    .list-item {
      padding: 6px 8px;
      color: var(--color-fg-default);
      ${CaptionStyle}
      :hover {
        background-color: var(--color-bg-muted);
      }
    }
    &::after {
      position: absolute;
      right: 25px;
      top: -8px;
      display: block;
      content: '';
      width: 0;
      height: 0;
      border: 6px solid var(--bg-color);
      border-top-width: 8px;
      border-bottom: none;
      border-left-color: transparent;
      border-right-color: transparent;
      margin-left: 25px;
      transform: rotate(180deg);
    }
  }
  @media ${DEVICE_QUERY_MOBILE} {
    width: auto;
    .globe-icon svg {
      width: 20px;
      height: 20px;
      color: var(--color-fg-muted);
    }
  }
`;

export const LangButtonContainer = styled.div`
  display: flex;
`;

export const Lang = styled(FlexCenter)`
  position: relative;
  font-size: 14px;
  height: 32px;
  color: var(--color-fg-muted);
  &.active {
    .language-value {
      color: var(--color-fg-emphasis);
    }
    .language-drop-icon {
      color: var(--color-bd-pink);
      transform: rotate(180deg);
    }
    .language-popup {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const Content = styled.div`
  height: 32px;
  line-height: 32px;
  cursor: pointer;
`;

export const Value = styled.span.attrs({
  className: 'language-value',
})`
  margin: 0 4px 0 8px;
  transition: var(--transition);
`;

export const DropIcon = styled(Drop).attrs({
  className: 'language-drop-icon',
})`
  display: inline-block;
  transition: var(--transition);
`;

export const Popup = styled.div.attrs({
  className: 'language-popup theme-light',
})`
  position: absolute;
  left: -16px;
  bottom: 32px;
  color: var(--color-fg-muted);
  transition: var(--transition);
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  z-index: 5;
  &::after {
    display: block;
    content: '';
    width: 0;
    height: 0;
    border: 6px solid var(--color-bg-muted);
    border-top-width: 8px;
    border-bottom: none;
    border-left-color: transparent;
    border-right-color: transparent;
    margin-left: 19px;
  }
  @media ${DEVICE_QUERY_MOBILE} {
    left: auto;
    &&::after {
      margin-left: 50%;
    }
  }
`;

export const List = styled(Caption)`
  padding: 16px;
  background: var(--color-bg-muted);
  border-radius: 4px;
`;

export const Item = styled(FlexBetween)`
  width: 128px;
  height: 30px;
  margin-bottom: 4px;
  padding: 0 8px;
  box-sizing: border-box;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  svg {
    opacity: 0;
  }
  &.active {
    svg {
      opacity: 1;
    }
  }
`;

export const CheckIcon = styled(Check)`
  line-height: 1;
  color: var(--color-bd-primary);
`;
